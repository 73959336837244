import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ToastrService } from 'ngx-toastr';
import { AttributeElement } from 'src/app/model/attribute-element';
import { Product } from 'src/app/model/product';
import { ProductAttribute } from 'src/app/model/product-attribute';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { IoService } from 'src/app/_services/io.service';
import { ProductService } from 'src/app/_services/product.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { CategoryPickerComponent } from '../../../components/category-picker/category-picker.component';
import { Category } from 'src/app/model/category';

// export interface Fruit {
//   name: string;
// }

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.css']
})
export class ProductAddComponent implements OnInit {
  @ViewChild('productAddForm') productAddForm: HTMLFormElement;
  // for add
  products = []
  product = new Product(this.appService.units.length > 0 ? this.appService.units[0].code : "");
  fileProductImg: File;
  imageUrl: any = environment.product_avatar;
  categorys: any
  category_selected = new Category(environment.cate_type);
  categorys_brand: any
  inputField: HTMLInputElement;// cho việc input
  isLoading = false;
  countType1Product = 0;
  // countProduct = 0;
  // for attribute
  attributeList = [];

  // scan code
  productCodeType = 'mts';
  codeScan = "";
  productCodeTemp = "";
  // for tags 
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  // fruits: Fruit[] = [{name: 'Lemon'}, {name: 'Lime'}, {name: 'Apple'}];

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log('Product document:keypress: ', event.key);
    if (this.productCodeType == 'qmv') {
      if (event.key === 'Enter') {  // enter from key broad
        console.log(this.codeScan);
        this.product.code = this.codeScan;
        this.codeScan = "";
      } else {
        if (this.codeScan === null) {
          this.codeScan = event.key;
        } else {
          this.codeScan = this.codeScan + event.key;
        }
      }
    }
  }

  constructor(
    public categoryService: CategoryService,
    public dialogRef: MatDialogRef<ProductAddComponent>,
    public appService: AppService,
    private toastr: ToastrService,
    public ioService: IoService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public productService: ProductService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    // dành cho chọn category
    this.category_selected.name = "Mặc định";

   
    this.countAllProduct();
    this.loadCategory();
    this.loadCategoryBrand();
    this.loadProduct_attributes();
    // khoi tao AttributeElement
    this.attributeList.push(new AttributeElement(''));
  }

  ngOnInit(): void {

  }

  openCategoryPicker() {
    // lấy những data đã được chọn

    const dialogRef = this.dialog.open(CategoryPickerComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '60%',
      autoFocus: false,
      // height: '98%',
      data: {'category_code': this.product.category_code}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.category_selected = result.data;
        this.product.category_code = this.category_selected.code;
      }
    });
  }
  product_types_change(item){
    this.product.type = item;
    if(this.product.type == 0){ // item tiger sau this.product.type nên bị ngược, 0
      this.product.unit = this.appService.units[0].code;
      this.product.unit2 = this.appService.units[0].code;
      this.product.packing = 1;
      this.productCodeTypeClick('mts');
    }else if(this.product.type == 1){// item tiger sau this.product.type nên bị ngược, 1
      this.product.unit = this.appService.units_weight_type[0].code;
      this.product.unit2 = this.appService.units_weight_type[1].code;
      this.product.packing = 1000;
      this.productCodeTypeClick('tn');
    }
    console.log(item);
    console.log(this.product.type);
    
  }
  changeToUnitInWeightType(){
    if(this.product.unit == 'kg'){
      //đơn vị 2 luôn luôn g
      this.product.packing = 1000;
    }else if(this.product.unit == 'g'){
      //đơn vị 2 luôn luôn g
      this.product.packing = 1;
    }
  }
  printer() {
    console.log(this.attributeList);
    this.prepareProducts();
    console.log(this.products);
  }
  addAttribute() {
    this.attributeList.push(new AttributeElement(''));
  }
  removeAttribute(i) {
    this.attributeList.splice(i, 1);
  }
  countAllProduct() {
    this.productService.countAllProduct().subscribe(
      response => {
        this.countType1Product = response.CountType1Product;
         //kiểm tra đầu vào khi tạo product
        if(typeof this.data.code != "undefined" && this.data.code != ''){
          this.productCodeType = 'qmv';
          this.product.code = this.data.code;
          this.productCodeTemp = this.data.code;
        }else{          
          this.product.code = environment.PRODUCT_PREFIX + (parseInt(response.CountAllProduct) + 1).toString();
          this.productCodeTemp = environment.PRODUCT_PREFIX + (parseInt(response.CountAllProduct) + 1).toString();
        }
        // this.countProduct = response.CountAllProduct;
      },
      error => {
        console.error(error);
      })
  }

  prepareProducts() {
    //check mảng thuộc tính
    let count_valid =0;
    this.attributeList.forEach(element => {
      if(element.name != '' && element.valueList.length >0)
      count_valid++;
    });
    if(this.attributeList.length == 0 || count_valid != this.attributeList.length)
    return;
    //check mảng thuộc tính

    this.products = [];
    this.attributeList.forEach((item, i) => {
      if (1 == this.attributeList.length) { // mảng chỉ có 1 phần tử
        item.valueList.forEach((item2, j) => {
          // thêm sản phẩm vào danh sách
          let temp = new Product(this.appService.units.length > 0 ? this.appService.units[0].code : "");
          temp.code = this.product.code + "-" + (j+1);
          temp.code_parent = this.product.code;
          temp.name = this.product.name + " - " + item2;
          temp.name_child = item2;
          temp.price = this.product.price;
          temp.price2 = this.product.price2;
          this.products.push(temp);
        });
      } else if (2 == this.attributeList.length) {// mảng chỉ có 2 phần tử
        item.valueList.forEach((item2, j) => {
          // thêm sản phẩm vào danh sách
          if(typeof this.attributeList[i+1] !== 'undefined'){
          this.attributeList[i+1].valueList.forEach((item23, k) => {
            // thêm sản phẩm vào danh sách
            let temp = new Product(this.appService.units.length > 0 ? this.appService.units[0].code : "");
            temp.code = this.product.code + "-" + (j+1) + "-" + (k+1);
            temp.code_parent = this.product.code;
            temp.name = this.product.name + " - " + item2+ " - " + item23;
            temp.name_child = item2+ " - " + item23;
            temp.price = this.product.price;
            temp.price2 = this.product.price2;
            this.products.push(temp);
          });
        }
        });
      }else if (3 == this.attributeList.length) { // mảng chỉ có 3 phần tử
        item.valueList.forEach((item2, j) => {
          if(typeof this.attributeList[i+2] !== 'undefined'){
          this.attributeList[i+1].valueList.forEach((item23, k) => {
            this.attributeList[i+2].valueList.forEach((item33, l) => {
              // thêm sản phẩm vào danh sách
              let temp = new Product(this.appService.units.length > 0 ? this.appService.units[0].code : "");
              temp.code = this.product.code + "-" + (j+1) + "-" + (k+1)+ "-" + (l+1);
              temp.code_parent = this.product.code;
              temp.name = this.product.name + " - " + item2 + " - " + item23+ " - " + item33;
              temp.name_child = item2 + " - " + item23+ " - " + item33;
              temp.price = this.product.price;
              temp.price2 = this.product.price2;
              this.products.push(temp);
            });
          });
        }
        });
      }

    });
  }
  removeFromProducts(i) {
    this.products.splice(i, 1);
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  focusOutCheckCode(code) {
    if(code.trim() == ""){
      return;
    }
    let filter = {
      code: code,
    }
    console.log(filter);
    this.productService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if (response.code == 0) {
            this.productAddForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code", "Lỗi");
          }
          else{
            this.prepareProducts();
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }
  insertProduct() {

    if (this.product.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
      this.productAddForm.controls.name.setErrors("Bạn chưa nhập tên");
      return;
    } else if (this.product.category_code.trim() == "") {
      this.toastr.error("", "Bạn chưa chọn danh mục hàng hóa");
      return;
    }

    if (isNaN(this.product.packing)) {
      this.toastr.error("Vui lòng nhập số", "Quy cách không hợp lệ");
      return;
    }

    if(this.product.packing == 0 || this.product.packing == 1){
      this.product.packing = 1
      if (this.product.unit == this.product.unit2) {
        this.product.unit2 = "";
      }
    }else{
      if (this.product.unit == this.product.unit2) {
        this.toastr.error("Đơn vị không được giống nhau", "Quy cách không hợp lệ");
        return;
      }
    }

    

    this.product.unit_default = this.product.unit;


    if (this.product.price == null) {
      this.product.price = 0;
    }
    if (this.product.price_in == null) {
      this.product.price_in = 0;
    }

    if (this.fileProductImg !== null && typeof this.fileProductImg != 'undefined') {
      this.product.image_url = environment.product + "/" + this.product.code + "." + this.utility.getExtensionFile(this.fileProductImg);
    }

    if(this.products.length > 0){ // có thuộc tính sản phẩm
      this.product.deleted = 1;
    }
    // Kiểm tra tên sản phẩm không được phép có ký tự !
    if(this.product.name.indexOf("!") > -1){
      this.toastr.error("Tên sản phẩm không được có ký tự !", "Tên không hợp lệ");
      return;
    }

    this.product.category_code = this.category_selected.code;
    
    // Xử lý kích thức Code
    if(this.product.type == 1){ // hàng hóa cân nặng
      if(this.product.code.length > 5){ // lỗi
        this.toastr.error("", "Lỗi định dạng mã sản phẩm. Độ dài quy định 5");
        this.productAddForm.controls.code.setErrors("Độ dài quy định 5");
        return;
      }else{
        // Xử lý thêm ký tự 0 để đủ 5 ký tự
        this.product.code = this.utility.padWithZeros(this.product.code,5);
      }

    }


    if (!this.productAddForm.invalid) {
      this.isLoading = true;
      this.productService.insert(this.product).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            // Thêm mới hàng hoá thuộc tính.
            if(this.product.deleted == 1){ // san pham co thuoc tinh
              this.insertProducts(); // 
            }else{
              let data = { 
                code: 'ok',
                msg: response.affectedRows,
                product : this.product
              }
              this.dialogRef.close(data);
            }

          } else {
            this.toastr.error("", "Mã sản phẩm đã tồn tại hoặc Có lỗi xảy ra.");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("", "Mã sản phẩm đã tồn tại hoặc Có lỗi xảy ra.");
        })
      this.uploadAvatar(this.fileProductImg, this.product.code);
    }
  }
  focusFunction(event) {
    event.target.select();
  }
  insertProducts() {
    // kiểm tra dữ liệu
    this.products.forEach(element => {
      if(element.code.trim() != '' && element.name.trim() != '' ){
        element.price_in = this.product.price_in;
        element.unit = this.product.unit;
        element.packing = this.product.packing;
        element.unit2 = this.product.unit2;
        element.stock = this.product.stock;
        element.category_code = this.product.category_code;
        element.category_code_brand = this.product.category_code_brand;
        element.description = this.product.description;
      }else{
        this.toastr.warning("","Vui lòng nhập đủ dữ liệu");
        return;
      }
    });
    console.log(this.products);
    this.productService.insertMulti(this.products).subscribe(
      async response => {
        if(response.code = 'ok' && response.affectedRows > 0){
          // đóng popup và trả kết quả
          this.dialogRef.close({code:'ok',msg: response.affectedRows});
        }
      },
      error => {
        console.log(error);
      })
  }

  attributeChange(attribute, $event) {
    //check đã tồn tại thuộc tính chưa
    let isExist = false;
    this.attributeList.forEach(element => {
      if (element.name == $event.target.value) {
        isExist = true;
      }
    });

    if (isExist) {// đã tồn tại
      this.toastr.error('', 'Thuộc tính đã tồn tại');
      $event.target.value = '';
      attribute.name = '';
    } else {
      attribute.name = $event.target.value;
    }

  }
  add(attribute, event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (attribute.name == '') {
      this.toastr.warning('', 'Bạn chưa chọn thuộc tính');
    } else {
      if(attribute.valueList.length >=5){
        this.toastr.warning('', 'Bạn đã nhập tối đa giá trị');
      }
      else if(attribute.valueList.indexOf(value) >= 0){ // check trùng
        this.toastr.warning('', 'Giá trị đã tồn tại');
      }
      else if (value) {
        attribute.valueList.push(value);
        this.prepareProducts();
      }
      event.input.value = '';
    }

  }
  remove(attribute, item: any): void {
    const index = attribute.valueList.indexOf(item);

    if (index >= 0) {
      attribute.valueList.splice(index, 1);
      this.prepareProducts();
    }
  }
  FromProducts(attribute, item: any): void {
    const index = attribute.valueList.indexOf(item);

    if (index >= 0) {
      attribute.valueList.splice(index, 1);
    }
  }

  async avatarChangeListener($event): Promise<void> {
    this.fileProductImg = $event.target.files[0];
    if (this.fileProductImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileProductImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileProductImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileProductImg, 300, 300).subscribe(result => {
        this.fileProductImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileProductImg);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof (fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: environment.product,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  loadProduct_attributes() {
    if (typeof (this.appService.currentUser.product_attributes) === 'undefined') {
      this.appService.currentUser.product_attributes = '[]';
    }

    this.appService.product_attributes = JSON.parse(this.appService.currentUser.product_attributes);

    if (this.appService.product_attributes == null || this.appService.product_attributes.length == 0) {
      this.appService.product_attributes = [];
      this.appService.product_attributes.push(new ProductAttribute({ id: 1 }))
    }
    console.log(this.appService.product_attributes);
  }
  productCodeTypeClick(type) {
    this.productCodeType = type;
    if(this.product.type == 1){
      this.product.code = (this.countType1Product + 1).toString();
    }else if(this.product.type == 0){
      if (this.productCodeType == "mts") {
        this.product.code = this.productCodeTemp;
      } else if (this.productCodeType == "tn" || this.productCodeType == "qmv") {
        this.product.code = "";
      }
    }
    // kiểm tra hợp lệ
    this.focusOutCheckCode(this.product.code);

  }
  async loadCategory() {
    let filter = {
      type: environment.cate_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  async loadCategoryBrand() {
    let filter = {
      type: environment.brand_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys_brand = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  onCancelClick() {
    this.dialogRef.close();
  }
}
