<div id="print-section" style="display: none" *ngIf="this.appService.printSizeSelected === 'k80'">
    <div style=" font-family: Tahoma; font-size: 14px;">
        <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
        <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
        <div style="text-align: center;">{{appService.currentUser.shop_address}}</div>
        <table style="width: 100%;">
            <tr>
                <td style="text-align: left;font-weight: bold;">
                    {{appService.cartObj.name}}
                </td>
                <td style="text-align: right;">
                    {{dateTimeStr}}
                </td>
            </tr>
        </table>
        <table style="font-size: 13px; width: 100%;">
            <tr style="text-align: center; ">
                <td style="text-align: left;">Khách hàng: </td>
                <td style="text-align: right;">{{appService.cartObj.customer_name}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Tổng hóa đơn: </td>
                <td style="text-align: right;">{{appService.cartObj.price_totol | qbscurrencyheader}}</td>
            </tr>
            <tr style="text-align: center; ">
                <td style="text-align: left;">Khuyến mãi: {{appService.cartObj.promotion_promotion}}{{appService.cartObj.promotion_type}} </td>
                <td style="text-align: right;">{{appService.cartObj.price_promotion | qbscurrencyheader}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Tổng thanh toán: </td>
                <td style="text-align: right;"> {{appService.cartObj.price_after_promotion | qbscurrencyheader}} </td>
            </tr>
            <tr style="text-align: center;  ">
                <td style="text-align: left;">Đã thanh toán: </td>
                <td style="text-align: right;"> {{appService.cartObj.payment_total | qbscurrencyheader}} </td>
            </tr>
            <tr style="text-align: center;  ">
                <td style="text-align: left;">Khách trả: </td>
                <td style="text-align: right;"> {{appService.cartObj.customer_pay | qbscurrencyheader}} </td>
            </tr>
            <tr style="text-align: center; ">
                <td style="text-align: left;">Tiền thừa: </td>
                <td style="text-align: right;"> {{appService.cartObj.customer_pay - appService.cartObj.price_after_promotion | qbscurrencyheader}} </td>
            </tr>
        </table>
        <!-- Chi tiết sử dụng dịch vụ -->
        <table *ngIf="appService.cartObj.is_time_service == 1" style="width: 100%; padding-top: 10px; font-size: 12px; ">
            <tr *ngFor="let item of appService.cartObj.cart_service; let i = index">
                <td style="border-top: 1px dashed gray; padding: 5px 0px;">
                    <div> Tiền giờ: {{item.price | qbscurrencyheader}} x {{item.quantityLable}}</div>
                    <div> {{item.serviceStartTime | date :'HH:mm' }} -> {{item.serviceEndTime | date :'HH:mm' }}</div>
                </td>
                <td style="text-align: right; vertical-align: middle; border-top: 1px dashed gray; padding: 5px 0px;">
                    {{item.amount | qbscurrencyheader}}
                </td>
            </tr>
        </table>

        <!-- END Chi tiết sử dụng dịch vụ -->
        <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
            <table style="font-size: 12px; width: 100%;">
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;">Tên</td>
                    <td style="text-align: right;vertical-align: middle;">ĐG</td>
                    <td style="text-align: center; min-width: 40px; vertical-align: middle;">SL</td>
                    <td style="text-align: right; vertical-align: middle;">T.Tiền</td>
                </tr>
                <!-- giá dịch vụ -->
                <tr *ngIf="appService.cartObj.is_time_service == 1" class="item-row-service">

                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;" colspan="3">Giá dịch vụ</td>
                    <td style="text-align: right; vertical-align: middle;">{{appService.cartObj.service_price | qbscurrencyheader}}
                    </td>
                </tr>
                <!-- Hết giá dịch vụ -->

                <div *ngFor="let item of appService.cartObj.cart; let i = index">
                    <tr>
                        <td style="vertical-align: middle;">{{i+1}}.</td>
                        <td style="vertical-align: middle;" colspan="4">{{item.product.name}}</td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"></td>
                        <td style="vertical-align: middle;"></td>
                        <td style="text-align: right; vertical-align: middle;">{{item.product.price| qbscurrencyheader}}
                        </td>
                        <td style="text-align: left; vertical-align: middle;">x {{item.quantity}}<span style="font-size: 9px;"> {{item.product.unit_default}}</span></td>
                        <td style="text-align: right; vertical-align: middle;">{{ this.utilityService.roundToTwo(item.quantity * item.product.price) | qbscurrencyheader:0}}
                        </td>
                    </tr>
                </div>


                <!-- <tr *ngFor="let item of appService.cartObj.cart; let i = index">
                    <td style="vertical-align: middle;">{{i+1}}</td>
                    <td style="vertical-align: middle;">{{item.product.name}}</td>
                    <td style="text-align: right; vertical-align: middle;">{{item.product.price| qbscurrencyheader}}
                    </td>
                    <td style="text-align: left; vertical-align: middle;">x {{item.quantity}}<span style="font-size: 9px;"> {{item.product.unit_default}}</span></td>
                    <td style="text-align: right; vertical-align: middle;">{{this.utilityService.roundToTwo(item.quantity * item.product.price) | qbscurrencyheader:0}}
                    </td>
                </tr> -->

                <tr style="font-weight: bold;">
                    <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
                    <!-- <td>{{appService.cartObj.total_quantity}}</td> -->
                    <td style="border-top: 1px dashed gray;padding-top: 5px;">{{appService.cartObj.price_totol | qbscurrencyheader}}
                    </td>
                </tr>
            </table>
        </div>
        <div style="text-align: center;">Cảm ơn quý khách, hẹn gặp lại</div>
        <div style="text-align: center; margin-bottom: 10; font-size: 7;">QBS solution</div>
    </div>
</div>
<div id="print-section" style="display: none" *ngIf="this.appService.printSizeSelected === 'k58'">
    <div style=" font-family: Tahoma; font-size: 12px;">
        <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
        <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
        <div style="text-align: center;">{{appService.currentUser.shop_address}}</div>
        <table style="width: 100%;">
            <tr>
                <td style="text-align: left;font-weight: bold;">
                    {{appService.cartObj.name}}
                </td>
                <td style="text-align: right;">
                    {{dateTimeStr}}
                </td>
            </tr>
        </table>
        <table style="font-size: 12px; width: 100%;">
            <tr style="text-align: center;">
                <td style="text-align: left;">Khách hàng: </td>
                <td style="text-align: right;">{{appService.cartObj.customer_name}}</td>
            </tr>
            <tr style="text-align: center;  ">
                <td style="text-align: left;">Tổng hóa đơn: </td>
                <td style="text-align: right;">{{appService.cartObj.price_totol | qbscurrencyheader}}</td>
            </tr>
            <tr style="text-align: center;">
                <td style="text-align: left;">Khuyến mãi: {{appService.cartObj.promotion_promotion}}{{appService.cartObj.promotion_type}} </td>
                <td style="text-align: right;">{{appService.cartObj.price_promotion | qbscurrencyheader}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Tổng TT: </td>
                <td style="text-align: right;"> {{appService.cartObj.price_after_promotion | qbscurrencyheader}} </td>
            </tr>
            <!-- <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Đã thanh toán: </td>
                <td style="text-align: right;"> {{appService.cartObj.payment_total | qbscurrencyheader}} </td>
            </tr> -->
            <tr style="text-align: center; ">
                <td style="text-align: left;">Khách trả: </td>
                <td style="text-align: right;"> {{appService.cartObj.customer_pay | qbscurrencyheader}} </td>
            </tr>
            <tr style="text-align: center;">
                <td style="text-align: left;">Tiền thừa: </td>
                <td style="text-align: right;"> {{appService.cartObj.customer_pay - appService.cartObj.price_after_promotion | qbscurrencyheader}} </td>
            </tr>
        </table>
        <!-- Chi tiết sử dụng dịch vụ -->
        <table *ngIf="appService.cartObj.is_time_service == 1" style="width: 100%; padding-top: 10px; font-size: 12px; ">
            <tr *ngFor="let item of appService.cartObj.cart_service; let i = index">
                <td style="border-top: 1px dashed gray; padding: 5px 0px;">
                    <div> Tiền giờ: {{item.price | qbscurrencyheader}} x {{item.quantityLable}}</div>
                    <div> {{item.serviceStartTime | date :'HH:mm' }} -> {{item.serviceEndTime | date :'HH:mm' }}</div>
                </td>
                <td style="text-align: right; vertical-align: middle; border-top: 1px dashed gray; padding: 5px 0px;">
                    {{item.amount | qbscurrencyheader}}
                </td>
            </tr>
        </table>

        <!-- END Chi tiết sử dụng dịch vụ -->
        <div style="border-top: 1px dashed black; border-bottom: 1px dashed gray; margin: 10px 0px;">
            <table style="font-size: 12px; width: 100%;">
                <!-- <tr style="text-align: center; font-weight: bold; ">
                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;">Tên</td>
                    <td style="text-align: right;vertical-align: middle;">ĐG</td>
                    <td style="text-align: center; min-width: 40px; vertical-align: middle;">SL</td>
                    <td style="text-align: right; vertical-align: middle;">T.Tiền</td>
                </tr> -->
                <!-- giá dịch vụ -->
                <tr *ngIf="appService.cartObj.is_time_service == 1" class="item-row-service">

                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;" colspan="3">Giá dịch vụ</td>
                    <td style="text-align: right; vertical-align: middle;">{{appService.cartObj.service_price | qbscurrencyheader}}
                    </td>

                </tr>
                <!-- Hết giá dịch vụ -->
                <div *ngFor="let item of appService.cartObj.cart; let i = index">
                    <tr>
                        <td style="vertical-align: middle;">{{i+1}}.</td>
                        <td style="vertical-align: middle;" colspan="4">{{item.product.name}}</td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"></td>
                        <td style="vertical-align: middle;"></td>
                        <td style="text-align: right; vertical-align: middle;">{{item.product.price| qbscurrencyheader}}
                        </td>
                        <td style="text-align: left; vertical-align: middle;">x {{item.quantity}}<span style="font-size: 9px;"> {{item.product.unit_default}}</span></td>
                        <td style="text-align: right; vertical-align: middle;">{{ this.utilityService.roundToTwo(item.quantity * item.product.price) | qbscurrencyheader:0}}
                        </td>
                    </tr>
                </div>

                <tr style="font-weight: bold;">
                    <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
                    <!-- <td>{{appService.cartObj.total_quantity}}</td> -->
                    <td style="border-top: 1px dashed gray;padding-top: 5px;">{{appService.cartObj.price_totol | qbscurrencyheader}}
                    </td>
                </tr>
            </table>
        </div>
        <div style="text-align: center;">Cảm ơn quý khách, hẹn gặp lại.</div>
        <div style="text-align: center; margin-bottom: 10; font-size: 7;">Provided by QBS solution</div>
    </div>
</div>