import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-revenue-product2-card-list',
  templateUrl: './revenue-product2-card-list.component.html',
  styleUrls: ['./revenue-product2-card-list.component.css']
})
export class RevenueProduct2CardListComponent implements OnInit {

  @Input("datas") categories: any[] = [];
  @Input("datasSum") datasSum: any;
  @Input("unitSelected") unitSelected: string;
  @Output("click2") clickEvent = new EventEmitter<any>();
  constructor(
    public utilityService : UtilityService
  ) { 
    console.log(1+ -1);
    console.log("selected:::: ",this.unitSelected);
  }

  ngOnInit(): void {

  }
  select_card(code) {
    // console.log(code);
    this.clickEvent.emit(code);
  }

}
