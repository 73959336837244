<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col-7 p-0">
            <div class="d-flex justify-content-start">
                <div class=" pr-2 mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                    <span *ngIf="searching" class="input-icon input-icon-right font-16"><i
                            class="ti ti-reload"></i></span>
                    <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i
                            class="ti ti-search"></i></span>
                    <input id="typeahead-template" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm sản phẩm" [resultTemplate]="rt" [inputFormatter]="formatter">
                </div>
                <ng-template #rt let-r="result" let-t="term">
                    <div class="row" (click)="onClick(r)" style="width: 450px;">
                        <div class="img-product-search ml-1" [style.background-image]="this.appService.showImageProduct(r['image_url'])"></div>
                        <div class="col"> #{{ r['code'] }}</div>
                        <div class="col">
                            <ngb-highlight [result]="r.name" [term]="t">
                            </ngb-highlight>
                        </div>
                        <div class="col">Kho: {{ r['stock'] == null ? 0: r['stock'] }}</div>
                        <div class="col">DM: {{ r['category_name'] }}</div>
                        <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
                    </div>
                </ng-template>
                <div class="pr-2">
                    <button [ngClass]="{'favorite-on': filter.is_favorite == 'f'}" class="btn btn-outline-secondary" style="height: 29.11px; padding: 0px;" (click)="toggle_favorite()">
                        <span class="btn-icon"><span class="material-icons">star </span></span>
                    </button>
                </div>
                <div class="pr-2">
                    <button [ngClass]="{'favorite-on': filter.type == '1'}" class="btn btn-outline-secondary" style="height: 29.11px; padding: 0px;" (click)="toggle_product_type()">
                        <span class="btn-icon"><span class="material-icons">scale </span></span>
                    </button>
                </div>
                <div class="pr-2">
                    <select class="custom-select" style="width: 110px;" [(ngModel)]="filter.category_code" (change)="category_change($event)" name="category" id="category">
                        <option value="">Danh mục</option>
                        <option [ngValue]="item.code" *ngFor="let item of categories">{{item.name}} ({{item.products}})</option>
                    </select>
                </div>
                <div class="pr-2">
                    <select class="custom-select" [(ngModel)]="filter.category_code_brand" (change)="category_change($event)" name="category" id="category">
                        <option value="">Thương hiệu</option>
                        <option [ngValue]="item.code" *ngFor="let item of categorys_brand">{{item.name}}</option>
                    </select>
                </div>
                <div class="pr-2">
                    <select style="width: 100px;" class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-5  p-0">
            <div class="col d-flex justify-content-end align-items-center">
                <div class="pr-2">
                    <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="openProductAdd()">
                        <span class="btn-label"><span class="material-icons">
                            add
                            </span></span>Thêm mới
                    </button>
                </div>
                <div class="pr-2">
                    <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="openProductAdd2()">
                        <span class="btn-label"><span class="material-icons">
                            playlist_add
                            </span></span>Thêm nhiều
                    </button>
                </div>
                <div class="">

                    <div ngbDropdown class="d-inline-block">
                        <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                            <span class="btn-label"><i class="ti ti-more-alt"></i></span>Thêm
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="openCategoryChangePicker()">
                                <span class="material-icons">group_work</span>
                                <span class="ml-2">Chuyển nhóm</span>
                            </button>
                            <button ngbDropdownItem (click)="popup_product_import()">
                                <span class="material-icons">import_export</span>
                                Nhập Excel</button>
                            <button ngbDropdownItem (click)="popup_product_export()">
                                <span class="material-icons">import_export</span>
                                Tải Excel</button>
                            <button ngbDropdownItem (click)="popup_product_export_cantem()">
                                <span class="material-icons">import_export</span>
                                Tải Excel vào Cân tem</button>
                            <!-- <button ngbDropdownItem>Something else is here</button> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <app-categorylist class="desktop" [datas]="this.categories" [selected]="this.filter.category_code" (click)="onClick_selectCate($event)"></app-categorylist>

    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4  p-0 ">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8  p-0 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="table-responsive display_type_1">
        <table class="table table-head-purple table-hover " id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 36px; text-align: center;">#</th>
                    <th class="sorting" (click)="doSorting('name')">Tên
                        <span>
                            <span *ngIf="sorter.sortStr != 'name ASC' && sorter.sortStr != 'name DESC'" class="material-icons sort">sort</span>
                        <span *ngIf="sorter.sortStr == 'name ASC'" class="material-icons">north</span>
                        <span *ngIf="sorter.sortStr == 'name DESC'" class="material-icons">south</span>
                        </span>
                    </th>
                    <th>Danh mục</th>
                    <th class="text-right sorting" (click)="doSorting('stock')">Tồn kho
                        <span>
                            <span *ngIf="sorter.sortStr != 'stock ASC' && sorter.sortStr != 'stock DESC'" class="material-icons sort">sort</span>
                        <span *ngIf="sorter.sortStr == 'stock ASC'" class="material-icons">north</span>
                        <span *ngIf="sorter.sortStr == 'stock DESC'" class="material-icons">south</span>
                        </span>
                    </th>
                    <th>Đơn vị</th>
                    <th>Quy cách</th>
                    <th class="text-right sorting" (click)="doSorting('price')">Giá lẻ
                        <span>
                            <span *ngIf="sorter.sortStr != 'price ASC' && sorter.sortStr != 'price DESC'" class="material-icons sort">sort</span>
                        <span *ngIf="sorter.sortStr == 'price ASC'" class="material-icons">north</span>
                        <span *ngIf="sorter.sortStr == 'price DESC'" class="material-icons">south</span>
                        </span>
                    </th>
                    <th class="text-right">Giá buôn</th>
                    <th class="action"></th>
                    <th style="width: 45px;">
                        <label class="checkbox myHeader checkbox-warning check-single">
                                <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                                <span class="input-span"></span>
                        </label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of appService.products; let i = index">
                    <td style=" text-align: center; padding: 1px;">
                        <a class="avatar-img-list" style="width: 30px;  height: 30px;" href="javascript:;" [style.background-image]="appService.showImageProduct(item.image_url)"></a>
                    </td>
                    <td style="position: relative">
                        <div style="font-size: 18px;">{{item.name}}</div>
                        <div [ngClass]="{'text-danger': item.stock < 1}" style="font-size: 13px; font-weight: bold;">
                            {{item.code}}
                        </div>

                        <a class="favorite_icon" (click)="save_toggle_favorite(item)" [ngClass]="{'favorite-on': item.is_favorite=='f'}"><span class="material-icons"> star </span></a>
                    </td>
                    <td>{{item.category_name}}</td>
                    <td class="text-right"><span [ngClass]="{'text-danger': item.stock < 1}">{{item.stock}}</span></td>
                    <td>{{item.unit}}</td>
                    <td>{{item.packing +" "+item.unit2}}</td>
                    <td class="text-right">{{item.price| qbscurrency:0}}</td>
                    <td class="text-right">{{item.price2| qbscurrency:0}}</td>

                    <td class="action">

                        <button class="btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick" (click)='openUpdate(item)'>
                            <i class="ti ti-pencil-alt"></i>
                        </button>

                    </td>
                    <td class="text-center">
                        <label class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox"  [(ngModel)]="item.checkbox">
                                <span class="input-span"></span>
                            </label>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>







<div class="modal fade" id="import-product-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-product">
            <div class="modal-header p-4">
                <h5 class="modal-title">Nhập hàng hóa từ file Excel</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div *ngIf="!import_result.result">
                    <div class="form-group mb-4">
                        <label class="btn btn-primary  mr-2" style="width: 100%;">
                            <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                            <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                (change)="changeListener($event)">
                        </label>
                    </div>
                    <div class="form-group mb-4">
                        <a style="color: blue;" href="{{template_file_url}}"> <i class="la la-download"></i>Tải mẫu nhập Excel
                        </a>
                    </div>
                    <div class="form-group mb-4">
                        <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                        </div>
                    </div>
                </div>
                <div *ngIf="import_result.result">
                    <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                    <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                </div>
            </div>
            <div class="modal-footer justify-content-center bg-primary-50">
                <div class="row align-items-center">
                    <div class="col text-center">
                        <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="upload()" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-upload"></i></span>
                            Import</button>
                        <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>