import { environment } from "src/environments/environment";
import { Cart } from "./cart";

export class ProductAttribute {
    id: number = 0;
    name: string = "";
    constructor({id}) {
        this.id = id
    }

}
