<div id="print-section-on-order" style="display: none;" *ngIf="this.appService.printSizeSelected === 'k80' && data !== 'undefined' ">
    <div style=" font-family: Tahoma; font-size: 14px;">
        <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
        <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
        <div style="text-align: center;">{{appService.currentUser.shop_address}}</div>
        <div style="text-align: right">{{data.created_date.substring(11, 16)}} {{data.dateStr}}</div>
        <table style="font-size: 13px; width: 100%;">
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Mã đơn: </td>
                <td style="text-align: right;">{{data.code}}</td>
            </tr>
            <tr style="text-align: center; ">
                <td style="text-align: left;">Khách hàng: </td>
                <td style="text-align: right;">{{data.customer_name}}</td>
            </tr>
            <tr style="text-align: center; ">
                <td style="text-align: left;">Tổng hóa đơn: </td>
                <td style="text-align: right;">{{data.price_totol | qbscurrency}}</td>
            </tr>
            <tr style="text-align: center;  ">
                <td style="text-align: left;">Khuyến mãi:
                </td>
                <td style="text-align: right;">
                    {{data.promotion_promotion}}{{data.promotion_type}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Tổng thanh toán: </td>
                <td style="text-align: right;"> {{data.price_after_promotion | qbscurrency}} </td>
            </tr>
            <!-- <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Đã thanh toán: </td>
                <td style="text-align: right;"> {{data.payment_total | qbscurrency}} </td>
            </tr> -->
            <tr style="text-align: center; ">
                <td style="text-align: left;">Khách đưa:</td>
                <td style="text-align: right;"> {{data.customer_pay | qbscurrency}} </td>
            </tr>
            <tr style="text-align: center;  ">
                <td style="text-align: left;">Tiền thừa:</td>
                <td style="text-align: right;"> {{data.customer_pay - data.price_after_promotion | qbscurrency}} </td>
            </tr>
        </table>
        <!-- Chi tiết sử dụng dịch vụ -->
        <table *ngIf="data.is_time_service == 1" style="width: 100%; padding-top: 10px; font-size: 12px; ">
            <tr *ngFor="let item of data.service_details; let i = index">
                <td style="border-top: 1px dashed gray; padding: 5px 0px;">
                    <div> Tiền giờ: {{item.price | qbscurrency}} x {{item.quantityLable}}</div>
                    <div> {{item.serviceStartTime | date :'HH:mm' }} -> {{item.serviceEndTime | date :'HH:mm' }}</div>
                </td>
                <td style="text-align: right; vertical-align: middle; border-top: 1px dashed gray; padding: 5px 0px;">
                    {{item.amount | qbscurrency}}
                </td>
            </tr>
        </table>
        <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
            <table style="font-size: 12px; width: 100%;">
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;">Tên</td>
                    <td style="text-align: right;vertical-align: middle;">ĐG</td>
                    <td style="text-align: center; min-width: 40px; vertical-align: middle;">SL</td>
                    <td style="text-align: right; vertical-align: middle;">T.Tiền</td>
                </tr>
                <!-- giá dịch vụ -->
                <tr *ngIf="data.is_time_service == 1" class="item-row-service">

                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;" colspan="3">Giá dịch vụ</td>
                    <td style="text-align: right; vertical-align: middle;">{{data.service_price | qbscurrency}}
                    </td>
                </tr>
                <!-- Hết giá dịch vụ -->

                <tr *ngFor="let item of cartDetail; let i = index">

                    <td style="vertical-align: middle;">{{i+1}}</td>
                    <td style="vertical-align: middle;">{{item.product_name}}</td>
                    <td style="text-align: right;; vertical-align: middle;">{{item.price| qbscurrency}}</td>
                    <td style="text-align: left;; vertical-align: middle;">
                        x {{item.quantity}}
                        <span style="font-size: 9px;">{{item.unit}}</span>
                    </td>
                    <!-- <td style="text-align: left;; vertical-align: middle;">x {{item.quantity}}<span style="font-size: 9px;">
          {{item.product.unit}}</span></td> -->
                    <td style="text-align: right;; vertical-align: middle;">{{item.quantity * item.price | qbscurrency:0}}
                    </td>

                </tr>
                <tr style="font-weight: bold;">
                    <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
                    <!-- <td>{{appService.cartObj.total_quantity}}</td> -->
                    <td style="border-top: 1px dashed gray;padding-top: 5px;">{{data.price_totol | qbscurrency}}
                    </td>
                </tr>
            </table>
        </div>
        <div style="text-align: center;">Cảm ơn quý khách.</div>
        <div style="text-align: center; margin-bottom: 10; font-size: 8;">GIẢI PHÁP SỐ QBS</div>
    </div>
</div>
<div id="print-section-on-order" style="display: none;" *ngIf="this.appService.printSizeSelected === 'k58' && data !== 'undefined' ">
    <div style=" font-family: Tahoma; font-size: 12px;">
        <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
        <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
        <div style="text-align: center;">{{appService.currentUser.shop_address}}..</div>
        <div style="text-align: right">{{data.created_date.substring(11, 16)}} {{data.dateStr}}</div>
        <table style="font-size: 11px; width: 100%;">
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Mã đơn: </td>
                <td style="text-align: right;">{{data.code}}</td>
            </tr>
            <tr style="text-align: center; ">
                <td style="text-align: left;">Khách hàng: </td>
                <td style="text-align: right;">{{data.customer_name}}</td>
            </tr>
            <tr style="text-align: center;  ">
                <td style="text-align: left;">Tổng hóa đơn: </td>
                <td style="text-align: right;">{{data.price_totol | qbscurrency}}</td>
            </tr>
            <tr style="text-align: center; ">
                <td style="text-align: left;">Khuyến mãi:
                </td>
                <td style="text-align: right;">
                    {{data.promotion_promotion}}{{data.promotion_type}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Tổng thanh toán: </td>
                <td style="text-align: right;"> {{data.price_after_promotion | qbscurrency}} </td>
            </tr>
            <tr style="text-align: center;  ">
                <td style="text-align: left;">Khách đưa:</td>
                <td style="text-align: right;"> {{data.customer_pay | qbscurrency}} </td>
            </tr>
            <tr style="text-align: center;  ">
                <td style="text-align: left;">Tiền thừa:</td>
                <td style="text-align: right;"> {{data.customer_pay - data.price_after_promotion | qbscurrency}} </td>
            </tr>
        </table>
        <!-- Chi tiết sử dụng dịch vụ -->
        <table *ngIf="data.is_time_service == 1" style="width: 100%; padding-top: 10px; font-size: 12px; ">
            <tr *ngFor="let item of data.service_details; let i = index">
                <td style="border-top: 1px dashed gray; padding: 5px 0px;">
                    <div> Tiền giờ: {{item.price | qbscurrency}} x {{item.quantityLable}}</div>
                    <div> {{item.serviceStartTime | date :'HH:mm' }} -> {{item.serviceEndTime | date :'HH:mm' }}</div>
                </td>
                <td style="text-align: right; vertical-align: middle; border-top: 1px dashed gray; padding: 5px 0px;">
                    {{item.amount | qbscurrency}}
                </td>
            </tr>
        </table>
        <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
            <table style="font-size: 12px; width: 100%;">
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;">Tên</td>
                    <td style="text-align: right;vertical-align: middle;">ĐG</td>
                    <td style="text-align: center; min-width: 40px; vertical-align: middle;">SL</td>
                    <td style="text-align: right; vertical-align: middle;">T.Tiền</td>
                </tr>
                <!-- giá dịch vụ -->
                <tr *ngIf="data.is_time_service == 1" class="item-row-service">

                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;" colspan="3">Giá dịch vụ</td>
                    <td style="text-align: right; vertical-align: middle;">{{data.service_price | qbscurrency}}
                    </td>
                </tr>
                <!-- Hết giá dịch vụ -->
                <div *ngFor="let item of cartDetail; let i = index">
                    <tr>
                        <td style="vertical-align: middle;">{{i+1}}</td>
                        <td style="vertical-align: middle;" colspan="4">{{item.product_name}}</td>
                    </tr>
                    <tr>
                        <td style="vertical-align: middle;"></td>
                        <td style="vertical-align: middle;"></td>
                        <td style="text-align: right;; vertical-align: middle;">{{item.price| qbscurrency}}</td>
                        <td style="text-align: left;; vertical-align: middle;">
                            x {{item.quantity}}
                            <span style="font-size: 9px;">{{item.unit}}</span>
                        </td>
                        <td style="text-align: right;; vertical-align: middle;">{{item.quantity * item.price | qbscurrency:0}}
                        </td>
                    </tr>
                </div>

                <tr style="font-weight: bold;">
                    <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
                    <!-- <td>{{appService.cartObj.total_quantity}}</td> -->
                    <td style="border-top: 1px dashed gray;padding-top: 5px;">{{data.price_totol | qbscurrency}}
                    </td>
                </tr>
            </table>
        </div>
        <div style="text-align: center;">Cảm ơn quý khách.</div>
        <div style="text-align: center; margin-bottom: 10; font-size: 8;">GIẢI PHÁP SỐ QBS</div>
    </div>
</div>