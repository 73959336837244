import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartObj } from 'src/app/model/cart-obj';
import { AppService } from 'src/app/_services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.css']
})
export class DeleteConfirmComponent implements OnInit {
  saletype = ''
  constructor(
    public appService: AppService,
    public dialogRef: MatDialogRef<DeleteConfirmComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    this.saletype = data;
  }

  ngOnInit(): void {
  }
  deleteCart() {
    console.log(this.saletype);
    if (this.saletype == 'servicedesk') {

      let tempp = new CartObj();
      tempp.position = { x: this.appService.deskList[this.appService.deskIndex].x, y: this.appService.deskList[this.appService.deskIndex].y };
      tempp.x = this.appService.deskList[this.appService.deskIndex].x;
      tempp.y = this.appService.deskList[this.appService.deskIndex].y;
      tempp.id = this.appService.deskList[this.appService.deskIndex].id;
      tempp.name = this.appService.deskList[this.appService.deskIndex].name;
      tempp.shop_code = this.appService.deskList[this.appService.deskIndex].shop_code;
      tempp.is_time_service = this.appService.deskList[this.appService.deskIndex].is_time_service;

      this.appService.deskList[this.appService.deskIndex] = tempp;
      localStorage.setItem(environment.deskIndex, this.appService.deskIndex.toString());
      localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
    } else if (this.saletype == 'sale') {
      if (this.appService.cartIndex > 0) {
        this.appService.listCart[this.appService.cartIndex] = new CartObj(); //delete cart
      } else {
        this.appService.cartIndex = 0;
        this.appService.listCart[this.appService.cartIndex] = new CartObj(); //delete cart
      }
      
      // gán giỏ hàng hiện tại
      this.appService.cartObj = this.appService.listCart[this.appService.cartIndex];
      // gán lại tên customer trong sale cart v2
      this.appService.customer_name_temp = '';

      localStorage.setItem(environment.cartIndex, this.appService.cartIndex.toString());
      localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
    }
    this.appService.updateDeskRevenue();


    this.dialogRef.close('ok');
  }
  cancelDeleteCart() {
    this.dialogRef.close('no');
  }
}
