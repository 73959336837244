import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { DebtService } from 'src/app/_services/debt.service';
import { environment } from 'src/environments/environment';
import { SaleDetailComponent } from '../sale-detail/sale-detail.component';

@Component({
  selector: 'app-debt-customer-detail',
  templateUrl: './debt-customer-detail.component.html',
  styleUrls: ['./debt-customer-detail.component.css']
})
export class DebtCustomerDetailComponent implements OnInit {

  customer_pay = 0;
  step = 0; // 0 thanh toán, 1 xác nhận
  sale_ids = "";; // lưu id thanh toán debt
  sale_debt_pays = "";; // lưu giá trị thanh toán debt
  index_pays = -1; // số hóa đơn được thanh toán

  sale_debt_pays_temp = []; // mảng sô gạch nợ của từng hóa đơn
  //filter status
  selectedStatusSale = environment.SALE_STATUS_DONE;
  dateStrStart: string;
  dateStrEnd: string;
  userNvbhSelected = "";
  code_filter = "";
  display_type = 0;

  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }
  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for detail
  SaleDebtDatas: any = [];
  constructor(
    public appService: AppService,
    public debtService: DebtService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DebtCustomerDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log("this.data:");
    console.log(this.data);
    // this.customer_pay = this.data.debt;
    this.loadSalesDebt(this.data.customer_code);
  }

  ngOnInit(): void {

  }
  focusoutCustomer_pay(event) {
    this.step = 0;
  }
  loadSalesDebt(customer_code) {
    let filter = {
      "type": environment.SALES_TYPE,
      "status": this.selectedStatusSale,
      "customer_code": customer_code, // Chỉ cần customer ID
      "day_num": 0, // số ngày bao cáo, 0 : tất cả
      "fromDate": this.data.dateStrStart,
      "toDate": this.data.dateStrEnd,
      "code": this.code_filter,
      "user_id": this.userNvbhSelected,
    };
    let pagingObj = {
      "viewNumsObj": this.viewNumsObj,
      "pageObj": this.curentPage
    };

    let data = { "filter": filter, "sorter": this.sorter, "pagingObj": pagingObj };

    this.debtService.getSaleDebt(data).subscribe(
      async response => {
        if (response) {
          this.SaleDebtDatas = response.data;
          console.log(response);
        }
      },
      error => {
        console.error(error);
      });
  }

  processDebtSale() {
    if (this.customer_pay == 0) {
      this.toastr.warning("Bạn chưa chọn hóa đơn hoặc chưa nhập số tiền thanh toán");
      return;
    }
    this.sale_ids = "";
    this.sale_debt_pays = "";
    let payment_total_rest_temp = this.customer_pay;
    this.sale_debt_pays_temp = [];
    // this.index_pays = this.SaleDebtDatas.length - 1;
    for (var i = this.SaleDebtDatas.length - 1; i >= 0; i--) {
      if (typeof (this.SaleDebtDatas[i].selected) !== 'undefined' && this.SaleDebtDatas[i].selected) { // chỉ những đơn được chọn
        var temp = (this.SaleDebtDatas[i]['price_after_promotion'] - this.SaleDebtDatas[i]['payment_total']); //số nợ của đơn hàng này
        console.log("temp", temp);
        if (payment_total_rest_temp > temp) {
          this.sale_debt_pays_temp[i] = temp;
          payment_total_rest_temp -= temp;

          this.sale_ids += this.SaleDebtDatas[i]['id'].toString() + ",";
          this.sale_debt_pays += this.sale_debt_pays_temp[i].toString() + ",";

        } else {
          this.sale_debt_pays_temp[i] = payment_total_rest_temp;
          payment_total_rest_temp = 0;
          this.index_pays = i;

          this.sale_ids += this.SaleDebtDatas[i]['id'].toString();
          this.sale_debt_pays += this.sale_debt_pays_temp[i].toString();

          break;
        }
      }

    }

    console.log(this.index_pays);
    console.log(this.sale_ids);
    console.log(this.sale_debt_pays);

    this.step = 1;
  }
  doDebtSale() {

    let data = {
      "sale_ids": this.sale_ids,
      "sale_debt_pays": this.sale_debt_pays,
    };
    this.debtService.updateSaleDebts(data).subscribe(
      async response => {
        if (response.code == "ok" && response.affectedRows > 0) {
          this.step = 0;
          this.toastr.success("", "Thanh toán thành công");

          let result = {
            code: "ok",
          }

          this.dialogRef.close(result);
        }
      },
      error => {
        console.error(error);
      });
  }
  focusFunction(event) {
    event.target.select();
  }
  clickItem(item, i) {
    if (typeof (item.selected) !== 'undefined' && item.selected) {
      item.selected = false;
    } else {
      item.selected = true;
    }
    console.log(item);
    this.updatePrice();
  }
  updatePrice() {
    this.customer_pay = 0;
    this.SaleDebtDatas.forEach(item => {
      if (typeof (item.selected) !== 'undefined' && item.selected) {
        this.customer_pay += item.price_after_promotion - item.payment_total
      }
    });
    this.processDebtSale();
  }

  openSaleDetail(item, i) {

    // xử lý dữ liệu  service_details từ String để hiển thị
    try {
      item.service_details = JSON.parse(item.service_detail_str);
    } catch (error) {
      item.service_details = [];
      console.error(error);
    }
    item.status = -111; //gán để không sử dụng status
    const dialogRef = this.dialog.open(SaleDetailComponent, {
      width: '100%',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Đã có thanh toán nợ", result);
    });
  }

}
