<div class="overlay"></div>
<div class="row login-content">
    <div class="col-sm-6 bg-white">
        <div class="text-center">
            <span class="auth-head-icon"><i class="ti ti-user"></i></span>
        </div>
        <div class="ibox m-0" style="box-shadow: none;">
            <form class="ibox-body" id="loginForm">
                <h3 class="font-strong text-center mb-5">Đăng ký</h3>
                <div class="form-group mb-2">
                    <input [(ngModel)]="user.shop_code" (ngModelChange)="user.shop_code = $event.toUpperCase()" (keydown.space)="$event.preventDefault();" class="form-control form-control-air" type="text" name="shop_code" placeholder="Mã Shop">
                </div>
                <div class="form-group mb-2">
                    <input [(ngModel)]="user.phone" class="form-control form-control-air" type="text" name="Phone" placeholder="Phone">
                </div>
                <div class="form-group mb-2">
                    <input [(ngModel)]="user.email" class="form-control form-control-air" type="text" name="email" placeholder="Email">
                </div>
                <div class="form-group mb-2">
                    <input [(ngModel)]="user.address" class="form-control form-control-air" type="text" name="address" placeholder="Địa chỉ">
                </div>
                <div class="form-group mb-2">
                    <input [(ngModel)]="user.password" class="form-control form-control-air" type="password" name="password" placeholder="Mật khẩu">
                </div>
                <div class="form-group mb-2">
                    <input [(ngModel)]="user.confirm_password" class="form-control form-control-air" type="password" name="password" placeholder="Nhập lại mật khẩu">
                </div>

                <div class="flexbox mb-5 mt-2">
                    <!-- <label class="checkbox checkbox-inline checkbox-primary">
                        <input type="checkbox" checked="true">
                        <span class="input-span"></span>Nhớ đăng nhập</label> -->
                    <a class="text-primary" routerLink="/login">Đăng nhập</a>
                </div>
                <div class="text-center">
                    <button (click)="register()" class="btn btn-primary  btn-air" [disabled]="isLoading">
                        <span class="btn-label" [ngClass]="{'spinner-border': isLoading}"><span class="material-icons">
                            app_registration
                            </span></span>
                        Đăng ký
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="col-sm-6 d-inline-flex align-items-center text-white py-4 px-5">
        <div>
            <div class="h2 mb-4">Phần mềm quản lý bán hàng QBSs</div>
            <div class="mb-4 pt-3">
                <button class="btn btn-outline btn-icon-only btn-circle mr-3"><a href="https://www.facebook.com/giaiphapsoqbs"><i class="ti ti-facebook"></i></a></button>
                <!-- <button class="btn btn-outline btn-icon-only btn-circle mr-3"><a href="https://thietbisoqb.com"><i class="ti ti-facebook"></i></a></button>  -->
                <!-- <button class="btn btn-outline btn-icon-only btn-circle mr-3"><i class="fa fa-pinterest-p"></i></button> -->
            </div>
            <p>Phát triển dựa theo nhu cầu của khách hàng.<br>Công cụ sáng tạo và quản lý bán hàng</p>
            <p>CTY TNHH Giải pháp Số QBS</p>
            <!-- <div class="flexbox-b mb-3"><i class="ti-check mr-3 text-success"></i>Lorem Ipsum dolar set.</div> -->
            <!-- <div class="flexbox-b mb-3"><i class="ti-check mr-3 text-success"></i>Lorem Ipsum dolar set.</div> -->
            <!-- <div class="flexbox-b mb-5"><i class="ti-check mr-3 text-success"></i>Lorem Ipsum dolar set.</div> -->
            <!-- <button class="btn btn-outline btn-rounded btn-fix">Register</button> -->
        </div>
    </div>
</div>