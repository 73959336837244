// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: "https://api.giaiphapsoqbs.com:3000",
  // apiUrl: "http://localhost:3000",
  // apiSocket: "http://localhost:4000",
  apiEndpoint: "/v1/",
  apiEndpoint_upload: "/upload/",
  currentUser: "curerntfsds",
  prinSize: "prinsizedsdff",
  unitSelected: "unitSelected",
  listCart: "listCartdss",
  deskList: "deskListdsrr",
  deskListPrice: "deskListPricessrr",
  cartIndex: "cartIndexfgfg",
  deskIndex: "deskIndexlge",
  importCart: "imapdortCsrtdss",
  qrcodePrintCart: "qrcodePrintCartdsdsdsd",
  inventoryCart: "inventoryCarttCsrtdsss",
  display_type: "display_typexfgfg",
  PRODUCT_PREFIX: "SP",
  CATEGORY_CODE_PREFIX: "DM",
  BRAND_CODE_PREFIX: "BRAND",
  BRANCH_CODE_PREFIX: "BRANCH",
  CUSTOMER_CODE_PREFIX: "KH",
  CUSTOMER_NAME_PREFIX: "Khách lẻ",
  CUSTOMER_CATE_CODE_PREFIX: "NKH",
  CUSTOMER_CATE_NAME_PREFIX: "Thường",
  unit_report: "unit", //don vi mac dinh cho report
  product_avatar: "./assets/img/picture-product.svg",
  customer_avatar: "./assets/img/person.svg",
  DAY_REPORT_DEFAULT: 15,
  MONTH_REPORT_DEFAULT: 12,
  DAY_NUM_DEFAULT: 7,
  SALE_STATUS_ALL: -1, // tất cả đơn hàng
  SALE_STATUS_DONE: 1, // hoàn thành đơn hàng
  SALE_STATUS_ORDERING: 0, // đặt hàng đơn hàng
  SALE_STATUS_DELETED: 3, // đã hủy đơn hàng
  SALE_STATUS_TAKING: 4, // đang xử lý

  SALES_TYPE : 0,
  SALES_IMPORT_TYPE : 1,
  SALES_INVENTORY_TYPE : 2,

  mau_nhap_hang_hoa: "io/downloadProductTemplate",
  
  product: "product",
  branch_type: "branch",
  brand_type: "brand",
  customer_type: "customer",
  supplier_type: "supplier",

  NKH_CATEGORY_CODE_PREFIX : 'NKH',
  DEFAULT_CUS_CATEGORY : {
    'name': "Khách lẻ",
    'code': 'NKH'
  },
  DEFAULT_SUPPLIER_CATEGORY : {
    'name': "Mặc định",
    'code': 'NNCC'
  },

  cate_type: "cate",
  firebaseConfig: {
    apiKey: "YOUR_API_KEY",
    authDomain: "YOUR_AUTH_DOMAIN",
    databaseURL: "YOUR_DATABASE_URL",
    projectId: "YOUR_PROJECT_ID",
    storageBucket: "YOUR_STORAGE_BUCKET",
    messagingSenderId: "YOUR_SENDER_ID",
    appId: "<YOUR_APP_ID>"
  }
};
