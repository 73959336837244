<div class="d-flex justify-content-between align-items-center">
    <div class="">
        <div class="text-primary font-20">Doanh thu sản phẩm từ
            <span style="color:black"> {{data.fromDate}} </span>đến <span style="color:black">{{data.toDate}}</span></div>
        <div class="">
            <span>
                {{data.product_name}}
            </span>
            <span class="text-muted">
                - {{data.product_code}}
            </span>
            <span class="text-info">
                - {{data.product_price | qbscurrency}}
                <span class="material-icons font-16 " style="color: grey;">
                    sell
                </span>
            </span>
        </div>
    </div>

    <div class="d-flex align-items-end">
        <select class="custom-select mr-2" [(ngModel)]="category_code" (change)="this.loadData();" name="category" id="category">
            <option value="">Nhóm KH</option>
            <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
          </select>

        <div>
            <button class="btn btn-secondary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </div>
</div>
<div class="mt-3"></div>
<div *ngIf="this.datas.length > 0">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th class="text-center">#</th>
                <th>Khách hàng</th>
                <th class="text-right">Hóa đơn</th>
                <th class="text-right">Số lượng</th>
                <th class="text-right">Doanh thu</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="datasSum.length > 0" class="font-weight-bold row_sum">
                <td colspan="2" class="text-center">
                    Tổng
                </td>
                <td class="text-right">
                    {{datasSum[0].invoices | qbscurrency}}
                </td>
                <td class="text-right">
                    <span *ngIf="data.unitSelected=='unit'">
                        <span class="text-muted  font-13">{{datasSum[0].unit}}</span> {{datasSum[0].quantity_unit | qbscurrency}}
                    </span>
                    <span *ngIf="data.unitSelected=='unit2'">
                        <span class="text-muted  font-13">{{datasSum[0].unit2}}</span> {{datasSum[0].quantity_unit2 | qbscurrency}}
                    </span>
                </td>

                <td class="text-right">
                    {{datasSum[0].revenue | qbscurrency}}
                    <img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg">
                </td>
            </tr>
            <tr *ngFor="let item of datas; let i = index">
                <td class="text-center">
                    {{i+1}}.
                </td>
                <td>
                    {{item.name}}
                    <span class="text-muted font-14">
                        - {{item.address}}
                    </span>
                </td>


                <td class="text-right">
                    {{item.invoices | qbscurrency}}
                </td>
                <td class="text-right">
                    <span *ngIf="data.unitSelected=='unit'">
                        <span class="text-muted  font-13">{{item.unit}}</span> {{item.quantity_unit | qbscurrency}}
                    </span>
                    <span *ngIf="data.unitSelected=='unit2'">
                        <span class="text-muted  font-13">{{item.unit2}}</span> {{item.quantity_unit2 | qbscurrency}}
                    </span>
                </td>

                <td class="text-right">
                    {{item.revenue | qbscurrency}}
                    <img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg">
                </td>
            </tr>
        </tbody>
    </table>

    <!-- <div class="revenueCustomerCard revenueCustomerCard_itself row d-flex justify-content-between align-items-center" *ngFor="let item of datas; let i = index">
        <div>
            <span class="mr-1">
                {{i+1}}.
            </span>
            <span>
                {{item.name}}
            </span>
            <span class="text-muted font-14">
                - {{item.phone}}
            </span>
            <div *ngIf="item.company !== ''" class="ml-3 mt-1 text-info">
                <span>
                    {{item.company}}
            </span>

            </div>
        </div>

        <div class="ml-auto font-16 text-right" style="width: 100px;" [ngClass]="{'text-danger': (item.stock < 0)}">
            {{item.invoices | qbscurrency}}
            <span class="material-icons font-16 " style="color: grey;">
                receipt_long
            </span>
        </div>
        <div class="row d-flex justify-content-between align-items-center">
            <div class="font-16 text-right" style="width: 100px;">
                {{item.quantity | qbscurrency}}
                <span class="material-icons font-16 " style="color: grey;">
                    looks_one
                </span>

            </div>

            <div class="font-16 text-right" style="width: 140px;">
                {{item.revenue | qbscurrency}}
                <img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg">
            </div>
        </div>
    </div> -->
    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
        {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
          </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
              <i class="ti ti-angle-left"></i>
            </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div *ngIf="this.datas.length == 0" style="height: 100%;">
    <app-nodata></app-nodata>
</div>
<div *ngIf="isLoading" class="d-flex align-items-center text-center justify-content-around" style="height: 100%;">
    <span class="spinner-border spinner-border-lg text-primary"></span>
</div>