import { AuthenticationService } from './../../_services/authentication.service';
import { AppService } from './../../_services/app.service';
import { environment } from './../../../environments/environment';
import { ActivatedRoute, Router} from '@angular/router';
import { UserService } from './../../_services/user.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Md5 } from 'ts-md5';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CategoryService } from 'src/app/_services/category.service';

declare var $: any;

@Component({
  selector: 'app-login-3',
  templateUrl: './login-3.component.html',
})
export class Login3Component implements OnInit, AfterViewInit, OnDestroy {
  isLoading = false;
  hide = true;// for password
  //Form login
  user = {
    shop_code: '',
    email: '',
    phone: '',
    password: ''
  }
  // checkout-widget ="sdsdsd";
  constructor(
    public userService: UserService,
    public appService: AppService,
    public categoryService: CategoryService,
    private router: Router,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute
  ) { 
    
    if(this.activatedRoute.snapshot.paramMap.get('shop_code') !== null && this.activatedRoute.snapshot.paramMap.get('phone') !== null){
      console.log("okokokoko");
      this.user.shop_code = this.activatedRoute.snapshot.paramMap.get('shop_code');
      this.user.phone = this.activatedRoute.snapshot.paramMap.get('phone');
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    $('body').removeClass('empty-layout');
  }



  login() {

    this.isLoading = true;
    // return;
    // this.user.password = Md5.hashStr(this.user.password).toString();
    console.log(this.user);
    if (true) {
      this.authenticationService.login(this.user)
        .pipe(first())
        .subscribe({
          next: response => {
            this.isLoading = false;
            if (response.code == 'ok') {
              

              this.appService.currentUser = response.data;
              this.appService.currentUser.isLoggedIn = true;
              localStorage.setItem(environment.currentUser, JSON.stringify(response.data));
              console.log('login this.appService.currentUser: ', this.appService.currentUser);
              

              this.appService.settingUnits(this.appService.currentUser.shop_unit);
              
              localStorage.setItem(environment.currentUser, JSON.stringify(response.data));


              console.log(JSON.parse(localStorage.getItem(environment.currentUser)));

              this.loadBranchCategory(); // load chi nhánh


              this.router.navigate(['/']);
            } else {
              this.toastr.error("", "Mã Shop hoặc Email hoặc SĐT hoặc Mật khẩu không đúng");
            }
          },
          error: error => {
            this.isLoading = false;
            this.toastr.error("Mã Shop hoặc Email hoặc SĐT hoặc Mật khẩu không đúng", "Cảnh báo");
            console.error(error);
          }
        })
    }
  }
  async loadBranchCategory() {
    let filter = {
      type: environment.branch_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        console.log(response);
        if (response) {
          this.appService.currentUser.branchList = response;
          this.appService.currentUser.currentBranch = '';
          localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
}
