<div class="page-content fade-in-up">
    <div class="flexbox-b mb-5">
        <span class="mr-4 static-badge badge-pink"><i class="ti ti-search"></i></span>
        <div>
            <h5 class="font-strong">Search Results</h5>
            <div class="text-light">Found 420 results in 3 categories</div>
        </div>
    </div>
    <div class="mobile-mb-2 input-group-icon input-group-icon-left input-group-lg mb-4">
        <span class="input-icon input-icon-right"><i class="ti ti-search"></i></span>
        <input class="form-control form-control-air font-light font-poppins border-0" type="text" placeholder="Search ..." style="box-shadow:0 3px 6px rgba(10,16,20,.15);">
    </div>
    <div class="ibox">
        <div class="ibox-body">
            <div class="mb-4">
                <h3 class="mt-1">420 results found for:
                    <span class="text-primary">“AdminCa Theme”</span>
                </h3><small class="text-muted">About 1,370 result ( 0.13 seconds)</small></div>
            <ul class="list-group list-group-full list-group-divider">
                <li class="list-group-item py-3">
                    <h5 class="font-strong">
                        <a class="link-blue">AdminCa: Perfect Design + Perfect Code</a>
                    </h5>
                    <p class="text-light">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                    <div class="text-muted font-13">
                        <span class="badge badge-primary">DESIGN</span>
                        <span class="mx-2">•</span>
                        <span>Feb 25, 2018</span>
                    </div>
                </li>
                <li class="list-group-item py-3">
                    <h5 class="font-strong">
                        <a class="link-blue">AdminCa: Web Design Trends</a>
                    </h5>
                    <p class="text-light">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy.</p>
                    <div class="text-muted font-13">
                        <span class="badge badge-danger">TRENDS</span>
                        <span class="mx-2">•</span>
                        <span>May 20, 2018</span>
                    </div>
                </li>
                <li class="list-group-item py-3">
                    <h5 class="font-strong">
                        <a class="link-blue">Easy Programing with Adminca</a>
                    </h5>
                    <p class="text-light">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an...</p>
                    <div class="text-muted font-13">
                        <span class="badge badge-success">Programing</span>
                        <span class="mx-2">•</span>
                        <span>Apr 14, 2018</span>
                    </div>
                </li>
                <li class="list-group-item py-3">
                    <h5 class="font-strong">
                        <a class="link-blue">Adminca and Angular</a>
                    </h5>
                    <p class="text-light">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                    <div class="text-muted font-13">
                        <span class="badge badge-pink">ANGULAR</span>
                        <span class="mx-2">•</span>
                        <span>Sep 22, 2018</span>
                    </div>
                </li>
                <li class="list-group-item py-3">
                    <h5 class="font-strong">
                        <a class="link-blue">AdminCa: Bootstrap Premium Admin Theme</a>
                    </h5>
                    <p class="text-light">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an.</p>
                    <div class="text-muted font-13">
                        <span class="badge badge-primary">BOOTSTRAP</span>
                        <span class="mx-2">•</span>
                        <span>Oct 18, 2018</span>
                    </div>
                </li>
            </ul>
            <ul class="pagination justify-content-center mt-4">
                <li class="page-item">
                    <a class="page-link page-link-solid" href="javascript:;" aria-label="First">
                        <span aria-hidden="true"><i class="la la-angle-double-left"></i></span>
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" href="javascript:;" aria-label="Previous">
                        <span aria-hidden="true"><i class="ti ti-angle-left"></i></span>
                    </a>
                </li>
                <li class="page-item active">
                    <a class="page-link" href="javascript:;">1</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="javascript:;">2</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="javascript:;">3</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="javascript:;">4</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="javascript:;"><i class="la la-ellipsis-h"></i></a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" href="javascript:;" aria-label="Next"><i class="ti ti-angle-right"></i></a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" href="javascript:;" aria-label="Last"><i class="la la-angle-double-right"></i></a>
                </li>
            </ul>
        </div>
    </div>
</div>
