import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import { LayoutComponent } from './/layout.component';
import { AppHeader } from './/app-header/app-header.component';
import { AppSidebar } from './/app-sidebar/app-sidebar.component';
import { AppFooter } from './/app-footer/app-footer.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { MetismenuAngularModule } from "@metismenu/angular";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PipeCurrencyHeaderComponent } from '../components/pipe-currency/pipe-currency-header.component';
import { PrinterSectionComponent } from './components/printer-section/printer-section.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    decimal: ",",
    precision: 0,
    prefix: "",
    suffix: "",
    thousands: "."
};

@NgModule({
	declarations: [
	  LayoutComponent,
	  AppHeader,
	  AppSidebar,
	  AppFooter,
	  PipeCurrencyHeaderComponent,
	  PrinterSectionComponent,
	  
	],
	exports: [
	  LayoutComponent,
	  AppHeader,
	  AppSidebar,
	  AppFooter,
	  
	],
	imports: [
		RouterModule,
		CommonModule,
		FormsModule,
		CurrencyMaskModule,
		MetismenuAngularModule,
		NgbModule,
		NgMultiSelectDropDownModule.forRoot(),
	],
	providers: [
		{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
	  ],
})
export class LayoutModule {
}