import { CategoryCustomerService } from './_services/category-customer.service';
import { UtilityService } from './_services/utility.service';
import { SaleService } from './_services/sale.service';
import { NumberDirective } from './number-directive';
import { CustomerService } from './_services/customer.service';
import { AppService } from './_services/app.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './_services/user.service';
import { NgModule } from '@angular/core';

import { AppComponent } from './/app.component';
import { AppRoutingModule } from './/app-routing.module';
import { LayoutModule } from './/layouts/layout.module';
import { ScriptLoaderService } from './_services/script-loader.service';
import { SaleComponent } from './pages/sale/sale.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerComponent } from './pages/customer/customer.component';
import { ToastrModule } from 'ngx-toastr';
import { WarehouseComponent } from './pages/warehouse/warehouse.component';
import { ProductComponent } from './pages/product/product.component';
import { CategoryService } from './_services/category.service';
import { ProductService } from './_services/product.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CategoryComponent } from './pages/category/category.component';
import { ReportComponent } from './pages/report/report.component';
import { Report2Component } from './pages/report2/report2.component';
import { StockinComponent } from './pages/stockin/stockin.component';
import { StockinCartComponent } from './pages/stockin/stockin-cart/stockin-cart.component';
import { Login3Component } from './pages/login-3/login-3.component';

import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { MembershipComponent } from './pages/membership/membership.component';
import { OrderComponent } from './pages/order/order.component';
import { SettingComponent } from './pages/setting/setting.component';
import { PromotionComponent } from './pages/promotion/promotion.component';
import { CustomerCateComponent } from './pages/customer-cate/customer-cate.component';
import { PromotionService } from './_services/promotion.service';
import { PromotionAddComponent } from './pages/promotion/promotion-add/promotion-add.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModule, NgbModalConfig, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MetismenuAngularModule } from "@metismenu/angular";
import { CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n } from './_services/datepicker-adapter.service';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { PrinterComponent } from './components/printer/printer.component';
import { InterceptorService } from './_services/interceptor.service';
import { ErrorInterceptorService } from './_services/error-interceptor.service';
import { BrandComponent } from './pages/brand/brand.component';
import { ChartsModule } from 'ng2-charts';
import { DebtComponent } from './pages/debt/debt.component';
import { ServicedeskComponent } from './pages/servicedesk/servicedesk.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { DesksaleComponent } from './components/desksale/desksale.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule,} from '@angular/material/icon';
import { DebtCustomerComponent } from './pages/debt-customer/debt-customer.component';
import { ServicedeskpriceComponent } from './components/servicedeskprice/servicedeskprice.component';
import {MatSelectModule} from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import { UnitComponent } from './components/unit/unit.component';
import { CategorylistComponent } from './components/categorylist/categorylist.component';
import { ReportMonthComponent } from './pages/report-month/report-month.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { EmployeePickerComponent } from './components/employee-picker/employee-picker.component';
import { InventoryListComponent } from './pages/inventory-list/inventory-list.component';
import { ReturnsProductComponent } from './components/returns-product/returns-product.component';
import { DeskChangeComponent } from './components/desk-change/desk-change.component';
import { ProductPickerComponent } from './components/product-picker/product-picker.component';
import { DebtService } from './_services/debt.service';
import { DebtCustomerDetailComponent } from './components/debt-customer-detail/debt-customer-detail.component';
import { BranchComponent } from './pages/branch/branch.component';
import { ReportCustomerComponent } from './pages/report-customer/report-customer.component';
import { ReportProductComponent } from './pages/report-product/report-product.component';
import { RevenueCustomerCardListComponent } from './components/revenue-customer-card-list/revenue-customer-card-list.component';
import { RevenueProductCardListComponent } from './components/revenue-product-card-list/revenue-product-card-list.component';
import { ReportEmployeeComponent } from './pages/report-employee/report-employee.component';
import { PromotionDetailComponent } from './pages/promotion/promotion-detail/promotion-detail.component';
import { RevenueEmployeeCartListComponent } from './components/revenue-employee-cart-list/revenue-employee-cart-list.component';
import { ReportCustomerDetailComponent } from './components/report-customer-detail/report-customer-detail.component';
import { NodataComponent } from './components/nodata/nodata.component';
import { ReportProductDetailComponent } from './components/report-product-detail/report-product-detail.component';
import { RangeTimePickerComponent } from './components/range-time-picker/range-time-picker.component';
import { HomeComponent } from './pages/home/home.component';
import { RevenueProduct2CardListComponent } from './components/revenue-product2-card-list/revenue-product2-card-list.component';
import { UnitSelectComponent } from './components/unit-select/unit-select.component';
import { WorkingShiftPickerComponent } from './components/working-shift-picker/working-shift-picker.component';
import { SaleDetailComponent } from './components/sale-detail/sale-detail.component';
import { PipeCurrencyComponent } from './components/pipe-currency/pipe-currency.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { StampPrintComponent } from './pages/stamp-print/stamp-print.component';
import { StampPrintingComponent } from './components/stamp-printing/stamp-printing.component';
import { ProductAddComponent } from './pages/product/product-add/product-add.component';
import { SettingProductAttributeComponent } from './components/setting-product-attribute/setting-product-attribute.component';
import { ProductAdd2Component } from './components/product-add2/product-add2.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipsModule } from '@angular/material/chips';
import { ProductUpdateComponent } from './pages/product/product-update/product-update.component';
import { InputMoneyComponent } from './components/input-money/input-money.component';
import { SettingSystemComponent } from './components/setting-system/setting-system.component';
import { RegisterComponent } from './pages/register/register.component';
import { ArrangeDataComponent } from './components/arrange-data/arrange-data.component';
import { CategoryPickerComponent } from './components/category-picker/category-picker.component';
import { SupplierComponent } from './pages/supplier/supplier.component';
import { SupplierCateComponent } from './pages/supplier-cate/supplier-cate.component';
import { SupplierPickerComponent } from './components/supplier-picker/supplier-picker.component';
import { ShipSettingComponent } from './pages/shippings/ship-setting/ship-setting.component';
import { ShipOrderComponent } from './pages/shippings/ship-order/ship-order.component';
import { ShipControlComponent } from './pages/shippings/ship-control/ship-control.component';
import { Salev2Component } from './pages/salev2/salev2.component';
import { CustomerPickerComponent } from './components/customer-picker/customer-picker.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { DeleteConfirmComponent } from './components/delete-confirm/delete-confirm.component';
import { SaleInvoiceComponent } from './components/sale-invoice/sale-invoice.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ServicedeskSaleComponent } from './components/servicedesk-sale/servicedesk-sale.component';
import { SaleDetailViewReturningComponent } from './components/sale-detail-view-returning/sale-detail-view-returning.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CatogoryChangePickerComponent } from './components/catogory-change-picker/catogory-change-picker.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ProductNotfoundComponent } from './components/product-notfound/product-notfound.component';
import { LoaddingIconComponent } from './components/loadding-icon/loadding-icon.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    decimal: ",",
    precision: 0,
    prefix: "",
    suffix: "",
    thousands: "."
};

@NgModule({
  // exports:[
  //   MatChipsModule
  // ],
  declarations: [
    AppComponent,
    SaleComponent,
    CustomerComponent,
    WarehouseComponent,
    ProductComponent,
    NumberDirective,
    CategoryComponent,
    ReportComponent,
    Report2Component,
    StockinComponent,
    StockinCartComponent,
    Login3Component,
    MembershipComponent,
    OrderComponent,
    SettingComponent,
    PromotionComponent,
    CustomerCateComponent,
    PromotionAddComponent,
    PrinterComponent,
    BrandComponent,
    DebtComponent,
    ServicedeskComponent,
    DesksaleComponent,
    DebtCustomerComponent,
    ServicedeskpriceComponent,
    UnitComponent,
    CategorylistComponent,
    ReportMonthComponent,
    MonthPickerComponent,
    InventoryComponent,
    EmployeePickerComponent,
    InventoryListComponent,
    ReturnsProductComponent,
    DeskChangeComponent,
    ProductPickerComponent,
    DebtCustomerDetailComponent,
    BranchComponent,
    ReportCustomerComponent,
    ReportProductComponent,
    RevenueCustomerCardListComponent,
    RevenueProductCardListComponent,
    ReportEmployeeComponent,
    PromotionDetailComponent,
    RevenueEmployeeCartListComponent,
    ReportCustomerDetailComponent,
    NodataComponent,
    ReportProductDetailComponent,
    RangeTimePickerComponent,
    HomeComponent,
    RevenueProduct2CardListComponent,
    UnitSelectComponent,
    WorkingShiftPickerComponent,
    SaleDetailComponent,
    PipeCurrencyComponent,
    StampPrintComponent,
    StampPrintingComponent,
    ProductAddComponent,
    SettingProductAttributeComponent,
    ProductAdd2Component,
    ProductUpdateComponent,
    InputMoneyComponent,
    SettingSystemComponent,
    RegisterComponent,
    ArrangeDataComponent,
    CategoryPickerComponent,
    SupplierComponent,
    SupplierCateComponent,
    SupplierPickerComponent,
    ShipSettingComponent,
    ShipOrderComponent,
    ShipControlComponent,
    Salev2Component,
    CustomerPickerComponent,
    DeleteConfirmComponent,
    SaleInvoiceComponent,
    ProductDetailComponent,
    ServicedeskSaleComponent,
    SaleDetailViewReturningComponent,
    CatogoryChangePickerComponent,
    ConfirmDialogComponent,
    ProductNotfoundComponent,
    LoaddingIconComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      maxOpened: 2,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    NgbModule,
    // NgbModule.forRoot(),
    // BsDatepickerModule.forRoot(),
    // 3. Initialize
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFirestoreModule, // firestore
    // AngularFireAuthModule, // auth
    // AngularFireStorageModule // storage
    MetismenuAngularModule,
    Ng2ImgMaxModule,
    ChartsModule,
    DragDropModule,
    MatBadgeModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgxBarcodeModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSidenavModule,
    NgxMaterialTimepickerModule,
    MatRadioModule,
    MatMenuModule,
    // NgxChartsModule,
  ],
  providers: [
    ScriptLoaderService,
    UserService,
    CustomerService,
    PromotionService,
    ProductService,
    CategoryService,
    CategoryCustomerService,
    SaleService,
    DebtService,
    UtilityService,
    HttpClient,
    AppService,
    NgbActiveModal,
    // SharedService,
    // NgbModalConfig, NgbModal,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    {provide: MAT_DATE_LOCALE, useValue: 'vi-VN'},
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    // {
    //   provide: MAT_CHIPS_DEFAULT_OPTIONS,
    //   useValue: {
    //     separatorKeyCodes: [ENTER, COMMA]
    //   }
    // }
    // MatChipsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
