import { User } from './../model/user';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  currentUser: User;
  constructor(private appService: AuthenticationService) {
    this.currentUser = JSON.parse(localStorage.getItem(environment.currentUser));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.currentUser = JSON.parse(localStorage.getItem(environment.currentUser));
    // console.log(this.currentUser.currentBranch ? this.currentUser.currentBranch:'');
    const isApiUrl = req.url.startsWith(environment.apiUrl);
    if (isApiUrl && this.currentUser && this.currentUser.isLoggedIn) {
      req = req.clone({
        setHeaders: {
          // 'Access-Control-Allow-Origin': '*',
          // 'Accept': '*/*',
          // 'Referer': '*',
          // 'Access-Control-Allow-Methods': 'GET, POST, PUT',
          // 'Access-Control-Allow-Headers': 'Content-Type',
          Authorization: `Bearer ${this.currentUser.token}`,
          shop_code: this.currentUser.shop_code,
          currentBranch: this.currentUser.currentBranch ? this.currentUser.currentBranch:''
        }
      });
    } 

    return next.handle(req);
  }
}
