import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-money',
  templateUrl: './input-money.component.html',
  styleUrls: ['./input-money.component.css']
})
export class InputMoneyComponent implements OnInit {
  datas=[
    {code:1, value : '1'},
    {code:2, value : '2'},
    {code:3, value : '3'},
    {code:4, value : '4'},
    {code:5, value : '5'},
    {code:6, value : '6'},
    {code:7, value : '7'},
    {code:8, value : '8'},
    {code:9, value : '9'},
    {code:'00', value : '00'},
    {code:'000', value : '000'},
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
