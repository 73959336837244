<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2">
        <div class="col">
            <div class="flexbox-b">
                <span class="mr-4 static-badge badge-primary"><span class="material-icons">qr_code</span></span>
                <div>
                    <div style="font-size: 20px;">Chọn sản phẩm in tem</div>
                </div>
            </div>
        </div>
        <div class="col text-right">
            <!-- <button class="btn btn-labeled mr-2 btn-labeled-left btn-icon" name="cancel" (click)="backToList()">
                <span class="btn-label"><i class="ti ti-arrow-left"></i></span>Lịch sử in
            </button> -->
            <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="popup_cart()">
        <span class="btn-label">{{cartObj.cart.length}}</span>Xem trước
      </button>
        </div>
    </div>
    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <div class="p-0 mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="typeahead-template" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm sản phẩm" [resultTemplate]="rt" [inputFormatter]="formatter">
            </div>
            <ng-template #rt let-r="result" let-t="term">
                <div class="row" (click)="onClick(r)" style="width: 450px;">
                    <div class="img-product-search ml-1" [style.background-image]="this.appService.showImageProduct(r['image_url'])"></div>
                    <div class="col"> #{{ r['code'] }}</div>
                    <div class="col">
                        <ngb-highlight [result]="r.name" [term]="t">
                        </ngb-highlight>
                    </div>
                    <div class="col">Kho: {{ r['stock'] == null ? 0: r['stock'] }}</div>
                    <div class="col">DM: {{ r['category_name'] }}</div>
                    <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
                </div>
            </ng-template>
        </div>
        <div class="col">
            <div class="d-flex justify-content-end">
                <div class="pl-2">
                    <select class="custom-select" [(ngModel)]="filter.category_code" (change)="category_change($event)" name="category" id="category">
            <option value="">Danh mục</option>
            <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
          </select>
                </div>
                <div class="pl-2">
                    <select class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
            <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}</option>
          </select>
                </div>
                <div class="pl-2">

                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-icon" ngbDropdownToggle>
              <span class="btn-label">
                <i *ngIf="display_type ==0" class="ti ti-layout-grid2"></i>
                <i *ngIf="display_type ==1" class="ti ti-align-justify"></i>
              </span>
              </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="changeViewType(0)"><i class="ti ti-layout-grid2"></i> Dạng lưới</button>
                            <button ngbDropdownItem (click)="changeViewType(1)"><i class="ti ti-align-justify"></i> Dạng danh sách</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div>

        <div class="row align-items-center mb-2 paging-cover">
            <div class="col-4  p-0 ">
                <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
          {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
            </div>
            <div class="col-8  p-0 d-flex justify-content-end">
                <ul class="pagination p-1" style="margin-bottom: 0px;">
                    <li>
                        <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
              <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
            </select>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                            <span aria-hidden="true">
                <i class="ti ti-angle-left"></i>
              </span>
                        </a>
                    </li>
                    <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                        <a class="page-link" href="javascript:;">{{item.i}}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                            <i class="ti ti-angle-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="display_type ==1" class="table-responsive">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px;">#</th>
                        <th>Tên hàng</th>
                        <th>Danh mục</th>
                        <th style="text-align: right;">Giá</th>
                        <th class="no-sort"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of appService.products; let i = index" (click)="popupAddToCart(item)" class="h-row-item row-display-type-1">
                        <td>
                            <!-- <a class="media-img" href="javascript:;">
                      <img class="img-circle" src="./assets/img/icons/user.svg" alt="image" width="30" />
                    </a> -->
                            <a (click)="popupAddToCart(item)">
                                <img style="width: 25px;" src="./assets/img/icons/add_cart.svg" alt="image" />
                            </a>
                        </td>
                        <td>
                            <div style="font-size: 18px;"> {{i+1}} {{item.name}}
                                <i *ngIf="item.stock < 1" class="la la-question-circle font-18 text-danger" title="Hết hàng trong kho"></i>
                            </div>
                        </td>
                        <td>{{item.category_name}}</td>
                        <td style="text-align: right;">{{item.price| qbscurrency:0}}</td>

                        <td>
                            <div class="enterQuantiti-temp-2 d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <div style="display: inline-block;">
                                        <button class="btn  btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_sub2(item)"><i class="ti ti-minus"></i></button>
                                    </div>
                                    <div style="display: inline-block; margin: 0 5px;">
                                        <input [(ngModel)]="item.temp_quantity" type="number" (focus)="focusFunction($event)" decimals="2" class="form-control" style="font-size: 18px; padding: 0px; height: 29px; text-align: center; width: 40px;" />
                                    </div>
                                    <div style="display: inline-block;"><button class="btn  btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_add2(item)"><i class="ti ti-plus"></i></button></div>
                                </div>

                                <div>
                                    <div style="display: inline-block;">
                                        <button class="btn  btn-primary btn-icon" (click)="openCheckout2(item, item.temp_quantity)">
                                            <span class="material-icons font-17">
                                                qr_code
                                            </span>
                                        </button>
                                    </div>
                                    <div style="display: inline-block;" class="ml-4">
                                        <button class="btn  btn-primary btn-icon" (click)="addToCart(item, item.temp_quantity)">
                                            <span class="material-icons  font-17 ">
                                                playlist_add
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div *ngIf="display_type ==0">
            <div class="d-flex flex-wrap mb-2">
                <!-- <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of appService.products; let i = index"
            (click)="popupAddToCart(item)"> -->
                <div class="mb-2 mr-3  cart-item-gird" *ngFor="let item of appService.products; let i = index">
                    <div class="enterQuantiti-temp">
                        <div>
                            <div style="display: inline-block;">
                                <button class="btn btn-sm btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_sub2(item)"><i class="ti ti-minus"></i></button>
                            </div>
                            <div style="display: inline-block; margin: 0 5px;">
                                <input [(ngModel)]="item.temp_quantity" type="number" (focus)="focusFunction($event)" decimals="2" class="form-control" style="font-size: 18px; padding: 0px; height: 35px; text-align: center; width: 45px;" />
                            </div>
                            <div style="display: inline-block;"><button class="btn btn-sm btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_add2(item)"><i class="ti ti-plus"></i></button></div>
                        </div>
                        <div class="mt-2">
                            <div style="display: inline-block;">
                                <button class="btn btn-sm btn-primary btn-icon" (click)="openCheckout2(item, item.temp_quantity)">
                                    <span class="material-icons font-17">
                                        print
                                    </span>
                                </button>
                            </div>
                            <div style="display: inline-block;" class="ml-4">
                                <button class="btn btn-sm btn-primary btn-icon" (click)="addToCart(item, item.temp_quantity)">
                                    <span class="material-icons font-17">
                                        local_mall
                                        </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="rel img-product2" [style.background-image]="this.appService.showImageProduct(item.image_url)">
                        <span class=".card-img-overlay">
                            <span *ngIf="item.stock < 1" class="ml-1 badge-danger badge-point"></span>
                        </span>
                    </div>
                    <div class="card-body">
                        <!--  (click)="popupAddToCart(item)" -->
                        <div style=" max-height: 37px; overflow: hidden;">{{item.name}}</div>
                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <div class="text-center">
                                <div style="font-weight: bold;" [ngClass]="{'text-danger': item.stock < 1}">
                                    <span>{{item.stock}}</span>
                                </div>

                            </div>
                            <div class="text-center">
                                <div *ngIf="item.unit_default =='' || item.unit==item.unit_default" style="font-weight: bold;">{{item.price | qbscurrency}}</div>
                                <div *ngIf="item.unit2 !='' && item.unit2==item.unit_default" style="font-weight: bold;">{{ utilityService.mathRound(item.price_temp / item.packing) | qbscurrency}}</div>

                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <div class="text-center">

                                <div class="text-muted font-11">
                                    <div class="btn-group btn-group-sm">
                                        <div *ngIf="item.unit!=''" (click)="setUnit_default(item.code,item.unit)" class="btn btn-info p-1" [ngClass]="{'btn-info': item.unit_default =='' || item.unit==item.unit_default}" style="line-height: 10px;">{{item.unit}}</div>
                                        <div *ngIf="item.unit2!=''" (click)="setUnit_default(item.code,item.unit2)" class="btn btn-secondary p-1" [ngClass]="{'btn-info': item.unit2==item.unit_default}" style="line-height: 10px;">{{item.unit2}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">

                                <div class="text-muted font-11">VNĐ</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row align-items-center mb-2 paging-cover">
            <div class="col-4">
                <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
          {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
            </div>
            <div class="col-8 d-flex justify-content-end">
                <ul class="pagination p-1" style="margin-bottom: 0px;">
                    <li>
                        <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
              <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
            </select>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                            <span aria-hidden="true">
                <i class="ti ti-angle-left"></i>
              </span>
                        </a>
                    </li>
                    <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                        <a class="page-link" href="javascript:;">{{item.i}}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                            <i class="ti ti-angle-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


    </div>




    <div class="modal fade" id="stamp-print-modal">
        <div class="modal-dialog" role="document">
            <form class="modal-content modal-content-lg form-horizontal" #productUpdateForm="ngForm" id="form-update-product">
                <div class="modal-header p-4">
                    <h5 class="modal-title">Chi tiết in tem</h5>
                    <a data-dismiss="modal"><i class="ti ti-close"></i></a>
                </div>
                <div class="modal-body p-4">
                    <div class="container">
                        <div class="row info-row">
                            <div class="col">
                                Tổng số lượng:
                            </div>
                            <div class="col text-right">
                                {{cartObj.quantity | qbscurrency}}
                            </div>
                        </div>

                        <table style="width: 100%; margin-top: 20px;">
                            <tr *ngFor="let item of cartObj.cart; let i = index" class="item-row">
                                <td style="width: 25px; text-align: center;">
                                    <button class=" btn-remove btn-adjust btn btn-outline-danger btn-icon-only btn-circle btn-sm btn-thick" (click)='removeProduct(i,item)'><i class="ti ti-close"></i></button>
                                    <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                                    <span class="lbl-stt">{{i+1}}</span>
                                </td>
                                <td>
                                    <div style="line-height: 16px;">
                                        {{item.product.code}}
                                    </div>
                                </td>
                                <td>
                                    <div style="line-height: 16px;">
                                        {{item.product.name}} <span style="color: gray; font-size: 12px;">{{item.product.price| number}}</span>
                                    </div>
                                </td>
                                <!-- <td style="text-align: right;"><span style="font-size: 11px;">{{item.product.price| qbscurrency}}</span> </td> -->
                                <td style="text-align: right; width: 92px;">
                                    <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti-minus"></i></a> -->
                                    <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='quantity_sub(item)'><i class="ti ti-minus"></i></button> x {{item.quantity}}

                                    <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='quantity_add(item)'><i class="ti ti-plus"></i></button>
                                </td>
                            </tr>

                        </table>

                    </div>
                </div>
                <div class="modal-footer justify-content-around bg-primary-50">

                    <button class="btn btn-primary" (click)="print('Bản nháp')"><i class="ti ti-printer"></i>
                        <span class="ml-2">In tem</span>
                    </button>
                    <button class="btn btn-primary" (click)="prin_nhan('Bản nháp')"><i class="ti ti-printer"></i>
                        <span class="ml-2">In tem nhãn</span>
                    </button>
                    <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
                </div>
            </form>
        </div>
    </div>


    <div id="print-section-stamp-component" style="display: none;">
        <div *ngFor="let item of cartObj.cart; let i = index" style="width: 7cm; ">
            <div *ngFor="let item2 of item.quantitys; let i = index" style="width: 50%; display:inline-block; text-align: center;">
                <div style="border: 1px solid transparent; height: 20mm; margin-bottom: 0mm; overflow: hidden;">
                    <div style="white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;">{{item.product.name}}</div>
                    <div style="vertical-align: middle;">
                        <ngx-barcode [bc-value]="item.product.code" [bc-display-value]="true" [bc-width]="1" [bc-height]="20" [bc-font]="'monospace'" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-font-size]="14"></ngx-barcode>
                    </div>
                    <div>{{item.product.price | qbscurrency}} VNĐ</div>
                </div>
            </div>
        </div>
    </div>
    <div id="print-section-stamp-component_nhan" style="display: none;">
        <div style="width: 7cm; ">
            <ng-container *ngFor="let item of cartObj.cart; let i = index">
                <ng-container *ngFor="let item2 of item.quantitys; let i = index">
                    <div style="width: 49%; display:inline-block; text-align: center; border: 1px solid transparent; height: 20mm; margin-bottom: 0mm; overflow: hidden;">
                        <div style="overflow: hidden; font-weight: bold;
                            text-overflow: ellipsis; height: 1.45cm; display: flex;
      justify-content: center;
      align-items: center;">{{item.product.name}}</div>

                        <div style="font-weight: bold; font-size: larger;">{{item.product.price | qbscurrency}} VNĐ</div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>