import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductAttribute } from 'src/app/model/product-attribute';
import { AppService } from 'src/app/_services/app.service';
import { ShopService } from 'src/app/_services/shop.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-setting-product-attribute',
  templateUrl: './setting-product-attribute.component.html',
  styleUrls: ['./setting-product-attribute.component.css']
})
export class SettingProductAttributeComponent implements OnInit {
  isLoading = false;
  constructor(
    public appService: AppService,
    public shopService: ShopService,
    private toastr: ToastrService,
  ) {
    this.loadProduct_attributes();
   }

  ngOnInit(): void {
  }
  addProduct_attributes(i) {
    this.appService.product_attributes.push(new ProductAttribute({id:i}));
  }
  removeProduct_attributes(i) {
    this.appService.product_attributes.splice(i, 1);
  }
  loadProduct_attributes(){
    if(typeof(this.appService.currentUser.product_attributes) === 'undefined'){
      this.appService.currentUser.product_attributes = '[]';
    }
    
    this.appService.product_attributes = JSON.parse(this.appService.currentUser.product_attributes);
 
    if(this.appService.product_attributes == null || this.appService.product_attributes.length == 0){
      this.appService.product_attributes = [];
      this.appService.product_attributes.push(new ProductAttribute({id:1}))
    }
    console.log(this.appService.product_attributes);
  }
  updateAttribute(){
    this.isLoading = true;
    this.appService.currentUser.product_attributes = JSON.stringify(this.appService.product_attributes);
    this.shopService.updateShopSomeInfo({"product_attributes":this.appService.currentUser.product_attributes}).subscribe(
      response => {
        this.isLoading = false;
        if (response.code == "ok" && response.affectedRows > 0) {
          localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
          console.log(this.appService.currentUser);
          this.toastr.success("Cập nhật thông tin thành công", );
        }
        else {
          this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      })
  }

}
