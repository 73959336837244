<div class="" id="view_detail_product">
    <div class="qbs-header p-1">
        <h5 class="qbs-title">Thông tin sản phẩm/hàng hóa</h5>
    </div>
    <div class="qbs-body pt-2 pb-2">
        <div class="img-product mb-2" [style.background-image]="'url('+imageUrl+')'"></div>
        <table>
            <tr>
                <td class="col-lable text-muted">
                    Tên:
                </td>
                <td class="col-value ">
                    {{product.name}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Mã:
                </td>
                <td class="col-value ">
                    {{product.code}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Giá lẻ:
                </td>
                <td class="col-value ">
                    {{product.price | qbscurrency}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Giá buôn:
                </td>
                <td class="col-value ">
                    {{product.price2 | qbscurrency}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Tồn kho:
                </td>
                <td class="col-value ">
                    {{product.stock}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Danh mục:
                </td>
                <td class="col-value ">
                    <select class="custom-select" [(ngModel)]="product.category_code" name="tap1_category">
                        <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Thương hiệu:
                </td>
                <td class="col-value ">
                    <select class="custom-select" [(ngModel)]="product.category_code_brand" name="tap1_categoryBrand">
                        <option [ngValue]="item.code" *ngFor="let item of categorys_brand">{{item.name}}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Đơn vị:
                </td>
                <td class="col-value ">
                    <select class="custom-select" [(ngModel)]="product.unit" name="tap1_unit">
                        <option [ngValue]="item.code" *ngFor="let item of appService.units">{{item.value}}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Quy cách:
                </td>
                <td class="col-value ">
                    {{product.packing}} {{product.unit2}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Trọng lượng (g):
                </td>
                <td class="col-value ">
                    {{product.ship_weight}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Chiều dài (cm):
                </td>
                <td class="col-value ">
                    {{product.ship_length}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Chiều rộng (cm):
                </td>
                <td class="col-value ">
                    {{product.ship_width}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Chiều cao (cm):
                </td>
                <td class="col-value ">
                    {{product.ship_height}}
                </td>
            </tr>
            <tr>
                <td class="col-lable text-muted">
                    Mô tả:
                </td>
                <td class="col-value">
                    {{product.description}}
                </td>
            </tr>
        </table>
    </div>
    <div class="qbs-footer d-flex justify-content-around pt-2">
        <button class="btn btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
            <span class="btn-label"> <span class="material-icons"> close </span> </span>
            Đóng
        </button>
    </div>
</div>