import { environment } from "src/environments/environment";

export class Customer {
    name : string = "";
    code: string ="";
    type: string ="";
    phone: string ="";
    description:string ="";
    tax:string ="";
    company:string ="";
    address:string ="";
    category_code:string ="";
    category_name:string ="";
    image_url:string ="";
    constructor(type:string) {
       this.type = type;
       this.category_code = environment.DEFAULT_CUS_CATEGORY.code;
       this.category_name = environment.DEFAULT_CUS_CATEGORY.name;
    }
}
