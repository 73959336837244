import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { SaleService } from 'src/app/_services/sale.service';

@Component({
  selector: 'app-report-customer-detail',
  templateUrl: './report-customer-detail.component.html',
  styleUrls: ['./report-customer-detail.component.css']
})
export class ReportCustomerDetailComponent implements OnInit {
  datas = [];
  isLoading = false;
    //for sorter
    sorter = {
      sortStr: this.appService.sort_revenue_customer_reports[0].code,
    }
    //for paging
    viewNumsObj = this.appService.viewNums[2].Code;
    curentPage = 1;
    totalItemOnPage: any;
    totalItems: any;
    listPage: any = [];
  constructor(
    public appService: AppService,
    public saleService: SaleService,
    public dialogRef: MatDialogRef<ReportCustomerDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    
  }

  ngOnInit(): void {
    this.loadData();
  }
  loadData(){
    let filter = {
      fromDate: this.data.fromDate,
      toDate: this.data.toDate,
      type_report: "Tocustomer", //customer and product
      customer_code: this.data.code, //customer code
    }
    console.log("filter", filter);
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    this.isLoading = true;
    this.saleService.getRevenueCustomerProduct(filter, this.sorter, pagingObj).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          this.datas = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.datas.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      });
  }
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadData();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadData();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadData();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadData();
    }
  };
}
