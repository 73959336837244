<div class="row">
    <div class="col-6">
        <h1 mat-dialog-title>Thanh toán nợ</h1>
    </div>
    <div class="col-6 text-right">
        <button *ngIf="step == 0" (click)="processDebtSale()" class="blurButton btn  btn-primary btn-labeled btn-labeled-left ">
            <span class="btn-label"><i class="ti ti ti-money"></i></span>Thanh toán
        </button>
        <button *ngIf="step == 1" (click)="doDebtSale()" class="blurButton btn btn-primary btn-labeled btn-labeled-left ">
            <span class="btn-label"><i class="ti ti ti-check-box"></i></span>Xác nhận
        </button>
        <a class="ml-4" (click)="this.dialogRef.close();"> <span class="material-icons">  close </span></a>
    </div>
</div>
<div class="container">
    <div class="row info-row">
        <div class="col">
            Khách hàng:
        </div>
        <div class="col text-right">
            {{data.customer_name}}
        </div>
    </div>
    <div class="row info-row">
        <div class="col">
            Tổng nợ:
        </div>
        <div class="col text-right">
            {{data.debt | qbscurrency}}
        </div>
    </div>
    <div class="row info-row">
        <div class="col">
            Khách trả:
        </div>
        <div class="col text-right">
            <input [(ngModel)]="customer_pay" class="form-control form-control-custom1" (focusout)="focusoutCustomer_pay($event)" (focus)="focusFunction($event)" placeholder="Khách trả" currencyMask type="text">
        </div>
    </div>
    <div class="row info-row">
        <div class="col">
            Tiền thừa:
        </div>
        <div *ngIf="data.debt - customer_pay < 0" class="col text-right">
            {{data.debt - customer_pay | qbscurrency }}
        </div>
    </div>
    <div class="text-info mt-2 font-smaller">Chọn hóa đơn cần thanh toán</div>
    <div class="d-flex flex-wrap mb-2" style="max-height: 500px;">

        <div *ngFor="let item of SaleDebtDatas; let i = index" class="ibox qbs-ibox m-2" [ngClass]="{'selected' : item.selected}">
            <div class="ibox-body">
                <div class="mb-1 font-13">{{item.price_after_promotion|number}}</div>
                <div class="text-danger">{{item.price_after_promotion - item.payment_total|number}}</div>
                <span class="normal-lable material-icons widget-stat-icon " [ngClass]="{'text-primary' : (item.selected) }"> price_change </span>
                <a class="viewDetail" (click)="openSaleDetail(item,i)">
                    <span class=" material-icons widget-stat-icon " [ngClass]="{'text-primary' : (item.selected) }"> visibility </span>
                </a>

                <div class="checkbox-cover form-group">
                    <label class="checkbox checkbox-blue">
                        <input type="checkbox" [checked]="item.selected" (click)="clickItem(item,i)">
                        <span class="input-span"></span></label>
                </div>
                <div *ngIf="!item.selected" class="mt-3  mb-3 text-center ">
                    <span class="text-danger"> Nợ </span>
                    <span class="font-weight-bold">
                        {{item.price_after_promotion - item.payment_total|number}}</span>
                </div>
                <div *ngIf="item.selected" class="mt-3  mb-3 text-center ">
                    <span *ngIf="item.selected" class="text-danger"> Trả</span>
                    <span *ngIf="!item.selected" class="text-danger"> Nợ </span>
                    <span class="font-weight-bold" [ngClass]="{'qbs-text-line-through' : (item.selected)}">
                        {{sale_debt_pays_temp[i] |number}}</span>
                </div>

                <div class="font-15 mt-1 text-right" style="color: gray;">
                    <span><i class="ti ti-timer"></i></span>
                    <span class="ml-1 mr-1"> {{item.created_date.substr(11, 5)}}</span>
                    <span> {{item.day_month }}</span>
                </div>
            </div>
        </div>

    </div>
</div>