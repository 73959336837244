<div class="row ml-5">
    Thuộc tính sản phẩm
</div>
<div class="row">
    <div class="col">
        <div class="row">
            <div class="col-sm">
                <form class="form-horizontal" id="workingShiftListForm" #attributeForm="ngForm" (ngSubmit)="updateAttribute()">
                    <div class="ibox-body">
                        <div class="row divider-bottom" *ngFor="let item of this.appService.product_attributes; let i = index">

                            <div class="col-8">
                                <div class="form-group ow">
                                    <div class="">
                                        <input [(ngModel)]="item.name" class="form-control" [name]="'name'+i" required maxlength="30" placeholder="Tên thuộc tính ( Màu, Size, Trọng lượng ...)" type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 text-left">

                                <button *ngIf="i+1 == this.appService.product_attributes.length && this.appService.product_attributes.length < 3 " class="btn btn-outline-primary btn-icon-only btn-circle btn-sm" (click)="addProduct_attributes(i+2)">
                                    <span class="btn-label"><i style="font-size: 15px;"
                                            class="ti ti-plus"></i></span>
                                </button>
                                <button *ngIf="i != 0" class="btn btn-outline-secondary btn-icon-only btn-circle btn-sm" (click)="removeProduct_attributes(i)">
                                    <span class="btn-label"><i style="font-size: 15px;"
                                            class="ti ti-close"></i></span>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <button class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.attributeForm.invalid">
                            <span class="btn-label" *ngIf="isLoading"><span
                                    class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><i
                                    class="ti ti-check"></i></span>
                            Cập nhật
                        </button>
                    </div>
                </form>
            </div>
            <div class="col-sm"></div>
        </div>
    </div>
</div>