import { Router } from '@angular/router';
import { UtilityService } from './../../_services/utility.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ProductService } from '../../_services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Cart } from '../../model/cart';
import { HostListener } from '@angular/core';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, startWith } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/model/product';
import { SharedService } from 'src/app/_services/shared.service';
import { PromotionService } from 'src/app/_services/promotion.service';
import { CustomerPickerComponent } from 'src/app/components/customer-picker/customer-picker.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { DeleteConfirmComponent } from 'src/app/components/delete-confirm/delete-confirm.component';
import { ProductDetailComponent } from 'src/app/components/product-detail/product-detail.component';

declare var $: any;
@Component({
  selector: 'app-salev2',
  templateUrl: './salev2.component.html',
  styleUrls: ['./salev2.component.css']
})
export class Salev2Component implements OnInit {
  
  constructor(
    public appService: AppService,
    public dialog: MatDialog,
    public productService: ProductService,
    public categoryService: CategoryService,
    public utilityService: UtilityService,
    public promotionService: PromotionService,
  ) {

    
  }
 

  ngOnInit() {
    this.appService.cartObj = this.appService.listCart[this.appService.cartIndex];
  }
  ngOnDestroy() {
    localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
  }

}

