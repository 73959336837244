import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/model/product';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { ProductService } from 'src/app/_services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-add2',
  templateUrl: './product-add2.component.html',
  styleUrls: ['./product-add2.component.css']
})
export class ProductAdd2Component implements OnInit {
  products = [];
  categorys: any
  categorys_brand: any
  inputField: HTMLInputElement;// cho việc input

  product_type: number = 0;
  constructor(
    public categoryService: CategoryService,
    public dialogRef: MatDialogRef<ProductAdd2Component>,
    public appService: AppService,
    private toastr: ToastrService,
    public productService: ProductService,
  ) {
    this.loadCategory();
    this.loadCategoryBrand();

    // lấy dữ liệu unit
    let temp_unit = "";
    if (this.appService.units.length > 0)
      temp_unit = this.appService.units[0].code;

    // thêm sản phẩm vào danh sách
    this.products.push(new Product(temp_unit));
    this.products.push(new Product(temp_unit)); // thêm 1 dòng ẩn vì không trỏ xuống ngay được
  }

  ngOnInit(): void {

  }
  product_types_change(item) {
    this.product_type = item;
    console.log();
  }
  onEnter(i) {
    console.log(i);
    // thêm sản phẩm vào danh sách
    let temp = new Product(this.products[i].unit);
    temp.category_code = this.products[i].category_code;
    temp.category_code_brand = this.products[i].category_code_brand;
    this.products.push(temp);

    // đưa trỏ chuột xuống
    this.inputField = document.querySelector('input[id=code' + (i + 1) + ']');
    this.inputField.focus();

  }
  remove(i) {
    this.products.splice(i, 1);
  }
  focusFunction(event) {
    event.target.select();
  }
  insertProducts() {
    // kiểm tra dữ liệu
    // this.products.splice(this.products.length-1,1); // xóa dòng cuối cùng
    let temp_invalid = false;
    for (let i = 0; i < this.products.length - 1; i++) { // khono tinh phan tu cuoi cung
      //kiem tra nhap lieu
      if (this.products[i].code.trim() != '' && this.products[i].name.trim() != '') {
      } else {
        temp_invalid = true;
        break;
      }
      //kiem tra nhap lieu name
      if (this.products[i].name.indexOf("!")>-1) {
        temp_invalid = true;
        break;
      }
      // kiem tra loi code
      var classCheck = document.querySelector('input[id=code' + i + ']').classList;
      if (classCheck.contains("error-class")) {
        temp_invalid = true;
        break;
      }
      if (this.products[i].packing == 0) this.products[i].packing = 1;

      // gán loại dữ liệu
      this.products[i].type = this.product_type;
    }
    if (temp_invalid) {
      this.toastr.warning("Tên không được có ký tự !", "Vui lòng nhập đủ và kiểm tra lại dữ liệu");
      return;
    }

    // this.products.splice(this.products.length-1,1); // xóa dòng cuối cùng

    // kiem tra max

    console.log(this.products);
    this.productService.insertMulti(this.products).subscribe(
      async response => {

        if (response.code = 'ok' && response.affectedRows > 0) {
          // đóng popup và trả kết quả
          this.dialogRef.close({ code: 'ok', msg: response.affectedRows });
        }

      },
      error => {
        console.log(error);
      })

  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  focusOutCheckCode(i, code) {
    let filter = {
      code: code,
    }

    if (this.product_type == 1) {

    } else {
      if (code.length >= 3) {

      } else {
        this.products[i].code = '';
        return;
      }
    }

    this.productService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if (response.code == 0) {
            this.inputField = document.querySelector('input[id=code' + i + ']');
            this.inputField.classList.add("error-class");
            // this.inputField.focus();
          } else {
            this.inputField = document.querySelector('input[id=code' + i + ']');
            this.inputField.classList.remove("error-class");
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })


    // kiểm tra trong danh sách
    this.products.forEach((element, j) => {
      if (i !== j) { // loại trừ chính nó
        if (element.code == code) { //khiểm tra trùng
          this.inputField = document.querySelector('input[id=code' + j + ']');
          this.inputField.classList.add("error-class");
        }
      }
    });
  }

  async loadCategory() {
    let filter = {
      type: environment.cate_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  async loadCategoryBrand() {
    let filter = {
      type: environment.brand_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys_brand = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  onCancelClick() {
    this.dialogRef.close();
  }
}
