import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ship-setting',
  templateUrl: './ship-setting.component.html',
  styleUrls: ['./ship-setting.component.css']
})
export class ShipSettingComponent implements OnInit {

  shipping_list = [
  {
    code: '',
    name: '',
    description:'Dịch vụ giao hàng thu tiền tận nơi, tốc độ nhanh, phủ sóng toàn quốc',
    img: './assets/img/shipping/ghtk.svg',
    status: ''
  },
  {
    code: '',
    name: '',
    description:'Dịch vụ nhận gửi, vận chuyển và phát nhanh bằng đường bộ',
    img: './assets/img/shipping/vtp.svg',
    status: ''
  },
  {
    code: '',
    name: '',
    description:'Hỗ trợ các hoạt động giao nhận hàng hóa nhanh chóng',
    img: './assets/img/shipping/jt.svg',
    status: ''
  },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
