import { environment } from "src/environments/environment";

export class Category {
    name : string = "Mặc định";
    code: string = environment.CATEGORY_CODE_PREFIX;
    image_url: string ="";
    type: string ="";
    fileUpload: File;
    constructor(type:string) {
       this.type = type;
    }
}
