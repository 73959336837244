import { Ng2ImgMaxService } from 'ng2-img-max';
import { Product } from './../../model/product';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ProductService } from '../../_services/product.service';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ProductAddComponent } from 'src/app/pages/product/product-add/product-add.component';
import { ProductAdd2Component } from 'src/app/components/product-add2/product-add2.component';
import { ProductUpdateComponent } from 'src/app/pages/product/product-update/product-update.component';
import { CategoryPickerComponent } from 'src/app/components/category-picker/category-picker.component';
import { CatogoryChangePickerComponent } from 'src/app/components/catogory-change-picker/catogory-change-picker.component';
declare var $: any;
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  isLoading = false;

  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  countProduct = 0;
  // scan code
  productCodeType = 'mts';
  codeScan = "";
  productCodeTemp = "";

  //for color
  colorList = [{ code: "black" }, { code: "white" }, { code: "gray" }, { code: "red" }, { code: "green" }, { code: "blue" }, { code: "yellow" }];





  categories: any
  categorys_brand: any
  filter = {
    name: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: "",
    is_favorite : '',
    type : ""
  }


  // for add
  product = new Product("");
  fileProductImg: File;
  imageUrl: any = environment.product_avatar;
  // for update
  productSelected: any;
  isDeleted: boolean;
  productSelected_imageUrl: any = environment.product_avatar;

  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;

  constructor(
    public appService: AppService,
    public productService: ProductService,
    public categoryService: CategoryService,
    public ioService: IoService,
    private toastr: ToastrService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.sorter.sortStr = this.appService.sort_products[0].code;
    this.productSelected = this.product;
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_hang_hoa);
  }


  ngOnInit() {
    this.loadProduct();
    this.loadCategory();
    this.loadCategoryBrand();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadProduct();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadProduct();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadProduct();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadProduct();
    }
  };

  openCategoryChangePicker() {
    // lấy những data đã được chọn
    let data = [];
    this.appService.products.forEach(element => {
      if(element.checkbox){
        data.push({
          code : element.code
        });
      }
    });
    console.log(data);
    if(data.length == 0){
      this.toastr.warning("", "Bạn chưa chọn sản phẩm");
      return;
    }

    const dialogRef = this.dialog.open(CatogoryChangePickerComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '60%',
      // height: '98%',
      data: {'products':data}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);
        this.toastr.success("","Cập nhật thành công");
        this.loadProduct();
        this.loadCategory();
      }
    });
  }
  
  resetProduct() {
    let temp_unit = "";
    if (this.appService.units.length > 0)
      temp_unit = this.appService.units[0].code;

    this.product = new Product(temp_unit);
    this.imageUrl = environment.product_avatar;
    this.fileProductImg = null;
    // this.product.category_code = "DM";
    // this.product.category_code_brand = "BRAND";

    console.log(this.product.unit);
  }
  doSorting(item){
    if(item == 'name'){
      this.sorter.sortStr = this.sorter.sortStr == "name ASC" ? "name DESC": 'name ASC' ;
      console.log(this.sorter.sortStr);
    }else if(item == 'stock'){
      this.sorter.sortStr = this.sorter.sortStr == "stock ASC" ? "stock DESC": 'stock ASC' ;
      console.log(this.sorter.sortStr);
    }else if(item == 'price'){
      this.sorter.sortStr = this.sorter.sortStr == "price ASC" ? "price DESC": 'price ASC' ;
      console.log(this.sorter.sortStr);
    }
    this.loadProduct();
  }
  loadProduct() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.appService.products = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.appService.products.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

          }
        },
        error => {

        })
    }
  }
  async loadCategory() {
    let filter = {
      type: environment.cate_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categories = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  async loadCategoryBrand() {
    let filter = {
      type: environment.brand_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys_brand = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  // popup_product_add() {
  //   $(".blurButton").trigger("blur"); // bỏ focus để tránh enter

  //   this.resetProduct();
  //   this.countAllProduct();
  //   $("#add-product-modal").modal('show');
  // }


  popup_product_import() {
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    this.import_result = {
      result: false,
      success: 0,
      fail: 0,
      msg: ""
    };
    this.countAllProduct();
    $("#import-product-modal").modal('show');
  }

  checkboxAll(evt) {
    // for (let index = 0; index < this.checkboxs_changeClass.length; index++) {
    //   this.checkboxs_changeClass[index] = evt.target.checked;
    // }

    this.appService.products.forEach(element => {
      element.checkbox = evt.target.checked;

    });
    console.log(this.appService);

  }

  category_change(event) {
    this.loadProduct();
  }

  // sự kiện từ component catelist
  onClick_selectCate(event){

    if(typeof event.category_code !== "undefined"){
      this.filter.category_code = event.category_code
      console.log("category_code:",this.filter.category_code);
      this.loadProduct();
    }
  }

  sort_change(event) {
    this.loadProduct();
  }
  // getTypingSearch(ev: any) {

  //   // Reset items back to all of the items
  //   this.products = this.products_backup;

  //   // set val to the value of the searchbar
  //   const val = ev.target.value.trim();

  //   // if the value is an empty string don't filter the items
  //   if (val && val.length > 2) {
  //     this.products = this.products.filter((item) => {
  //       return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  //     })
  //   }
  // }


  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof (fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: environment.product,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  countAllProduct() {
    this.productService.countAllProduct().subscribe(
      response => {
        this.product.code = this.appService.currentUser.shop_code + (parseInt(response.CountAllProduct) + 1).toString();
        this.productCodeTemp = this.appService.currentUser.shop_code + (parseInt(response.CountAllProduct) + 1).toString();
        this.countProduct = response.CountAllProduct;
      },
      error => {
        console.error(error);
      })
  }
  changeListener($event): void {
    this.file = $event.target.files[0];
    console.log(this.file);
  }
  upload() {


    if (this.file == null || typeof (this.file) == "undefined") {
      this.toastr.warning("", "Vui lòng chọn file");
      return;
    }

    this.isLoading = true;
    console.log(this.file);
    this.productService.importProduct(this.file, this.countProduct).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
        if (response.value > 0) {
          this.import_result.result = true;
          this.import_result.success = response.value;
          this.loadProduct();
        }
      },
      error => {
        this.isLoading = false;
        this.import_result.result = true;
        this.import_result.fail = error;
        // console.error(error);
      })
  }
  popup_product_export() {
    // let filter = {};
    this.productService.exportProduct(this.filter);
  }
  popup_product_export_cantem() {
    // let filter = {};
    this.filter.type = "1";
    this.productService.exportProduct_cantem(this.filter);
  }
  productCodeTypeClick(type) {
    this.productCodeType = type;
    if (this.productCodeType == "mts") {
      this.product.code = this.productCodeTemp;
    } else if (this.productCodeType == "tn" || this.productCodeType == "qmv") {
      this.product.code = "";
    }
  }
  // focusOutCheckCode() {

  //   let filter = {
  //     code: this.product.code,
  //   }
  //   console.log(filter);
  //   this.productService.checkCode(filter).subscribe(
  //     response => {
  //       if (response) {
  //         if(response.code == 0){
  //           this.productAddForm.controls.code.setErrors("Đã tồn tại code");
  //           this.toastr.error("Đã tồn tại code","Lỗi");
  //         }
  //       }
  //       console.log(response);
  //     },
  //     error => {
  //       console.error(error);
  //     })
  // }
  async avatarChangeListener($event): Promise<void> {
    this.fileProductImg = $event.target.files[0];
    if (this.fileProductImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileProductImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileProductImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileProductImg, 300, 300).subscribe(result => {
        this.fileProductImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileProductImg);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  async avatarChangeUpdateListener($event): Promise<void> {
    this.fileProductImg = $event.target.files[0];
    if (this.fileProductImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileProductImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileProductImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileProductImg, 300, 300).subscribe(result => {
        this.fileProductImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileProductImg);
        reader.onload = (_event) => {
          this.productSelected_imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  openUpdate(item) {
    const dialogRef = this.dialog.open(ProductUpdateComponent, {
      position: {
        // left: '10px',
        // right: '10px'
        // top: '10px'
      },
      disableClose: true,
      // width: '98%',
      height: '98%',
      panelClass: 'full-screen-modal',
      data: item
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadProduct();
        this.toastr.success(result.msg, "Cập nhật thành công")
      }
    });
  }
  openProductAdd() {
    const dialogRef = this.dialog.open(ProductAddComponent, {
      position: {
        // left: '10px',
        // right: '10px'
        // top: '10px'
      },
      disableClose: true,
      // width: '98%',
      height: '98%',
      panelClass: 'full-screen-modal',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadProduct();
        this.loadCategory();
        this.toastr.success(result.msg, "Thêm mới thành công")
      }
    });
  }
  openProductAdd2() {
    const dialogRef = this.dialog.open(ProductAdd2Component, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      disableClose: true,
      width: '98%',
      height: '98%',
      panelClass: 'full-screen-modal',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadProduct();
        this.loadCategory();
        this.toastr.success(result.msg, "Thêm mới thành công")
      }
    });
  }

  toggle_favorite(){
    this.filter.is_favorite = this.filter.is_favorite == "" ? "f":"";
    this.loadProduct();
  }
  toggle_product_type(){
    this.filter.type = this.filter.type == "" ? "1": "";
    this.loadProduct();
  }
  save_toggle_favorite(item){
    item.is_favorite = item.is_favorite == "" ? "f":"";
    let data ={
      is_favorite: item.is_favorite
    }
    this.productService.updateSomeInfo(item.code,data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Cập nhật yêu thích thành công");
          this.loadProduct();
        }
      },
      error => {
        console.error(error);
      })
  }

  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.product_avatar + ')';
  // }
  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadProduct();
      }),
      map(term => term === '' ? [] : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
    this.openUpdate(r);
  }
}


