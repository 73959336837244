import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';


@Injectable()
export class SaleService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }
  insertSale(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('sale'), data,).map(data => { return data; });
  }
  updateSaleReturning(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('sale/updateSaleReturning'), data,).map(data => { return data; });
  }
  getSalesTitleBydays(filter,sorter, pagingObj){
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('sale/report/bydays'), data,).map(data => { return data; });
  }
  getRevenueCustomer(filter,sorter, pagingObj){
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('sale/report/revenueCustomer'), data,).map(data => { return data; });
  }
  getRevenueProduct(filter,sorter, pagingObj){
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('sale/report/revenueProduct'), data,).map(data => { return data; });
  }
  getRevenueProductCustomer(filter,sorter, pagingObj){
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('sale/report/revenueProductCustomer'), data,).map(data => { return data; });
  }
  getRevenueCustomerProduct(filter,sorter, pagingObj){
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('sale/report/revenueCustomerProduct'), data,).map(data => { return data; });
  }
  getRevenueEmployee(filter,sorter, pagingObj){
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('sale/report/revenueEmployee'), data,).map(data => { return data; });
  }
  exportRevenueCustomer(filter) {
    let a = filter.fromDate.replaceAll("/","-");
    let b = filter.toDate.replaceAll("/","-");
    window.open(this.appService.getApiEndpoint('io/exportRevenueCustomer/'+this.appService.currentUser.shop_code+"/"+a+"/"+b
    ) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadProduct'), filter,).map(data => { return data; });
  }
  exportRevenueEmployee(filter) {
    let a = filter.fromDate.replaceAll("/","-");
    let b = filter.toDate.replaceAll("/","-");
    window.open(this.appService.getApiEndpoint('io/exportRevenueEmployee/'+this.appService.currentUser.shop_code+"/"+a+"/"+b
    ) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadProduct'), filter,).map(data => { return data; });
  }
  exportRevenueProduct(filter) {
    let a = filter.fromDate.replaceAll("/","-");
    let b = filter.toDate.replaceAll("/","-");
    window.open(this.appService.getApiEndpoint('io/exportRevenueProduct/'+this.appService.currentUser.shop_code+"/"+a+"/"+b
    ) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadProduct'), filter,).map(data => { return data; });
  }
  exportWarehouse(filter) {
    let a = filter.fromDate.replaceAll("/","-");
    let b = filter.toDate.replaceAll("/","-");
    if(filter.fromTime == '') filter.fromTime = 'x';
    if(filter.toTime == '') filter.toTime = 'x';
    window.open(this.appService.getApiEndpoint('io/exportWarehouse/'+this.appService.currentUser.shop_code+"/"+a+"/"+b+"/"+filter.fromTime+"/"+filter.toTime
    ) );
    // return this.http.post<any>(this.appService.getApiEndpoint('io/downloadProduct'), filter,).map(data => { return data; });
  }
  loadCartDetail(sale_id) {
    return this.http.get<any>(this.appService.getApiEndpoint('sale/saleDetail/'+ sale_id),).map(data => { return data; });
  }
  loadCartDetailReturning(sale_id) {
    return this.http.get<any>(this.appService.getApiEndpoint('sale/saleDetailReturning/'+ sale_id),).map(data => { return data; });
  }
  getSalesTitleByBays(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('sale/report/titlebydays'), data,).map(data => { return data; });
  }
  getSalesTitleByMonths(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('sale/report/titlebyMonths'), data,).map(data => { return data; });
  }

  updateSaleStatus(data) {
    
    console.log(data);
    return this.http.post<any>(this.appService.getApiEndpoint('sale/updateSaleStatus'), data,).map(data => { return data; });
  }
   ///////

   getSales3months(param) {
    param.shop_code = this.appService.currentUser.shop_code;
    let data = param;
    return this.http.post<any>(this.appService.getApiEndpoint('sale.php?action=REPORT_3MONTH'), data,).map(data => { return data; });
  }
  updateSaleStatusNVGH(id, status, user_id, user_name) {
    let data = {
      id: id,
      status: status,
      shop_code: this.appService.currentUser.shop_code,
      user_id: user_id,
      user_name: user_name
    };
    console.log(data);
    return this.http.post<any>(this.appService.getApiEndpoint('sale.php?action=updateSaleStatus'), data,).map(data => { return data; });
  }

  insertSaleDetail(param) {
    param.shop_code = this.appService.currentUser.shop_code;
    let data = param;
    console.log(data);
    return this.http.post<any>(this.appService.getApiEndpoint('sale.php?action=INSERT_DETAIL'), data,).map(data => { return data; });
  }
  loadSales(param) {
    param.shop_code = this.appService.currentUser.shop_code;
    let data = param;
    return this.http.post<any>(this.appService.getApiEndpoint('sale.php?action=SALES_TODAY'), data,).map(data => { return data; });
  }
  getSales7days(param) {
    param.shop_code = this.appService.currentUser.shop_code;
    let data = param;
    return this.http.post<any>(this.appService.getApiEndpoint('sale.php?action=REPORT_7DAYS'), data,).map(data => { return data; });
  }
  getSales15days(param) {
    param.shop_code = this.appService.currentUser.shop_code;
    let data = param;
    return this.http.post<any>(this.appService.getApiEndpoint('sale.php?action=REPORT_15DAYS'), data,).map(data => { return data; });
  }
  
  
  
  countAllProduct() {
    let filter = { shop_code: this.appService.currentUser.shop_code };
    return this.http.post<any>(this.appService.getApiEndpoint('product.php?action=countAllProduct'), filter,).map(data => { return data; });
  }
  getTotalInfoShop(filter) {
    filter.shop_code = this.appService.currentUser.shop_code;
    return this.http.post<any>(this.appService.getApiEndpoint('product.php?action=SUM_SHOP_ACTION'), filter,).map(data => { return data; });
  }
  update(product) {
    delete product["modal"];
    product.shop_code = this.appService.currentUser.shop_code;

    let data = {
      // filter: filter,
      param: product
    };
    return this.http.post<any>(this.appService.getApiEndpoint('product.php?action=UPDATE'), data,).map(data => { return data; });
  }


  countPenddingImport() {
    let filter = { shop_code: this.appService.currentUser.shop_code };
    return this.http.post<any>(this.appService.getApiEndpoint('sale.php?action=countPenddingImport'), filter,).map(data => { return data; });
  }

}
