<div>
    <div class="qbs-header">
        <h4>Chuyển {{data.products.length}} sản phẩm đến nhóm</h4>
    </div>
    <div class="qbs-body">
        <div class="row mt-2 mb-4">
            <div class="select_category row align-items-center mr-1" *ngFor="let item of categorys" (click)="click_select(item)" [ngClass]="{'selected_category' : item.code == this.filter.category_code}">
                <span>
                    {{item.name}}
                </span>
                <span class="badge badge-default ml-1">{{item.products}}</span>
            </div>
        </div>
    </div>
    <div class="qbs-footer d-flex justify-content-around">
        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="changeCategory()">
            
            <span class="btn-label"> <span class="material-icons"> save </span> </span>
            Ok
        </button>


        <button class="btn btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
            <span class="btn-label"> <span class="material-icons"> close </span> </span>
            Đóng
        </button>

    </div>
</div>