<div class="qbs-header d-flex justify-content-between">
    <h5 class="modal-title">
        <span class="ml-4">Lịch sử trả hàng</span>
    </h5>

    <a (click)="close()"><i class="ti ti-close"></i></a>
</div>
<div class="qbs-body">
    <div class="chat-list">
        <div class="scroller">
            <div class="">

                <table style="width: 100%; margin-top: 10px;">
                    <tr class="item-row font-bold">
                        <td style="width: 25px; text-align: center;">

                        </td>
                        <td>
                            Sản phẩm/dịch vụ
                        </td>
                        <td class="input-quantity-cover" style="text-align: center;width: 100px;">
                            Số lượng
                        </td>
                        <td class="input-quantity-cover" style="text-align: center;width: 100px;">
                            SL trả
                        </td>
                        <td style="text-align: right;width: 125px;    font-weight: bold;">
                            Trả khách
                        </td>
                        <td style="text-align: center; width: 250px;">
                            Ngày giờ
                        </td>
                    </tr>
                    <tr *ngFor="let item of cartDetail; let i = index" class="item-row">
                        <td style="width: 25px; text-align: center;">
                            <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                            <span class="lbl-stt">{{i+1}}</span>
                        </td>
                        <td style="width: 55%;">
                            <div style="line-height: 16px;">
                                {{item.product_name}} - <span>{{item.price|number}}</span>
                                <span style="color: gray; margin-left: 3px;">{{item.unit}}</span>
                            </div>
                        </td>
                        <td style="text-align: center;">
                            {{item.quantity}}
                        </td>
                        <td style="text-align: center;">
                            {{item.quantity_return}}
                        </td>
                        <td style="text-align: right; font-weight: bold;">
                            {{item.quantity_return * item.price | qbscurrency:0}}
                        </td>
                        <td style="text-align: right; ">
                            {{item.created_date_str}}
                        </td>
                    </tr>

                </table>

            </div>
        </div>
    </div>
</div>