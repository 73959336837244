<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col-9  pl-0 d-flex justify-content-start">
            <div class="mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="cus-typeahead-template" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm khách hàng" [resultTemplate]="rt" [inputFormatter]="formatter">
            </div>
            <ng-template #rt let-r="result" let-t="term">
                <div class="row" (click)="onClick(r)" style="width: 450px;">
                    <div class="img-product-search ml-1" [style.background-image]="this.appService.showImageCustomer(r['image_url'])"></div>
                    <div class="col"> #{{ r['code'] }}</div>
                    <div class="col">
                        <ngb-highlight [result]="r.name" [term]="t">
                        </ngb-highlight>
                    </div>
                    <div class="col">Phone: {{ r['phone'] }}</div>
                    <div class="col">Nhóm: {{ r['category_code'] }}</div>
                    <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
                </div>
            </ng-template>


            <div class="pl-2">
                <select class="custom-select" [(ngModel)]="filter.category_code" (change)="this.loadCustomer();" name="category" id="category">
                    <option value="">Nhóm KH</option>
                    <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
                </select>
            </div>
            <div class="pl-2 desktop">
                <select class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="this.loadCustomer();" name="sorter" id="sorter">
                    <option [ngValue]="item.code" *ngFor="let item of appService.sort_customer">{{item.name}}</option>
                </select>
            </div>
        </div>


        <div class="col-3 pr-0">
            <div class="d-flex justify-content-end">
                <div class="pl-2"> <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="popup_customer_add($event)" id="popup_customer_add">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row paging-cover align-items-center mb-2">
        <div class="col-4">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px;">#</th>
                    <th>Khách hàng</th>
                    <th>Điện thoại</th>
                    <th>Đơn hàng</th>
                    <th>Lần cuối</th>
                    <th>Nhóm</th>
                    <th>Địa chỉ</th>
                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of appService.customers; let i = index">
                    <td>
                        <!-- <span style="position: absolute; left: 42px;
            line-height: 20px;">{{i+1}}</span> -->
                        <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageCustomer(item.image_url)"></a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.name}}</div>
                        <span style="font-size: small; font-weight: bold;">{{item.code}}</span>
                    </td>
                    <td>{{item.phone}}</td>
                    <td>{{item.sale_count}}</td>
                    <td>{{item.sale_last_date}}</td>
                    <td>
                        <div>{{item.categoryName}}</div>
                        <!-- <span style="font-size: small; font-weight: bold;">{{item.category_code}}</span> -->
                    </td>
                    <!-- <td>{{item.created_date | date :'dd/MM/yyyy'}}</td> -->
                    <td>{{item.address}}</td>

                    <td>
                        <button [disabled]="item.code == 'KH'" class="btn-adjust btn  btn-icon-only btn-circle  btn-thick" (click)='popupUpdate(item)'><i class="ti ti-pencil-alt"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>



<div class="modal fade" id="add-customer-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-customer" (ngSubmit)="insertCustomer()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới khách hàng</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <div class="form-group text-center">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+imageUrl+')'">
                            <!-- <img [src]="imageUrl"> -->
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile" name="uploadFile" (change)="changeListener($event)">
                    </div>
                    <div class="text-info">Vui lòng upload hình vuông</div>
                </div>

                <div class="form-group row">
                    <mat-form-field appearance="fill" class="qbh-mat-lg">
                        <mat-label>Mã</mat-label>
                        <input matInput placeholder="Mã" [(ngModel)]="customer.code" minlength="3" maxlength="10" (focusout)="focusOutCheckCode()" [readonly]="'mts' === CodeType" name="code" type="text" (ngModelChange)="customer.code = $event.toUpperCase()" (keydown.space)="$event.preventDefault();">
                    </mat-form-field>
                </div>
                <div class="form-group row" style="position: relative; height: 20px;">
                    <div style="position: absolute; top: -7px;">
                        <div class="">
                            <label class="radio radio-grey radio-primary radio-inline">
                                <input type="radio" name="b" [checked]="'mts' === CodeType"
                                    (click)="codeTypeClick('mts');">
                                <span class="input-span"></span>Mã tự sinh</label>
                            <label class="radio radio-grey radio-primary radio-inline">
                                <input type="radio" name="b" [checked]="'tn' === CodeType"
                                    (click)="codeTypeClick('tn');">
                                <span class="input-span"></span>tự nhập</label>
                        </div>
                    </div>
                </div>



                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Họ và tên</mat-label>
                    <input matInput placeholder="Họ và tên" type="text" [(ngModel)]="customer.name" required maxlength="30" name="name">
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Số điện thoại</mat-label>
                    <input matInput placeholder="Số điện thoại" type="text" [(ngModel)]="customer.phone" numbersOnly required name="phone" maxlength="10">
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Đơn vị</mat-label>
                    <input matInput placeholder="Đơn vị" type="text" [(ngModel)]="customer.company" maxlength="200" name="company">
                </mat-form-field>
                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Mã số thuế</mat-label>
                    <input matInput placeholder="Mã số thuế" type="text" [(ngModel)]="customer.tax" maxlength="20" name="tax">
                </mat-form-field>
                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Địa chỉ</mat-label>
                    <input matInput placeholder="Địa chỉ" type="text" [(ngModel)]="customer.address" maxlength="200" name="address">
                </mat-form-field>
                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Ghi chú</mat-label>
                    <input matInput placeholder="Địa chỉ" type="text" [(ngModel)]="customer.description" maxlength="200" name="description">
                </mat-form-field>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">
                <div class="row align-items-center">
                    <div class="col text-center">
                        <button class="btn btn-primary  btn-labeled btn-labeled-left " [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                            Thêm mới
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<div class="modal fade" id="update-customer-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-customer" (ngSubmit)="update()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật khách hàng</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <div class="form-group text-center">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="this.customerSelected_imageUrl"></div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="uploadFile_update" name="uploadFile" (change)="changeImageUpdate($event)">
                    </div>
                    <div class="text-info">Vui lòng upload hình vuông</div>
                </div>
                <!-- <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Mã KH</label>
                    <div class="col-sm-9">
                        <input [(ngModel)]="customerSelected.code" class="form-control" readonly name="code" type="text">
                    </div>
                </div> -->
                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Mã</mat-label>
                    <input matInput placeholder="Mã" [(ngModel)]="customerSelected.code" readonly="true" name="update_code">
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Họ và tên</mat-label>
                    <input matInput placeholder="Họ và tên" type="text" [(ngModel)]="customerSelected.name" required maxlength="30" name="update_name">
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Số điện thoại</mat-label>
                    <input matInput placeholder="Số điện thoại" type="text" [(ngModel)]="customerSelected.phone" numbersOnly required name="update_phone" maxlength="10">
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Đơn vị</mat-label>
                    <input matInput placeholder="Đơn vị" type="text" [(ngModel)]="customerSelected.company" maxlength="200" name="update_company">
                </mat-form-field>
                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Mã số thuế</mat-label>
                    <input matInput placeholder="Mã số thuế" type="text" [(ngModel)]="customerSelected.tax" maxlength="20" name="update_tax">
                </mat-form-field>
                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Địa chỉ</mat-label>
                    <input matInput placeholder="Địa chỉ" type="text" [(ngModel)]="customerSelected.address" maxlength="200" name="update_address">
                </mat-form-field>
                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Ghi chú</mat-label>
                    <input matInput placeholder="Địa chỉ" type="text" [(ngModel)]="customerSelected.description" maxlength="200" name="update_description">
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Nhóm khách hàng</mat-label>
                    <mat-select [(value)]="customerSelected.category_code" name="update_category_code">
                        <!-- <mat-option [value]="'NKH'">
                            Nhóm mặc định
                        </mat-option> -->
                        <mat-option *ngFor="let item of categorys" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Xóa</label>
                    <div class="col-sm-6 d-flex align-items-end">
                        <label class="ui-switch switch-icon">
                            <input type="checkbox" [(ngModel)]="isDeleted" [disabled]="customerSelected.code=='KH'"
                                name="deleted">
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="(isLoading || this.updateForm.invalid) && customerSelected.code == 'KH'">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Lưu
                </button>
            </div>
        </form>
    </div>
</div>