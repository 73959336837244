
export class Promotion {
    name : string = "";
    code: string ="";
    type: string ="";
    promotion :number = 0;
    promotion_price_range :string ="";
    description:string ="";
    address:string ="";
    time_start:string ="";
    time_end:string ="";
    time_start_str:string ="";
    time_end_str:string ="";
    cate_join:string ="";
    cateCustomer_join:string ="";
    deleted:boolean =false;
    constructor(type) {
       this.type = type;
    }
}
