import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ship-control',
  templateUrl: './ship-control.component.html',
  styleUrls: ['./ship-control.component.css']
})
export class ShipControlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
