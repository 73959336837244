import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-revenue-employee-cart-list',
  templateUrl: './revenue-employee-cart-list.component.html',
  styleUrls: ['./revenue-employee-cart-list.component.css']
})
export class RevenueEmployeeCartListComponent implements OnInit {
  @Input("datas") categories: any[] = [];
  @Input("selected") selected: string = "";
  @Output("click2") clickEvent = new EventEmitter<any>();
  constructor(
     public utilityService : UtilityService
  ) { }

  ngOnInit(): void {

  }
  select_card(code) {
    console.log(code);
    this.clickEvent.emit(code);
  }

}
