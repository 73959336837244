import { CartObj } from './../model/cart-obj';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { User } from '../model/user';
import { Router } from '@angular/router';
declare var $: any;
@Injectable()
export class AppService {

  // public currentUser = { id: 0, name: "", shop_code: "", shop_name: "", email: "", phone: "", shop_address: "", role: 0, isLoggedIn: false };
  public currentUser = new User();
  // public currentBranch = "";
  listCart = [];
  cartIndex = 0;
  cartObj = new CartObj();
  products = [];
  products_backup: any
  customer_name_temp = '' // Hiển thị tên khách hàng trong sale cart v2

  //for promotion
  promotions = [];

  //for desk service
  isFromDeskCart = false;
  deskList = [];
  deskIndex = -1;
  deskRevenue = 0;
  deskActive = 0;
  deskListPrice = [];
  workingShiftList = [];
  product_attributes = [];

  //for customer
  customers = [];

  //for paging
  viewNumsObj: any;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  // for printerSize;
  printSizeSelected = 'k80'; // mặc định
  public screenWidth: any;
  public screenHeight: any;
  public screenHeight_no_header: any;
  public itemCartHeight: any;
  public itemCartWidth_order_desire = 126;
  public itemCartWidth_sale_desire = 136;
  public itemCartWidth_order = 0;
  public itemCartWidth_sale = 0;
  public currentTime: Date = new Date();

  constructor(
    private router: Router,
    public httpClient: HttpClient,
  ) {
    this.screenWidth = window.innerWidth; // tru 5 vi scroll
    this.screenHeight = window.innerHeight;
    this.screenHeight_no_header = this.screenHeight - 59;
    
    let temp  = Math.round((this.screenWidth - 8)/this.itemCartWidth_order_desire);
    this.itemCartWidth_order = (this.screenWidth - 8)/temp - 6; // vì margin-right 6
    temp  = Math.round((this.screenWidth -  (16 + 500))/this.itemCartWidth_sale_desire); // 16: marring; 500: la phan invoice
    this.itemCartWidth_sale = (this.screenWidth -  (16 + 500))/temp - 6; // vì margin-right 6
 
    console.log('this.itemCartWidth_ok',this.itemCartWidth_order);
    console.log("screenWidth: ",this.screenWidth,this.screenHeight);
    console.log("appService");
  

    if (localStorage.getItem(environment.currentUser)) {
      this.currentUser = JSON.parse(localStorage.getItem(environment.currentUser));
      if(this.currentUser.unit_report == ""){
        this.currentUser.unit_report = environment.unit_report;
      }
    }

    //load unit 
    this.settingUnits(this.currentUser.shop_unit);

    if (localStorage.getItem(environment.listCart)) {
      this.listCart = JSON.parse(localStorage.getItem(environment.listCart));
      
      if(localStorage.getItem(environment.cartIndex) != null)
      this.cartIndex = parseInt(localStorage.getItem(environment.cartIndex));
      
      if(this.cartIndex < this.listCart.length ) 
      this.cartObj = this.listCart[this.cartIndex];
      else
      this.cartObj = this.listCart[0];

    } else {
      // đặt mặt đinh cart 0
      this.listCart[0] = new CartObj();
      this.listCart[1] = new CartObj();
      this.listCart[2] = new CartObj();
      this.listCart[3] = new CartObj();
      // this.listCart[4] = new CartObj();
    }


    //for paging
    this.viewNumsObj = this.viewNums[1].Code;
    // for service price
    if (typeof this.currentUser.shop_service_prices !== 'undefined' && this.currentUser.shop_service_prices != '')
      this.deskListPrice = JSON.parse(this.currentUser.shop_service_prices);

    //printSize
    if (localStorage.getItem(environment.prinSize)) {
      this.printSizeSelected = localStorage.getItem(environment.prinSize);
      console.log(this.printSizeSelected);
    }
    
    // cập nhật giờ
    setInterval(() => {
      this.currentTime = new Date();
      console.warn("-currentTime-", this.currentTime);
      //tính toán giờ dịch vụ - cho từng giờ
      this.calculateServicePrice();

    }, 60000);

  }

  // private apiHost = "https://localhost";
  private apiHost = environment.apiUrl;
  private apiEndpoint = environment.apiEndpoint;
  private apiEndpoint_upload = environment.apiEndpoint_upload;
  public customer_code = environment.CUSTOMER_CODE_PREFIX;
  public customer_name = environment.CUSTOMER_NAME_PREFIX;
  public supplier_name = "Phiếu nhập kho ";

  public DAY_REPORT_DEFAULT = 15;

  public getApiEndpoint(service: string) {
    return this.apiHost + this.apiEndpoint + service;
  }
  public getUrlImage(imageURl: string) {
    // console.log(imageURl);
    return this.apiHost + this.apiEndpoint_upload + this.currentUser.shop_code + "/" + imageURl;
  }
  public showImageProduct(image_url: string) {
    // console.log(imageURl);
    return (image_url == "" || image_url == null) ? 'url(' + environment.product_avatar + ')' : 'url(' + this.getUrlImage(image_url) + ')';
  }
  public showImageCustomer(image_url) {
    return (image_url == "" || image_url == null) ? 'url(' + environment.customer_avatar + ')' : 'url(' + this.getUrlImage(image_url) + ')';
  }

  public settingUnits(units: string) {
    if (units == undefined) return "";
    let temp = units.split(",");
    this.units = [];
    for (let i = 0; i < temp.length; i++) {
      this.units.push({ 'code': temp[i].trim(), 'value': temp[i].trim() });
    }
  }

  openCheckout() {
    this.isFromDeskCart = false;

    // if(this.router.url != '/salev2'){
    //     if (!$('body').hasClass('has-backdrop')) {
    //     $('.checkout-widget').backdrop();
    //   }
    // }
    
    console.log(this.listCart[this.cartIndex]);

    this.cartObj = this.listCart[this.cartIndex];
    this.customer_name_temp = this.cartObj.customer_name;

    this.prepareSaleObject({ sale_status: 99 ,isPromotion:true,isPromotionDetail:true,isCheckCustomerPay:false})

    localStorage.setItem(environment.cartIndex, this.cartIndex.toString());
  }
  toggleCheckout() {
    if ($('body').hasClass('has-backdrop')) {
      $('.checkout-widget').closeCheckout();
    }else{
      $('.checkout-widget').backdrop();
    }
  }
  closeCheckout() {
    $('.checkout-widget').closeCheckout();
  }
  openCheckoutDesk() {
    // deskList = [];
    // deskIndex = -1;
    this.isFromDeskCart = true;
    
    this.cartObj = this.deskList[this.deskIndex];
    // gán để hiển thị tên trên search customer
    this.customer_name_temp = this.cartObj.customer_name;

    //tính toán giờ dịch vụ
    this.calculateServicePrice();

    this.cartObj.price_after_promotion = this.cartObj.price_totol - this.cartObj.price_promotion;
    this.cartObj.customer_pay = this.cartObj.price_after_promotion;

    localStorage.setItem(environment.deskIndex, this.deskIndex.toString());
  }
  public calculateServicePrice() {
    if (this.cartObj.is_time_service == 1) {
      var timeEnd_temp = new Date().getTime();

      var temp = 0;
      this.cartObj.cart_service.forEach((element,i) => {
        if ((element.isActive == 1 && element.isEnable == 1) || i == (this.cartObj.cart_service.length - 1)) {// chỉ những dòng đang hoạt động hoặc dòng cuối cùng
          if (element.serviceEndTimeNumberType > 0) {
            temp = this.diff_minutes(element.serviceStartTimeNumberType, element.serviceEndTimeNumberType);
          } else {
            temp = this.diff_minutes(element.serviceStartTimeNumberType, timeEnd_temp);
          }
          element.quantity = (temp / 60).toFixed(3); // số lượng = phút chia ra giờ
          element.quantityLable = this.timeConvertMtoHM(temp);
          element.amount = Math.round(element.quantity * element.price);
          console.log(element.quantityLable + " ^ "+ (element.quantity * element.price) + " ^ "+ element.amount);
        }
      });

      this.checkOutService("");
    }
  }

  public diff_minutes(dt2, dt1) {
    var diff = (dt2 - dt1) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff)) + 1;
  }
  public timeConvertMtoHM(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return (rhours < 10 ? "0" + rhours : rhours) + ":" + (rminutes < 10 ? "0" + rminutes : rminutes);
  }
  checkOutService(type) {
    if (type == "reset") {
      this.deskList[this.deskIndex].service_price = 0;
      this.deskList[this.deskIndex].price_totol -= this.deskList[this.deskIndex].service_price_temp;
      this.deskList[this.deskIndex].service_price = 0;
    } else {
      let service_price_temp = 0
      for (var i = 0; i < this.deskList[this.deskIndex].cart_service.length; i++) {
        service_price_temp += this.deskList[this.deskIndex].cart_service[i].amount;
      }
      this.deskList[this.deskIndex].service_price = service_price_temp;
      this.deskList[this.deskIndex].service_price_temp = service_price_temp;
    }
    // cập nhật lại giá
    this.prepareSaleObject({ sale_status: 99 ,isPromotion:false,isPromotionDetail:false,isCheckCustomerPay:false})
    // this.preparePromotion(this.deskList[this.deskIndex]);
  }

  prepareSaleObject({ sale_status,isPromotion,isPromotionDetail,isCheckCustomerPay}) {
    console.log("this.cartObj this.cartObj this.cartObj this.cartObj");
    console.log(this.cartObj.cart);
   
    if(isPromotionDetail) this.preparePromotionEachProduct();
    console.log(this.cartObj.cart);
    // for insert detail sale
    let product_codes = "";
    let product_names = "";
    let product_stocks = "";
    let prices = "";
    let units = "";
    let packings = "";
    let quantitys = "";
    let unit_types = "";
    // for cartObj ( cart chính, cart to)
    let price_totol = this.cartObj.service_price; // tổng giá trị  = 0 + giá dịch vụ
    let quantity = 0;
    for (let i = 0; i < this.cartObj.cart.length; i++) {
      if(this.cartObj.cart[i].product.price == null || typeof(this.cartObj.cart[i].product.price) == "undefined")
      this.cartObj.cart[i].product.price = 0;
      // dành cho cartObj
      price_totol += this.cartObj.cart[i].quantity * this.cartObj.cart[i].product.price;
      quantity =  Math.round((quantity + this.cartObj.cart[i].quantity) * 100) / 100;  // chỉ là cộng 2 số thập phân
      // dành cho detail
      if (this.cartObj.cart.length == i + 1) {
        product_codes += this.cartObj.cart[i].product.code.toString();
        product_names += this.cartObj.cart[i].product.name.toString();
        product_stocks += this.cartObj.cart[i].product.stock.toString();
        prices += this.cartObj.cart[i].product.price.toString();
        units += this.cartObj.cart[i].product.unit_default !=='' ? this.cartObj.cart[i].product.unit_default.toString() : this.cartObj.cart[i].product.unit.toString();
        packings += this.cartObj.cart[i].product.unit2==this.cartObj.cart[i].product.unit_default ? this.cartObj.cart[i].product.packing.toString() : '1';
        unit_types += this.cartObj.cart[i].unit_type;
        quantitys += this.cartObj.cart[i].quantity.toString();
      } else {
        product_codes += this.cartObj.cart[i].product.code.toString() + ",";
        product_names += this.cartObj.cart[i].product.name.toString() + "!";
        product_stocks += this.cartObj.cart[i].product.stock.toString() + ",";
        prices += this.cartObj.cart[i].product.price.toString() + ",";
        units += this.cartObj.cart[i].product.unit_default !=='' ? this.cartObj.cart[i].product.unit_default.toString()+ "," : this.cartObj.cart[i].product.unit.toString() + ",";
        packings += this.cartObj.cart[i].product.unit2==this.cartObj.cart[i].product.unit_default ? this.cartObj.cart[i].product.packing.toString()+ "," : '1' + ",";
        unit_types += this.cartObj.cart[i].unit_type + ",";
        quantitys += this.cartObj.cart[i].quantity.toString() + ",";
      }
    }

    // let cartObj = new CartObj();
    // for insert  sale
    // cartObj.code: "",
    this.cartObj.type = this.cartObj.type; // 1 = nhập hàng, 0 = bán hàng, 0 is sale, 1 is imporrt
    this.cartObj.status = sale_status; //1: đã thanh toán, 0: đặt hàng, 2: Chờ thanh toán, 3: đã hủy, 4: đang xử lý
    this.cartObj.product_quantity = this.cartObj.cart.length;// số lượng mặt hàng
    this.cartObj.quantity = quantity; // tổng số lượng sản phẩm của giỏ hàng
    this.cartObj.price_totol = price_totol; // giá sản phẩm  + giá dich vụ
    this.cartObj.price_after_promotion = this.cartObj.price_totol;


    // this.cartObj.price_promotion = this.cartObj.price_promotion;
    // this.cartObj.promotion_code = this.code;
    // this.cartObj.promotion_type = this.type;
    // this.cartObj.promotion_promotion = this.promotion;


    // this.appService.cartObj.total_quantity = product_quantity;
    this.cartObj.customer_id = this.cartObj.customer_id;
    this.cartObj.customer_code = this.cartObj.customer_code == '' ? environment.CUSTOMER_CODE_PREFIX :this.cartObj.customer_code;
    this.cartObj.customer_name = this.cartObj.customer_name == '' ? environment.CUSTOMER_NAME_PREFIX :this.cartObj.customer_name;
    this.cartObj.user_id = this.currentUser.id;
    this.cartObj.user_name = this.currentUser.name;
    // this.cartObj.note = "";

    //for service price
    this.cartObj.service_price = this.cartObj.service_price;
    this.cartObj.service_price_temp = 0;
    this.cartObj.service_detail_str = JSON.stringify(this.cartObj.cart_service);


    this.cartObj.product_codes = product_codes;// for insert detail sale
    this.cartObj.product_names = product_names;// for insert detail sale
    this.cartObj.product_stocks = product_stocks;// for insert detail sale
    this.cartObj.prices = prices;// for insert detail sale
    this.cartObj.units = units;// for insert detail sale
    this.cartObj.packings = packings;// for insert detail sale
    this.cartObj.unit_types = unit_types;// for insert detail sale
    this.cartObj.quantitys = quantitys;// for insert detail sale
    
    if(isPromotion) this.preparePromotion(this.cartObj);// cập nhật khuyến mãi tổng
    

    this.cartObj.customer_pay = isCheckCustomerPay ? this.cartObj.customer_pay : this.cartObj.price_after_promotion; // Tiền khách đưa
    this.cartObj.payment_total =  (this.cartObj.customer_pay > this.cartObj.price_after_promotion) ? this.cartObj.price_after_promotion : this.cartObj.customer_pay ; // Tiền khách thanh toán

    return this.cartObj;
  }
  preparePromotionEachProduct() {
    console.log("checking promotion.....", this.promotions.length);
    if (this.promotions.length > 0 && this.cartObj.cart.length > 0) {
      this.promotions.forEach((element, i) => {
        let cateCustomer_joins = element.cateCustomer_join.split(","); // lấy các nhóm khác hàng được tham gia khuyến mãi
        if (element.type == 'dl') { // kiểm tra kiểu khuyến mãi là kiểu giá đại lý 
          if (cateCustomer_joins.indexOf(this.cartObj.customer_category_code) >= 0) { // kiểm tra kiểu khuyến mãi là kiểu giá đại lý và khách hàng thuộc nhóm khuyến mãi
            let cate_joins = element.cate_join.split(","); // lấy các nhóm sản phẩm được tham gia khuyến mãi
            this.cartObj.cart.forEach(element => { // lắp tất cả các item cart
              if (cate_joins.indexOf(element.product.category_code) >= 0) {// kiểm tra sản phẩm có nằm trong khuyến mãi không
                if( element.unit_type == 'unit') element.product.price = element.product.price2;
                else if( element.unit_type == 'unit2') element.product.price = element.product.price2_unit2;
              }
            });
          } 
          else { // nếu kh không thuộc khuyến mãi
            this.cartObj.cart.forEach(element => { // lắp tất cả các item cart
              if( element.unit_type == 'unit') element.product.price = element.product.price_temp;
              else if( element.unit_type == 'unit2') element.product.price = element.product.price_unit2;
            });
          }
        }else if(element.type == '%'){
          if (cateCustomer_joins.indexOf(this.cartObj.customer_category_code) >= 0) { // kiểm tra nhóm khách hàng thuộc được khuyến mãi không
            let cate_joins = element.cate_join.split(","); // lấy các nhóm sản phẩm được tham gia khuyến mãi
            this.cartObj.cart.forEach(item => { // lắp tất cả các item cart
              if (cate_joins.indexOf(item.product.category_code) >= 0) {// kiểm tra sản phẩm có nằm trong khuyến mãi không
                item.product.price = Math.round(item.product.price_temp * ((100-element.promotion) / 100 ));
                item.promotion_name = element.name; // gán tên chương trình khuyến mãi

              }
            });
          } 
        }else if(element.type == 'vnd'){
          if (cateCustomer_joins.indexOf(this.cartObj.customer_category_code) >= 0) { // kiểm tra nhóm khách hàng thuộc được khuyến mãi không
            let cate_joins = element.cate_join.split(","); // lấy các nhóm sản phẩm được tham gia khuyến mãi
            this.cartObj.cart.forEach(item => { // lắp tất cả các item cart
              if (cate_joins.indexOf(item.product.category_code) >= 0) {// kiểm tra sản phẩm có nằm trong khuyến mãi không
                item.product.price = item.product.price_temp-element.promotion;
                item.promotion_name = element.name; // gán tên chương trình khuyến mãi
              }
            });
          } 
        }
      });
    }
  }
  preparePromotion(cartObj) {
    if (cartObj.promotion_type == 'đ') {
      cartObj.price_promotion = cartObj.promotion_promotion;
    } else if (cartObj.promotion_type == '%') {
      if (cartObj.promotion_promotion > 100) {
        cartObj.promotion_promotion = 100;
      }
      cartObj.price_promotion = Math.floor(cartObj.price_totol * (cartObj.promotion_promotion / 100));
    }
    cartObj.price_after_promotion = cartObj.price_totol - cartObj.price_promotion;
    
    if(cartObj.customer_pay >= cartObj.price_after_promotion)
    cartObj.customer_pay = cartObj.price_after_promotion;
  }
  public updateDeskRevenue() {
    this.deskRevenue = 0;
    this.deskActive = 0;
    console.log('updateDeskRevenue d');
    for (let item of this.deskList) {
      // tính price_totol
      let price_totol_temp = 0
      for (let i = 0; i < item.cart.length; i++) {
        price_totol_temp += item.cart[i].quantity * item.cart[i].product.price;
      }
      for (let i = 0; i < item.cart_service.length; i++) {
        price_totol_temp += item.cart_service[i].amount;;
      }
      item.price_totol = price_totol_temp;
      this.deskRevenue += item.price_totol;
      if (typeof item.cart !== 'undefined' && item.cart.length > 0 || typeof item.cart_service !== 'undefined' && item.cart_service.length > 0)
        this.deskActive++;
    }
  }


  public viewNums = [
    { 'Code': 20, 'Value': '20 items' },
    { 'Code': 30, 'Value': '30 items' },
    { 'Code': 40, 'Value': '40 items' },
    { 'Code': 50, 'Value': '50 items' },
    { 'Code': 100, 'Value': '100 items' },
    { 'Code': 200, 'Value': '200 items' },
  ];
  public sort_promotions = [
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'created_date ASC',
      name: 'Xếp theo thời gian tạo',
    },

  ];
  public sort_products = [
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'modified_date DESC',
      name: 'Xếp theo thời gian sửa',
    },
    {
      code: 'stock DESC',
      name: 'Xếp theo tồn kho',
    },
    {
      code: 'price DESC',
      name: 'Xếp theo giá bán',
    },
  ];
  public sort_customer = [
    {
      code: 'name ASC',
      name: 'Xếp theo tên A-Z',
    },
    {
      code: 'name DESC',
      name: 'Xếp theo tên Z-A',
    },
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'modified_date DESC',
      name: 'Xếp theo thời gian sửa',
    },
  ];
  public sort_reports = [
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tăng',
    },
    {
      code: 'created_date ASC',
      name: 'Xếp theo thời gian giảm',
    },
    {
      code: 'quantity DESC',
      name: 'Xếp theo số lượng giảm',
    },
    {
      code: 'quantity ASC',
      name: 'Xếp theo số lượng tăng',
    },
    {
      code: 'product_quantity DESC',
      name: 'Xếp theo sản phẩm giảm',
    },
    {
      code: 'product_quantity ASC',
      name: 'Xếp theo sản phẩm tăng',
    },
  ];
  public sort_revenue_customer_reports = [
    {
      code: 'revenue DESC',
      name: 'Doanh thu cao',
    },
    {
      code: 'revenue ASC',
      name: 'Doanh thu thấp',
    },
    {
      code: 'quantity_unit DESC',
      name: 'Số lượng bán cao',
    },
    {
      code: 'quantity_unit ASC',
      name: 'Số lượng bán thấp',
    },
  ];
  public sort_revenue_warehouse_reports = [
    {
      code: 'rangeIn_revenueOut DESC',
      name: 'Doanh thu cao',
    },
    {
      code: 'rangeIn_revenueOut ASC',
      name: 'Doanh thu thấp',
    },
    {
      code: 'rangeIn_quantityOut_unit DESC',
      name: 'Số lượng bán cao',
    },
    {
      code: 'rangeIn_quantityOut_unit ASC',
      name: 'Số lượng bán thấp',
    },
  ];
  public sort_category = [
    {
      code: 'od ASC',
      name: 'Xếp theo thứ tự',
    },
    {
      code: 'name ASC',
      name: 'Xếp theo tên A-Z',
    },
    {
      code: 'name DESC',
      name: 'Xếp theo tên Z-A',
    },
    {
      code: 'created_date DESC',
      name: 'Xếp theo thời gian tạo',
    },
    {
      code: 'modified_date DESC',
      name: 'Xếp theo thời gian sửa',
    },
    {
      code: 'stock_price DESC',
      name: 'Xếp theo doanh số',
    },
    // {
    //   code: 'stock DESC',
    //   name: 'Xếp theo tồn kho',
    // },
    // {
    //   code: 'price DESC',
    //   name: 'Xếp theo giá bán',
    // },
  ];
  public sale_statuss = [
    {
      code: -1,
      name: 'Trạng thái: Tất cả',
    },
    {
      code: 0,
      name: 'Đặt hàng',
    },
    {
      code: 1,
      name: 'Hoàn thành',
    },
    {
      code: 4,
      name: 'Đang xử lý',
    },
    {
      code: 3,
      name: 'Đã hủy',
    },

  ];
  public day_nums = [
    {
      code: 7,
      name: '7 ngày',
    },
    {
      code: 10,
      name: '10 ngày',
    },
    {
      code: 15,
      name: '15 ngày',
    },
    {
      code: 20,
      name: '20 ngày',
    },
    {
      code: 25,
      name: '25 ngày',
    },
    {
      code: 30,
      name: '30 ngày',
    },
  ];
  public month_nums = [
    {
      code: 3,
      name: '3 tháng',
    },
    {
      code: 6,
      name: '6 tháng',
    },
    {
      code: 9,
      name: '9 tháng',
    },
    {
      code: 12,
      name: '12  tháng',
    },
    {
      code: 24,
      name: '24 tháng',
    }
  ];
  public memberships = [
    {
      code: "admin",
      name: 'Chủ sở hữu',
      color: ""
    },
    {
      code: "ql",
      name: 'Quản lý/Kế toán',
      color: ""
    },
    {
      code: 'nvbh',
      name: 'NV bán hàng',
      color: ""
    },
    {
      code: 'nvgh',
      name: 'NV giao hàng',
      color: ""
    },
    {
      code: 'nvkt',
      name: 'NV kỹ thuật',
      color: ""
    },
  ];
  public units = [];
  public units_weight_type = [
    { 'code': 'kg', 'value': 'kg' },
    { 'code': 'g', 'value': 'g' },
  ];

  public colors = [
    { 'code': 'sa', 'value': 'Mặc định' },
    { 'code': 'Đen', 'value': 'Đen' },
    { 'code': 'Trắng', 'value': 'Trắng' },
    { 'code': 'Ghi', 'value': 'Ghi' },
    { 'code': 'Vàng', 'value': 'Vàng' },
    { 'code': 'Cam', 'value': 'Cam' },
    { 'code': 'Xanh', 'value': 'Xanh' },
    { 'code': 'Hồng', 'value': 'Hồng' },
  ];
  public promotion_price_ranges = [
    { 'code': 'Đơn giá 2', 'value': 'Đơn giá buôn' },
  ];
  public product_types = [
    { 'code': 0, 'value': 'Hàng hóa thường','img':'./assets/img/icons/package.png' },
    { 'code': 1, 'value': 'Hàng hóa khối lượng','img':'./assets/img/icons/weighing-machine.png' },
  ];
  public promotion_types = [
    { 'code': '%', 'name': '%' },
    { 'code': 'vnd', 'name': 'Tiền' },
    { 'code': 'dl', 'name': 'Giá đại lý' },
  ];
  public printSizeList = [
    { 'code': 'none', 'name': 'Không dùng'},
    { 'code': 'k80', 'name': 'Máy in khổ 80mm'},
    { 'code': 'k58', 'name': 'Máy in khổ 58mm'},
  ];
}
