<div class="page-content fade-in-up">


    <div class="table-responsive">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th>Access</th>
                    <th>Refresh</th>
                    <th>Ngày</th>
                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data; let i = index">
                    <td>

                    </td>
                    <td>
                        {{item.zalo_access_token}}
                    </td>
                    <td>
                        {{item.zalo_refresh_token }}
                    </td>
                    <td>
                        {{item.modified_date }}
                    </td>
                    <td>
                        <button class="btn-adjust btn btn-secondary btn-icon-only btn-circle btn-sm btn-thick" (click)='popupUpdate(item)'><i class="ti ti-pencil-alt"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal fade" id="update-item-modal">
        <div class="modal-dialog" role="document">
            <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category" (ngSubmit)="updateItem()">
                <div class="modal-header p-4">
                    <h5 class="modal-title">Cập nhật</h5>
                    <a data-dismiss="modal"><i class="ti ti-close"></i></a>
                </div>
                <div class="modal-body p-4">


                    <div class="form-group ow">
                        <label class="col-form-label">Access</label>
                        <div class="">
                            <input [(ngModel)]="item.zalo_access_token" class="form-control" name="access" placeholder="Access" type="text" (focus)="focusFunction($event)">
                        </div>
                    </div>
                    <div class="form-group ow">
                        <label class="col-form-label">Refresh</label>
                        <div class="">
                            <input [(ngModel)]="item.zalo_refresh_token" class="form-control" name="refresh" placeholder="Refresh" type="text" (focus)="focusFunction($event)">
                        </div>
                    </div>


                </div>
                <div class="modal-footer justify-content-around bg-primary-50">


                    <button class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.updateForm.invalid">
                  <span class="btn-label" *ngIf="isLoading" ><span class="spinner-border"></span></span>
                  <span class="btn-label" *ngIf="!isLoading" ><i class="ti ti-check"></i></span>
                  Lưu
                </button>

                </div>
            </form>
        </div>
    </div>

</div>