import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteConfirmComponent } from '../delete-confirm/delete-confirm.component';

@Component({
  selector: 'app-product-notfound',
  templateUrl: './product-notfound.component.html',
  styleUrls: ['./product-notfound.component.css']
})
export class ProductNotfoundComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 

  }

  ngOnInit(): void {

  }
  okClick(){
    this.dialogRef.close('ok');
  }
  cancelClick() {
    this.dialogRef.close('no');
  }

}
