import { Router } from '@angular/router';
import { UtilityService } from './../../_services/utility.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ProductService } from '../../_services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Cart } from '../../model/cart';
import { HostListener } from '@angular/core';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/model/product';
import { SharedService } from 'src/app/_services/shared.service';
declare var $: any;

@Component({
  selector: 'app-desksale',
  templateUrl: './desksale.component.html',
  styleUrls: ['./desksale.component.css']
})
export class DesksaleComponent implements OnInit {

  loading = false;
  display_type = 0;
  //for search and scanner
  searchValue = "";
  productCodeTemp = "";
  // searchStr: string;
  searchFailed = false;


  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;
  categorys_brand: any
  // for Subscription
  private subscription: Subscription;

  constructor(
    public appService: AppService,
    private router: Router,
    public productService: ProductService,
    public categoryService: CategoryService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private sharedService: SharedService

  ) {


    this.sorter.sortStr = this.appService.sort_products[0].code;
    this.productSelected = this.product;
    if (localStorage.getItem(environment.display_type)) {
      this.display_type = parseInt(localStorage.getItem(environment.display_type));
    }

    // if(this.appService.products.length == 0){
    this.loadProduct();
    this.loadCategoryBrand();
    this.loadCategory();

    this.subscription = this.sharedService.getClickEvent().subscribe((type) => {
      console.log("Sale component:",type);
      this.loadProduct();
    })

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);
    if (event.key === 'Enter') {
      console.log(this.productCodeTemp);
      if (this.productCodeTemp.trim().length > 3) {
        this.searchValue = this.productCodeTemp;
        this.productCodeTemp = "";
        console.log(this.searchValue);
        // this.popupAddToCart(this.utilityService.searchProduct(this.searchValue));
        let product_temp = this.utilityService.searchProduct(this.searchValue);
        if (product_temp) {
          this.productSelected = product_temp;
          this.addToCartDesk(this.productSelected,1);
          this.appService.openCheckout();
        }


      }
    } else {
      if (this.productCodeTemp === null || this.productCodeTemp === "") {
        this.productCodeTemp = event.key;
      } else {
        this.productCodeTemp = this.productCodeTemp + event.key;
      }
    }
  }

  // products = [];
  products_backup: any
  categories: any
  filter = {
    code: "",
    name: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  sorter = {
    sortStr: "",
  }

  // for add product
  product = new Product("");
  // for update product
  productSelected: any;
  isDeleted: boolean;

  //for cart
  productCart = {
    name: "",
    code: "",
    price: 0,
    price_in: 0,
    description: "",
  };
  txt_quantity = 1;



  ngOnInit() {
    if (!this.appService.currentUser.isLoggedIn) {
      this.router.navigate(['/login', { id: 1990 }]);
      return;
    }
    this.loadCategory();
  }
  ngAfterViewInit() {
    // dropdown danh mục

  }



  resetProduct() {
    let temp_unit = "";
    if(this.appService.units.length > 0)
    temp_unit = this.appService.units[0].code;

    this.product = new Product(temp_unit);

  }
  loadProduct() {
    let pagingObj = {
      viewNumsObj: 2000,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.appService.products = response.data;
            this.appService.products_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.appService.products.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

          }
        },
        error => {

        })
    }
  }
  loadTotalPage() {
    // request to server
    this.productService.loadItemSum(this.filter)
      .subscribe(
        response => {
          console.log('ItemListComponent load Item');
          console.log(response);
          this.totalItems = response[0].NumberOfItems;
          let pages_temp = Math.ceil(this.totalItems / this.appService.viewNumsObj);
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          console.log(pages_temp);
          console.log(this.listPage);
        },
        error => {
          console.error(error);
        });
  };

  async loadCategory() {

    let filter = {
      type: environment.cate_type
    };

    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.categories = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })

  }
  popup_product_add(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-product-modal").modal('show');
  }


  popup_product_import(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-customer-modal").modal('show');
  }



  category_change(event) {
    this.loadProduct();
  }
  select_category(code) {
    console.log(code);
    this.filter.category_code = code;
    this.loadProduct();
  }
  sort_change(event) {
    this.loadProduct();
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.appService.products = this.appService.products_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.appService.products = this.appService.products.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  // insertProduct() {

  //   if (this.product.name.trim() == "") {
  //     this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
  //     return;
  //   } else if (this.product.category_code.trim() == "") {
  //     this.toastr.error("", "Bạn chưa chọn danh mục hàng hóa");
  //     return;
  //   }

  //   this.loading = true;
  //   this.productService.insert(this.product).subscribe(
  //     async response => {
  //       this.loading = false;
  //       console.log(response);
  //       if (response) {
  //         this.toastr.success("", "Thêm mới hàng hóa thành công");
  //         $("#add-product-modal").modal('hide');
  //         this.resetProduct();
  //         this.loadProduct();
  //       }
  //     },
  //     error => {
  //       this.loading = false;
  //       console.error(error);
  //     })


  // }
  // updateProduct() {

  //   this.productSelected.deleted = this.isDeleted ? 1 : 0;
  //   this.loading = true;
  //   this.productService.update(this.productSelected).subscribe(
  //     async response => {
  //       this.loading = false;
  //       console.log(response);
  //       if (response) {
  //         this.toastr.success("", "Cập nhật hàng hóa thành công");
  //         $("#update-product-modal").modal('hide');
  //         this.loadProduct();
  //       }
  //     },
  //     error => {
  //       this.loading = false;
  //       console.error(error);
  //     })

  // }
  countAllProduct() {

    this.productService.countAllProduct().subscribe(
      response => {
        this.product.code = this.appService.currentUser.shop_code + (parseInt(response[0].CountAllProduct) + 1).toString();
      },
      error => {
        console.error(error);
      })

  }

  popupAddToCart(item) {
    if (item) {
      this.txt_quantity = 1;
      this.productSelected = item;

      if (!$('#add-to-cart-modal').hasClass('show')) {
        $("#add-to-cart-modal").modal('show');
      }
    }
  }

  quantity_add() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity)) {
      this.txt_quantity = ++this.txt_quantity;
    }
  }
  quantity_sub() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity) && this.txt_quantity > 1) {
      this.txt_quantity = --this.txt_quantity;
    }
  }
  quantity_add2(item) {
    if (!isNaN(item.temp_quantity)) {
      item.temp_quantity = ++item.temp_quantity;
    }
    console.log(item);
  }
  
  quantity_sub2(item) {
    if (!isNaN(item.temp_quantity) && item.temp_quantity > 1) {
      item.temp_quantity = --item.temp_quantity;
    }
    console.log(item);
  }

  // addToCart(product) {
  //   console.log(this.appService.listCart);
  //   console.log(this.appService.cartIndex);
  //   console.log("--------------------------------------------");
  //   let cart = new Cart();
  //   cart.product = product;
  //   // cart.quantity = this.txt_quantity;
  //   cart.quantity = parseInt(this.txt_quantity.toString());
  //   //kiểm tra trong giỏ đã có sp chưa, nếu chưa thì thêm nếu có thì update
  //   if (this.appService.listCart[this.appService.cartIndex].cart.length > 0) {
  //     if (this.utilityService.checkToAddCart(this.appService.listCart[this.appService.cartIndex].cart, cart)) {
  //       // nếu có thì thêm luôn ở trong hàm check
  //     }
  //     else {
  //       this.appService.listCart[this.appService.cartIndex].cart.push(cart);
  //     }
  //   } else {
  //     this.appService.listCart[this.appService.cartIndex].cart.push(cart);
  //   }

  //   this.appService.listCart[this.appService.cartIndex].total_quantity = this.appService.listCart[this.appService.cartIndex].total_quantity + cart.quantity;
  //   this.appService.listCart[this.appService.cartIndex].price_totol = this.appService.listCart[this.appService.cartIndex].price_totol + (cart.quantity * product.price);
  //   this.appService.listCart[this.appService.cartIndex].customer_pay = this.appService.listCart[this.appService.cartIndex].price_totol;
  //   // this.storage.set('cartList',this.cartList);
  //   localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
  //   product.temp_quantity = 1; // reset to default
  //   $("#add-to-cart-modal").modal('hide');
  // }
  addToCartDesk(product,quantity) {
    console.log("quantity",quantity);
    if(quantity == null || isNaN(quantity) || quantity <= 0){
        this.toastr.success("", "Số lượng không hợp lệ");
        return
    }

    let cart = new Cart();
    cart.product = product;
    cart.quantity = quantity;
    // cart.quantity = parseInt(product.temp_quantity);
    // cart.quantity = parseInt(this.txt_quantity.toString());
    console.log("cart",cart);

    if(typeof  this.appService.deskList[this.appService.deskIndex].cart === 'undefined'){
      alert("Đã có lỗi vui lòng đăng nhập lại");
    }
    if (this.appService.deskList[this.appService.deskIndex].cart.length > 0) {
      if (this.utilityService.checkToAddCart(this.appService.deskList[this.appService.deskIndex].cart, cart) > -1) {
        // nếu có thì thêm luôn ở trong hàm check
      }
      else {
        this.appService.deskList[this.appService.deskIndex].cart.push(cart);
      }
    } else {
      this.appService.deskList[this.appService.deskIndex].cart.push(cart);
    }

    this.appService.deskList[this.appService.deskIndex].total_quantity = this.utilityService.toFixedIfNecessary((this.appService.deskList[this.appService.deskIndex].total_quantity + cart.quantity));
    this.appService.deskList[this.appService.deskIndex].price_totol = this.utilityService.toFixedIfNecessary((this.appService.deskList[this.appService.deskIndex].price_totol + (cart.quantity * product.price)));
    this.appService.deskList[this.appService.deskIndex].customer_pay = this.appService.deskList[this.appService.deskIndex].price_totol;
    // this.storage.set('cartList',this.cartList);
    localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
    product.temp_quantity = 1; // reset to default
    $("#add-to-cart-modal").modal('hide');
    this.toastr.success("", "Thêm vào hóa đơn thành công");
  }

  openCheckout(product,quantity) {
    this.addToCartDesk(product,quantity);
    this.appService.openCheckout();
  }
  openCheckout2(product,quantity) {
    this.addToCartDesk(product,quantity);
    this.appService.openCheckout();
    // product.temp_quantity = 1;
  }

  popupPayment(item) {
    console.log("xacd");
  }
  changeViewType(type) {
    this.display_type = type;
    localStorage.setItem(environment.display_type, this.display_type.toString());
  }


  //for paging
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.appService.viewNumsObj);
    this.loadProduct();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadProduct();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadProduct();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadProduct();
    }
  };






  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        // this.filter.code = this.filter.name;
        console.log(this.filter.name);
        this.loadProduct();
      }),
      map(term => term === '' ? []
        : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => {

      }),
    )

  onClick(r) {
    console.log(r);
    this.filter.name = "";
    console.log("this.filter.name", this.filter.name);
    this.popupAddToCart(r);
  }
  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.product_avatar + ')';
  // }
  statesWithFlags: { name: string, flag: string }[] = [
    { 'name': 'Alabama', 'flag': '5/5c/Flag_of_Alabama.svg/45px-Flag_of_Alabama.svg.png' },
    { 'name': 'Alaska', 'flag': 'e/e6/Flag_of_Alaska.svg/43px-Flag_of_Alaska.svg.png' },
  ];

  loadCategoryBrand() {
    let filter = {
      type: environment.brand_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys_brand = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  mouseover(item){
    console.log("mouseover",item)
  }
  mouseout(item){
    console.log("mouseout",item)
  }
}

