<form class="modal-content form-horizontal">
    <div class="modal-header p-2">
        <h5 class="modal-title">{{data.title}}</h5>
        <a data-dismiss="modal" (click)="this.dialogRef.close();">
            <i class="ti ti-close"></i>
        </a>
    </div>
    <div class="modal-body p-2 mt-3 mb-3">

        {{data.msg}}

    </div>
    <div class="modal-footer justify-content-around">
        <button (click)="okClick()" class="btn btn-primary ">
            Đồng ý
        </button>
        <button (click)="cancelClick()" class="btn">
            Bỏ qua
        </button>
    </div>
</form>