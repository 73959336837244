// import { Component, Inject, OnInit } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { AppService } from 'src/app/_services/app.service';
// import { CategoryService } from 'src/app/_services/category.service';
// import { environment } from 'src/environments/environment';
// import { EmployeePickerComponent } from '../employee-picker/employee-picker.component';

// @Component({
//   selector: 'app-category-picker',
//   templateUrl: './category-picker.component.html',
//   styleUrls: ['./category-picker.component.css']
// })
// export class CategoryPickerComponent implements OnInit {
//   categorys  = [];
//   //for paging
//   viewNumsObj = this.appService.viewNums[1].Code;
//   curentPage = 1;
//   totalItemOnPage: any;
//   totalItems: any;
//   listPage: any = [];

//   sorter = {
//     sortStr: this.appService.sort_category[0].code,
//   }
  
//   filter = {
//     deleted: 0,
//     category_code: "",
//     name: "",
//     type: environment.cate_type
//   }
//   category_code_selected = '';
  
//   constructor(
//     public appService: AppService,
//     public categoryService: CategoryService,
//     public dialogRef: MatDialogRef<EmployeePickerComponent>,
//     @Inject(MAT_DIALOG_DATA)
//     public data: any
//     ) {
//       this.category_code_selected = this.data.category_code;
//       this.loadCategory();
//   }
//   ngOnInit(): void {
//   }

//   loadCategory() {

//     let pagingObj = {
//       viewNumsObj: this.viewNumsObj,
//       pageObj: this.curentPage
//     };

//     this.categoryService.loadCategory(this.filter, this.sorter , pagingObj).subscribe(
//       async response => {
//         if (response) {
//           this.categorys = response.data;
          
//           // // đặt mặc định
//           // if(this.categorys.length > 0){
//           //   this.filter.category_code = this.categorys[0].code;
//           // }

//           this.totalItems = response.totalItems;
//           this.totalItemOnPage = this.categorys.length;
//           //for select page
//           let pages_temp = response.totalPage;
//           this.listPage = [];
//           for (let i = 1; i <= pages_temp; i++) {
//             this.listPage.push({ i: i });
//           }

//         }
//       },
//       error => {
//         console.error(error);
//       })
//   }

//   onCancelClick(): void {
//     this.dialogRef.close();
//   }
//   click_select(item): void {
//     this.category_code_selected = item.code;
//     this.onOkClick();
//   }
//   onOkClick(){
//     let data = {
//       code : "ok",
//       category_code : this.category_code_selected
//     }
//     this.dialogRef.close(data);
//   }

// }






import { CategoryService } from './../../_services/category.service';

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import {  debounceTime,  map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArrangeDataComponent } from 'src/app/components/arrange-data/arrange-data.component';
declare var $: any;
@Component({
  selector: 'app-category-picker',
  templateUrl: './category-picker.component.html',
  styleUrls: ['./category-picker.component.css']
})
export class CategoryPickerComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;

  isLoading = false;
  formGroup: FormGroup;
  // for create
  CodeType = 'mts';
  CodeTemp = "";
  file: File = null;
  imageUrl: any = environment.product_avatar;
  
  categorys : any;
  categorys_backup: any
  // for load
  type = environment.cate_type;
  
  // for add
  cate = new Category(environment.cate_type);
  // for update
  categoryselected: any;
  categoryselected_file: File = null;
  categoryselected_imageUrl: any = environment.product_avatar;
  isDeleted: boolean;

  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;

  sorter = {
    sortStr: this.appService.sort_category[0].code,
  }
  
  filter = {
    name: "",
    type: environment.cate_type,
    deleted: 0
  }


  category_code_selected = '';
  isAdding = false;
  constructor(
    public categoryService: CategoryService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    public dialog: MatDialog,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public dialogRef: MatDialogRef<CategoryPickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.categoryselected = this.cate; // để thêm mới
    this.category_code_selected = this.data.category_code; // để chọn picker
  }

  ngOnInit() {
    this.loadCategory();
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ])
    });
  }
  onSubmit() {
    console.log(this.formGroup);
  }

  onReset() {
    this.formGroup.reset();
  }

  resetCategory() {
    this.cate = new Category(environment.cate_type);
    this.file == null;
    this.imageUrl = environment.product_avatar; 
  }

  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    this.filter.deleted = this.filter.deleted ? 1 : 0;
    this.categoryService.loadCategory(this.filter, this.sorter , pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys = response.data;
          this.categorys_backup = response.data;
          
          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

        }
      },
      error => {
        console.error(error);
      })
  }
  insertAndSelectCategory() {
    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }
    if (this.cate.code.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập mã");
      return false;
    }
    if(this.file !== null){
      this.cate.image_url = environment.cate_type + "/" + this.cate.code + "." + this.utility.getExtensionFile(this.file);
    }
   
    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryService.insertCategory(this.cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.success("", "Thêm mới danh mục thành công");
            this.click_select(this.cate);
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("", error);
          console.error(error);
        })
    }
  }



  countAllCategory() {
    this.categoryService.countAllCategory(environment.cate_type).subscribe(
      response => {
        this.cate.code = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        this.CodeTemp = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        // console.log("this.cate.code",this.cate.code);
      },
      error => {

        console.error(error);
      })

  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.categorys = this.categorys_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.categorys = this.categorys.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  popup_category_add() {
    this.isAdding = true;
    this.resetCategory();
    this.countAllCategory();
  }

  
  
  codeTypeClick(type) {
    this.CodeType = type;
    if (this.CodeType == "mts") {
      this.cate.code = this.CodeTemp;
    } else if (this.CodeType == "tn") {
      this.cate.code = "";
    }
  }
  focusOutCheckCode() {
    let filter = {
      code: this.cate.code,
      type: this.type
    }
    this.categoryService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if(response.code == 0){
            this.addForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code","Lỗi");
            console.log(this.addForm.controls.code.errors);
            console.log("kkkkk", this.addForm.controls.code.errors);
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCategory();
      }),
      map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
    onClick(r) {
      this.filter.name = "";
    }


  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(item): void {
    this.category_code_selected = item.code;
    
    let data = {
      code : "ok",
      data : item
    }
    this.dialogRef.close(data);
  }
}
