<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 align-items-center">
        <div class="col p-0">
            <div class=" p-0 mobile-mb-2 input-group-icon input-group-icon-left mr-3">

                <div class="mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                    <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                    <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                    <input id="promotion-typeahead-template" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm chương trình" [inputFormatter]="formatter">
                </div>
                <!-- <ng-template #rt let-r="result" let-t="term">
            <div class="row" style="width: 450px;">
              <div class="img-product-search ml-1"
                [style.background-image]="this.appService.showImageProduct(r['image_url'])"></div>
              <div class="col"> #{{ r['code'] }}</div>
              <div class="col">
                <ngb-highlight [result]="r.name" [term]="t">
                </ngb-highlight>
              </div>
            </div>
          </ng-template> -->

            </div>
        </div>
        <div class="col p-0">
            <div class="d-flex justify-content-end">

                <div class="pl-2">
                    <select class="custom-select" style="width: 150px;" [(ngModel)]="filter.deleted" (change)="deleteStatus_change($event)" name="deleteStatus">
            <option value="">Tất cả</option>
            <option value="0">Hiệu lực</option>
            <option value="1">Hết hiệu lực</option>
          </select>
                </div>
                <div class="pl-2">
                    <select class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
            <option [ngValue]="item.code" *ngFor="let item of appService.sort_promotions">{{item.name}}</option>
          </select>
                </div>
                <div class="pl-2"> <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="gotoAddPopup()" id="popup_promotion_add">
            <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
          </button>
                </div>

            </div>
        </div>
    </div>


    <div class="table-responsive">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px;">#</th>
                    <th>Mã</th>
                    <th>Tên</th>
                    <th>Loại</th>
                    <th>Giá trị</th>
                    <th>Bắt đầu</th>
                    <th>Kết thúc</th>
                    <th>Trạng thái</th>
                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of promotions; let i = index">
                    <td>
                        <span style="position: absolute; left: 42px;
            line-height: 20px;">{{i+1}}</span>
                        <a class="media-img" href="javascript:;">
                            <span class="material-icons">
                redeem
              </span>
                        </a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.code}}</div>
                    </td>
                    <td>{{item.name}}</td>
                    <td>{{item.type}}</td>
                    <td *ngIf="item.type !=='dl'">{{item.promotion | qbscurrency}}</td>
                    <td *ngIf="item.type =='dl'">{{item.promotion_price_range}}</td>
                    <td>{{item.time_start_str}} </td>
                    <td>{{item.time_end_str}} </td>
                    <!-- <td>{{item.created_date | date :'dd/MM/yyyy'}}</td> -->
                    <td>
                        <span *ngIf="item.deleted != 1" class="badge badge-primary">Hoạt động</span>
                        <span *ngIf="item.deleted == 1" class="badge badge-danger">Ngừng</span>
                    </td>

                    <td>
                        <button class="btn-adjust btn  btn-icon-only btn-circle  btn-thick" (click)='popupUpdate(item)'><i
                class="ti ti-pencil-alt"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
        {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
          </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
              <i class="ti ti-angle-left"></i>
            </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>



<!-- <div class="modal fade" id="add-promotion-modal">
  <div class="modal-dialog" role="document">
    <form class="modal-content form-horizontal" id="form-add-promotion" (ngSubmit)="insertCustomer()">
      <div class="modal-header p-4">
        <h5 class="modal-title">Thêm chương trình khuyến mãi</h5>
        <a data-dismiss="modal"><i class="ti ti-close"></i></a>
      </div>
      <div class="modal-body p-4">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Mã</label>
          <div class="col-sm-9">
            <input [(ngModel)]="promotion.code" class="form-control" name="code" type="text" placeholder="Mã">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Tên</label>
          <div class="col-sm-9">
            <input [(ngModel)]="promotion.name" class="form-control" name="name" id="ssss"
              placeholder="Tên chương trình" type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Kiểu</label>
          <div class="col-sm-9 d-flex align-items-center">
            <label class="radio radio-grey radio-primary radio-inline">
              <input type="radio" name="b" checked="" (click)="SelectkieuKhuyenMai('%')">
              <span class="input-span"></span>Khuyến mãi %</label>
            <label class="radio radio-grey radio-primary radio-inline">
              <input type="radio" name="b" (click)="SelectkieuKhuyenMai('đ')">
              <span class="input-span"></span>Khuyến mãi tiền</label>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Khuyến mãi</label>
          <div class="col-sm-9">
            <div class="input-group">
              <input class="form-control" type="text" placeholder="khuyến mãi">
              <span class="input-group-addon">{{kieuKhuyenMai}}</span>
            </div>
          </div>
        </div>
        <div class="form-group row" id="date_5">
          <label class="col-sm-3 col-form-label">Thời gian</label>
          <div class="col-sm-9">
            <div class="input-daterange input-group" id="datepicker">
              <input class="input-sm form-control" type="text" name="start" value="04/12/2017">
              <span class="input-group-addon pl-3 pr-3">đến</span>
              <input class="input-sm form-control" type="text" name="end" value="08/17/2018">
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Sản phẩm</label>
          <div class="col-sm-9">
            <input [(ngModel)]="promotion.phone" class="form-control" name="phone"
              placeholder="sản phẩm tham gia chương trình" type="text">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Khách hàng</label>
          <div class="col-sm-9">
            <input [(ngModel)]="promotion.description" class="form-control" name="description"
              placeholder="Khách hàng tham gia chương trình" type="text">
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center bg-primary-50">
        <div class="row align-items-center">
          <div class="col text-center">
            <button class="btn btn-primary " [disabled]="isLoading">Thêm mới</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div> -->