import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class UtilityService {



  constructor(
    private appService: AppService
  ) { }
  public getTypeData(data) {
    // console.log(imageURl);
    return typeof data;
  }
  public getFullDateStrFromNgbDate(date: any): string {
    if (date.month < 10) {
      return date.day + "/0" + date.month + "/" + date.year;
    } else {
      return date.day + "/" + date.month + "/" + date.year;
    }
  }
  public getFullDateStrFromDate(date: Date): string {
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return day + "/" + month + "/" + date.getFullYear();
  }
  public getFirstFullDateStrFromDate(date: Date): string {
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return "01" + "/" + month + "/" + date.getFullYear();
  }
  public getFirstQuarterFromDate(date: Date): string {
    const quarter = Math.floor((date.getMonth() / 3));
    const day_temp = new Date(date.getFullYear(), quarter * 3, 1);
    return this.getFullDateStrFromDate(day_temp);
  }
  public getLastQuarterFromDate(date: Date): string {
    const quarter = Math.floor((date.getMonth() / 3));
    const day_temp = new Date(date.getFullYear(), quarter * 3+3, 0);
    return this.getFullDateStrFromDate(day_temp);
  }
  public getSixMonthDateStrFromDate(date: Date): string {
    date.setMonth(date.getMonth() - 6);
    return this.getFullDateStrFromDate(date);
  }
  public getNineMonthDateStrFromDate(date: Date): string {
    date.setMonth(date.getMonth() - 9);
    return this.getFullDateStrFromDate(date);
  }
  public getLastFullDateStrFromDate(date: Date): string {
    let day_temp = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return this.getFullDateStrFromDate(day_temp);
  }
  public getFullDateTimeStrFromDate(date: Date): string {
    // let day = 0
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return "" + date.getHours() + ":" + date.getMinutes() + " " + date.getDate() + "/" + month + "/" + date.getFullYear();
  }

  public getFullDatetimeStr(): string {
    let date = new Date();
    // return date.toString();
    return date.getHours() + ":" + date.getMinutes() + " " + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  }
  public getDayMonthStrFromDate(date: Date): string {
    return date.getDate() + "/" + (date.getMonth() + 1);
  }
  public getDiffDaysFromStr(fromDate: string,toDate: string): number {
    const a_temp = this.getDateFromStr(fromDate);
    const b_temp = this.getDateFromStr(toDate);
    const diffTime = Math.abs(a_temp.getTime() - b_temp.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  public getDiffDaysFromDate(fromDate: Date,toDate: Date): number {
    const diffTime = Math.abs(fromDate.getTime() - toDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  public mathRound(data: number): number {
    return Math.round(data);
  }
  public stringToNumber(data: string): number {
    return Number(data);
  }
  public getDateFromStr(dateStr: String): Date {
    var temps = dateStr.split("/");
    var dt   = parseInt(temps[0]);
    var mon  = parseInt(temps[1]);
    var yr   = parseInt(temps[2]);
    return new Date(yr, mon-1, dt);
  }
  checkToAddCart(list: [any], cart: any): number {
    let isUpdateQantity = -1;
    for (var i = 0; i < list.length; i++) {
      // nếu ID và loại giá bằng nhau
      if (list[i].product.id == cart.product.id && list[i].product.unit_default == cart.product.unit_default) {
        list[i].quantity = list[i].quantity + cart.quantity;
        isUpdateQantity = i;
        break;
      }
    }
    return isUpdateQantity;
  }
  roundToTwo(num : any) {
    return Math.round(num * 100) / 100;
    // return +(Math.round(num + "e+2")  + "e-2");
  }
  checkExistCart(list: [any], cart: any) {
    let isExisted = false;
    for (var i = 0; i < list.length; i++) {
      if (list[i].product.id == cart.product.id) {
        isExisted = true;
        break;
      }
    }
    return isExisted;
  }

  checkToAddCartService(list: [any], cartService: any) {
    console.log(list);
    console.log(cartService);
    let isExisted = false;

    var ii = list;

    for (var i = 0; i < list.length; i++) {
      if (i < list.length - 1) {
        list[i].isEnable = 0;
      }
      else {
        if (list[i].id == cartService.id) {
          list[i].isEnable = 1;
          isExisted = true;
        } else {
          list[i].isEnable = 0;
        }
      }
    }
    console.log("isExisted", isExisted);
    return isExisted;
  }

  
  changeDeskService(indexFrom, indexTo): boolean {

    try {
      console.log("indexFrom", this.appService.deskList[indexFrom]);
      this.appService.deskList[indexTo].cart = this.appService.deskList[indexFrom].cart
      this.appService.deskList[indexTo].cart_service = this.appService.deskList[indexFrom].cart_service
      this.appService.deskList[indexTo].customer_code = this.appService.deskList[indexFrom].customer_code
      this.appService.deskList[indexTo].customer_id = this.appService.deskList[indexFrom].customer_id
      this.appService.deskList[indexTo].customer_name = this.appService.deskList[indexFrom].customer_name
      this.appService.deskList[indexTo].customer_pay = this.appService.deskList[indexFrom].customer_pay
      this.appService.deskList[indexTo].payment_total = this.appService.deskList[indexFrom].payment_total
      this.appService.deskList[indexTo].price = this.appService.deskList[indexFrom].price
      this.appService.deskList[indexTo].price_after_promotion = this.appService.deskList[indexFrom].price_after_promotion
      this.appService.deskList[indexTo].price_promotion = this.appService.deskList[indexFrom].price_promotion
      this.appService.deskList[indexTo].price_totol = this.appService.deskList[indexFrom].price_totol
      this.appService.deskList[indexTo].product_quantity = this.appService.deskList[indexFrom].product_quantity
      this.appService.deskList[indexTo].promotion_promotion = this.appService.deskList[indexFrom].promotion_promotion
      this.appService.deskList[indexTo].promotion_type = this.appService.deskList[indexFrom].promotion_type
      this.appService.deskList[indexTo].quantity = this.appService.deskList[indexFrom].quantity
      this.appService.deskList[indexTo].service_detail_str = this.appService.deskList[indexFrom].service_detail_str
      this.appService.deskList[indexTo].service_details = this.appService.deskList[indexFrom].service_details
      this.appService.deskList[indexTo].service_price = this.appService.deskList[indexFrom].service_price
      this.appService.deskList[indexTo].service_price_temp = this.appService.deskList[indexFrom].service_price_temp
      this.appService.deskList[indexTo].status = this.appService.deskList[indexFrom].status
      this.appService.deskList[indexTo].total_quantity = this.appService.deskList[indexFrom].total_quantity
      this.appService.deskList[indexTo].type = this.appService.deskList[indexFrom].type
      this.appService.deskList[indexTo].user_id = this.appService.deskList[indexFrom].user_id

      this.appService.deskList[indexFrom].cart = new Array();
      this.appService.deskList[indexFrom].cart_service = [];
      this.appService.deskList[indexFrom].customer_code = "";
      this.appService.deskList[indexFrom].customer_id = 0
      this.appService.deskList[indexFrom].customer_name = "Khách lẻ"
      this.appService.deskList[indexFrom].customer_pay = 0
      this.appService.deskList[indexFrom].payment_total = 0
      this.appService.deskList[indexFrom].price = 0
      this.appService.deskList[indexFrom].price_after_promotion = 0
      this.appService.deskList[indexFrom].price_promotion = 0
      this.appService.deskList[indexFrom].price_totol = 0
      this.appService.deskList[indexFrom].product_quantity = 0
      this.appService.deskList[indexFrom].promotion_promotion = 0
      this.appService.deskList[indexFrom].promotion_type = "%"
      this.appService.deskList[indexFrom].quantity = 0
      this.appService.deskList[indexFrom].service_detail_str = ""
      this.appService.deskList[indexFrom].service_details = []
      this.appService.deskList[indexFrom].service_price = 0
      this.appService.deskList[indexFrom].status = 0
      this.appService.deskList[indexFrom].total_quantity = 0
      this.appService.deskList[indexFrom].type = 0
      this.appService.deskList[indexFrom].user_id = 0
      localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
      console.log("indexTo", this.appService.deskList[indexTo]);
      return true
    } catch (error) {
      console.error("error", error);
      return false
    }
  }

  getCurrentTimeService() {
    var today = new Date();
    today.setSeconds(0);

    // let i_index = 1;
    let i_id = 1;
    for (var i = 0; i < this.appService.deskListPrice.length; i++) {
      var hourS_temp = this.appService.deskListPrice[i].timeStart.split(":"); // tách hh:mm
      var hourE_temp = this.appService.deskListPrice[i].timeEnd.split(":"); // tách hh:mm
      var start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hourS_temp[0], hourS_temp[1], 0);
      var end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hourE_temp[0], hourE_temp[1], 0);

      if (start < today && today < end) {
        console.log("start", start);
        console.log("end", end);
        // i_index = i;
        i_id = this.appService.deskListPrice[i].id;
        break;
      }
    }

    return i_id;
  }


  public checkUnit(unit: string) {
    let isExist = false;

    this.appService.units.forEach(function (item) {
      if (item.value == unit) {
        isExist = true;
        return isExist;
      }
    });
    return isExist;
  }
  getNameOfRoleUser(role: string) {
    if (role == "admin") return "Admin";
    for (var i = 0; i < this.appService.memberships.length; i++) {
      if (this.appService.memberships[i].code == role) {
        return this.appService.memberships[i].name;
      }
    }
    return "Unknow";
  }
  // getNamesBycodes(codes: string, datas) {
  //   let temps = codes.split(",");
  //   let result = '';
  //   for (var i = 0; i < datas.length; i++) {
  //     for (var j = 0; j < temps.length; j++) {
  //       if (datas[i].code == temps[j]) {
  //          result += datas[i].name;
  //       }
  //     }
  //   }
  //   return result;
  // }
  countDecimals = function (value) {
    value = Number(value);
  
    if(Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0; 
  }
  searchProduct(searchValue: string) {
    console.log("products products_backup:", this.appService.products_backup);
    console.log("products searchValue:", searchValue);

    

    for (var i = 0; i < this.appService.products_backup.length; i++) {
      if (this.appService.products_backup[i].code == searchValue) {
        console.log("products result:", this.appService.products_backup[i]);
        return this.appService.products_backup[i];
      }
    }
    
    return null;
  }
  public padWithZeros(inputString, desiredLength) {
    return inputString.padStart(desiredLength, '0');
  }
  public get7days(dateSelect: Date): any {
    let temp = dateSelect;
    let result = [];
    for (var i = 0; i < 7; i++) {
      result[i] = {
        date1: this.getFullDateStrFromDate(temp),
        date2: this.getDayMonthStrFromDate(temp)
      };
      temp = new Date(temp.getTime() - 86400000)
    }
    return result;
  }
  public formatDateStr(date): any {
    let temp = date.split("-");
    return temp[2] + "/" + temp[1] + "/" + temp[0];
  }

  public getExtensionFile(file): string {
    if (file !== null && file !== undefined) {
      let name = file.name.split(".");
      return name[name.length - 1];
    } else return "";
  }
  public formatDateJsonToStr(date): any {
    if (date !== null) {
      return date.day + "/" + date.month + "/" + date.year;
    }
    return "";
  }
  public toFixedIfNecessary(value) {
    return +parseFloat(value).toFixed(2);
  }


  public calculatePercent(datas,prop) {
    if(datas.length > 0){
      let max = this.getMax(datas, prop);
      console.log("Max:", max[prop]);
      datas.forEach(element => {
        if(parseInt(max[prop]) != 0)
        element.temp = Math.floor((parseInt(element[prop]) / parseInt(max[prop]) ) * 100);
        else
        element.temp - 0
      });
    }
    return datas;
  }
  getMax(arr, prop) {
    var max;
    for (var i=0 ; i<arr.length ; i++) {
        if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
            max = arr[i];
    }
    return max;
}

}
