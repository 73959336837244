<table class="table table-head-purple table-hover">
    <thead class="thead-default thead-lg">
        <tr>
            <th class="text-center">#</th>
            <th class="text-center">Khách hàng</th>
            <th class="text-right">SL đơn</th>

            <th class="text-right">Doanh thu<img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg"></th>
            <th class="text-right">
                Nợ<img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg">
            </th>

        </tr>
    </thead>
    <tbody>
        <tr class="font-weight-bold" class="row_sum">
            <td colspan="2" class="text-center">
                Tổng
            </td>

            <td class="text-right">
                <span *ngIf="datasSum.length > 0">{{datasSum[0].invoices | qbscurrency}}</span>
                <span class="material-icons font-16 " style="color: grey;">
                    receipt_long
                </span>
            </td>
            <td class="text-right">
                <span *ngIf="datasSum.length > 0">{{datasSum[0].revenue | qbscurrency}}</span>
            </td>
            <td class="text-right">
                <span *ngIf="datasSum.length > 0"> {{datasSum[0].debt | qbscurrency}}</span>
            </td>
        </tr>


        <tr *ngFor="let item of categories; let i = index" (click)="select_card(item)">
            <td class="text-center">
                {{i+1}}.
            </td>
            <td class="text-left">
                {{item.name}}
                <span class="text-muted font-14">
                    - {{item.phone}}
                </span>
                <div *ngIf="item.company !== ''" class="ml-3 mt-1 text-info">
                    <span>
                        {{item.company}}
                    </span>
                    <span class="text-muted">
                        - {{item.tax}}
                    </span>
                </div>
            </td>
            <td class="text-right">
                {{item.invoices | qbscurrency}}
                <span class="material-icons font-16 " style="color: grey;">
                    receipt_long
                </span>
            </td>
            <td class="text-right">
                {{item.revenue | qbscurrency}}
            </td>
            <td class="text-right">
                {{item.debt | qbscurrency}}
            </td>

        </tr>
    </tbody>
</table>