<div class="page-content fade-in-up">
    <div class="row">
        <div class="ml-2 select_category" (click)="select_category('')" [ngClass]="{'selected_category' : '' == this.filter.category_code}">
            <div>
                Tất cả
            </div>
        </div>
        <!-- [style.background-image]="appService.showImageProduct(item.image_url)" -->
        <!-- this.filter.category_code -->
        <div class="select_category" *ngFor="let item of categories" (click)="select_category(item.code)" [ngClass]="{'selected_category' : item.code == this.filter.category_code}">
            <div>
                {{item.name}}
            </div>
        </div>
    </div>

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">


            <div class=" p-2 mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="typeahead-templates" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm hàng hóa" [resultTemplate]="rt" [inputFormatter]="formatter">
            </div>
            <ng-template #rt let-r="result" let-t="term">
                <div class="d-flex align-content-center justify-content-between" (click)="onClick(r)">
                    <div class="d-flex align-content-start align-items-center">
                        <div class="img-product-search mr-1" [style.background-image]="this.appService.showImageProduct(r['image_url'])">
                        </div>
                        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                        <div class="mr-1 ml-1" style="font-size: smaller"> #{{ r['code'] }}</div>

                    </div>
                    <div style="font-weight: bold; width: 100px; text-align: right;">
                        <img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg"> {{ (r['price'] | qbscurrency )}}
                    </div>
                </div>
            </ng-template>

        </div>
        <!-- <div class="col">
        <div class=" p-2 mobile-mb-2 input-group-icon input-group-icon-left mr-3">
          <span class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
          <input class="form-control form-control-rounded " [(ngModel)]="searchValue" name="key-search" id="key-search" (keydown)="getTypingSearch($event)"
            type="text" placeholder="Tìm hàng hóa">
        </div>
      </div> -->
        <div class="col">
            <div class="d-flex justify-content-end">
                <!-- <div class="p-2">
            <select class="custom-select" [(ngModel)]="filter.category_code" (change)="category_change($event)">
              <option value="">Danh mục</option>
              <option [ngValue]="item.code" *ngFor="let item of categories">{{item.name}}</option>
            </select>
          </div>
          <div class="p-2">
            <select class="custom-select" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)">
              <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}</option>
            </select>
          </div> -->
                <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.category_code" (change)="category_change($event)" name="category" id="category">
            <option value="">Danh mục</option>
            <option [ngValue]="item.code" *ngFor="let item of categories">{{item.name}}</option>
          </select>
                </div>
                <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.category_code_brand" (change)="category_change($event)" name="category" id="category">
            <option value="">Thương hiệu</option>
            <option [ngValue]="item.code" *ngFor="let item of categorys_brand">{{item.name}}</option>
          </select>
                </div>
                <div class="p-2">
                    <select style="width: 100px;" class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
            <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}</option>
          </select>
                </div>
                <div class="p-2">

                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-sm btn-icon" ngbDropdownToggle>
              <span class="btn-label">
                <i *ngIf="display_type ==0" class="ti ti-layout-grid2"></i>
                <i *ngIf="display_type ==1" class="ti ti-align-justify"></i>
              </span>
            </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="changeViewType(0)"><i class="ti ti-layout-grid2"></i> Dạng lưới</button>
                            <button ngbDropdownItem (click)="changeViewType(1)"><i class="ti ti-align-justify"></i> Dạng danh
                sách</button>
                        </div>
                    </div>

                    <!-- <div class="ibox-tools">
              <button class="btn btn-outline-primary btn-icon-only" data-toggle="dropdown"
                style="height: 36px; width: 36px;">
                <i *ngIf="display_type ==0" class="ti ti-layout-grid2"></i>
                <i *ngIf="display_type ==1" class="ti ti-align-justify"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" (click)="changeViewType(0)"> <i class="ti ti-layout-grid2"></i>Dạng lưới</a>
                <a class="dropdown-item" (click)="changeViewType(1)"> <i class="ti ti-align-justify"></i>Dạng danh sách</a>
              </div>
            </div> -->
                </div>
            </div>
        </div>
    </div>



    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
        {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
          </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
              <i class="ti ti-angle-left"></i>
            </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="display_type ==1" class="table-responsive">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px;">#</th>
                    <th>Tên hàng</th>
                    <th>Danh mục</th>
                    <th>Tồn kho</th>
                    <th>Giá</th>
                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of appService.products; let i = index" (click)="popupAddToCart(item)" class="h-row-item">
                    <td>
                        <!-- <a class="media-img" href="javascript:;">
                <img class="img-circle" src="./assets/img/icons/user.svg" alt="image" width="30" />
              </a> -->
                        <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageProduct(item.image_url)"></a>
                    </td>
                    <td>
                        <div style="font-size: 18px;"> {{i+1}} {{item.name}}

                        </div>
                        <div [ngClass]="{'text-danger': item.stock < 1}" style="font-size: 13px; font-weight: bold;">
                            {{item.code}}
                        </div>
                    </td>
                    <td>{{item.category_name}}</td>
                    <td><span [ngClass]="{'text-danger': item.stock < 1}">{{item.stock}}</span></td>
                    <td style="text-align: right;">{{item.price| qbscurrency:0}}</td>

                    <!-- <td>
              <a (click)="popupPayment(item)">
                <img style="width: 20px;" src="./assets/img/icons/payment.svg" alt="image" />
              </a>
            </td> -->
                </tr>
                <tr>
                    <td colspan="5">

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="display_type ==0">
        <div class="d-flex flex-wrap mb-2">
            <!-- <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of appService.products; let i = index"
          (click)="popupAddToCart(item)"> -->
            <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of appService.products; let i = index">
                <div class="rel img-product2" [style.background-image]="this.appService.showImageProduct(item.image_url)">

                    <div class="enterQuantiti-temp">
                        <div>
                            <div style="display: inline-block;">
                                <button class="btn btn-sm btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_sub2(item)"><i class="ti ti-minus"></i></button>
                            </div>
                            <div style="display: inline-block; margin: 0 5px;">
                                <input [(ngModel)]="item.temp_quantity" type="number" decimals="2" class="form-control" style="font-size: 18px; padding: 0px; height: 35px; text-align: center; width: 45px;" /></div>
                            <div style="display: inline-block;"><button class="btn btn-sm btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_add2(item)"><i class="ti ti-plus"></i></button></div>
                        </div>
                        <div class="mt-2">
                            <div style="display: inline-block;">
                                <button class="btn btn-sm btn-primary btn-icon" (click)="addToCartDesk(item,item.temp_quantity)">
                  <span class="btn-label"><i class="ti ti-shopping-cart"></i></span>
                </button>
                            </div>
                        </div>
                    </div>
                    <span class=".card-img-overlay">
            <span *ngIf="item.stock < 1" class="badge-danger badge-point"></span>
                    </span>
                </div>
                <div class="card-body" (click)="popupAddToCart(item)">
                    <p style=" max-height: 37px; overflow: hidden;">{{item.name}}</p>
                    <div class="d-flex align-items-center justify-content-between mt-2">
                        <div class="text-center">
                            <div style="font-weight: bold;" [ngClass]="{'text-danger': item.stock < 1}">{{item.stock}}</div>
                            <div class="text-muted font-11">{{item.unit}}</div>
                            <!-- kho -->
                        </div>
                        <div class="text-center">
                            <div style="font-weight: bold;">{{item.price | qbscurrency}}</div>
                            <div class="text-muted font-11">VNĐ</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
        {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
          </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
              <i class="ti ti-angle-left"></i>
            </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>

<div class="modal fade" id="add-to-cart-modal">
    <div class="modal-dialog" role="document">
        <div class="modal-content form-horizontal" id="form-add-product">
            <div class="modal-header p-4">
                <h5 class="modal-title">Nhập số lượng <img style="width: 20px;" src="./assets/img/icons/add_cart.svg" alt="image" /></h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4 mb-3">
                <div style="text-align: center; padding: 25px 0px;">
                    <h2 style="font-weight: bold;">{{productSelected.name}}</h2>
                    <h4>{{productSelected.price | qbscurrency}} x {{txt_quantity}} = {{txt_quantity * productSelected.price | qbscurrency}}</h4>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-end">
                        <button class="btn btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_sub()"><i
                class="ti ti-minus"></i></button>
                    </div>
                    <div class="col">
                        <input type="number" [(ngModel)]="txt_quantity" decimals="2" class="form-control" style="font-size: 24px; padding: 4px; text-align: center;" />
                    </div>
                    <div class="col ">
                        <button class="btn btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_add()"><i
                class="ti ti-plus"></i></button>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center bg-primary-50">
                <!-- <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="openCheckout(this.productSelected,this.txt_quantity.toString())">
          <span class="btn-label"><i class="ti ti-money"></i></span>Thanh toán
        </button> -->
                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="addToCartDesk(this.productSelected,this.txt_quantity)">
          <span class="btn-label"><i class="ti ti-shopping-cart"></i></span>Thêm vào hóa đơn
        </button>

            </div>
        </div>
    </div>
</div>