import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SaleService } from 'src/app/_services/sale.service';

@Component({
  selector: 'app-sale-detail-view-returning',
  templateUrl: './sale-detail-view-returning.component.html',
  styleUrls: ['./sale-detail-view-returning.component.css']
})
export class SaleDetailViewReturningComponent implements OnInit {
  // for detail
  cartDetail: any;
  constructor(
    public saleService: SaleService,
    public dialogRef: MatDialogRef<SaleDetailViewReturningComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    this.loadCartDetail(this.data.id);
  }

  ngOnInit(): void {
  }
  loadCartDetail(saleID) {
    this.saleService.loadCartDetailReturning(saleID).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.cartDetail = response;
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })
  }
  close(): void {
    this.dialogRef.close();
  }

}
