<form class="form-horizontal" #productUpdateForm="ngForm" id="form-add-product">
    <div class="qbs-header p-1">
        <div class="d-flex justify-content-between align-items-center">
            <h5 class="qbs-title">Cập nhật hàng hóa</h5>
            <div>
                <div class="btn-group">
                    <div class="btn btn-outline-primary" [ngClass]="{'status-active': item.code == product.type }" (click)="product_types_change(item.code)" *ngFor="let item of appService.product_types; let i = index">
                        {{item.value}}
                        <img class="icon-select" style="width: 15px;" [src]="item.img" alt="image" />
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="qbs-body pt-4 pb-2">
        <div class="row" style="min-width: 700px;">
            <div class="col-sm-9">


                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Tên hàng hóa</mat-label>
                    <input matInput placeholder="Tên hàng hóa" type="text" [(ngModel)]="product.name" required maxlength="100" name="name" (focusout)="prepareProducts()">
                </mat-form-field>


                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Mã hàng hóa</mat-label>
                    <input matInput placeholder="Mã hàng hóa" type="text" [(ngModel)]="product.code" name="code" [readonly]="true">
                </mat-form-field>

            </div>
            <div class="col d-flex justify-content-center">
                <div class="">
                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+imageUrl+')'">
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="upload2File" name="upload2File" (change)="avatarChangeListener($event)">
                    </div>
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs tabs-line pt-2">
            <li class="nav-item"><a href="#tab-basic" data-toggle="tab" aria-expanded="true" class="nav-link active">Thông tin chung</a></li>
            <li class="nav-item" *ngIf="product.code_parent==''"><a href="#tab-attribute" data-toggle="tab" aria-expanded="true" class="nav-link">Thuộc
                    tính sản phẩm</a></li>
        </ul>
        <div class="tab-content">

            <div class="tab-pane fade nav-link active show" id="tab-basic" aria-expanded="true">

                <div class="row">
                    <div class="col pr-3">
                        <mat-form-field appearance="fill" class="qbh-mat-lg">
                            <mat-label>Giá lẻ</mat-label>
                            <input matInput placeholder="Giá lẻ" currencyMask type="text" [(ngModel)]="product.price" name="tap1_price" (focusout)="prepareProducts()" (focus)="focusFunction($event)">
                        </mat-form-field>
                    </div>
                    <div class="col  pl-3">
                        <mat-form-field appearance="fill" class="qbh-mat-lg">
                            <mat-label>Giá buôn</mat-label>
                            <input matInput placeholder="Giá buôn" currencyMask type="text" [(ngModel)]="product.price2" name="tap1_price2" (focusout)="prepareProducts()" (focus)="focusFunction($event)">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col pr-3">

                        <mat-form-field appearance="fill" class="qbh-mat-lg">
                            <mat-label>Giá nhập</mat-label>
                            <input matInput placeholder="Giá nhập" currencyMask type="text" [(ngModel)]="product.price_in" name="tap1_price-in" (focus)="focusFunction($event)">
                        </mat-form-field>
                    </div>
                    <div class="col pl-3">
                        <mat-form-field appearance="fill" class="qbh-mat-lg">
                            <mat-label>Tồn kho</mat-label>
                            <input matInput placeholder="Tồn kho" currencyMask type="text" [(ngModel)]="product.stock" name="tap1_stock" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col pr-3">
                        <mat-form-field *ngIf="product.type == 0" appearance="fill" class="qbh-mat-lg">
                            <mat-label>Đơn vị tính</mat-label>
                            <mat-select [(value)]="product.unit">
                                <mat-option *ngFor="let item of appService.units" [value]="item.code">
                                    {{item.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="product.type == 1" appearance="fill" class="qbh-mat-lg">
                            <mat-label>Đơn vị tính</mat-label>
                            <mat-select [(value)]="product.unit" (selectionChange)="changeToUnitInWeightType()">
                                <mat-option *ngFor="let item of appService.units_weight_type" [value]="item.code">
                                    {{item.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="col pl-3">

                        <mat-form-field appearance="fill" class="qbh-mat-lg" style="width: 50%;">
                            <mat-label>Quy cách</mat-label>
                            <input matInput type="number" placeholder="Quy cách" [(ngModel)]="product.packing" name="packing" [disabled]="product.type == 1" (focus)="focusFunction($event)">
                        </mat-form-field>
                        <mat-form-field *ngIf="product.type == 0" appearance="fill" class="qbh-mat-lg" style="width: 50%;">
                            <mat-label>Đơn vị con</mat-label>
                            <mat-select [(value)]="product.unit2">
                                <mat-option *ngFor="let item of appService.units" [value]="item.code">
                                    {{item.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="product.type == 1" appearance="fill" class="qbh-mat-lg" style="width: 50%;">
                            <mat-label>Đơn vị con</mat-label>
                            <mat-select [(value)]="product.unit2" [disabled]="product.type == 1">
                                <mat-option *ngFor="let item of appService.units_weight_type" [value]="item.code">
                                    {{item.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col pr-3">
                        <mat-form-field appearance="fill" class="qbh-mat-lg">
                            <mat-label>Danh mục</mat-label>
                            <input matInput placeholder="Danh mục" type="text" [(value)]="category_selected.name" (click)="openCategoryPicker()">
                        </mat-form-field>
                    </div>
                    <div class="col pl-3">
                        <mat-form-field appearance="fill" class="qbh-mat-lg">
                            <mat-label>Thương hiệu</mat-label>
                            <mat-select [(value)]="product.category_code_brand">
                                <mat-option *ngFor="let item of categorys_brand" [value]="item.code">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill" class="qbh-mat-lg">
                            <mat-label>Mô tả</mat-label>
                            <input matInput placeholder="Mô tả hàng hóa" [(ngModel)]="product.description" name="description">
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row ">
                    <label class="col-sm-6 col-form-label">Ngưng kinh doanh</label>
                    <div class="col-sm-6 d-flex align-items-center pt-2">
                        <label class="ui-switch switch-icon">
                        <input type="checkbox" [(ngModel)]="isDeleted"  name="deleted">
                        <span></span>
                    </label>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade  nav-link text-center" id="tab-attribute" aria-expanded="false">
                <table class="table table-head-purple table-hover">
                    <thead>
                        <td style="width: 132px">
                            <div class="d-flex align-items-center">
                                <span class="mr-1">Thuộc tính</span>
                                <a (click)="addAttribute()" *ngIf="attributeList.length < this.appService.product_attributes.length">
                                    <span class="material-icons"> add_circle_outline </span>
                                </a>
                            </div>
                        </td>
                        <td colspan="2">
                            Giá trị thuộc tính
                        </td>
                    </thead>
                    <tbody>
                        <tr *ngFor="let attribute of attributeList; let i = index">
                            <td>
                                <select (change)="attributeChange(attribute, $event)" class="custom-select" name="attributes">
                                    <option [ngValue]="">Chọn
                                    </option>
                                    <option [ngValue]="item.name"
                                        *ngFor="let item of this.appService.product_attributes; let i = index">
                                        {{item.name}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <mat-form-field class="example-chip-list" appearance="fill" style="width: 100%;">
                                    <!-- <mat-label>Favorite Fruits</mat-label> -->
                                    <mat-chip-list #chipList aria-label="">
                                        <mat-chip *ngFor="let chip of attribute.valueList" (removed)="remove(attribute,chip)">
                                            {{chip}}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip>
                                        <input placeholder="Nhập và nhấn Enter" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add(attribute,$event)">
                                    </mat-chip-list>
                                </mat-form-field>
                            </td>
                            <td style="width: 36px;">
                                <a (click)="removeAttribute(i)">
                                    <span class="material-icons"> highlight_off </span>
                                </a>

                            </td>
                        </tr>


                    </tbody>
                </table>
                <table class="table table-head-purple table-hover mb-0" id="table-add-products">
                    <thead class="thead-default thead-lg">
                        <tr>
                            <th style="width: 20px; text-align: center;">#</th>
                            <th style=" text-align: center;">Code <span class="text-danger">*</span></th>
                            <th style=" text-align: center;">Tên <span class="text-danger">*</span></th>
                            <th style="width: 92px; text-align: center;">Giá lẻ</th>
                            <th style="width: 92px; text-align: center;">Giá buôn</th>
                            <th style="width: 92px; text-align: center;">Tồn kho</th>
                            <th class="no-sort"></th>
                        </tr>
                    </thead>
                </table>
                <table class="table table-head-purple table-hover" id="table-add-products">
                    <tbody>
                        <tr *ngFor="let item of products; let i = index">
                            <td style=" text-align: center; padding: 0.5rem;">
                                {{i+1}}
                            </td>
                            <td>
                                <input type="text" [(ngModel)]="item.code" readonly [id]="'code' + i" [name]="'code' + i" (focusout)="focusOutCheckCode(item.code)" maxlength="30" style="width: 140px;" name="s_code">
                            </td>
                            <td>
                                <input type="text" [ngModel]="item.name_child" readonly [name]="'name' + i" maxlength="100">
                            </td>
                            <td>
                                <input type="text" [(ngModel)]="item.price" currencyMask class="input-number" style="width: 92px;" [name]="'price' + i">
                            </td>
                            <td>
                                <input type="text" [(ngModel)]="item.price2" currencyMask class="input-number" style="width: 92px;" [name]="'price2_' + i">
                            </td>
                            <td>
                                <input type="text" [(ngModel)]="item.stock" currencyMask class="input-number" style="width: 92px;" [name]="'stock' + i">
                            </td>
                            <td>
                                <a (click)="removeFromProducts(i)">
                                    <span class="material-icons"> highlight_off </span>
                                </a>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>

    </div>

    <div class="qbs-footer d-flex justify-content-around pt-2">

        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.productUpdateForm.invalid" (click)="updateProduct()">
            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
            <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> done </span></span>
            Cập nhật
        </button>


        <button class="btn btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
            <span class="btn-label"> <span class="material-icons"> close </span> </span>
            Đóng
        </button>
    </div>
</form>