import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-revenue-customer-card-list',
  templateUrl: './revenue-customer-card-list.component.html',
  styleUrls: ['./revenue-customer-card-list.component.css']
})
export class RevenueCustomerCardListComponent implements OnInit {
  @Input("datas") categories: any[] = [];
  @Input("datasSum") datasSum: any;
  @Input("selected") selected: string = "";
  @Output("click2") clickEvent : EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {

  }
  select_card(item) {
    this.clickEvent.emit(item);
  }

}
