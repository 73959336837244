import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ToastrService } from 'ngx-toastr';
import { AttributeElement } from 'src/app/model/attribute-element';
import { Product } from 'src/app/model/product';
import { ProductAttribute } from 'src/app/model/product-attribute';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { IoService } from 'src/app/_services/io.service';
import { ProductService } from 'src/app/_services/product.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Category } from 'src/app/model/category';
import { CategoryPickerComponent } from '../../../components/category-picker/category-picker.component';

// export interface Fruit {
//   name: string;
// }
@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.css']
})
export class ProductUpdateComponent implements OnInit {
  @ViewChild('productUpdateForm') productUpdateForm: HTMLFormElement;
  // for add
  products = []
  product: any;
  fileProductImg: File;
  imageUrl: any = environment.product_avatar;
  categorys: any
  categorys_brand: any
  inputField: HTMLInputElement;// cho việc input
  isLoading = false;
  countProduct = 0;

  category_selected = new Category(environment.cate_type);
  // for attribute
  attributeList = [];

  // scan code
  productCodeType = 'mts';
  codeScan = "";
  productCodeTemp = "";
  // for tags 
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  // fruits: Fruit[] = [{name: 'Lemon'}, {name: 'Lime'}, {name: 'Apple'}];
  /////////////////////

  // for update
  productSelected: any;
  isDeleted: boolean;
  productSelected_imageUrl: any = environment.product_avatar;


  constructor(
    public categoryService: CategoryService,
    public dialogRef: MatDialogRef<ProductUpdateComponent>,
    public appService: AppService,
    private toastr: ToastrService,
    public ioService: IoService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public productService: ProductService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.product = data;
    this.category_selected.code = this.product.category_code;
    this.category_selected.name = this.product.category_name;
    console.log(this.product);
    // for image and delete
    if (this.product.image_url != "") {
      this.imageUrl = this.appService.getUrlImage(this.product.image_url);
    }
    this.isDeleted = this.product.deleted == 0 ? false : true;

    this.loadCategory();
    this.loadCategoryBrand();
    this.loadProduct_attributes();
    // khoi tao AttributeElement
    this.attributeList.push(new AttributeElement(''));
  }

  ngOnInit(): void {

  }
  printer() {
    console.log(this.attributeList);
    this.prepareProducts();
    console.log(this.products);
  }
  addAttribute() {
    this.attributeList.push(new AttributeElement(''));
  }
  removeAttribute(i) {
    this.attributeList.splice(i, 1);
  }
  focusFunction(event) {
    event.target.select();
  }

  prepareProducts() {
    //check mảng thuộc tính
    let count_valid = 0;
    this.attributeList.forEach(element => {
      if (element.name != '' && element.valueList.length > 0)
        count_valid++;
    });
    if (this.attributeList.length == 0 || count_valid != this.attributeList.length)
      return;
    //check mảng thuộc tính

    this.products = [];
    this.attributeList.forEach((item, i) => {
      if (1 == this.attributeList.length) { // mảng chỉ có 1 phần tử
        item.valueList.forEach((item2, j) => {
          // thêm sản phẩm vào danh sách
          let temp = new Product(this.appService.units.length > 0 ? this.appService.units[0].code : "");
          temp.code = this.product.code + "-" + (j + 1);
          temp.code_parent = this.product.code;
          temp.name = this.product.name + " - " + item2;
          temp.name_child = item2;
          temp.price = this.product.price;
          temp.price2 = this.product.price2;
          temp.unit = this.product.unit;
          temp.unit2 = this.product.unit2;
          temp.stock = this.product.stock;
          temp.category_code = this.product.category_code;
          temp.category_code_brand = this.product.category_code_brand;
          this.products.push(temp);
        });
      } else if (2 == this.attributeList.length) {// mảng chỉ có 2 phần tử
        item.valueList.forEach((item2, j) => {
          // thêm sản phẩm vào danh sách
          if (typeof this.attributeList[i + 1] !== 'undefined') {
            this.attributeList[i + 1].valueList.forEach((item23, k) => {
              // thêm sản phẩm vào danh sách
              let temp = new Product(this.appService.units.length > 0 ? this.appService.units[0].code : "");
              temp.code = this.product.code + "-" + (j + 1) + "-" + (k + 1);
              temp.code_parent = this.product.code;
              temp.name = this.product.name + " - " + item2 + " - " + item23;
              temp.name_child = item2 + " - " + item23;
              temp.price = this.product.price;
              temp.price2 = this.product.price2;
              temp.unit = this.product.unit;
              temp.unit2 = this.product.unit2;
              temp.stock = this.product.stock;
              temp.category_code = this.product.category_code;
              temp.category_code_brand = this.product.category_code_brand;
              this.products.push(temp);
            });
          }
        });
      } else if (3 == this.attributeList.length) { // mảng chỉ có 3 phần tử
        item.valueList.forEach((item2, j) => {
          if (typeof this.attributeList[i + 2] !== 'undefined') {
            this.attributeList[i + 1].valueList.forEach((item23, k) => {
              this.attributeList[i + 2].valueList.forEach((item33, l) => {
                // thêm sản phẩm vào danh sách
                let temp = new Product(this.appService.units.length > 0 ? this.appService.units[0].code : "");
                temp.code = this.product.code + "-" + (j + 1) + "-" + (k + 1) + "-" + (l + 1);
                temp.code_parent = this.product.code;
                temp.name = this.product.name + " - " + item2 + " - " + item23 + " - " + item33;
                temp.name_child = item2 + " - " + item23 + " - " + item33;
                temp.price = this.product.price;
                temp.price2 = this.product.price2;
                console.log('price2', this.product.price2);
                temp.unit = this.product.unit;

                temp.unit2 = this.product.unit2;
                temp.stock = this.product.stock;
                temp.category_code = this.product.category_code;
                temp.category_code_brand = this.product.category_code_brand;
                this.products.push(temp);
              });
            });
          }
        });
      }

    });
  }
  removeFromProducts(i) {
    this.products.splice(i, 1);
  }

  updateProduct() {

    if (this.product.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
      this.productUpdateForm.controls.name.setErrors("Bạn chưa nhập tên");
      return;
    } else if (this.product.category_code.trim() == "") {
      this.toastr.error("", "Bạn chưa chọn danh mục hàng hóa");
      return;
    }
    if(this.product.name.indexOf("!") > -1){
      this.toastr.error("Tên sản phẩm không được có ký tự !", "Tên không hợp lệ");
      return;
    }
    
    else if (this.product.unit2 != "") {
      if (this.product.packing > 1 && this.product.unit == this.product.unit2) {
        this.toastr.error("Đơn vị không được giống nhau", "Quy cách không hợp lệ");
        return;
      }
      else if (this.product.packing == 0) {
        this.toastr.error("Quy cách phải lớn hơn 0", "Quy cách không hợp lệ");
        return;
      }
    } else {
      this.product.packing = 1
    }

    if (this.product.type == 1 && this.product.code.length > 5) {
      this.toastr.error("", "Độ dài hàng cân phải nhỏ hơn hoặc bằng 5");
      return;
    }

    this.product.unit_default = this.product.unit;

    if (this.product.price == null) {
      this.product.price = 0;
    }
    if (this.product.price_in == null) {
      this.product.price_in = 0;
    }

    if (this.fileProductImg !== null && typeof (this.fileProductImg) !== "undefined") {
      this.product.image_url = environment.product + "/" + this.product.code + "." + this.utility.getExtensionFile(this.fileProductImg);
      console.log("1111");
    } else {
      console.log("2222");
    }

    if (!this.productUpdateForm.invalid) {
      this.product.deleted = this.isDeleted ? 1 : 0;
      // thay thế , bằng - trong name
      this.isLoading = true;
      this.productService.update(this.product).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            // this.toastr.success("", "Cập nhật hàng hóa thành công");
            // đóng popup và trả kết quả
            this.dialogRef.close({ code: 'ok', msg: response.affectedRows });
          }
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
      this.uploadAvatar(this.fileProductImg, this.product.code);
    }
  }
  insertProducts() {
    // kiểm tra dữ liệu
    this.products.forEach(element => {
      if (element.code.trim() != '' && element.name.trim() != '') {
      } else {
        this.toastr.warning("", "Vui lòng nhập đủ dữ liệu");
        return;
      }
    });
    console.log(this.products);
    this.productService.insertMulti(this.products).subscribe(
      async response => {
        if (response.code = 'ok' && response.affectedRows > 0) {
          // đóng popup và trả kết quả
          this.dialogRef.close({ code: 'ok', msg: response.affectedRows });
        }
      },
      error => {
        console.log(error);
      })
  }

  attributeChange(attribute, $event) {
    //check đã tồn tại thuộc tính chưa
    let isExist = false;
    this.attributeList.forEach(element => {
      if (element.name == $event.target.value) {
        isExist = true;
      }
    });

    if (isExist) {// đã tồn tại
      this.toastr.error('', 'Thuộc tính đã tồn tại');
      $event.target.value = '';
      attribute.name = '';
    } else {
      attribute.name = $event.target.value;
    }

  }
  add(attribute, event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (attribute.name == '') {
      this.toastr.warning('', 'Bạn chưa chọn thuộc tính');
    } else {
      if (attribute.valueList.length >= 5) {
        this.toastr.warning('', 'Bạn đã nhập tối đa giá trị');
      }
      else if (attribute.valueList.indexOf(value) >= 0) { // check trùng
        this.toastr.warning('', 'Giá trị đã tồn tại');
      }
      else if (value) {
        attribute.valueList.push(value);
        this.prepareProducts();
      }
      event.input.value = '';
    }

  }
  openCategoryPicker() {
    // lấy những data đã được chọn

    const dialogRef = this.dialog.open(CategoryPickerComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '60%',
      autoFocus: false,
      // height: '98%',
      data: { 'category_code': this.product.category_code }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.category_selected = result.data;
        this.product.category_code = this.category_selected.code;
      }
    });
  }
  remove(attribute, item: any): void {
    const index = attribute.valueList.indexOf(item);

    if (index >= 0) {
      attribute.valueList.splice(index, 1);
      this.prepareProducts();
    }
  }
  FromProducts(attribute, item: any): void {
    const index = attribute.valueList.indexOf(item);

    if (index >= 0) {
      attribute.valueList.splice(index, 1);
    }
  }

  async avatarChangeListener($event): Promise<void> {
    this.fileProductImg = $event.target.files[0];
    if (this.fileProductImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileProductImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileProductImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileProductImg, 300, 300).subscribe(result => {
        this.fileProductImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileProductImg);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  uploadAvatar(fileUpload: File, name: string) {
    console.log(fileUpload);
    if (fileUpload == null || typeof (fileUpload) == "undefined") {
      return;
    }
    let param = {
      des: environment.product,
      name: name
    }
    this.ioService.uploadImage(fileUpload, param).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  loadProduct_attributes() {
    if (typeof (this.appService.currentUser.product_attributes) === 'undefined') {
      this.appService.currentUser.product_attributes = '[]';
    }

    this.appService.product_attributes = JSON.parse(this.appService.currentUser.product_attributes);

    if (this.appService.product_attributes == null || this.appService.product_attributes.length == 0) {
      this.appService.product_attributes = [];
      this.appService.product_attributes.push(new ProductAttribute({ id: 1 }))
    }
    console.log(this.appService.product_attributes);
  }
  productCodeTypeClick(type) {
    this.productCodeType = type;
    if (this.productCodeType == "mts") {
      this.product.code = this.productCodeTemp;
    } else if (this.productCodeType == "tn" || this.productCodeType == "qmv") {
      this.product.code = "";
    }
  }
  async loadCategory() {
    let filter = {
      type: environment.cate_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }

  product_types_change(item) {
    this.product.type = item;
    if (this.product.type == 0) { // item tiger sau this.product.type nên bị ngược, 0
      this.product.unit = this.appService.units[0].code;
      this.product.unit2 = this.appService.units[0].code;
      this.product.packing = 1;

    } else if (this.product.type == 1) {// item tiger sau this.product.type nên bị ngược, 1
      this.product.unit = this.appService.units_weight_type[0].code;
      this.product.unit2 = this.appService.units_weight_type[1].code;
      this.product.packing = 1000;

    }
    console.log(item);
    console.log(this.product.type);

  }

  async loadCategoryBrand() {
    let filter = {
      type: environment.brand_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys_brand = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  onCancelClick() {
    this.dialogRef.close();
  }
}
