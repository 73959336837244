<div class="page-content fade-in-up">
    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col p-0">
            <div class="flexbox-b">
                <span class="mr-4 static-badge badge-primary"><i class="ti ti-ruler-alt"></i></span>
                <div>
                    <div class="font-weight-bold font-18">Đơn vị tính</div>
                </div>
            </div>
        </div>
        <div class="col  p-0 d-flex justify-content-end">
            <div class="pl-2">
                <button class="blurButton btn  btn-primary btn-labeled btn-labeled-left btn-icon" (click)="popup_unit_add()">
                    <span class="btn-label"><i class="ti ti ti-plus"></i></span>Thêm mới
                </button>
            </div>
        </div>
    </div>

    <div class="row align-items-center mt-3 m-2">
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">

            <div class="example-box" *ngFor="let item of appService.units ; let i = index" cdkDrag>
                <div class="col">{{i+1}}. {{item.value}}</div>
                <div class="col-4 text-right">
                    <div style="display: inline-block; margin-right: 11px;">
                        <a class="btn-label" (click)="popup_unit_edit(item,i)"><i class="ti ti ti-pencil-alt"></i>
                        </a>
                    </div>
                    <div style="display: inline-block; color: #ccc;">
                        <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                            </path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade " id="unit-edit-modal">
    <div class="modal-dialog " role="document">
        <form class="modal-content  form-horizontal" #unitEditForm="ngForm" id="form-edit-unit" (ngSubmit)="editUnit()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Sửa đơn vị tính</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <div class="form-group">
                    <label class=" col-form-label">Đơn vị tính</label>
                    <div class="">
                        <input [(ngModel)]="unit_edit" required class="form-control" name="unit_edit" placeholder="Đơn vị" type="text">
                    </div>
                </div>
            </div>

            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.unitEditForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Cập nhật
                </button>

                <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
            </div>
        </form>
    </div>
</div>
<div class="modal fade " id="unit-add-modal">
    <div class="modal-dialog " role="document">
        <form class="modal-content  form-horizontal" #unitAddForm="ngForm" id="form-add-unit" (ngSubmit)="insertUnit()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm đơn vị tính</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <div class="form-group">
                    <label class=" col-form-label">Đơn vị tính</label>
                    <div class="">
                        <input [(ngModel)]="unit_add" required class="form-control" name="unit_add" placeholder="Đơn vị" type="text">
                    </div>
                </div>
            </div>

            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.unitAddForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>

                <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
            </div>
        </form>
    </div>
</div>