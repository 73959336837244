import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounceTime, tap, map } from 'rxjs/operators';
import { AppService } from 'src/app/_services/app.service';
import { CategoryCustomerService } from 'src/app/_services/category-customer.service';
import { CategoryService } from 'src/app/_services/category.service';
import { CustomerService } from 'src/app/_services/customer.service';
import { environment } from 'src/environments/environment';
import { EmployeePickerComponent } from '../employee-picker/employee-picker.component';

@Component({
  selector: 'app-supplier-picker',
  templateUrl: './supplier-picker.component.html',
  styleUrls: ['./supplier-picker.component.css']
})

export class SupplierPickerComponent implements OnInit {
  //selected
  customer_code_selected = ''
  // for data
  suppliers
  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;
  //for filter
  categorys: any;
  filter = {
    type: environment.supplier_type,
    deleted: "0",
    name: "",
    category_code: "",
  }
  sorter = {
    sortStr: this.appService.sort_customer[0].code,
  }
  
  constructor(
    public categoryService: CategoryService,
    public customerService: CustomerService,
    public categoryCustomerService: CategoryCustomerService,
    public appService: AppService,
    public dialogRef: MatDialogRef<EmployeePickerComponent>,
      @Inject(MAT_DIALOG_DATA)
      public data: any
  ) { 
    this.customer_code_selected = this.data.customer_code_selected;
    this.loadCustomer();
    this.loadCategory();
  }

  ngOnInit(): void {
  }

  async loadCustomer() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.customerService.loadCustomer(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        this.searching = false;
        if (response) {
          this.suppliers = response.data;
          // this.customers_backup = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.appService.customers.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

        }
      },
      error => {
        this.searching = false;
        console.error(error);
      })
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(item): void {
    console.log(item);
    let result = {
      code: "ok",
      customer_name: item.name,
      customer_code: item.code
    }
    this.dialogRef.close(result);
  }
  loadCategory() {
    let filter = {
      type: environment.supplier_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })

  }
  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCustomer();
      }),
      map(term => term === '' ? [] : this.appService.customers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    
  }
}
