import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private subject = new Subject<any>();
  private subject2 = new Subject<any>();
  private subject3 = new Subject<any>();
  private subject4 = new Subject<any>();
  private subject5 = new Subject<any>();
  private subject6 = new Subject<any>();
  private subject7 = new Subject<any>();
  
  constructor() { }

  sendClickEvent(type) {
    this.subject.next(type);
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }


  sendoadPromotionEvent(type) {
    this.subject2.next(type);
  }
  getLoadPromotionEvent(): Observable<any> {
    return this.subject2.asObservable();
  }

  sendAddProductToCartEvent(product , quantity) {
    console.log("Gửi nè: ", {'item': product, 'quantity': quantity});
    this.subject3.next({'item': product, 'quantity': quantity});
  }
  getAddProductToCartEvent(): Observable<any> {
    return this.subject3.asObservable();
  }


  sendDoPaymentEvent() {
    this.subject4.next();
  }
  getDoPaymentEvent(): Observable<any> {
    return this.subject4.asObservable();
  }

  sendPrinttEvent(type) {
    this.subject5.next(type);
  }
  getPrintEvent(): Observable<any> {
    return this.subject5.asObservable();
  }

  sendCloseServiceDeskNavEvent() {
    this.subject6.next();
  }
  getCloseServiceDeskNavEvent(): Observable<any> {
    return this.subject6.asObservable();
  }

  sendOpenTimePricePickerEvent() {
    this.subject7.next();
  }
  getOpenTimePricePickerEvent(): Observable<any> {
    return this.subject7.asObservable();
  }


  // sendClickEvent_loadCustomer(type) {
  //   this.subject2.next(type);
  // }

  // getClickEvent_loadCustomer(): Observable<any> {
  //   return this.subject2.asObservable();
  // }
  
}
