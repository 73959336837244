import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { environment } from 'src/environments/environment';
import { EmployeePickerComponent } from '../employee-picker/employee-picker.component';

@Component({
  selector: 'app-catogory-change-picker',
  templateUrl: './catogory-change-picker.component.html',
  styleUrls: ['./catogory-change-picker.component.css']
})
export class CatogoryChangePickerComponent implements OnInit {
  categorys  = [];
  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: this.appService.sort_category[0].code,
  }
  
  filter = {
    deleted: 0,
    category_code: "",
    name: "",
    type: environment.cate_type
  }
  
  constructor(
    public appService: AppService,
    public categoryService: CategoryService,
    public dialogRef: MatDialogRef<EmployeePickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
    ) {
      this.loadCategory();
  }
  ngOnInit(): void {
  }
  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryService.loadCategory(this.filter, this.sorter , pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys = response.data;
          
          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }

        }
      },
      error => {
        console.error(error);
      })
  }
  changeCategory(){
      let data = {
        cate_code : this.filter.category_code,
        products : this.data.products
      }
      this.categoryService.changeCategory(data).subscribe(
        async response => {
          console.log(response);
          if (response.code == "ok" && response.affectedRows > 0) {
            let result = {
              code: "ok"
            }
            this.dialogRef.close(result);
          }
        },
        error => {
          console.error("error:");
          console.error(error);
        })  
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(item): void {
    this.filter.category_code = item.code;
  }
}
