<div *ngIf="this.appService.deskListPrice.length == 0">

    <h1 mat-dialog-title>Bạn chưa cài đặt giá dịch vụ</h1>
    <div class="m-3">
        <div class="row mt-2">
            <div class="font-weight-bold font-18">
                Hướng dẫn: Cài đặt -> Giá dịch vụ
            </div>
        </div>
        <div class="d-flex justify-content-around">
            <button class="btn btn-secondary " (click)="onCancelClick()">Đóng</button>
        </div>
    </div>
</div>
<div *ngIf="this.appService.deskListPrice.length > 0">
    <h1 mat-dialog-title>Xác nhận bắt đầu tính thời gian</h1>
    <div class="m-3">
        <div class="mt-2 mb-2 d-flex justify-content-around align-items-center">
            <div class="font-weight-bold font-20">
                Thời gian bắt đầu:
            </div>
            <div class="ngx-timepicker-field-example">
                <ngx-timepicker-field [(ngModel)]="currentTimeStr" [format]="24" [ngClass]="{'timepicker-qbs' : true} "></ngx-timepicker-field>
            </div>
        </div>
        <div class="row mt-5 mb-4">
            <div class="font-weight-bold font-18">
                <mat-form-field style="width: 450px;" appearance="fill">
                    <mat-label>Giá dịch vụ</mat-label>
                    <mat-select [(value)]="deskPriceIdSelected" [disabled]="false">
                        <mat-option *ngFor="let item of appService.deskListPrice" [value]="item.id">
                            {{item.price | qbscurrency}} - {{item.name}} - Khung giờ {{item.timeStart}} - {{item.timeEnd}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex justify-content-around">
            <button class="btn btn-secondary " (click)="onCancelClick()">Đóng</button>

            <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="onOkClick()">
        <span class="btn-label"><i class="ti ti-timer"></i></span>
        Bắt đầu
      </button>

        </div>
    </div>
</div>