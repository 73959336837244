<div ngbDropdown class="d-inline-block">
    <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
        <span class="btn-label"><span class="material-icons font-18">
            schedule
            </span></span>
            {{this.name}}
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="selectDateType(null)">
            <i class="ti ti-time"></i>
            Tất cả
        </button>
        <button *ngFor="let item of appService.workingShiftList; let i = index" ngbDropdownItem (click)="selectDateType(item)">
            <i class="ti ti-time"></i>
            {{item.name}}   [ <span class="font-weight-bold">{{item.timeStart}}</span> đến <span class="font-weight-bold">{{item.timeEnd}}</span> ]   
        </button>

    </div>
</div>