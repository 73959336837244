<div>
    <h4 mat-dialog-title>Chọn nhà cung cấp</h4>
    <div class="m-2">
        <div class="row mb-2 mt-2 justify-content-between">
            <div class="col-9 d-flex justify-content-start">
                <div class=" p-2 mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                    <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                    <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                    <input id="cus-typeahead-template" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm nhà cung cấp" [resultTemplate]="rt" [inputFormatter]="formatter">
                </div>
                <ng-template #rt let-r="result" let-t="term">
                    <div class="row" (click)="onClick(r)" style="width: 450px;">
                        <div class="img-product-search ml-1" [style.background-image]="this.appService.showImageCustomer(r['image_url'])"></div>
                        <div class="col"> #{{ r['code'] }}</div>
                        <div class="col">
                            <ngb-highlight [result]="r.name" [term]="t">
                            </ngb-highlight>
                        </div>
                        <div class="col">Phone: {{ r['phone'] }}</div>
                        <div class="col">Nhóm: {{ r['category_code'] }}</div>
                        <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
                    </div>
                </ng-template>


                <div class="p-2">
                    <select class="custom-select" [(ngModel)]="filter.category_code" (change)="this.loadCustomer();" name="category" id="category">
              <option value="">Tất cả</option>
              <option [ngValue]="item.code" *ngFor="let item of categorys">{{item.name}}</option>
            </select>
                </div>
                <div class="p-2 desktop">
                    <select class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="this.loadCustomer();" name="sorter" id="sorter">
              <option [ngValue]="item.code" *ngFor="let item of appService.sort_customer">{{item.name}}</option>
            </select>
                </div>
            </div>


        </div>

        <div class="row mb-2">
            <div class="row">
                <div class="select_category mb-2" *ngFor="let item of suppliers" (click)="click_select(item)" [ngClass]="{'selected_category' : item.code == customer_code_selected}">
                    <div>
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-around">
            <button class="btn btn-secondary " (click)="onCancelClick()">Đóng</button>
        </div>
    </div>
</div>