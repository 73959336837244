<div class=" align-items-center  d-flex justify-content-between ">
    <div class="text-blue">
        Nhấn và giữ, sau đó kéo thả sau đó nhấn lưu lại
    </div>
    <div>
        <div class="p-2">
            <button class="blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="saveArrangeABC()">
                <span class="btn-label"><span class="material-icons">abc</span></span>Xếp lại theo ABC
            </button>
            <button class="ml-2 blurButton btn  btn-primary btn-labeled btn-labeled-left btn-icon" (click)="saveArrange()">
                <span class="btn-label"><span class="material-icons">done</span></span>Lưu sắp xếp
            </button>

            <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="this.dialogRef.close();">
                <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
            </button>
        </div>

    </div>
</div>
<div id="is_arrange_mode" cdkDropList [cdkDropListData]="this.data" cdkDropListConnectedTo="even" class="example-list" (cdkDropListDropped)="drop($event)" [cdkDropListEnterPredicate]="noReturnPredicate">
    <div class="example-box" *ngFor="let item of this.data ; let i = index" [cdkDragData]="item" cdkDrag>
        {{i+1}} . {{item.name}}
    </div>
</div>