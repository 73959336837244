import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';
import { AppService } from '../../_services/app.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ShopService } from 'src/app/_services/shop.service';
import { CdkDrag, DragRef, CdkDragEnd } from '@angular/cdk/drag-drop';
import { DeskPrice } from 'src/app/model/deskPrice';
import { CartObj } from 'src/app/model/cart-obj';
import { WorkingShift } from 'src/app/model/workingShift';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  @ViewChild('userProfileForm') userProfileForm: HTMLFormElement;
  @ViewChild('shopProfileForm') shopProfileForm: HTMLFormElement;

  @ViewChild('addDeskForm') addDeskForm: HTMLFormElement;

  @ViewChild(CdkDrag) cdkDrag: CdkDrag;
  reset() {
    this.cdkDrag._dragRef['_previewRect'] = undefined;
    this.cdkDrag._dragRef['_boundaryRect'] = undefined;
  }



  is_time_service: boolean = false;


  isRessetPass: boolean = false;
  isLoading = false;

  password: string = "";
  repassword: string = "";

  // for desk
  deskAddList = [new CartObj()];
  deskSelected = new CartObj();
  // deskList = [];



  constructor(
    public appService: AppService,
    public userService: UserService,
    public shopService: ShopService,
    private toastr: ToastrService,
    config: NgbModalConfig,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }


  updateUserProfile() {
    console.log(this.isRessetPass);
    console.log(this.userProfileForm);
    console.log(this.userProfileForm.invalid);
    console.log(this.appService.currentUser);

    if (this.appService.currentUser.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên thành viên");
      this.userProfileForm.controls.name.setErrors("");
      return false;
    }
    if (this.appService.currentUser.phone.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập số điện thoại");
      this.userProfileForm.controls.phone.setErrors("");
      return false;
    }

    if (this.isRessetPass) {
      if (this.password.trim() == "") {
        this.toastr.warning("", "Vui lòng nhập mật khẩu");
        this.userProfileForm.controls.pass.setErrors("Vui lòng nhập mật khẩu");
        return false;
      } else if (this.password != this.repassword) {
        this.toastr.warning("", "Mật khẩu chưa trùng khớp");
        this.userProfileForm.controls.repasss.setErrors("Mật khẩu chưa trùng khớp");
        return false;
      }
      this.appService.currentUser.isResset = 1
      this.appService.currentUser.password = this.password;
    }


    // return;!this.addForm.invalid
    if (!this.userProfileForm.invalid) {
      this.isLoading = true;
      this.userService.updateUser(this.appService.currentUser).subscribe(
        response => {
          this.isLoading = false;
          if (response.code == "ok" && response.affectedRows > 0) {
            this.appService.currentUser.password = "";
            localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
            this.toastr.success("Cập nhật thông tin thành công", "");
          }
          else {
            this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
          console.error(error);
        })
    }

  }
  updateShopProfile() {
    console.log(this.shopProfileForm);
    console.log(this.shopProfileForm.invalid);
    console.log(this.appService.currentUser);
    if (this.appService.currentUser.shop_name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên Shop");
      this.shopProfileForm.controls.shop_name.setErrors("");
      return false;
    }
    if (this.appService.currentUser.shop_name2.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên viết tắt");
      this.shopProfileForm.controls.shop_name2.setErrors("");
      return false;
    }
    if (true) {
      this.isLoading = true;
      this.shopService.updateShopInfo(this.appService.currentUser).subscribe(
        response => {
          this.isLoading = false;
          if (response.code == "ok" && response.affectedRows > 0) {
            localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
            this.toastr.success("Cập nhật thông tin thành công", "");
          }
          else {
            this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
          console.error(error);
        })
    }

  }

  // for desk

  // addMoreDesk() {
  //   this.deskAddList.push(new Desk(0, ""));
  // }
  // removeMoreDesk(i) {
  //   this.deskAddList.splice(i, 1);
  // }
  openModal(idModal) {
    this.modalService.open(idModal);
  }
  openModalUpdate(idModal, item, i) {
    this.deskSelected = item;
    this.deskSelected.index = i
    this.modalService.open(idModal);
  }
  openModalDelete(idModal, item, i) {
    this.deskSelected = item;
    this.deskSelected.index = i
    this.modalService.open(idModal);
  }
  insertDesk() {
    console.log(this.is_time_service);
    // let names = [];
    // let is_time_services = [];
    // for (let item of this.deskAddList.values()) {
    //   names.push(item.name);
    //   is_time_services.push(item.is_time_service);
    // }

    if (true) {
      this.isLoading = true;
      let data = {
        name: this.deskAddList[0].name,
        is_time_service: this.deskAddList[0].is_time_service
      }
      this.shopService.insertDesk(data).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            this.toastr.success("", "Thêm mới thành công " + response.affectedRows);
            this.modalService.dismissAll();

            // thêm vào danh sách và storage
            // for(let item of names){
            //   this.appService.deskList.push(new Desk(response.insertId, item));
            // }
            let temp = new CartObj();
            temp.id = response.insertId;
            temp.name = data.name;
            temp.is_time_service = data.is_time_service;
            this.appService.deskList.push(temp);
            console.log(response);
            localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
            this.reset();
          }
          else {
            this.toastr.error("", "Đã có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("", error);
          console.error(error);
        })
    }
  }
  updateDesk() {
    this.deskSelected.is_time_service = (this.deskSelected.is_time_service || this.deskSelected.is_time_service == 1) ? 1 : 0
    console.log(this.deskSelected);
    if (true) {
      this.isLoading = true;
      this.shopService.updateDesk([this.deskSelected]).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            this.toastr.success("", "Cập nhật thành công " + response.affectedRows);
            this.modalService.dismissAll();
            // save to deskList in storage

            this.appService.deskList[this.deskSelected.index].name = this.deskSelected.name;
            this.appService.deskList[this.deskSelected.index].is_time_service = this.deskSelected.is_time_service;
            localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));

            console.log(this.appService.deskList[this.deskSelected.index].name);
          }
          else {
            this.toastr.error("", "Đã có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("", error);
          console.error(error);
        })
    }
  }

  deleteDesk() {
    console.log(this.deskSelected);
    if (true) {
      this.isLoading = true;
      this.shopService.deleteDesk(this.deskSelected).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            this.toastr.success("", "Xóa thành công " + response.affectedRows);
            this.modalService.dismissAll();
            // save to deskList in storage
            this.appService.deskList.splice(this.deskSelected.index, 1);
            localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));

            console.log(this.appService.deskList[this.deskSelected.index].name);
          }
          else {
            this.toastr.error("", "Đã có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("", error);
          console.error(error);
        })
    }
  }
  dragEnd(desk, event: CdkDragEnd) {
    let offset = { ...(<any>event.source._dragRef)._passiveTransform };

    desk.position.x = offset.x;
    desk.position.y = offset.y;
    desk.x = offset.x;
    desk.y = offset.y;

    console.log(this.appService.deskList);
  }
  bankList = [];
  loadBankList(){
    this.shopService.loadBankList().subscribe(
      async response => {
        console.log("-----loadBankList----");
        if (response) {
          this.bankList = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }
  loadDesk() {

    if (localStorage.getItem(environment.deskList) == null || localStorage.getItem(environment.deskList) == 'undefined') {
      this.shopService.loadDesk().subscribe(
        async response => {
          console.log("-----this.appService.deskList load----");
          if (response) {
            for (let item of response) {
              item.position = { x: item.x, y: item.y };
            }
            this.appService.deskList = response;
            console.log("this.deskList", this.appService.deskList);
          }
        },
        error => {
          console.error(error);
        })
    } else {
      this.appService.deskList = JSON.parse(localStorage.getItem(environment.deskList));
    }
    console.log("-----this.appService.deskList----", this.appService.deskList);
  }
  saveArrangeDesk() {
    if (true) {
      this.isLoading = true;
      this.shopService.updateDesk(this.appService.deskList).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            this.toastr.success("", "Cập nhật thành công " + response.affectedRows);
            this.modalService.dismissAll();
            // save to deskList in storage
            localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
            console.log(this.appService.deskList[this.deskSelected.index].name);
          }
          else {
            this.toastr.error("", "Đã có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("", error);
          console.error(error);
        })
    }
  }
  loadDeskPrice() {
    // có 2 vị trí như thế này : 1/2
    this.appService.deskListPrice = JSON.parse(this.appService.currentUser.shop_service_prices);
    if (this.appService.deskListPrice == null || this.appService.deskListPrice.length == 0) {
      this.appService.deskListPrice = [];
      this.appService.deskListPrice.push(new DeskPrice({ id: 1 }))
    }

  }
  loadWorkingShift() {
    if (typeof (this.appService.currentUser.workingShifts) === 'undefined') {
      this.appService.currentUser.workingShifts = '[]';
    }

    this.appService.workingShiftList = JSON.parse(this.appService.currentUser.workingShifts);

    if (this.appService.workingShiftList == null || this.appService.workingShiftList.length == 0) {
      this.appService.workingShiftList = [];
      this.appService.workingShiftList.push(new WorkingShift({ id: 1 }))
    }
    console.log(this.appService.deskListPrice);
  }
  addMoreDeskPrice(i) {
    this.appService.deskListPrice.push(new DeskPrice({ id: i }));
  }
  addWorkingShiftList(i) {
    this.appService.workingShiftList.push(new WorkingShift({ id: i }));
  }
  removeMoreDeskPrice(i) {
    this.appService.deskListPrice.splice(i, 1);
  }
  removeWorkingShiftList(i) {
    this.appService.workingShiftList.splice(i, 1);
  }
  updateDeskPrice() {
    console.log(this.appService.deskListPrice);
    this.isLoading = true;
    this.appService.currentUser.shop_service_prices = JSON.stringify(this.appService.deskListPrice);
    this.shopService.updateShopSomeInfo({ "service_prices": this.appService.currentUser.shop_service_prices }).subscribe(
      response => {
        this.isLoading = false;
        if (response.code == "ok" && response.affectedRows > 0) {
          localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
          console.log(this.appService.currentUser);
          this.toastr.success("Cập nhật thông tin thành công",);
        }
        else {
          this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      })
  }
  updateWorkingShift() {
    this.isLoading = true;
    this.appService.currentUser.workingShifts = JSON.stringify(this.appService.workingShiftList);
    this.shopService.updateShopSomeInfo({ "workingShifts": this.appService.currentUser.workingShifts }).subscribe(
      response => {
        this.isLoading = false;
        if (response.code == "ok" && response.affectedRows > 0) {
          localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
          console.log(this.appService.currentUser);
          this.toastr.success("Cập nhật thông tin thành công",);
        }
        else {
          this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      })
  }

  clickSelectPrinterSize(code) {
    //printSize
    this.appService.printSizeSelected = code;
    console.log(this.appService.printSizeSelected);
    localStorage.setItem(environment.prinSize, this.appService.printSizeSelected);
    this.toastr.success("", "Chọn kích thước máy in bill thành công");

  }




}
