
export class User {
    id: number = 0;
    name = "";
    shop_code = "";
    shop_name = "";
    email = "";
    phone = "";
    shop_address = "";
    shop_email = "";
    shop_phone = "";
    shop_name2 = "";
    shop_type = 0;
    shop_unit = "";
    shop_service_prices = "";
    workingShifts = '';
    product_attributes = '';
    desk_number = 0;
    created_date : Date;
    role = 0;
    isLoggedIn = false;
    isResset =0;
    password = "";
    token = "ttttttttttt";
    branchList = [];
    currentBranch = "";
    unit_report = "unit";
    zalo_user_id = "";
    telegram_chat_id = "";
    
    bank_id = "";
    account_no = "";
    account_name = "";
    vietqr_client_id = "";
    vietqr_api_key = "";
    vietqr_template = "";

    constructor() {
    }
}
