import { AuthenticationService } from './../../_services/authentication.service';
import { AppService } from './../../_services/app.service';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from './../../_services/user.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Md5 } from 'ts-md5';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CategoryService } from 'src/app/_services/category.service';

declare var $: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  isLoading = false;
  //Form login
  user = {
    shop_code: '',
    email: '',
    phone: '',
    address: '',
    password: '',
    confirm_password:''
  }
  // checkout-widget ="sdsdsd";
  constructor(
    public userService: UserService,
    public appService: AppService,
    public categoryService: CategoryService,
    private router: Router,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    $('body').removeClass('empty-layout');
  }



  register() {

    this.isLoading = true;
    // return;
    // this.user.password = Md5.hashStr(this.user.password).toString();
    console.log(this.user);
    if (true) {
      this.authenticationService.register(this.user)
        .pipe(first())
        .subscribe({
          next: response => {
            this.isLoading = false;
            if (response.code == 'ok') {
              this.router.navigate(['/login',{ shop_code: this.user.shop_code, phone: this.user.phone }]);
            } else {
              this.toastr.error("Mã Shop hoặc Email hoặc SĐT hoặc Mật khẩu không đúng", "Đăng ký");
            }
          },
          error: error => {
            this.isLoading = false;
            this.toastr.error("Mã Shop hoặc Email hoặc SĐT hoặc Mật khẩu không đúng", "Đăng ký");
            console.error(error);
          }
        })
    }
  }
  async loadBranchCategory() {
    let filter = {
      type: environment.branch_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        console.log(response);
        if (response) {
          this.appService.currentUser.branchList = response;
          this.appService.currentUser.currentBranch = '';
          localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
}
