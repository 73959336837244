<form class="modal-content form-horizontal">
    <div class="modal-header p-2">
        <h5 class="modal-title">Xác nhận xóa giỏ hàng</h5>
        <a data-dismiss="modal" (click)="this.dialogRef.close();">
            <i class="ti ti-close"></i>
        </a>
    </div>
    <div class="modal-body p-2 mt-3 mb-3">

        Bạn có chắn chắn muốn xóa giỏ hàng này ?

    </div>
    <div class="modal-footer justify-content-around bg-primary-50">
        <button (click)="deleteCart()" class="btn btn-primary ">
            Đồng ý xóa
        </button>
        <button (click)="cancelDeleteCart()" class="btn">
            Bỏ qua
        </button>
    </div>
</form>