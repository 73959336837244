<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">

            <app-month-picker (change)="monthSelected_change($event)"></app-month-picker>



        </div>
        <div class="col">
            <div class="d-flex justify-content-end">
                <select class="custom-select" [(ngModel)]="userNvbhSelected" (change)="userSelected_change()">
          <option value="">Nhân viên : All</option>
          <option [ngValue]="item.id" *ngFor="let item of userNvbhList">{{item.name}}</option>
        </select>

                <select class="custom-select" style="width: 115px;" [(ngModel)]="month_num" (ngModelChange)="month_change()" name="month_num" id="month_num">
          <option [ngValue]="item.code" *ngFor="let item of appService.month_nums">{{item.name}}</option>
        </select>
            </div>
        </div>
    </div>
    <!-- <div class="row mb-2 mt-2 justify-content-between">
      <div class="col d-flex justify-content-center">
        <button class="btn btn-primary btn-labeled " data-toggle="modal"
          data-target="#add-customer-modal" (click)="check()">
          Xem
        </button>
      </div>
    </div> -->

    <div class="row justify-content-between" style="margin: 20px 0px 12px 0px; font-size: 17px; font-weight: bold;">
        <div>
            <span style="background: #fda1b5;"><span color="primary">Doanh thu</span> : {{sum_prices | qbscurrency}} </span>
            <span class="ml-4" style="    background: #bedff7;"><span color="primary">Thanh toán</span> : {{sum_payment | qbscurrency}}</span>
        </div>

        <div><span color="primary">Công nợ</span> : {{sum_congno | qbscurrency}}</div>
    </div>


    <div>
        <div style="display: block; height: 400px;">
            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
      </canvas>
        </div>
    </div>
</div>