import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryService } from 'src/app/_services/category.service';

@Component({
  selector: 'app-arrange-data',
  templateUrl: './arrange-data.component.html',
  styleUrls: ['./arrange-data.component.css']
})
export class ArrangeDataComponent implements OnInit {

  constructor(
    public categoryService: CategoryService,
    public dialogRef: MatDialogRef<ArrangeDataComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    console.log(data);
  }

  ngOnInit(): void {
  }


  saveArrange(){
    let data = [];
    this.data.forEach((item,i) => {
      data.push({
        id: item.id,
        od: i
      });
    });
    this.categoryService.saveArrange(data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.dialogRef.close({code : "ok"});
        }
      },
      error => {
        console.error("error:");
        console.error(error);
      })
  }
  drop(event: CdkDragDrop<number[]>) {
    if (event.previousContainer === event.container) {
      console.log(event.previousIndex, event.currentIndex);
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
        );
        console.log(event.previousIndex, event.currentIndex);
        console.log(event.previousIndex, event.currentIndex);
    }
  }
  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false;
  }
  saveArrangeABC(){
    this.data.sort(function(a, b) {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });


    // this.studentService.saveArrange(data).subscribe(
    //   async response => {
    //     console.log(response);
    //     if (response.code == "ok") {
    //       this.toastr.success("", "Cập nhật thứ tự thành công");
    //       this.is_arrange_mode = false;
    //     }
    //   },
    //   error => {
    //     console.error("error:");
    //     console.error(error);
    //   })
  }
}
