<div ngbDropdown class="d-inline-block">
    <button class="blurButton btn btn-primary btn-labeled btn-labeled-left" ngbDropdownToggle>
        <span class="btn-label"><span class="material-icons font-18">
            calendar_month
            </span></span>
            {{this.date_type_selected}}   <span class="ml-2">{{this.dateStrStart}} - {{this.dateStrEnd}}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="selectDateType('Hôm nay')">
            <i class="ti ti-calendar"></i>
            Hôm nay
        </button>
        <button ngbDropdownItem (click)="selectDateType('Tuần này')">
            <i class="ti ti-calendar"></i>
            Tuần này
        </button>
        <button ngbDropdownItem (click)="selectDateType('Tháng này')">
            <i class="ti ti-calendar"></i>
            Tháng này
        </button>
        <button ngbDropdownItem (click)="selectDateType('Quý này')">
            <i class="ti ti-calendar"></i>
            Quý này
        </button>
        <button ngbDropdownItem (click)="selectDateType('Sáu tháng')">
            <i class="ti ti-calendar"></i>
            Sáu tháng
        </button>
        <button ngbDropdownItem (click)="selectDateType('Chín tháng')">
            <i class="ti ti-calendar"></i>
            Chín tháng
        </button>
        <button ngbDropdownItem (click)="selectDateType('Năm nay')">
            <i class="ti ti-calendar"></i>
            Năm nay
        </button>
        <button ngbDropdownItem (click)="selectDateType('Thời gian'); picker.open()">
            <i class="ti ti-calendar"></i>
            Khoảng thời gian
        </button>
        <button ngbDropdownItem (click)="selectDateType('Tất cả')">
            <i class="ti ti-calendar"></i>
            Tất cả
        </button>
    </div>
</div>
<div class="ml-3" style="display: none;">
    <mat-form-field appearance="fill">
        <mat-label>Chọn khoảng thời gian</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Bắt đầu" [(ngModel)]="dateStart">
            <input matEndDate placeholder="Kết thúc" [(ngModel)]="dateEnd" (dateChange)="dateChangeEvent($event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker touchUi #picker></mat-date-range-picker>
    </mat-form-field>

</div>