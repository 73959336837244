import { UtilityService } from './../../_services/utility.service';
import { environment } from './../../../environments/environment';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ProductService } from '../../_services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Cart } from '../../model/cart';
import { debounceTime, delay, map, tap} from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/model/product';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductDetailComponent } from 'src/app/components/product-detail/product-detail.component';
import { ProductAddComponent } from '../../pages/product/product-add/product-add.component';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ProductNotfoundComponent } from '../product-notfound/product-notfound.component';

declare var $: any;
@Component({
  selector: 'app-product-picker',
  templateUrl: './product-picker.component.html',
  styleUrls: ['./product-picker.component.css']
})
export class ProductPickerComponent implements OnInit {
  @Input() saletype: string ; //sale và servicedesk
  addProductToCartEvent: Subscription;
  clickEvent: Subscription;

  loading = false;
  display_type = 1;
  //for search and scanner
  searchValue = "";
  productCodeTemp = "";
  // searchStr: string;
  searchFailed = false;

  //for promotions;
  promotions = [];

  //for paging
  viewNumsObj = this.appService.viewNums[4].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;
  categorys_brand: any
  // size screen
  itemAreaSize = 10;

  subscription

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // 

    if(event.key == "Shift") {
      // console.log('Không hiểu vì sao có Shift');
    }

    // nếu đang ở màn hình bán hàng.
    else if(this.router.url == '/salev2'){
      if (event.key === 'Enter') {
        console.log("Barcode: ",this.productCodeTemp);
        if (this.productCodeTemp.trim().length >= 3) {
          this.searchValue = this.productCodeTemp;
          
          let code = this.searchValue;
          let quantity = 1;
          // kiểm tra nếu mã vạch cân điện tử thì xử lý code và quantity
          // if(this.searchValue.length == 13 && this.searchValue.substring(0, 2) == '20' &&  this.searchValue.substring(12, 13) == '1'){
          if(this.searchValue.length == 13 && this.searchValue.substring(0, 2) == '20'){
            console.warn("Đây là Hàng Cân tem");
            // đẩy là mã vạch máy cân
            code = this.searchValue.slice(2, 7);
            quantity = parseInt(this.searchValue.slice(7, 12)); // này là đơn vị g
            quantity = quantity/1000 // đổi sang đơn vị kg
          }

          let product_temp = this.utilityService.searchProduct(code);


          if (product_temp) {
            this.sharedService.sendAddProductToCartEvent(product_temp, quantity);
            this.appService.openCheckout();
          }else{
            this.toastr.error("Tạo mới sản phẩm", "Không tìm thấy mã sản phẩm");
            this.warningDialog(code);
            // this.openProductAdd(code);
          }
        }
        this.productCodeTemp = "";
      }
      else if(event.key == "Escape") {
        this.productCodeTemp = '';
      }
      else {
        if (this.productCodeTemp === null || this.productCodeTemp === "") {
          this.productCodeTemp = event.key;
        } else {
          this.productCodeTemp = this.productCodeTemp + event.key;
        }
      }
    }

  }
  
  constructor(
    public appService: AppService,
    public dialog: MatDialog,
    public productService: ProductService,
    public categoryService: CategoryService,
    private toastr: ToastrService,
    public utilityService: UtilityService,
    private sharedService: SharedService,
    private router: Router,

    // public promotionService: PromotionService,
  ) {

    this.sorter.sortStr = this.appService.sort_products[0].code;
    this.productSelected = this.product;
    if (localStorage.getItem(environment.display_type)) {
      this.display_type = parseInt(localStorage.getItem(environment.display_type));
    }

    // if(this.appService.products.length == 0){
    this.loadProduct();
    this.loadCategoryBrand();
    this.loadCategory();
    

    setTimeout(()=>{this.loadProductForScan();},2500);
    
    

    // load promotion from header component
    // this.sharedService.sendoadPromotionEvent("");


  }
 

  ngOnInit() {
    this.clickEvent = this.sharedService.getClickEvent().subscribe((type) => {
      console.log("Sale component:",type);
      this.loadProduct();
    });
    
    this.addProductToCartEvent = this.sharedService.getAddProductToCartEvent().subscribe((data) => {
      console.log("Product pickerCall from other component:",data);
      // this.addToCart(product,1);
      this.addToCart(data.item,data.quantity);
    },);
    console.log(this.saletype);
  }

  ngOnDestroy() {
    if (this.addProductToCartEvent) {
      this.addProductToCartEvent.unsubscribe();
    }
    if (this.clickEvent) {
      this.clickEvent.unsubscribe();
    }
  }
  clickBarcode(){
    $(".btn").trigger("blur");  // bỏ focus để tránh enter
    this.productCodeTemp='';
    this.toastr.success('','Sẵn sàng quét mã vạch');
  }

  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if(event.key == "F9") {
  //     event.preventDefault();
  //     $('#input-search-sale').focus();
  //   }
  // }
  




  ngAfterViewInit() {

  }
  
  clearSearchInput(){
    if(this.filter.name !=''){
      this.filter.name='';
      this.loadProduct();
    }
  }

  products_backup: any
  categories: any
  filter = {
    code: "",
    name: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: "",
    is_favorite: "",
    type: ""
  }
  sorter = {
    sortStr: "",
  }


  // for add product
  product = new Product("");
  // for update product
  productSelected: any;
  isDeleted: boolean;

  //for cart
  productCart = {
    name: "",
    code: "",
    price: 0,
    price_in: 0,
    description: "",
  };
  txt_quantity = 1;







  resetProduct() {
    let temp_unit = "";
    if(this.appService.units.length > 0)
    temp_unit = this.appService.units[0].code;

    this.product = new Product(temp_unit);
  }
  loadProduct() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.appService.products = response.data;
            console.log(this.appService.products);
        
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.appService.products.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

          }
        },
        error => {

        })
    }
  }
  toggle_favorite(){
    $(".btn").trigger("blur");
    this.filter.is_favorite = this.filter.is_favorite == "" ? "f":"";
    this.loadProduct();
  }
  toggle_product_type(){
    $(".btn").trigger("blur");
    this.filter.type = this.filter.type == "" ? "1": "";
    this.loadProduct();
  }
  save_toggle_favorite(item){
    item.is_favorite = item.is_favorite == "" ? "f":"";
    let data ={
      is_favorite: item.is_favorite
    }
    this.productService.updateSomeInfo(item.code,data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Cập nhật yêu thích thành công");
          this.loadProduct();
        }
      },
      error => {
        console.error(error);
      })
  }
  loadProductForScan() {
    let pagingObj = {
      viewNumsObj: 3000,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.appService.products_backup = response.data;

            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.appService.products.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

          }
        },
        error => {

        })
    }
  }
  setUnit_default(code,unit_default){

    let data ={
      unit_default: unit_default
    }
    this.productService.updateSomeInfo(code,data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Cập nhật đơn vị mặc định thành công");
          this.loadProduct();
        }
      },
      error => {
        console.error(error);
      })
  }
  loadTotalPage() {
    // request to server
    this.productService.loadItemSum(this.filter)
      .subscribe(
        response => {
          console.log('ItemListComponent load Item');
          console.log(response);
          this.totalItems = response[0].NumberOfItems;
          let pages_temp = Math.ceil(this.totalItems / this.appService.viewNumsObj);
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          console.log(pages_temp);
          console.log(this.listPage);
        },
        error => {
          console.error(error);
        });
  };

  async loadCategory() {

    let filter = {
      type: environment.cate_type
    };

    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.categories = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })

  }

  openDetail(item) {
    const dialogRef = this.dialog.open(ProductDetailComponent, {
      position: {
        // left: '10px',
        // right: '10px'
        // top: '10px'
      },
      // disableClose: true,
      panelClass: 'full-screen-modal',
      data: item
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadProduct();
        this.toastr.success(result.msg, "Cập nhật thành công")
      }
    });
  }

  popup_product_add(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-product-modal").modal('show');
  }


  popup_product_import(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-customer-modal").modal('show');
  }



  category_change(event) {
    this.loadProduct();
  }
  sort_change(event) {
    this.loadProduct();
  }

  doSorting(item){
    if(item == 'name'){
      this.sorter.sortStr = this.sorter.sortStr == "name ASC" ? "name DESC": 'name ASC' ;
      console.log(this.sorter.sortStr);
    }else if(item == 'stock'){
      this.sorter.sortStr = this.sorter.sortStr == "stock ASC" ? "stock DESC": 'stock ASC' ;
      console.log(this.sorter.sortStr);
    }else if(item == 'price'){
      this.sorter.sortStr = this.sorter.sortStr == "price ASC" ? "price DESC": 'price ASC' ;
      console.log(this.sorter.sortStr);
    }
    this.loadProduct();
  }
  

 
  countAllProduct() {

    this.productService.countAllProduct().subscribe(
      response => {
        this.product.code = this.appService.currentUser.shop_code + (parseInt(response[0].CountAllProduct) + 1).toString();
      },
      error => {
        console.error(error);
      })

  }



  quantity_add2(item) {
    if (!isNaN(item.temp_quantity)) {
      item.temp_quantity = ++item.temp_quantity;
    }
  }
  
  quantity_sub2(item) {
    if (!isNaN(item.temp_quantity) && item.temp_quantity > 1) {
      item.temp_quantity = --item.temp_quantity;
    }
  }


  openProductAdd(code) {
    $(".btn").trigger("blur");  // bỏ focus để tránh enter
    const dialogRef = this.dialog.open(ProductAddComponent, {
      position: {
        // left: '10px',
        // right: '10px'
        // top: '10px'
      },
      disableClose: true,
      // width: '98%',
      // height: '98%',
      panelClass: 'full-screen-modal',
      data: {code:code}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        // thêm mới thành công và add vào cart
        this.addToCart(result.product,1);
        //load lại Product
        this.loadProduct();
      }
    });
  }
  warningDialog(code) {
    $(".btn").trigger("blur");  // bỏ focus để tránh enter
    let data = {
      title : 'Không tìm thấy mã sản phẩm ',
      code :  code,
      msg : 'Vui lòng thử lại. Hoặc thêm mới sản phẩm.',
    }
      const dialogRef = this.dialog.open(ProductNotfoundComponent, {
        data: data
      });
  }

  focusFunction(event) {
    event.target.select();
  }
  addToCart(product,quantity) {
    // product.price_temp = product.price; // giá lẻ temp dành cho tính toán khuyến mãi.
    $(".btn").trigger("blur");  // bỏ focus để tránh enter
    //START Quan trọng ____________ xử lý giá cho loại giá bán buôn
    if(product.packing == null || product.packing ==0) product.packing = 1;
    product.price_unit2 = this.utilityService.mathRound(product.price_temp / product.packing);
    if(product.price2 == 0 || product.price2_temp == 0){
      product.price2 = product.price;
      product.price2_temp = product.price;
    }
    else
    product.price2_unit2 = this.utilityService.mathRound(product.price2_temp / product.packing);


    if(product.unit2 !='' && product.unit2==product.unit_default){ // nếu là unit2 thì tính giá unit2
      product.price = product.price_unit2;
    }
    //END Quan trọng ____________ xử lý giá cho loại giá đơn vị 2


    
    
    let cart = new Cart();
    cart.product = Object.assign({}, product); // copy product không tham chiếu

    cart.unit_type = product.unit2==product.unit_default ? "unit2" : 'unit';
    // cart.quantity = this.txt_quantity;
    cart.quantity = quantity;

    if(this.saletype == 'sale'){
      //kiểm tra trong giỏ đã có sp chưa, nếu chưa thì thêm nếu có thì update
      if (this.appService.listCart[this.appService.cartIndex].cart.length > 0) { // list đã có dữ liệu nên kiểm tra để update hoặc add
        var check =this.utilityService.checkToAddCart(this.appService.listCart[this.appService.cartIndex].cart, cart);
        if (check > -1) {
          // nếu có thì thêm luôn ở trong hàm check
          // cuộn đến item update
          console.log(document.getElementById(cart.product.code));
          document.getElementById(cart.product.code).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          this.appService.listCart[this.appService.cartIndex].highlight_index = check
        }
        else {
          this.appService.listCart[this.appService.cartIndex].cart.push(cart);
          // đặt highlight_index cho product mới
          this.appService.listCart[this.appService.cartIndex].highlight_index = this.appService.listCart[this.appService.cartIndex].cart.length - 1
          //cuộn đến cuối cùng
          document.getElementById("row-last").scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          

          // var scrollContainer = document.getElementById('scrollContainer');
          // scrollContainer.scrollTop = scrollContainer.scrollHeight;
          // console.log(scrollContainer);
          // console.log(document.getElementById(this.appService.listCart[this.appService.cartIndex].cart[this.appService.listCart[this.appService.cartIndex].cart.length-2].product.code));
          // scrollContainer.scrollTop = document.getElementById(this.appService.listCart[this.appService.cartIndex].cart[this.appService.listCart[this.appService.cartIndex].cart.length-2].product.code).offsetTop;
          // document.getElementById(this.appService.listCart[this.appService.cartIndex].cart[this.appService.listCart[this.appService.cartIndex].cart.length-2].product.code).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      } else { // List chưa có dữ liệu thì add luôn.
        this.appService.listCart[this.appService.cartIndex].cart.push(cart);
      }
      this.appService.prepareSaleObject({sale_status:99,isPromotion:true,isPromotionDetail:true, isCheckCustomerPay:false}); //99 chi de cap nhatprice2

      localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));

    }else if(this.saletype == 'servicedesk'){
      //kiểm tra trong giỏ đã có sp chưa, nếu chưa thì thêm nếu có thì update
      if (this.appService.deskList[this.appService.deskIndex].cart.length > 0) {
        if (this.utilityService.checkToAddCart(this.appService.deskList[this.appService.deskIndex].cart, cart) > -1) {
          // nếu có thì thêm luôn ở trong hàm check
        }
        else {
          this.appService.deskList[this.appService.deskIndex].cart.push(cart);
        }
      } else {
        this.appService.deskList[this.appService.deskIndex].cart.push(cart);
      }
      this.appService.prepareSaleObject({sale_status:99,isPromotion:false,isPromotionDetail:true, isCheckCustomerPay:false}); //99 chi de cap nhatprice2

      localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
    }

    product.temp_quantity = 1; // reset to default
    this.toastr.clear();
    this.toastr.success("", "Thêm vào giỏ hàng thành công");
  }


  changeViewType(type) {
    this.display_type = type;
    localStorage.setItem(environment.display_type, this.display_type.toString());
  }


  //for paging
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.appService.viewNumsObj);
    this.loadProduct();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadProduct();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadProduct();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadProduct();
    }
  };






  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        // this.filter.code = this.filter.name;
        console.log(this.filter.name);
        this.loadProduct();
      }),
      map(term => term === '' ? []
        : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => {

      }),
    )
    datas = ["d","sd"];
  onClick(r) {
    console.log(r);
    this.filter.name = "";
    this.addToCart(r, 1)
    // this.popupAddToCart(r);
  }



  


  
  // sự kiện từ component catelist
  onClick_selectCate(event){

    if(typeof event.category_code !== "undefined"){
      this.filter.category_code = event.category_code
      console.log("category_code:",this.filter.category_code);
      this.loadProduct();
    }
    
  }
  
  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.product_avatar + ')';
  // }
  statesWithFlags: { name: string, flag: string }[] = [
    { 'name': 'Alabama', 'flag': '5/5c/Flag_of_Alabama.svg/45px-Flag_of_Alabama.svg.png' },
    { 'name': 'Alaska', 'flag': 'e/e6/Flag_of_Alaska.svg/43px-Flag_of_Alaska.svg.png' },
  ];

  loadCategoryBrand() {
    let filter = {
      type: environment.brand_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys_brand = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  mouseover(item){
    console.log("mouseover",item)
  }
  mouseout(item){
    console.log("mouseout",item)
  }

  // sale







  
  // customer_save(){

  // }
  // customer_cate_select(code){
  //   this.filter.category_code = code;
  // }
  
  // isAddingCustomer = false

 



  


}

