<form class="modal-content form-horizontal">
    <div class="modal-header p-2">
        <h5 class="modal-title"><span style="color: red;">{{data.title}}</span> {{data.code}}</h5>
    </div>
    <div class="modal-body p-2 mt-3 mb-3">
        <div style="    color: #fffcfc;
        background-color: red;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 19px;">Sản phẩm chưa được thêm vào hóa đơn.</div>
        <div class="text-center">
            <img class="img-circle" src="./assets/img/icons/notfound.png" alt="image" width="100px" />
        </div>
        <div class="text-center">
            {{data.msg}}
        </div>

    </div>
    <div class="modal-footer justify-content-around">
        <button (click)="okClick()" class="btn btn-primary ">
            Đóng
        </button>

    </div>
</form>