import { Component, Inject, OnInit } from '@angular/core';
import { AppService } from 'src/app/_services/app.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/_services/utility.service';
import { DeskPrice } from 'src/app/model/deskPrice';

@Component({
  selector: 'app-servicedeskprice',
  templateUrl: './servicedeskprice.component.html',
  styleUrls: ['./servicedeskprice.component.css']
})
export class ServicedeskpriceComponent implements OnInit {


  deskPriceIdSelected = 1;
  public currentTimeStr: string = '00:00';

  constructor(
    public appService: AppService,
    public utilityService: UtilityService,
    public dialogRef: MatDialogRef<ServicedeskpriceComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {}
  ) {
    // có 2 vị trí như thế này :2/2
    this.appService.deskListPrice = JSON.parse(this.appService.currentUser.shop_service_prices);
    if (this.appService.deskListPrice == null || this.appService.deskListPrice.length == 0) {
      this.appService.deskListPrice = [];
      this.appService.deskListPrice.push(new DeskPrice({ id: 1 }))
    }
    //chọn giá theo giờ.
    this.deskPriceIdSelected = this.utilityService.getCurrentTimeService();
    console.log("this.deskPriceIdSelected",this.deskPriceIdSelected);

    var currentTime = new Date();
    this.currentTimeStr = currentTime.getHours() + ':' + currentTime.getMinutes();
    console.log(this.currentTimeStr);
  }

  ngOnInit(): void {
  }
   


  onCancelClick(): void {
    this.dialogRef.close();
  }
  onOkClick(): void {

    let temp = {}

    for (let i = 0; i < this.appService.deskListPrice.length; i++) {
      if (this.appService.deskListPrice[i].id == this.deskPriceIdSelected) {
        temp = this.appService.deskListPrice[i];
      }
    }
    var temptemp = this.currentTimeStr.split(":");
    let result = {
      code: "ok",
      data: temp,
      timer: {
        hh: temptemp[0],
        mm: temptemp[1]
      }
    }
    this.dialogRef.close(result);
  }

}
