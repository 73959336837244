<div class="">
    <div class="d-flex justify-content-between  align-items-center pt-2 pb-2">
        <div>
            <app-range-time-picker (click2)="onClick_selectDate($event)" [datetype]="'dateStrStart'"></app-range-time-picker>
        </div>
        <div class="d-flex justify-content-start  align-items-center">
            <div class="mr-2">
                <div style="font-weight: bold;">Tổng nợ: </div>
            </div>
            <div class="mr-2">
                <div style="font-weight: bold;">{{debtItem.debtTotal | qbscurrency}}</div>
            </div>
            <div>
                <img style="width: 20px;" src="../assets/img/icons/vnd.svg">
            </div>
        </div>
    </div>
    <div *ngIf="display_type == 0" class="table-responsive">
        <table class="table table-head-purple table-hover">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px;">#</th>
                    <th>Khách hàng</th>
                    <th>Tổng nợ</th>
                    <th>Số đơn</th>
                    <th class="no-sort"><span><i class="ti ti-control-stop"></i></span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of debtItem.data; let i = index" (click)="openDebtCustomerDetail(item,i)" class="h-row-item">
                    <td>
                        <span>{{i+1}}</span>
                    </td>
                    <td>
                        <div style="font-size: 18px;"> {{item.customer_name}}</div>
                    </td>
                    <td>{{item.debt| qbscurrency:0}}</td>
                    <td><span>{{item.debtTime}}</span></td>
                    <td><span><i class="ti ti-control-play"></i></span></td>

                </tr>
            </tbody>
        </table>
    </div>

</div>