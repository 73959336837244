import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
import { User } from '../model/user';
import { CartObj } from '../model/cart-obj';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';


@Injectable()
export class UserService {

  constructor(
    public http: HttpClient,
    private router: Router,
    private appService: AppService
  ) {
    console.log('UserService constructor');
  }

  // login(user) {
  //   return this.http.post<any>(this.appService.getApiEndpoint('user.php?action=LOGIN'), user)
  //     .pipe(map(data => { return data; }));
  // }
  loadMembership(filter) {
    return this.http.post<any>(this.appService.getApiEndpoint('users/get'), filter,).map(data => { return data; });
  }
  insertUser(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('users/member'), data,).map(data => { return data; });
  }
  updateMember(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('users/member/'+ data.id), data,).map(data => { return data; });
    // return this.http.post<any>(this.appService.getApiEndpoint('user.php?action=UPDATE_USERINFO_ACTION'), data,).map(data => { return data; });
  }
  updateUser(data) {
    console.log(data);
    return this.http.put<any>(this.appService.getApiEndpoint('users/'+ data.id), data,).map(data => { return data; });
    // return this.http.post<any>(this.appService.getApiEndpoint('user.php?action=UPDATE_USERINFO_ACTION'), data,).map(data => { return data; });
  }


  /////////////////////////////////
  // login(user) {

  //   return this.http.post<any>(this.appService.getApiEndpoint('user.php?action=LOGIN'), user,).map(data => { return data; });

  // }
  
  logout() {
    this.appService.currentUser = new User();
    localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
    this.router.navigate(['/login', { id: 1990 }]);
    this.appService.listCart = [new CartObj()];
  }
  // logins(email, password) {
  //   // console.log(email, password);
  //   return this.http.post<any>(this.appService.getApiEndpoint('user.php?action=LOGIN'), { email, password },
  //   )
  //     .map(data => {
  //       if (data.length > 0) {
  //         localStorage.setItem('currentUser', JSON.stringify(data[0]));
  //         console.log('login localStorage');
  //         console.log(localStorage.getItem('currentUser'));
  //         return data;
  //       }
  //     });
  // }
  
}




