<div class="qbs-header d-flex justify-content-between">
    <h5 class="modal-title">
        <b>Mã đơn #{{data.code}}</b> <span class="ml-4">Ngày {{data.created_date.substring(11, 16)}}
            {{data.dateStr}}</span> <span class="ml-4">{{data.user_name}}</span>
        <span class="text-danger" *ngIf="data.status == 3"> Đã hủy</span>
    </h5>

    <a (click)="close()"><i class="ti ti-close"></i></a>
</div>
<div class="qbs-body">
    <div class="chat-list">
        <div class="scroller">
            <div class="">

                <div class="row info-row" *ngIf="data.status == 4">
                    <div class="col">
                        Nhân viên GH:
                    </div>
                    <div class="col text-right">
                        {{data.processing_user_name}}
                    </div>
                </div>
                <div class="row info-row font-weight-bold">
                    <div class="col">
                        Khách hàng:
                    </div>
                    <div class="col text-right">
                        {{data.customer_name}}
                    </div>
                </div>
                <div class="row info-row">
                    <div class="col">
                        Tổng đơn hàng:
                    </div>
                    <div class="col text-right">
                        {{data.price_totol | qbscurrency}}
                    </div>
                </div>
                <div class="row info-row">
                    <div class="col">
                        Khuyến mãi:{{data.promotion_type}}
                    </div>
                    <div class="col text-right">
                        {{data.promotion_promotion | qbscurrency }}
                    </div>
                </div>
                <div class="row info-row font-weight-bold">
                    <div class="col">
                        Tổng thanh toán:
                    </div>
                    <div class="col text-right font-18">
                        {{data.price_after_promotion | qbscurrency}}
                    </div>
                </div>
                <div class="row info-row">
                    <div class="col">
                        Đã thanh toán:
                    </div>
                    <div class="col text-right">
                        {{data.payment_total | qbscurrency}}
                    </div>
                </div>
                <div class="row info-row">
                    <div class="col">
                        Ghi chú:
                    </div>
                    <div class="col text-right">
                        {{data.note}}
                    </div>
                </div>
                <!-- <div class="row info-row">
                    <div class="col">
                        Tiền thừa:
                    </div>
                    <div class="col text-right">
                        {{this.utilityService.roundToTwo(data.customer_pay - data.price_after_promotion) | qbscurrency}}
                    </div>
                </div> -->


                <div class="row mt-2 mb-2  justify-content-between">
                    <div>
                        <div *ngIf="data.status == 0">
                            <button class="btn btn-info btn-labeled btn-labeled-left btn-sm btn-icon" (click)="popupSelectNvgh()">
                                    <span class="btn-label"><i class="ti ti-truck"></i></span>
                                    Giao hàng
                                </button>
                        </div>
                        <div *ngIf="data.status == 0">
                            <button class="btn btn-primary btn-labeled btn-labeled-left btn-sm btn-icon" (click)="doPayment()">
                                    <span class="btn-label"><i class="ti ti-money"></i></span>
                                    Thanh toán
                                </button>
                        </div>

                        <div *ngIf="data.status == 1">
                            <button class="btn btn-secondary btn-labeled btn-labeled-left  btn-icon" (click)="openDeleteSale()">
                                    <span class="btn-label"><span class="material-icons"> clear </span></span>
                                    Hủy đơn
                                </button>
                            <span class="m-1"></span>
                            <button class="btn btn-secondary btn-labeled btn-labeled-left  btn-icon" (click)="returning()">
                                    <span class="btn-label"><span class="material-icons"> undo </span></span>
                                    Trả hàng
                                </button>
                            <span class="m-1"></span>
                            <button class="btn btn-secondary btn-labeled btn-labeled-left  btn-icon" [disabled]="data.has_returning == 0" (click)="view_returning_history()">
                                    <span class="btn-label"><span class="material-icons"> history </span></span>
                                    Lịch sử trả hàng
                                </button>
                            <span class="m-1"></span>
                        </div>
                    </div>
                    <button (click)="print(data.status)" class="btn btn-outline-primary btn-icon-only "><i
                                class="ti ti-printer"></i>
                        </button>
                </div>



                <div *ngIf=" (data.service_details.length > 0)" style="padding-top: 10px">
                    <div *ngFor="let item of data.service_details; let i = index" style="border-bottom: 1px dashed gray; padding: 10px 0px;">
                        <div class="row">
                            <div class="ml-3">
                                <a>
                                    <img class="img-circle" src="./assets/img/icons/clock-spinner.png" alt="image" width="35px" />
                                </a>
                            </div>
                            <div class="col">
                                <div> Tiền giờ: {{item.price | qbscurrency}} x {{item.quantityLable}}</div>
                                <div class="row">
                                    <div class="col">
                                        <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">
                                            <span><i class="ti ti-control-play"></i></span>
                                            <span>: {{item.serviceStartTime | date :'HH:mm dd/MM/yyyy'
                                                }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">
                                            <span><i class="ti ti-control-pause"></i></span>
                                            <span>: {{item.serviceEndTime | date :'HH:mm dd/MM/yyyy'
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mr-3 text-right">
                                {{item.amount | qbscurrency}}
                            </div>
                        </div>
                    </div>
                </div>

                <table style="width: 100%; margin-top: 10px;">
                    <tr class="item-row font-bold">
                        <td style="width: 25px; text-align: center;">

                        </td>
                        <td style="width: 55%;">
                            Sản phẩm/dịch vụ
                        </td>
                        <td class="input-quantity-cover" style="text-align: center;">
                            Số lượng
                        </td>
                        <td *ngIf="this.isReturning" class="input-quantity-cover" style="text-align: center;">
                            SL trả
                        </td>
                        <td style="text-align: right; color: black;">
                            Thành tiền
                        </td>
                    </tr>
                    <tr *ngFor="let item of cartDetail; let i = index" class="item-row">
                        <td style="width: 25px; text-align: center;">
                            <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                            <span class="lbl-stt">{{i+1}}</span>
                        </td>
                        <td style="width: 55%;">
                            <div style="line-height: 16px;">
                                {{item.product_name}} - <span>{{item.price|number}}</span>
                                <span style="color: gray; margin-left: 3px;">{{item.unit}}</span>
                            </div>
                        </td>
                        <!-- <td style="text-align: right;"><span style="font-size: 11px;">{{item.product.price| qbscurrency}}</span> </td> -->
                        <td style="text-align: center;">
                            {{item.quantity}}
                        </td>
                        <td *ngIf="this.isReturning" class="input-quantity-cover" style="text-align: center;">
                            <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti-minus"></i></a> -->
                            <button class=" btn-adjust-left btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='returning_quantity_sub(item)'><i class="ti ti-minus"></i></button>
                            <input [(ngModel)]="item.quantity_return" type="number" (focus)="focusFunction($event)" (focusout)="quantity_tempChange(item)" class="input-quantity" />
                            <button class="btn-adjust-right btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='returning_quantity_add(item)'><i class="ti ti-plus"></i></button>
                            <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti ti-plus"></i></a> -->
                        </td>
                        <td *ngIf="!this.isReturning" style="text-align: right; color: black;">
                            {{item.quantity * item.price | qbscurrency:0}}
                        </td>
                        <td *ngIf="this.isReturning" style="text-align: right; color: black;">
                            {{item.quantity_return * item.price | qbscurrency:0}}
                        </td>
                    </tr>
                    <tr *ngIf="isReturning" style="background: lightgray;line-height: 30px;">
                        <td colspan="2" style="text-align: center;font-weight: bold;">Trả lại khách hàng</td>
                        <td></td>
                        <td style="text-align: center;font-weight: bold;">{{returning_quantity | qbscurrency}}
                        </td>
                        <td style="text-align: right; color: black; font-weight: bold;">
                            {{returning_price | qbscurrency}}</td>
                    </tr>
                </table>
                <div *ngIf="isReturning" class="row mt-2 mb-2  justify-content-between">
                    <div></div>
                    <div>

                        <button class="btn btn-secondary btn-labeled btn-labeled-left  btn-icon" (click)="isReturning=false">
                                    <span class="btn-label"><span class="material-icons">
                                            clear
                                        </span></span>
                                    Bỏ qua
                                </button>
                        <span class="m-1"></span>
                        <button class="btn btn-danger btn-labeled btn-labeled-left  btn-icon" (click)="returning_confirm()">
                                    <span class="btn-label"><span class="material-icons">
                                            undo
                                        </span></span>
                                    Xác nhận trả hàng
                                </button>
                        <span class="m-1"></span>

                    </div>

                </div>
            </div>
        </div>
    </div>



</div>



<app-printer [data]="data" [cartDetail]="cartDetail"> </app-printer>
<!-- <div id="print-section-on-order" style="display: inline-block;">
    <div style=" font-family: Tahoma; font-size: 14px;">
        <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
        <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
        <div style="text-align: center;">{{appService.currentUser.shop_address}}</div>
        <div style="text-align: right">{{data.created_date.substring(11, 16)}} {{data.dateStr}}
        </div>
        <table style="font-size: 13px; width: 100%;">
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Khách hàng: </td>
                <td style="text-align: right;">{{data.customer_name}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Tổng hóa đơn: </td>
                <td style="text-align: right;">{{data.price_totol | qbscurrency}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Khuyến mãi:
                </td>
                <td style="text-align: right;">
                    {{data.promotion_promotion}}{{data.promotion_type}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Tổng thanh toán: </td>
                <td style="text-align: right;"> {{data.price_after_promotion | qbscurrency}} </td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Đã thanh toán: </td>
                <td style="text-align: right;"> {{data.payment_total | qbscurrency}} </td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Khách đưa:</td>
                <td style="text-align: right;"> {{data.customer_pay | qbscurrency}} </td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Tiền thừa:</td>
                <td style="text-align: right;"> {{data.customer_pay - data.price_after_promotion | qbscurrency}} </td>
            </tr>
        </table>
        <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
            <table style="font-size: 12px; width: 100%;">
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;">Tên</td>
                    <td style="text-align: right;vertical-align: middle;">ĐG</td>
                    <td style="text-align: center; min-width: 40px; vertical-align: middle;">SL</td>
                    <td style="text-align: right; vertical-align: middle;">T.Tiền</td>
                </tr>
                <tr *ngFor="let item of cartDetail; let i = index">

                    <td style="vertical-align: middle;">{{i+1}}</td>
                    <td style="vertical-align: middle;">{{item.product_name}}</td>
                    <td style="text-align: right;; vertical-align: middle;">{{item.price| qbscurrency}}</td>
                    <td style="text-align: left;; vertical-align: middle;">
                        x {{item.quantity}}
                        <span style="font-size: 9px;">{{item.unit}}</span>
                    </td>

                    <td style="text-align: right;; vertical-align: middle;">{{item.quantity * item.price | qbscurrency:0}}
                    </td>

                </tr>
                <tr style="font-weight: bold;">
                    <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
                    <td style="border-top: 1px dashed gray;padding-top: 5px;">{{data.price_totol | qbscurrency}}
                    </td>
                </tr>
            </table>
        </div>
        <div style="text-align: center;">Cảm ơn quý khách, hẹn gặp lại</div>
        <div style="text-align: center; margin-bottom: 10; font-size: 8;">GIẢI PHÁP SỐ QBS</div>
    </div>
</div> -->