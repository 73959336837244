<div class="row mb-2 mt-2 justify-content-between">
    <div class="col p-0">
        <div class="flexbox-b">
            <span class="mr-4 static-badge badge-primary">
                <span class="material-icons">
                    local_shipping
                </span>
            </span>
            <div>
                <div class="font-weight-bold font-18">Kết nối trực tiếp tới các đơn vị vận chuyển</div>
            </div>
        </div>
    </div>
    <div class="col  p-0 d-flex justify-content-end">
        <div class="pl-2">

        </div>
    </div>
</div>

<div class="">
    <div *ngFor="let item of shipping_list; let i = index" class="ship-cover d-flex justify-content-between align-items-center">
        <div class="">
            <div class="mb-3">
                <img class="" [src]="item.img" style="height: 36px; opacity: 0.7;">
                <span class="" style="height: 24px; margin-left: 24px;">Chưa kết nối</span>
            </div>
            <div class="">
                <span class="">{{item.description}}</span>
            </div>
        </div>

        <div class="">
            <div class="">
                <a class="mr-3">
                    <span class="material-icons">info</span>
                </a>
                <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons">link</span></span>
                    Kết nối
                </button>
            </div>
        </div>
    </div>




</div>