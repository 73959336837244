import { Router } from '@angular/router';


import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { SaleService } from 'src/app/_services/sale.service';
import { Product } from 'src/app/model/product';
import {  debounceTime,  map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProductService } from 'src/app/_services/product.service';
import { CartObj } from 'src/app/model/cart-obj';
import { environment } from 'src/environments/environment';
import { Cart } from 'src/app/model/cart';
import { StampPrintingComponent } from 'src/app/components/stamp-printing/stamp-printing.component';
import { MatDialog } from '@angular/material/dialog';
import { newArray } from '@angular/compiler/src/util';
declare var $: any;
@Component({
  selector: 'app-stamp-print',
  templateUrl: './stamp-print.component.html',
  styleUrls: ['./stamp-print.component.css']
})
export class StampPrintComponent implements OnInit {

  loading = false;
  display_type = 0;
  cartObj = new CartObj();

  // products = [];
  products_backup: any
  categorys: any
  filter = {
    name: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  sorter = {
    sortStr: "",
  }

  // for add product
  product: any = new Product("");
  // for update product
  productSelected: any = new Product("");
  isDeleted: boolean;

  //for cart
  productCart: any = new Product("");
  txt_quantity = 1;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;
  //for print
  dateTimeStr :string
  constructor(
    private router: Router,
    public appService: AppService,
    public saleService: SaleService,
    public productService: ProductService,
    public categoryService: CategoryService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    public dialog: MatDialog,
  ) {

    if (localStorage.getItem(environment.qrcodePrintCart)) {
      this.cartObj = JSON.parse(localStorage.getItem(environment.qrcodePrintCart));
    }
    this.cartObj.type = 1;

    this.sorter.sortStr = this.appService.sort_products[0].code;
    this.productSelected = this.product;

    // cách hiển thị
    if (localStorage.getItem(environment.display_type)) {
      this.display_type = parseInt(localStorage.getItem(environment.display_type));
    }

    //load product

    this.loadProduct();


  }




  ngOnInit() {
    this.loadCategory();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
  }

  resetProduct() {
    let temp_unit = "";
    if(this.appService.units.length > 0)
    temp_unit = this.appService.units[0].code;

    this.product = new Product(temp_unit);
  }
  loadProduct() {
    let pagingObj = {
      viewNumsObj: 2000,
      pageObj: this.curentPage
    };
    console.log("load product form sale");
    this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        console.log(response);
        if (response) {
          this.appService.products = response.data;
            this.appService.products_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.appService.products.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;
        }
      },
      error => {

      })
  }
  async loadCategory() {

    // this.loading = await this.loadingCtrl.create({
    //   message: 'Đang xử lý...',
    // });
    // await this.loading.present();

    let filter = {
      type: environment.cate_type
    };

    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })

  }
  popup_product_add(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-product-modal").modal('show');
  }


  popup_product_import(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-customer-modal").modal('show');
  }



  category_change(event) {
    this.loadProduct();
  }
  sort_change(event) {
    this.loadProduct();
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.appService.products = this.appService.products_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.appService.products = this.appService.products.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  insertProduct() {

    if (this.product.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên hàng hóa");
      return;
    } else if (this.product.category_code.trim() == "") {
      this.toastr.error("", "Bạn chưa chọn danh mục hàng hóa");
      return;
    }

    this.loading = true;
    this.productService.insert(this.product).subscribe(
      async response => {
        this.loading = false;
        console.log(response);
        if (response) {
          this.toastr.success("", "Thêm mới hàng hóa thành công");
          $("#add-product-modal").modal('hide');
          this.resetProduct();
          this.loadProduct();
        }
      },
      error => {
        this.loading = false;
        console.error(error);
      })


  }
  // updateProduct() {

  //   this.productSelected.deleted = this.isDeleted ? 1 : 0;
  //   this.loading = true;
  //   this.productService.update(this.productSelected).subscribe(
  //     async response => {
  //       this.loading = false;
  //       console.log(response);
  //       if (response) {
  //         this.toastr.success("", "Cập nhật hàng hóa thành công");
  //         $("#update-product-modal").modal('hide');
  //         this.loadProduct();
  //       }
  //     },
  //     error => {
  //       this.loading = false;
  //       console.error(error);
  //     })

  // }
  countAllProduct() {

    this.productService.countAllProduct().subscribe(
      response => {
        this.product.code = this.appService.currentUser.shop_code + (parseInt(response[0].CountAllProduct) + 1).toString();
      },
      error => {
        console.error(error);
      })

  }

  popupAddToCart(item) {
    this.txt_quantity = 1;
    this.productSelected = item;
    $("#add-to-import-cart-modal").modal('show');
  }

  quantity_form_add() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity)) {
      this.txt_quantity = ++this.txt_quantity;
    }
  }
  quantity_form_sub() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity) && this.txt_quantity > 1) {
      this.txt_quantity = --this.txt_quantity;
    }
  }
  // cho tông đơn
  quantity_sub(item) {
    if (item.quantity > 0) {
      item.quantity--;
      this.prepareSaleObject();
    }
  }
  // cho tông đơn
  quantity_add(item) {
    item.quantity++;
    this.prepareSaleObject();
  }
  quantity_add2(item) {
    if (!isNaN(item.temp_quantity)) {
      item.temp_quantity = ++item.temp_quantity;
      this.prepareSaleObject();
    }
  }
  
  quantity_sub2(item) {
    if (!isNaN(item.temp_quantity) && item.temp_quantity > 1) {
      item.temp_quantity = --item.temp_quantity;
      this.prepareSaleObject();
    }
  }
  focusFunction(event) {
    event.target.select();
  }
  addToCart(product,quantity) {

    //xử lý giá cho loại giá đơn vị 2
    if(product.unit2 !='' && product.unit2==product.unit_default){
    // chuyển thành giá theo dơn vị 2
      product.price = this.utilityService.mathRound(product.price_temp / product.packing);
      product.price2 = this.utilityService.mathRound(product.price2_temp / product.packing);
    }
    console.log("--------------------------------------------",product);


    let cart = new Cart();
    cart.product = Object.assign({}, product); // copy product không tham chiếu
    cart.unit_type = product.unit2==product.unit_default ? "unit2" : 'unit';
    cart.quantity = quantity;
    //kiểm tra trong giỏ đã có sp chưa, nếu chưa thì thêm nếu có thì update
    if (this.cartObj.cart.length > 0) {
      if (this.utilityService.checkToAddCart(this.cartObj.cart, cart) > -1) {
        // nếu có thì thêm luôn ở trong hàm check
      }
      else {
        this.cartObj.cart.push(cart);
      }
    } else {
      this.cartObj.cart.push(cart);
    }

    // this.cartObj.total_quantity = this.cartObj.total_quantity + cart.quantity;
    // this.cartObj.price_totol = this.cartObj.price_totol + (cart.quantity * product.price);
    // this.cartObj.customer_pay = this.cartObj.price_totol;
    this.prepareSaleObject();
    localStorage.setItem(environment.qrcodePrintCart, JSON.stringify(this.cartObj));
    product.temp_quantity = 1; // reset to default
    this.toastr.remove;
    this.toastr.success("", "Thêm vào giỏ hàng thành công");
  }
 
  openCheckout2(item, quantity ) {
    this.addToCart(item, quantity);
    this.popup_cart();
    item.temp_quantity = 1;
    this.prepareSaleObject();
  }
  setUnit_default(code,unit_default){

    let data ={
      unit_default: unit_default
    }
    this.productService.updateSomeInfo(code,data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Cập nhật đơn vị mặc định thành công");
          this.loadProduct();
        }
      },
      error => {
        console.error(error);
      })
  }
  // popupPayment(item) {
  //   console.log("xacd");
  // }
  changeViewType(type) {
    this.display_type = type;
    localStorage.setItem(environment.display_type, this.display_type.toString());
  }

  removeProduct(i, item) {
    console.log("item1", this.cartObj.cart);
    this.cartObj.cart.splice(i, 1); //delete index i

    // this.cartObj.total_quantity = this.cartObj.total_quantity - item.quantity;
    // this.cartObj.price_totol = this.cartObj.price_totol - (parseInt(item.product.price) * item.quantity);
    // this.cartObj.customer_pay = this.cartObj.price_totol;
    // // this.customer_pay = this.cartObj.price_totol;
    this.prepareSaleObject();
    console.log("item2", this.cartObj.cart);
  }
  doOrder() {
    this.toastr.success("", "Tính năng chưa được khai báo");
  }
  backToList(){
    this.router.navigate(['/stockin', {  }]);
  }
  print(type) {
    // this.toastr.success("", "Máy in chưa được thiết lập");
    let printContents, popupWin;
    printContents = document.getElementById('print-section-stamp-component').innerHTML;

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>In Tem sản phẩm</title>
          <style>
          //........Customized style.......
          </style>
        </head>
        
        <body style='margin:0' onload="window.print();window.close()" >
          ${printContents}
     
        </body>
      </html>`
    );
    // onload="window.print();window.close()"
    popupWin.document.close();
    this.cartObj = new CartObj();
    $("#stamp-print-modal").modal('hide');
  }
  prin_nhan(type) {
    // this.toastr.success("", "Máy in chưa được thiết lập");
    let printContents, popupWin;
    printContents = document.getElementById('print-section-stamp-component_nhan').innerHTML;

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>In Tem nhãn Sản phẩm</title>
          <style>
          //........Customized style.......
          </style>
        </head>
        
        <body style='margin:0' onload="window.print();window.close()">
          ${printContents}
     
        </body>
      </html>`
    );
    // onload="window.print();window.close()"
    popupWin.document.close();
    this.cartObj = new CartObj();
    $("#stamp-print-modal").modal('hide');
  }

  prepareSaleObject() {
    // for insert detail sale
    // for insert detail sale
    let product_codes = "";
    let product_names = "";
    let product_stocks = "";
    let prices = "";
    let units = "";
    let packings = "";
    let unit_types = "";
    let quantitys = "";
    // for cartObj ( cart chính, cart to)
    let price_totol = 0;
    let quantity = 0;
    // tạo mảng số lượng để lặp in tem
    this.cartObj.cart.forEach((element,i) => {
      price_totol += element.quantity * element.product.price;
      quantity += Number(element.quantity);
      element.quantitys = newArray(element.quantity);
    });
    // cartObj.code: "",
    // this.cartObj.type = this.cartObj.type; // dã co dulieu nen k can gan nua
    this.cartObj.status = -1; //1: đã thanh toán, 0: đặt hàng, 2: Chờ thanh toán, 3: đã hủy, 4: đang xử lý; -1 in tem
    this.cartObj.product_quantity = this.cartObj.cart.length;// số lượng mặt hàng
    this.cartObj.quantity = quantity; // số lượng
    this.cartObj.price_totol = price_totol;
    this.cartObj.price_after_promotion = this.cartObj.price_totol;
    this.cartObj.customer_pay = this.cartObj.price_after_promotion; // Tiền khách đưa
    this.cartObj.payment_total = this.cartObj.customer_pay; // Tiền khách phải trả

    // this.appService.cartObj.total_quantity = product_quantity;
    this.cartObj.customer_id = this.cartObj.customer_id;
    this.cartObj.customer_code = this.cartObj.customer_code;
    this.cartObj.customer_name = this.cartObj.customer_name;
    this.cartObj.user_id = this.appService.currentUser.id;
    this.cartObj.user_name = this.appService.currentUser.name;
    

    //for service price
    this.cartObj.service_price = this.cartObj.service_price;
    this.cartObj.service_price_temp = 0;
    this.cartObj.service_detail_str = JSON.stringify(this.cartObj.cart_service);

    this.cartObj.promotion_code ="",
    this.cartObj.promotion_type = "",
    this.cartObj.promotion_promotion =0,

    this.cartObj.product_codes = product_codes;// for insert detail sale
    this.cartObj.product_names = product_names;// for insert detail sale
    this.cartObj.product_stocks = product_stocks;// for insert detail sale
    this.cartObj.prices = prices;// for insert detail sale
    this.cartObj.units = units;// for insert detail sale
    this.cartObj.packings = packings;// for insert detail sale
    this.cartObj.unit_types = unit_types;// for insert detail sale
    this.cartObj.quantitys = quantitys;// for insert detail sale

    localStorage.setItem(environment.qrcodePrintCart, JSON.stringify(this.cartObj));

    return this.cartObj;
  }
  
  updateStockProduct() {
    let product_codes = "";
    let quantity_changes = "";


    for (let i = 0; i < this.cartObj.cart.length; i++) {
      if (this.cartObj.cart.length == i + 1) {
        quantity_changes += this.cartObj.cart[i].quantity.toString();
        product_codes += this.cartObj.cart[i].product.code;
      } else {
        quantity_changes += this.cartObj.cart[i].quantity.toString() + ",";
        product_codes += this.cartObj.cart[i].product.code + ",";
      }
    }

    let updateStockList = {
      product_codes: product_codes,
      quantity_changes: quantity_changes,
      type: this.cartObj.type // 0 is sale, 1 is import
    }

    this.productService.updateStockProduct(updateStockList).subscribe(
      async result => {
        console.log('insertSaleDetail');
        console.log(result);
        if (result > 0) {
          this.loadProduct();
          this.cartObj = new CartObj();
          localStorage.setItem(environment.qrcodePrintCart, JSON.stringify(this.cartObj));
          this.toastr.success("", "Thanh toán thành công");
          this.router.navigate(['/stockin']);
        }
      },
      error => {
        console.error(error);
      });

  }
  popup_cart() {
    $("#stamp-print-modal").modal('show');
  }

  // paging
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadProduct();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadProduct();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadProduct();
    }
  }
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.appService.viewNumsObj);
    this.loadProduct();
  }
  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadProduct();
      }),
      map(term => term === '' ? [] : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
    onClick(r) {
      this.filter.name = "";
      this.popupAddToCart(r);
    }
  
}

