import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ReportProductDetailComponent } from 'src/app/components/report-product-detail/report-product-detail.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { SaleService } from 'src/app/_services/sale.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit {
  
  //date
  revenueCustomerList = [];
  revenueCustomerSum : any;
 
  // param
  dateStrStart: any ="";
  dateStrEnd: any ="";
  timeStrStart: any ="";
  timeStrEnd: any ="";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_revenue_warehouse_reports[0].code,
  }
  //for paging
  viewNumsObj = this.appService.viewNums[5].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  categorys: any
  filter = {
    name : '',
    category_code: "",
  }
  // unit
  unitSelected = 'unit';
  searching = false;
  constructor(
    public appService: AppService,
    private categoryService: CategoryService,
    public utilityService: UtilityService,
    public saleService: SaleService,
    public dialog: MatDialog,
  ) {
    this.dateStrStart = this.utilityService.getFullDateStrFromDate(new Date());
    this.dateStrEnd = this.dateStrStart;
    if(localStorage.getItem(environment.unitSelected) !== null){
      this.unitSelected = localStorage.getItem(environment.unitSelected);
    }
  }

  ngOnInit(): void {
    this.loadData();
    this.loadCategory();
  }
  // sự kiện từ component catelist
  onClick_selectItem(item) {
    let data = {
      fromDate: this.dateStrStart,
      toDate: this.dateStrEnd,
      type_report: "ProductCustomer", //customer and product not using
      code: item.code, //customer code
      product_code: item.code, //customer code
      product_name: item.name, //customer code
      product_price: item.price, //customer code
      unitSelected: this.unitSelected, // unitSelected
    }
    const dialogRef = this.dialog.open(ReportProductDetailComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      width: '95%',
      height: '95%',
      panelClass: 'full-screen-modal',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result.data", result.data);
      }
    });
    
    
  }
  sort_change($event){
    console.log(this.sorter);
    this.loadData();
  }

  selectUnitReport(unit) {
    this.appService.currentUser.unit_report = unit;
    localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
    this.loadData();
  }
  onClick_selectDate(dateObj) {
    this.dateStrStart = dateObj.dateStrStart;
    this.dateStrEnd = dateObj.dateStrEnd;
    this.loadData();
  }
  onClick_selectTime(dateObj) {
    this.timeStrStart = dateObj.timeStrStart;
    this.timeStrEnd = dateObj.timeStrEnd;
    this.loadData();
  }
  loadData(){
    let filter = {
      fromDate: this.dateStrStart,
      toDate: this.dateStrEnd,
      fromTime: this.timeStrStart,
      toTime: this.timeStrEnd,
      unit_report: this.appService.currentUser.unit_report, //customer and product không sử dụng
      type_report: "warehouse", //customer and product không sử dụng
      code: "", //customer code
      category_code : this.filter.category_code,
      name : this.filter.name // product name or code 
    }
    console.log("filter", filter);
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    this.searching = true;
    this.revenueCustomerList = [];
    this.revenueCustomerSum = undefined;
    this.saleService.getRevenueProduct(filter, this.sorter, pagingObj).subscribe(
      async response => {
        this.searching = false;
        if (response) {
          this.revenueCustomerList = response.data;
          if(response.dataSum.length > 0)
          this.revenueCustomerSum = response.dataSum[0];
          
          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.revenueCustomerList.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
        }
      },
      error => {
        this.searching = false;
        console.error(error);
      });
  }
  unitSelect(code){
    this.unitSelected = code;
    localStorage.setItem(environment.unitSelected, this.unitSelected);
    console.log(this.unitSelected);
  }
  data_export() {
    let filter = {
      fromDate: this.dateStrStart,
      toDate: this.dateStrEnd,
      fromTime: this.timeStrStart,
      toTime: this.timeStrEnd,
      type_report: "warehouse", //customer and product
      code: "", //product code
    }
    this.saleService.exportWarehouse(filter);
  }




  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadData();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadData();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadData();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadData();
    }
  };


  async loadCategory() {
    let filter = {
      type: environment.cate_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  category_change(event) {
    this.loadData();
  }

    //for search
    formatter = (x: { name: string }) => x.name;

    search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(400),
        tap(() => {
          this.searching = true;
          console.log("typing search");
          this.loadData();
        }),
        map(term => term === '' ? [] : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
        tap(() => { }),
      );
      onClick(r) {
        this.filter.name = "";
        // this.popupUpdate(r);
      }
}



