<div class="page-content fade-in-up">
    <div class="row" style="height: calc(100vh - 46px); background: #0288d1;">
        <div class="col pr-0" style="background: white; margin-right: 3px;">
            <app-product-picker [saletype]="'sale'"></app-product-picker>
        </div>
        <div class="pr-2 pl-2" style="width: 500px;  
        background: white;
        /* background-image: url(./assets/img/bill-background.jpg); */
        ">
            <app-sale-invoice [saletype]="'sale'"></app-sale-invoice>
        </div>
    </div>


</div>