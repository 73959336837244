<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2">
        <div class="col">
            <div class="flexbox-b">
                <span class="mr-4 static-badge badge-primary"><i class="ti ti-receipt"></i></span>
                <div>
                    <div style="font-size: 20px;">Tạo phiếu nhập kho</div>
                </div>
            </div>
        </div>
        <div class="col text-right">
            <button class="btn btn-labeled mr-2 btn-labeled-left btn-icon" name="cancel" (click)="backToList()">
                <span class="btn-label"><i class="ti ti-arrow-left"></i></span>Danh sách phiếu
            </button>
            <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="popup_cart()">
                <span class="btn-label">{{cartObj.cart.length}}</span>Phiếu nhập
            </button>
        </div>
    </div>
    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <div class="mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="typeahead-template" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm sản phẩm" [resultTemplate]="rt" [inputFormatter]="formatter" (focus)="focusFunction($event)">
            </div>
            <ng-template #rt let-r="result" let-t="term">
                <div class="d-flex align-content-center justify-content-between" (click)="onClick(r)">
                    <div class="d-flex align-content-start align-items-center">
                        <div class="img-product-search mr-1" [style.background-image]="this.appService.showImageProduct(r['image_url'])">
                        </div>
                        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                        <div class="mr-1 ml-1" style="font-size: smaller"> #{{ r['code'] }}</div>

                    </div>
                    <div style="font-weight: bold; width: 100px; text-align: right;">
                        <img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg"> {{ (r['price'] | qbscurrency )}}
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="col">
            <div class="d-flex justify-content-end">
                <div class="pl-2">
                    <select class="custom-select" [(ngModel)]="filter.category_code" (change)="category_change($event)" name="category" id="category">
                        <option value="">Danh mục</option>
                        <option [ngValue]="item.code" *ngFor="let item of categories">{{item.name}}</option>
                    </select>
                </div>
                <div class="pl-2">
                    <select class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}
                        </option>
                    </select>
                </div>
                <div class="pl-2">
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn  btn-icon" ngbDropdownToggle>
                            <span class="btn-label">
                                <i *ngIf="display_type ==0" class="ti ti-layout-grid2"></i>
                                <i *ngIf="display_type ==1" class="ti ti-align-justify"></i>
                            </span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="changeViewType(0)"><i class="ti ti-layout-grid2"></i> Dạng
                                lưới</button>
                            <button ngbDropdownItem (click)="changeViewType(1)"><i class="ti ti-align-justify"></i> Dạng
                                danh sách</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <app-categorylist class="desktop" [datas]="this.categories" [selected]="this.filter.category_code" (click)="onClick_selectCate($event)"></app-categorylist>
    <div>

        <div class="row align-items-center mb-2 paging-cover">
            <div class="col-4">
                <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                    {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
            </div>
            <div class="col-8 d-flex justify-content-end">
                <ul class="pagination p-1" style="margin-bottom: 0px;">
                    <li>
                        <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}
                            </option>
                        </select>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                            <span aria-hidden="true">
                                <i class="ti ti-angle-left"></i>
                            </span>
                        </a>
                    </li>
                    <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                        <a class="page-link" href="javascript:;">{{item.i}}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                            <i class="ti ti-angle-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div *ngIf="display_type ==1" class="table-responsive">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px;">#</th>
                        <th class="sorting" (click)="doSorting('name')">Tên
                            <span>
                                <span *ngIf="sorter.sortStr != 'name ASC' && sorter.sortStr != 'name DESC'" class="material-icons sort">sort</span>
                            <span *ngIf="sorter.sortStr == 'name ASC'" class="material-icons">north</span>
                            <span *ngIf="sorter.sortStr == 'name DESC'" class="material-icons">south</span>
                            </span>
                        </th>
                        <th>Danh mục</th>
                        <th class="text-right sorting" (click)="doSorting('stock')">Tồn kho
                            <span>
                                <span *ngIf="sorter.sortStr != 'stock ASC' && sorter.sortStr != 'stock DESC'" class="material-icons sort">sort</span>
                            <span *ngIf="sorter.sortStr == 'stock ASC'" class="material-icons">north</span>
                            <span *ngIf="sorter.sortStr == 'stock DESC'" class="material-icons">south</span>
                            </span>
                        </th>
                        <th class="text-right sorting" (click)="doSorting('price')">Giá
                            <span>
                                <span *ngIf="sorter.sortStr != 'price ASC' && sorter.sortStr != 'price DESC'" class="material-icons sort">sort</span>
                            <span *ngIf="sorter.sortStr == 'price ASC'" class="material-icons">north</span>
                            <span *ngIf="sorter.sortStr == 'price DESC'" class="material-icons">south</span>
                            </span>
                        </th>
                        <th style="text-align: center;">Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of appService.products; let i = index" class="h-row-item row-display-type-1">
                        <td>
                            <!-- <a class="media-img" href="javascript:;">
                      <img class="img-circle" src="./assets/img/icons/user.svg" alt="image" width="30" />
                    </a> -->
                            <a (click)="popupAddToCart(item)">
                                <img style="width: 25px;" src="./assets/img/icons/add_cart.svg" alt="image" />
                            </a>
                        </td>
                        <td>
                            <!-- <div style="font-size: 18px;"> {{i+1}} {{item.name}}
                                <i *ngIf="item.stock < 1" class="la la-question-circle font-18 text-danger" title="Hết hàng trong kho"></i>
                            </div> -->
                            <div style="font-size: 18px;">{{item.name}}</div>
                            <div [ngClass]="{'text-danger': item.stock < 1}" style="font-size: 12px; font-weight: bold;">
                                {{item.code}}
                            </div>
                        </td>
                        <td>{{item.category_name}}</td>
                        <td><span [ngClass]="{'text-danger': item.stock < 1}">{{item.stock}}</span></td>
                        <td style="text-align: right;">{{item.price| qbscurrency:0}}</td>

                        <td>
                            <div class="enterQuantiti-temp-2 d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <div style="display: inline-block;">
                                        <button class="btn  btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_sub2(item)"><i class="ti ti-minus"></i></button>
                                    </div>
                                    <div style="display: inline-block; margin: 0 5px;">
                                        <input [(ngModel)]="item.temp_quantity" type="number" (focus)="focusFunction($event)" decimals="2" class="form-control" style="font-size: 18px; padding: 0px; height: 29px; text-align: center; width: 40px;" />
                                    </div>
                                    <div style="display: inline-block;"><button class="btn  btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_add2(item)"><i class="ti ti-plus"></i></button></div>
                                </div>

                                <div>
                                    <div style="display: inline-block;">
                                        <button class="btn  btn-primary btn-icon" (click)="openCheckout2(item, item.temp_quantity)">
                                            <span class="material-icons font-17">
                                                paid
                                            </span>
                                        </button>
                                    </div>
                                    <div style="display: inline-block;" class="ml-4">
                                        <button class="btn  btn-primary btn-icon" (click)="addToCart(item, item.temp_quantity)">
                                            <span class="material-icons font-17">
                                                local_mall
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="display_type ==0">
            <div class="d-flex flex-wrap mb-2">
                <!-- <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of appService.products; let i = index"
            (click)="popupAddToCart(item)"> -->
                <div class="mb-2 cart-item-gird" [style.width.px]="appService.itemCartWidth_sale" style="margin-right: 6px;" *ngFor="let item of appService.products; let i = index">
                    <div class="enterQuantiti-temp">
                        <div>
                            <div style="display: inline-block;">
                                <button class="btn btn-sm btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_sub2(item)"><i class="ti ti-minus"></i></button>
                            </div>
                            <div style="display: inline-block; margin: 0 5px;">
                                <input [(ngModel)]="item.temp_quantity" type="number" (focus)="focusFunction($event)" decimals="2" class="form-control" style="font-size: 18px; padding: 0px; height: 35px; text-align: center; width: 45px;" />
                            </div>
                            <div style="display: inline-block;"><button class="btn btn-sm btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_add2(item)"><i class="ti ti-plus"></i></button></div>
                        </div>
                        <div class="mt-2">
                            <div style="display: inline-block;">
                                <button class="btn btn-sm btn-primary btn-icon" (click)="openCheckout2(item, item.temp_quantity)">
                                    <span class="material-icons font-17">
                                        paid
                                    </span>
                                </button>
                            </div>
                            <div style="display: inline-block;" class="ml-4">
                                <button class="btn btn-sm btn-primary btn-icon" (click)="addToCart(item, item.temp_quantity)">
                                    <span class="material-icons font-17">
                                        local_mall
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="rel img-product2" [style.background-image]="this.appService.showImageProduct(item.image_url)">
                        <span class=".card-img-overlay">
                            <span *ngIf="item.stock < 1" class="ml-1 badge-danger badge-point"></span>
                        </span>
                    </div>
                    <div class="card-body">
                        <!--  (click)="popupAddToCart(item)" -->
                        <div style=" white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis; ">{{item.name}}</div>
                        <div class="d-flex align-items-center justify-content-between mt-2">
                            <div class="text-center">
                                <div style="font-weight: bold;" [ngClass]="{'text-danger': item.stock < 1}">
                                    <span>{{item.stock}}</span>
                                </div>

                            </div>
                            <div class="text-center">
                                <div *ngIf="item.unit_default =='' || item.unit==item.unit_default" style="font-weight: bold;">{{item.price | qbscurrency}}</div>
                                <div *ngIf="item.unit2 !='' && item.unit2==item.unit_default" style="font-weight: bold;">{{ utilityService.mathRound(item.price_temp / item.packing) | qbscurrency}}</div>

                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mt-1">
                            <div class="text-center">

                                <div class="text-muted font-11">
                                    <div class="btn-group btn-group-sm">
                                        <div *ngIf="item.unit!=''" (click)="setUnit_default(item.code,item.unit)" class="btn btn-info p-1" [ngClass]="{'btn-info': item.unit_default =='' || item.unit==item.unit_default}" style="line-height: 10px;">{{item.unit}}</div>
                                        <div *ngIf="item.unit2!=''" (click)="setUnit_default(item.code,item.unit2)" class="btn btn-secondary p-1" [ngClass]="{'btn-info': item.unit2==item.unit_default}" style="line-height: 10px;">{{item.unit2}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">

                                <div class="text-muted font-11">VNĐ</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row align-items-center mb-2 paging-cover">
            <div class="col-4">
                <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                    {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
            </div>
            <div class="col-8 d-flex justify-content-end">
                <ul class="pagination p-1" style="margin-bottom: 0px;">
                    <li>
                        <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}
                            </option>
                        </select>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                            <span aria-hidden="true">
                                <i class="ti ti-angle-left"></i>
                            </span>
                        </a>
                    </li>
                    <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                        <a class="page-link" href="javascript:;">{{item.i}}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                            <i class="ti ti-angle-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


    </div>




    <div class="modal fade" id="stockin-cart-modal">
        <div class="modal-dialog" role="document">
            <form class="modal-content modal-content-lg form-horizontal" #productUpdateForm="ngForm" id="form-update-product">
                <div class="modal-header p-3">
                    <h5 class="modal-title">Phiếu nhập hàng</h5>
                    <a data-dismiss="modal"><i class="ti ti-close"></i></a>
                </div>
                <div class="modal-body p-0">
                    <div class="container">
                        <div class="row info-row">
                            <div class="col">
                                Tổng đơn:
                            </div>
                            <div class="col text-right">
                                {{cartObj.price_totol | qbscurrency}}
                            </div>
                        </div>
                        <div class="row info-row">
                            <div class="col">
                                Nhà cung cấp:
                            </div>
                            <div class="col text-right">
                                <button class="btn btn-info btn-labeled btn-labeled-left btn-icon" id="electCustomer" (click)="openSelectSupplier()">
                                    <span class="btn-label"><span class="material-icons">
                                        local_shipping
                                        </span></span>
                                    {{cartObj.customer_name}}
                                </button>
                            </div>
                        </div>
                        <div class="row info-row">
                            <div class="col">
                                Trả NCC:
                            </div>
                            <div class="col text-right">
                                <input [(ngModel)]="cartObj.customer_pay" readonly currencyMask (focus)="focusFunction($event)" class="form-control form-control-custom1" numbersOnly name="customer_pay" placeholder="Giá nhập" type="text">
                            </div>
                        </div>
                        <div class="row info-row">
                            <div class="col">
                                Tiền thừa:
                            </div>
                            <div class="col text-right">
                                {{cartObj.customer_pay - cartObj.price_totol | qbscurrency}}
                            </div>
                        </div>
                        <div class="row info-row">
                            <input [(ngModel)]="cartObj.note" class="form-control " name="note" placeholder="Ghi chú" type="text">
                        </div>
                        <table style="width: 100%; margin-top: 20px;">
                            <tr *ngFor="let item of cartObj.cart; let i = index" class="item-row">
                                <td style="width: 25px; text-align: center;">
                                    <button class=" btn-remove btn-adjust btn btn-outline-danger btn-icon-only btn-circle btn-sm btn-thick" (click)='removeProduct(i,item)'><i class="ti ti-close"></i></button>
                                    <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                                    <span class="lbl-stt">{{i+1}}</span>
                                </td>
                                <td style="width: 55%;">
                                    <div style="line-height: 16px;">
                                        {{item.product.name}} <span style="color: gray; font-size: 12px;">{{item.product.price|
                                            number}}</span>
                                    </div>
                                </td>
                                <!-- <td style="text-align: right;"><span style="font-size: 11px;">{{item.product.price| qbscurrency}}</span> </td> -->
                                <td style="text-align: center;">
                                    <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti-minus"></i></a> -->
                                    <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='quantity_sub(item)'><i class="ti ti-minus"></i></button> x {{item.quantity}}
                                    <span style="font-size: small;"> {{item.product.unit_default}}</span>
                                    <button class="btn-adjust btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='quantity_add(item)'><i class="ti ti-plus"></i></button>
                                </td>
                                <td style="text-align: right; color: black;">
                                    {{item.quantity * item.product.price | qbscurrency:0}}</td>
                            </tr>
                            <tr style="background: lightgray;line-height: 35px;">
                                <td colspan="2" style="text-align: center;font-weight: bold;">Tổng</td>
                                <td style="text-align: center;font-weight: bold;">{{cartObj.quantity}}
                                </td>
                                <td style="text-align: right; color: black; font-weight: bold;">
                                    {{cartObj.price_totol | qbscurrency}}</td>
                            </tr>
                        </table>

                        <!-- <div class="container" style="position: fixed;bottom: 0;width: 490px;margin: 0 auto;">
              <div class="row m-2 justify-content-between">
                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="doPayment()"
                  [disabled]="cartObj.total_quantity == 0">
                  <span class="btn-label"><i class="ti ti-money"></i></span>Thanh toán
                </button>

                <button class="btn m-1 btn-outline-primary btn-icon-only btn-sm btn-thick" (click)="print()"><i
                    class="ti ti-printer"></i></button>
              </div>
            </div> -->
                    </div>
                </div>
                <div class="modal-footer justify-content-around bg-primary-50">
                    <!-- <button class="btn btn-primary " [disabled]="loading" (click)="insertProduct()">Thêm mới</button> -->
                    <button class="btn btn-primary" (click)="doPayment()" [disabled]="cartObj.quantity == 0">Thanh
                        toán</button>
                    <button class="btn btn-outline-primary btn-icon-only " (click)="print('Bản nháp')"><i
                            class="ti ti-printer"></i></button>
                    <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
                </div>
            </form>
        </div>
    </div>



    <div id="print-section-stockin-cart" style="display: none;">
        <div style=" font-family: Tahoma; font-size: 14px;">
            <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
            <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
            <h4 style="text-align: center;">Phiếu nhập kho</h4>
            <div style="text-align: right">{{this.dateTimeStr}}</div>
            <table style="font-size: 13px; width: 100%;">
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="text-align: left;">Tổng hóa đơn: </td>
                    <td style="text-align: right;">{{cartObj.price_totol | qbscurrency}}</td>
                </tr>
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="text-align: left;">Nhân viên: </td>
                    <td style="text-align: right;"> {{appService.currentUser.name}} </td>
                </tr>
            </table>
            <!-- Chi tiết sử dụng dịch vụ -->
            <!-- END Chi tiết sử dụng dịch vụ -->
            <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
                <table style="font-size: 12px; width: 100%;">
                    <tr style="text-align: center; font-weight: bold; ">
                        <td style="vertical-align: middle;"></td>
                        <td style="vertical-align: middle;">Tên</td>
                        <td style="text-align: right;vertical-align: middle;">ĐG</td>
                        <td style="text-align: center; min-width: 40px; vertical-align: middle;">SL</td>
                        <td style="text-align: right; vertical-align: middle;">T.Tiền</td>
                    </tr>
                    <!-- giá dịch vụ -->
                    <!-- Hết giá dịch vụ -->
                    <tr *ngFor="let item of cartObj.cart; let i = index">
                        <td style="vertical-align: middle;">{{i+1}}</td>
                        <td style="vertical-align: middle;">{{item.product.name}}</td>
                        <td style="text-align: right; vertical-align: middle;">{{item.product.price| qbscurrency}}</td>
                        <td style="text-align: left; vertical-align: middle;">x {{item.quantity}}<span style="font-size: 9px;"> {{item.product.unit_default}}</span></td>
                        <td style="text-align: right; vertical-align: middle;">{{item.quantity * item.product.price | qbscurrency:0}}
                        </td>
                    </tr>
                    <tr style="font-weight: bold;">
                        <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
                        <!-- <td>{{appService.cartObj.total_quantity}}</td> -->
                        <td style="border-top: 1px dashed gray;padding-top: 5px;">{{cartObj.price_totol | qbscurrency}}
                        </td>
                    </tr>
                </table>
            </div>
            <div style="text-align: center;">Cảm ơn quý khách, hẹn gặp lại</div>
            <div style="text-align: center; margin-bottom: 10; font-size: 7;">GIẢI PHÁP SỐ QBS</div>
        </div>
    </div>

    <div class="modal fade" id="popupAddToCart-modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content form-horizontal" id="form-add-product">
                <div class="modal-header p-4">
                    <h5 class="modal-title">Nhập số lương <img style="width: 20px;" src="./assets/img/icons/add_cart.svg" alt="image" /></h5>
                    <a data-dismiss="modal"><i class="ti ti-close"></i></a>
                </div>
                <div class="modal-body p-4 mb-3">
                    <div style="text-align: center; padding: 25px 0px;">
                        <h2 style="font-weight: bold;">{{productSelected.name}}</h2>
                        <h4>{{productSelected.price | qbscurrency}} x {{txt_quantity}} = {{txt_quantity * productSelected.price | qbscurrency}}
                        </h4>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-end">
                            <button class="btn btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_form_sub()"><i class="ti ti-minus"></i></button>
                        </div>
                        <div class="col">
                            <input [(ngModel)]="txt_quantity" type="number" decimals="2" class="form-control" style="font-size: 24px; padding: 4px; text-align: center;" />
                        </div>
                        <div class="col ">
                            <button class="btn btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_form_add()"><i class="ti ti-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between bg-primary-50">
                    <!-- <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="openCheckout(this.productSelected, txt_quantity)">
                        <span class="btn-label"><i class="ti ti-money"></i></span>Thanh toán
                    </button> -->
                    <span></span>
                    <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="addToCart(this.productSelected, txt_quantity)">
                        <span class="btn-label"><span class="material-icons">
                            add_shopping_cart
                            </span></span>Thêm vào hóa đơn
                    </button>

                </div>
            </div>
        </div>
    </div>