import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { ShopService } from 'src/app/_services/shop.service';
import { UserService } from 'src/app/_services/user.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit {
  public isLoading = false
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.appService.units, event.previousIndex, event.currentIndex);
    console.log(this.appService.units);
    if(event.previousIndex != event.currentIndex){
      this.updateShopProfile();
    }
  }
  public unit_edit = "";
  public unit_index_selected  = -1;
  public unit_add = '';
  constructor(
    public appService: AppService,
    public utilityService: UtilityService,
    private toastr: ToastrService,
    private shopService: ShopService,
  ) { }

  ngOnInit(): void {
  }

  popup_unit_edit(unit, i) {
    this.unit_edit = unit.value;
    this.unit_index_selected = i;
    $("#unit-edit-modal").modal('show');
  }
  popup_unit_add() {
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    $("#unit-add-modal").modal('show');
  }
  insertUnit() {
    // kiểm tra tồn tại
    if (this.utilityService.checkUnit(this.unit_add.trim())) {
      this.toastr.warning("", "Đã tồn tại");
    } else {
      this.appService.units.push({ "code": this.unit_add, "value": this.unit_add })
      this.updateShopProfile();
      $("#unit-add-modal").modal('hide');
      this.unit_add = "";

    }
  }
  editUnit() {
    // kiểm tra tồn tại
    if (this.utilityService.checkUnit(this.unit_edit.trim())) {
      this.toastr.warning("", "Đã tồn tại");
    } else {
      this.appService.units[this.unit_index_selected].code = this.unit_edit.trim();
      this.appService.units[this.unit_index_selected].value = this.unit_edit.trim();
      this.updateShopProfile();
      $("#unit-edit-modal").modal('hide');
      this.unit_index_selected = -1;

    }
  }
  
  updateShopProfile() {
    this.isLoading = true;
    this.appService.currentUser.shop_unit = this.appService.units.map(x => x.value).toString();
    this.shopService.updateShopSomeInfo({"unit":this.appService.currentUser.shop_unit}).subscribe(
      response => {
        this.isLoading = false;
        if (response.code == "ok" && response.affectedRows > 0) {
          localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
          console.log(this.appService.currentUser);
          this.toastr.success("Cập nhật thông tin thành công", this.appService.currentUser.shop_unit);
        }
        else {
          this.toastr.error("", "Tài khoản hoặc mật khẩu hoặc mã shop không đúng");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      })
  }

}
