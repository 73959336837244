<mat-sidenav-container (backdropClick)="backdropClick_close_nav()">

    <mat-sidenav #productpicker position="start" [style.width.px]="productpicker_nav_width">
        <app-product-picker [saletype]="'servicedesk'"></app-product-picker>
    </mat-sidenav>

    <mat-sidenav #saleinvoice position="end" [autoFocus]="false" style="width: 500px; padding: 0px 5px; ">

        <app-sale-invoice [saletype]="'servicedesk'"></app-sale-invoice>
    </mat-sidenav>


    <!-- <div>
        <button mat-button (click)="right.open()">right</button>
        <button mat-button (click)="left.open()">left</button>
        a
    </div> -->

    <!-- <a (click)="closeNav(productpicker,saleinvoice)" style="position: absolute; right: 496px; z-index: 9999; background-color: white; ">
        <span class="material-icons font-26" style="color: black"> cancel </span>
    </a> -->


    <div class="page-content fade-in-up" [style.height.px]="this.appService.screenHeight_no_header">
        <div class="row mb-1 mt-1">
            <div class="col font-weight-bold">Doanh thu: {{this.appService.deskRevenue | qbscurrency}} </div>
            <div class="col font-weight-bold text-right">Đang phục vụ {{this.appService.deskActive}} </div>
        </div>
        <div class="desk-boundary">
            <div *ngFor="let item of this.appService.deskList; let i = index" class="desk-box" cdkDragBoundary=".desk-boundary" cdkDrag [(cdkDragFreeDragPosition)]="item.position" [cdkDragDisabled]="true" [ngClass]="{'desk-active': i == appService.deskIndex, 'desk-on-service': ( (item.cart && item.cart.length > 0) ||  (item.cart_service && item.cart_service.length > 0) ) }">
                <div class="d-flex justify-content-between" style="height: 28px;">


                    <div class="d-flex justify-content-start align-content-center" (click)="opendCheckout(productpicker, saleinvoice,i)" style="cursor: pointer;">
                        <span *ngIf="item.is_time_service == 0">
                            <span class="material-icons text-secondary" [ngClass]="{'text-primary' : (item.cart && item.cart.length > 0) ||  (item.cart_service && item.cart_service.length > 0)}">
                                view_day
                            </span>
                        </span>
                        <span *ngIf="item.is_time_service == 1">
                        <span class="material-icons text-secondary"
                        [ngClass]="{'text-primary' : (item.cart && item.cart.length > 0) ||  (item.cart_service && item.cart_service.length > 0)}"
                        >
                            timer
                        </span>
                        </span>
                        <span style="line-height: 24px"> {{item.name}}</span>
                    </div>
                    <a *ngIf="( (item.cart && item.cart.length > 0) ||  (item.cart_service && item.cart_service.length > 0) )" class="change-desk-icon" (click)="openSelectDeskChange(i, item.is_time_service)">
                        <span style="font-size: 16px;" class="material-icons">
                        sync_alt
                    </span>
                    </a>
                </div>
                <div class="pt-2 pb-2" style="font-weight: bold; text-align: right;">
                    {{item.price_totol | qbscurrency}}
                </div>
                <div>
                    <mat-icon *ngIf="item.cart.length > 0" [matBadge]="item.cart.length" matBadgeColor="accent">reorder
                    </mat-icon>


                </div>
                <div class="action-desk pt-2">
                    <a *ngIf="item.is_time_service == 1" class="pr-2" (click)="openSelectTimePrice(i)">
                        <span style="font-size: 36px;" class="material-icons">
                        timer
                        </span>
                    </a>
                    <a class="pl-2" (click)="opendCheckout(productpicker, saleinvoice,i)">
                        <span style="font-size: 36px;" class="material-icons">
                        request_quote
                    </span>
                    </a>
                </div>
            </div>
        </div>

    </div>


    <ng-template #selectProductModel let-c="close" let-d="dismiss">
        <form class="modal-content form-horizontal" id="openSelectProduct">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title">Chọn thực đơn</h5>
                <button type="button" class="btn btn-sm btn-primary" (click)="this.appService.updateDeskRevenue(); c('Save click')">Đóng</button>
            </div>
            <div class="modal-body">
                <app-desksale></app-desksale>
            </div>
            <div class="modal-footer">
                <!-- <button type="submit" class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading">
        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
        Cập nhật
      </button> -->
                <button type="button" class="btn btn-sm btn-primary" (click)="this.appService.updateDeskRevenue(); c('Save click')">Đóng</button>
            </div>
        </form>
    </ng-template>
    <ng-template #selectTimePriceModel let-c="close" let-d="dismiss">
        <form class="modal-content form-horizontal" id="openSelectProduct">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title">Chọn giá dịch vụ</h5>
            </div>
            <div class="modal-body">
                <app-servicedeskprice></app-servicedeskprice>
            </div>
            <div class="modal-footer">
                <!-- <button type="submit" class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading">
        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
        Cập nhật
      </button> -->
                <button type="button" class="btn btn-sm btn-primary" (click)="c('Save click')">Đóng</button>
            </div>
        </form>
    </ng-template>

</mat-sidenav-container>