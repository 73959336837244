import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  insertDesk(data) {
    console.log("data:::: ", data);
    return this.http.post<any>(this.appService.getApiEndpoint('shop/desk'), data,).map(data => { return data; });
  }
  loadSystem() {
    return this.http.post<any>(this.appService.getApiEndpoint('shop/getsystem'), {},).map(data => { return data; });
  }
  loadDesk() {
    return this.http.get<any>(this.appService.getApiEndpoint('shop/desk'), {},).map(data => { return data; });
  }
  loadBankList() {
    return this.http.get<any>("https://api.vietqr.io/v2/banks", {},).map(data => { return data; });
  }
  updateDesk(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('shop/desk/qbs'), data,).map(data => { return data; });
  }
  deleteDesk(data) {
    console.log('data.id: ', data.id);
    return this.http.delete<any>(this.appService.getApiEndpoint('shop/desk/qbs/'+ data.id), {},).map(data => { return data; });
  }
  updateShopSomeInfo(data) {
    console.log("updateShopSomeInfo",data);
    return this.http.put<any>(this.appService.getApiEndpoint('shop/qbs/someinfo'), data,).map(data => { return data; });
  }
  updateSystemInfo(data) {
    console.log("updateShopSomeInfo",data);
    return this.http.put<any>(this.appService.getApiEndpoint('shop/qbs/someinfosystem'), data,).map(data => { return data; });
  }
  updateShopInfo(data) {
    console.log("updateShopInfo",data);
    return this.http.put<any>(this.appService.getApiEndpoint('shop/update'), data,).map(data => { return data; });
  }

}
