import { Router } from '@angular/router';
import { CartObj } from './../../../model/cart-obj';

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Cart } from '../../../model/cart';
import { AppService } from '../../../_services/app.service';
import { CategoryService } from '../../../_services/category.service';
import { ProductService } from '../../../_services/product.service';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { UtilityService } from '../../../_services/utility.service';
import { SaleService } from '../../../_services/sale.service';
import { Product } from 'src/app/model/product';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SupplierPickerComponent } from 'src/app/components/supplier-picker/supplier-picker.component';

declare var $: any;

@Component({
  selector: 'app-stockin-cart',
  templateUrl: './stockin-cart.component.html',
  styleUrls: ['./stockin-cart.component.css']
})
export class StockinCartComponent implements OnInit {

  loading = false;
  display_type = 0;
  cartObj = new CartObj();

  // products = [];
  products_backup: any
  categories: any
  filter = {
    name: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  sorter = {
    sortStr: "",
  }

  // for add product
  product: any = new Product("");
  // for update product
  productSelected: any = new Product("");
  isDeleted: boolean;

  //for cart
  productCart: any = new Product("");
  txt_quantity = 1;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;
  //for print
  dateTimeStr: string
  constructor(
    private router: Router,
    public appService: AppService,
    public saleService: SaleService,
    public productService: ProductService,
    public categoryService: CategoryService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private utilityService: UtilityService,
  ) {

    //customer là supplier
    this.cartObj.customer_code = environment.DEFAULT_SUPPLIER_CATEGORY.code;
    this.cartObj.customer_name = environment.DEFAULT_SUPPLIER_CATEGORY.name;

    if (localStorage.getItem(environment.importCart)) {
      this.cartObj = JSON.parse(localStorage.getItem(environment.importCart));
    }
    this.cartObj.type = 1;

    this.sorter.sortStr = this.appService.sort_products[0].code;
    this.productSelected = this.product;

    // cách hiển thị
    if (localStorage.getItem(environment.display_type)) {
      this.display_type = parseInt(localStorage.getItem(environment.display_type));
    }

    //load product

    this.loadProduct();


  }




  ngOnInit() {
    this.loadCategory();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
  }

  resetProduct() {
    let temp_unit = "";
    if (this.appService.units.length > 0)
      temp_unit = this.appService.units[0].code;

    this.product = new Product(temp_unit);
  }
  loadProduct() {
    let pagingObj = {
      viewNumsObj: 2000,
      pageObj: this.curentPage
    };
    console.log("load product form sale");
    this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        console.log(response);
        if (response) {
          this.appService.products = response.data;
          this.appService.products_backup = response.data;
          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.appService.products.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;
        }
      },
      error => {

      })
  }
  async loadCategory() {

    // this.loading = await this.loadingCtrl.create({
    //   message: 'Đang xử lý...',
    // });
    // await this.loading.present();

    let filter = {
      type: environment.cate_type
    };

    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.categories = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })

  }
  popup_product_add(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-product-modal").modal('show');
  }


  popup_product_import(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-customer-modal").modal('show');
  }



  category_change(event) {
    this.loadProduct();
  }
  // sự kiện từ component catelist
  onClick_selectCate(event) {

    if (typeof event.category_code !== "undefined") {
      this.filter.category_code = event.category_code
      console.log("category_code:", this.filter.category_code);
      this.loadProduct();
    }
  }
  sort_change(event) {
    this.loadProduct();
  }

  openSelectSupplier() {
    const dialogRef = this.dialog.open(SupplierPickerComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      data: { name: "hahh", customer_code_selected: this.cartObj.customer_code }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);
        this.cartObj.customer_code = result.customer_code
        this.cartObj.customer_name = result.customer_name
      }
    });
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.appService.products = this.appService.products_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.appService.products = this.appService.products.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
    countAllProduct() {

    this.productService.countAllProduct().subscribe(
      response => {
        this.product.code = this.appService.currentUser.shop_code + (parseInt(response[0].CountAllProduct) + 1).toString();
      },
      error => {
        console.error(error);
      })

  }

  popupAddToCart(item) {
    this.txt_quantity = 1;
    this.productSelected = item;
    $("#popupAddToCart-modal").modal('show');
  }

  quantity_form_add() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity)) {
      this.txt_quantity = ++this.txt_quantity;
    }
  }
  quantity_form_sub() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity) && this.txt_quantity > 1) {
      this.txt_quantity = --this.txt_quantity;
    }
  }
  // cho tông đơn
  quantity_sub(item) {
    if (item.quantity > 0) {
      item.quantity--;
      this.prepareSaleObject();
    }
  }
  // cho tông đơn
  quantity_add(item) {
    item.quantity++;
    this.prepareSaleObject();
  }



  quantity_add2(item) {
    if (!isNaN(item.temp_quantity)) {
      item.temp_quantity = ++item.temp_quantity;
      this.prepareSaleObject();
    }
  }

  quantity_sub2(item) {
    if (!isNaN(item.temp_quantity) && item.temp_quantity > 1) {
      item.temp_quantity = --item.temp_quantity;
      this.prepareSaleObject();
    }
  }
  focusFunction(event) {
    event.target.select();
  }
  addToCart(product, quantity) {

    //xử lý giá cho loại giá đơn vị 2
    if (product.unit2 != '' && product.unit2 == product.unit_default) {
      // chuyển thành giá theo dơn vị 2
      product.price = this.utilityService.mathRound(product.price_temp / product.packing);
      product.price2 = this.utilityService.mathRound(product.price2_temp / product.packing);
    }
    console.log("--------------------------------------------", product);


    let cart = new Cart();
    cart.product = Object.assign({}, product); // copy product không tham chiếu
    cart.unit_type = product.unit2 == product.unit_default ? "unit2" : 'unit';
    cart.quantity = quantity;
    //kiểm tra trong giỏ đã có sp chưa, nếu chưa thì thêm nếu có thì update
    if (this.cartObj.cart.length > 0) {
      if (this.utilityService.checkToAddCart(this.cartObj.cart, cart) > -1) {
        // nếu có thì thêm luôn ở trong hàm check
      }
      else {
        this.cartObj.cart.push(cart);
      }
    } else {
      this.cartObj.cart.push(cart);
    }

    // this.cartObj.total_quantity = this.cartObj.total_quantity + cart.quantity;
    // this.cartObj.price_totol = this.cartObj.price_totol + (cart.quantity * product.price);
    // this.cartObj.customer_pay = this.cartObj.price_totol;
    this.prepareSaleObject();
    localStorage.setItem(environment.importCart, JSON.stringify(this.cartObj));
    product.temp_quantity = 1; // reset to default
    this.toastr.remove;
    this.toastr.success("", "Thêm vào giỏ hàng thành công");
    $("#popupAddToCart-modal").modal('hide');
  }

  openCheckout2(item, quantity) {
    this.addToCart(item, quantity);
    this.popup_cart();
    item.temp_quantity = 1;
    this.prepareSaleObject();
  }
  setUnit_default(code, unit_default) {

    let data = {
      unit_default: unit_default
    }
    this.productService.updateSomeInfo(code, data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Cập nhật đơn vị mặc định thành công");
          this.loadProduct();
        }
      },
      error => {
        console.error(error);
      })
  }
  // popupPayment(item) {
  //   console.log("xacd");
  // }
  changeViewType(type) {
    this.display_type = type;
    localStorage.setItem(environment.display_type, this.display_type.toString());
  }

  removeProduct(i, item) {
    console.log("item1", this.cartObj.cart);
    this.cartObj.cart.splice(i, 1); //delete index i

    // this.cartObj.total_quantity = this.cartObj.total_quantity - item.quantity;
    // this.cartObj.price_totol = this.cartObj.price_totol - (parseInt(item.product.price) * item.quantity);
    // this.cartObj.customer_pay = this.cartObj.price_totol;
    // // this.customer_pay = this.cartObj.price_totol;
    this.prepareSaleObject();
    console.log("item2", this.cartObj.cart);
  }
  doOrder() {
    this.toastr.success("", "Tính năng chưa được khai báo");
  }
  backToList() {
    this.router.navigate(['/stockin', {}]);
  }
  print(type) {
    this.dateTimeStr = this.utilityService.getFullDateTimeStrFromDate(new Date());
    // this.toastr.success("", "Máy in chưa được thiết lập");
    let lableContents, printContents, popupWin;
    printContents = document.getElementById('print-section-stockin-cart').innerHTML;
    lableContents = type == "" ? '' : `<div style="position: absolute;top: 22px;left: 0px;font-size: 20px;border: 2px  dashed grey;padding: 5px 20px;">${type}</div>`;

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>In Hóa Đơn</title>
          <style>
          //........Customized style.......
          </style>
        </head>
        
        <body onload="window.print();window.close()">
          ${printContents}
          ${lableContents}
        </body>
      </html>`
    );
    popupWin.document.close();
  }

  doPayment() {


    let param = this.prepareSaleObject();
    this.saleService.insertSale(param).subscribe(
      async response => {
        if (response.code == "ok" && response.affectedRows > 0) {
          this.loadProduct();
          this.cartObj = new CartObj();
          this.cartObj.customer_code = environment.DEFAULT_SUPPLIER_CATEGORY.code;
          this.cartObj.customer_name = environment.DEFAULT_SUPPLIER_CATEGORY.name;
          localStorage.setItem(environment.importCart, JSON.stringify(this.cartObj));
          this.toastr.success("", "Tạo phiếu thành công");
          $("#stockin-cart-modal").modal('hide');
          this.print("");
          this.router.navigate(['/stockin']);
        }
      },
      error => {
        console.error(error);
      });

  }
  prepareSaleObject() {

    // for insert detail sale

    // for insert detail sale
    let product_codes = "";
    let product_names = "";
    let product_stocks = "";
    let prices = "";
    let units = "";
    let packings = "";
    let unit_types = "";
    let quantitys = "";
    // for cartObj ( cart chính, cart to)
    let price_totol = 0;
    let quantity = 0;
    for (let i = 0; i < this.cartObj.cart.length; i++) {
      // dành cho cartObj
      price_totol += this.cartObj.cart[i].quantity * this.cartObj.cart[i].product.price;
      quantity += this.cartObj.cart[i].quantity;
      // dành cho detail
      if (this.cartObj.cart.length == i + 1) {
        product_codes += this.cartObj.cart[i].product.code.toString();
        product_names += this.cartObj.cart[i].product.name.toString();
        product_stocks += this.cartObj.cart[i].product.stock.toString();
        prices += this.cartObj.cart[i].product.price.toString();
        units += this.cartObj.cart[i].product.unit_default !== '' ? this.cartObj.cart[i].product.unit_default.toString() : this.cartObj.cart[i].product.unit.toString();
        packings += this.cartObj.cart[i].product.unit2 == this.cartObj.cart[i].product.unit_default ? this.cartObj.cart[i].product.packing.toString() : '1';
        unit_types += this.cartObj.cart[i].unit_type;
        quantitys += this.cartObj.cart[i].quantity.toString();
      } else {
        product_codes += this.cartObj.cart[i].product.code.toString() + ",";
        product_names += this.cartObj.cart[i].product.name.toString() + "!";
        product_stocks += this.cartObj.cart[i].product.stock.toString() + ",";
        prices += this.cartObj.cart[i].product.price.toString() + ",";
        units += this.cartObj.cart[i].product.unit_default !== '' ? this.cartObj.cart[i].product.unit_default.toString() + "," : this.cartObj.cart[i].product.unit.toString() + ",";
        packings += this.cartObj.cart[i].product.unit2 == this.cartObj.cart[i].product.unit_default ? this.cartObj.cart[i].product.packing.toString() + "," : '1' + ",";
        unit_types += this.cartObj.cart[i].unit_type + ",";
        quantitys += this.cartObj.cart[i].quantity.toString() + ",";
      }
    }
    // let cartObj = new CartObj();
    // for insert  sale
    // cartObj.code: "",
    this.cartObj.type = this.cartObj.type; // 1 = nhập hàng, 0 = bán hàng, 0 is sale, 1 is imporrt
    this.cartObj.status = 1; //1: đã thanh toán, 0: đặt hàng, 2: Chờ thanh toán, 3: đã hủy, 4: đang xử lý
    this.cartObj.product_quantity = this.cartObj.cart.length;// số lượng mặt hàng
    this.cartObj.quantity = quantity; // số lượng
    this.cartObj.price_totol = price_totol;
    this.cartObj.price_after_promotion = this.cartObj.price_totol;
    this.cartObj.customer_pay = this.cartObj.price_after_promotion; // Tiền khách đưa
    this.cartObj.payment_total = this.cartObj.customer_pay; // Tiền khách phải trả

    // this.appService.cartObj.total_quantity = product_quantity;
    this.cartObj.customer_id = this.cartObj.customer_id;
    this.cartObj.customer_code = this.cartObj.customer_code;
    this.cartObj.customer_name = this.cartObj.customer_name;
    this.cartObj.user_id = this.appService.currentUser.id;
    this.cartObj.user_name = this.appService.currentUser.name;
    // this.cartObj.note = "";

    //for service price
    this.cartObj.service_price = this.cartObj.service_price;
    this.cartObj.service_price_temp = 0;
    this.cartObj.service_detail_str = JSON.stringify(this.cartObj.cart_service);

    this.cartObj.promotion_code = "",
      this.cartObj.promotion_type = "",
      this.cartObj.promotion_promotion = 0,

      this.cartObj.product_codes = product_codes;// for insert detail sale
    this.cartObj.product_names = product_names;// for insert detail sale
    this.cartObj.product_stocks = product_stocks;// for insert detail sale
    this.cartObj.prices = prices;// for insert detail sale
    this.cartObj.units = units;// for insert detail sale
    this.cartObj.packings = packings;// for insert detail sale
    this.cartObj.unit_types = unit_types;// for insert detail sale
    this.cartObj.quantitys = quantitys;// for insert detail sale


    // for insert  sale

    console.log("this.cartObj:", this.cartObj);

    return this.cartObj;
  }
  // insertSaleDetail(sale_id) {
  //   let product_codes = "";
  //   let product_names = "";
  //   let prices = "";
  //   let quantitys = "";

  //   for (let i = 0; i < this.cartObj.cart.length; i++) {
  //     if (this.cartObj.cart.length == i + 1) {
  //       product_codes += this.cartObj.cart[i].product.code.toString();
  //       product_names += this.cartObj.cart[i].product.name.toString();
  //       prices += this.cartObj.cart[i].product.price.toString();
  //       quantitys += this.cartObj.cart[i].quantity.toString();
  //     } else {
  //       product_codes += this.cartObj.cart[i].product.code.toString() + ",";
  //       product_names += this.cartObj.cart[i].product.name.toString() + ",";
  //       prices += this.cartObj.cart[i].product.price.toString() + ",";
  //       quantitys += this.cartObj.cart[i].quantity.toString() + ",";
  //     }
  //   }

  //   let ItemDetailList = {
  //     sale_id: sale_id,
  //     product_codes: product_codes,
  //     product_names: product_names,
  //     prices: prices,
  //     quantitys: quantitys,
  //     type: this.cartObj.type // 0 is sale, 1 is imporrt
  //   }
  //   console.log("ItemDetailList",ItemDetailList);
  //   console.log("this.cartObj",this.cartObj);
  //   this.saleService.insertSaleDetail(ItemDetailList).subscribe(
  //     async result => {
  //       console.log('insertSaleDetail');
  //       console.log(result);
  //     },
  //     error => {
  //       console.error(error);
  //     });
  // }
  updateStockProduct() {
    let product_codes = "";
    let quantity_changes = "";


    for (let i = 0; i < this.cartObj.cart.length; i++) {
      if (this.cartObj.cart.length == i + 1) {
        quantity_changes += this.cartObj.cart[i].quantity.toString();
        product_codes += this.cartObj.cart[i].product.code;
      } else {
        quantity_changes += this.cartObj.cart[i].quantity.toString() + ",";
        product_codes += this.cartObj.cart[i].product.code + ",";
      }
    }

    let updateStockList = {
      product_codes: product_codes,
      quantity_changes: quantity_changes,
      type: this.cartObj.type // 0 is sale, 1 is import
    }

    this.productService.updateStockProduct(updateStockList).subscribe(
      async result => {
        console.log('insertSaleDetail');
        console.log(result);
        if (result > 0) {
          this.loadProduct();
          this.cartObj = new CartObj();
          localStorage.setItem(environment.importCart, JSON.stringify(this.cartObj));
          this.toastr.success("", "Thanh toán thành công");
          this.router.navigate(['/stockin']);
        }
      },
      error => {
        console.error(error);
      });

  }
  popup_cart() {
    $("#stockin-cart-modal").modal('show');
  }
  doSorting(item){
    if(item == 'name'){
      this.sorter.sortStr = this.sorter.sortStr == "name ASC" ? "name DESC": 'name ASC' ;
      console.log(this.sorter.sortStr);
    }else if(item == 'stock'){
      this.sorter.sortStr = this.sorter.sortStr == "stock ASC" ? "stock DESC": 'stock ASC' ;
      console.log(this.sorter.sortStr);
    }else if(item == 'price'){
      this.sorter.sortStr = this.sorter.sortStr == "price ASC" ? "price DESC": 'price ASC' ;
      console.log(this.sorter.sortStr);
    }
    this.loadProduct();
  }
  // paging
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadProduct();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadProduct();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadProduct();
    }
  }
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.appService.viewNumsObj);
    this.loadProduct();
  }

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadProduct();
      }),
      map(term => term === '' ? [] : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => {
        console.log("aaaaaaaaaaaa");
      }),
    );
  onClick(r) {
    console.log("bbbbbbbbb");
    this.filter.name = "";
    this.addToCart(r, 1);
  }

}

