import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qbscurrencyheader'
})
export class PipeCurrencyHeaderComponent implements  PipeTransform{
  transform(num: number) {

    if(num == null) return 0;
    var int_temp = num.toString().split('.')[0];
    var dec_temp = num.toString().split('.')[1];
    var int = int_temp.replace(/(\d)(?=(\d{3})+$)/g, '$1.');
    
    if(dec_temp !== undefined){
      return int + ',' + dec_temp;
    }else
      return int;

    // return num.toString().replace(/^[+-]?\d+/, function(int) {
      
    //   var decial = num.toString().split('.')[1];
    //   var integer = int.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

    //   console.log(integer);
    //   console.log(decial);
    //   console.log(integer + ',' + decial);

    //   if(decial !== undefined){
    //     return integer + ',' + decial+',' + decial;
    //   }else
    //     return int.replace(/(\d)(?=(\d{3})+$)/g, '$1.');
    // });
  }
  // transform(n: number) {
  //   return n == null ? '0': n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  // }
}