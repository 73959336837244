import { environment } from "src/environments/environment";
import { Cart } from "./cart";

export class WorkingShift {
    id: number = 0;
    name: string = "";
    price: number = 0;
    timeStart: string = "00:00";
    timeEnd: string = "23:59";
    constructor({id}) {
        this.id = id
    }

}
