import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CartObj } from 'src/app/model/cart-obj';
import { User } from 'src/app/model/user';
import { AppService } from 'src/app/_services/app.service';
import { environment } from 'src/environments/environment';
import { version } from '../../../../package.json';

@Component({
  selector: '[app-sidebar]',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.css']
})
export class AppSidebar {
  public version: string = version;
  constructor(
    public appService: AppService,
    private router: Router,
  ){
    
  }
  ngOnInit() {}

  category_branch_change($event) {
    console.log(this.appService.currentUser);
    localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
  }
  logout() {
    this.appService.currentUser = new User();
    localStorage.setItem(environment.currentUser, JSON.stringify(this.appService.currentUser));
    localStorage.removeItem(environment.listCart);
    localStorage.removeItem(environment.cartIndex);
    localStorage.removeItem(environment.deskList);
    localStorage.removeItem(environment.deskIndex);

    this.router.navigate(['/login', { id: 1990 }]);

    this.appService.listCart = [new CartObj()];

  }
}
