import { environment } from './../../environments/environment';

export class CartObj {
    id: number = 0;
    code : string;
    customer_id: number = 0;
    customer_code: string;
    customer_name: string;
    customer_category_code: string;
    nhanvienkythuat_code: string;
    nhanvienkythuat_name: string;
    created_date: string = "";
    dateStr: string = "";
    user_name: string;
    // total_quantity: number = 0;
    price_totol: number = 0;
    
    customer_pay: number = 0;
    payment_type: number = 0; // 0 : tiền mặt; 1: QRcode chuyển khoản : 2 Quẹt thẻ
    cart : any = []; // for product
    cart_service : any = []; // for product
    type: number = 0; // 0 = bán, 1 = nhập
    status: number; // 0 = đặt hàng, 1 = đã thanh toán
    processing_user_name : string;
    promotion_type: string = "%";
    promotion_promotion = 0;
    price_promotion = 0;
    price_after_promotion: number = 0;
    product_quantity = 0;
    payment_total: number = 0;
    quantity = 0;
    user_id = 0;
    promotion_code: string;
    note: string;
    //for service price
    service_price = 0;
    service_price_temp = 0; // dùng để cộng trừ với giá hàng
    service_detail_str = ""; // lưu trữ chi tiết sử dụng service
    service_details : any = [];

    // for insertdetail
    index: number = 0;
    product_codes: string;
    product_names: string;
    product_stocks: string;
    prices: string;
    units: string;
    packings: string;
    unit_types: string;
    quantitys: string;

    //for desk
    name: string = "";
    x: number = 5;
    y: number = 5;
    position = {};
    is_time_service: number = 0;
    shop_code: string = "";
    constructor() {
        this.customer_code = environment.CUSTOMER_CODE_PREFIX;
        this.customer_name = environment.CUSTOMER_NAME_PREFIX;
        this.customer_category_code = environment.CUSTOMER_CATE_CODE_PREFIX;
        this.customer_id = 0;
    }
  
}
