<div class="" style="overflow: auto;">
    <div class="tab-pane chat-panel active" id="tab-checkout">
        <div id="cart-invoice">
            <div class="scroller">
                <div class="">
                    <div class="row info-row d-flex align-items-center">
                        <div class="">
                            Khách hàng F9:
                        </div>
                        <div class="col text-right">


                            <form class="example-form searching-cover">
                                <input type="text" [(ngModel)]="appService.customer_name_temp" class="form-control search_customer_input" id="search_customer_input" (focusout)="focusoutCustomer_search($event)" (focus)="focusCustomer_search($event)" placeholder="Khách lẻ" [formControl]="control"
                                    [matAutocomplete]="auto">

                                <a *ngIf="appService.customer_name_temp == appService.cartObj.customer_name" (click)="clearSearchCustomerInput()" class="icon">
                                    <span class="input-icon input-icon-right font-14"><span
                                            class="material-icons">cancel</span></span>
                                </a>
                                <a *ngIf="appService.customer_name_temp != appService.cartObj.customer_name" (click)="popupSelectCustomer(appService.customer_name_temp)" class="icon">
                                    <span class="input-icon input-icon-right font-14"><span
                                            class="material-icons">add</span></span>
                                </a>

                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let item of filteredCustomer  | async" [value]="item.name" (click)="onCustomerChange(item)">
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex justify-content-start align-items-center ">
                                                <div class="mr-1">
                                                    <a class="avatar-img-list" style="width: 28px;  height: 28px;" [style.background-image]="appService.showImageCustomer(item.image_url)"></a>
                                                </div>
                                                <div>
                                                    <div>{{item.name}} <span *ngIf="item.sale_count != null " class="badge badge-default">{{item.sale_count}} <span
                                                                class="material-icons font-11 mb-1">
                                                                receipt_long</span></span>
                                                    </div>
                                                    <div class="text-primary">{{item.phone | qbscurrency}} <span *ngIf="item.category_code != 'NKH'" class="badge badge-default font-11">{{item.categoryName}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <div class="text-right">
                                                    <div *ngIf="item.sale_ago_day != null " class="font-14">
                                                        {{item.sale_ago_day}} <span class="font-11">ngày</span></div>
                                                    <div class="font-13">{{item.sale_last_date}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                            </form>
                        </div>
                    </div>
                    <div class="row info-row">
                        <div *ngIf="appService.cartObj.name == ''">
                            Tổng đơn:
                        </div>
                        <div *ngIf="appService.cartObj.name != ''" class="font-weight-bold">
                            {{appService.cartObj.name}}
                        </div>
                        <div class="col text-right  pr-0">
                            {{appService.cartObj.price_totol | qbscurrency}}
                        </div>
                    </div>

                    <div class="row info-row">
                        <div class="">
                            <div class="row ">
                                <div class="d-flex align-items-center">
                                    <span style=" padding-right: 10px;">Khuyến mãi: </span>
                                    <label class="radio radio-grey radio-primary radio-inline">
                                        <input type="radio" name="bv2"
                                            [checked]="'%' == appService.cartObj.promotion_type"
                                            (change)="SelectkieuKhuyenMai('%')">
                                        <span class="input-span"></span>%</label>
                                    <label class="radio radio-grey radio-primary radio-inline">
                                        <input type="radio" name="bv2"
                                            [checked]="'đ' == appService.cartObj.promotion_type"
                                            (change)="SelectkieuKhuyenMai('đ')">
                                        <span class="input-span"></span>tiền</label>
                                </div>
                            </div>
                        </div>
                        <div class="col text-right  pr-0">
                            <div class="row">
                                <div class="col">
                                    <input class="form-control form-control-custom1 input-money-noborder" type="text" (focusout)="focusOutPromotion()" (focus)="focusFunction($event)" [(ngModel)]="appService.cartObj.promotion_promotion" name="promotion" currencyMask placeholder="Giá trị">
                                    <span style="position: absolute; top: 1px; right: -10px;">{{appService.cartObj.promotion_type}}</span>
                                </div>
                                <div class="col  pr-0">
                                    {{appService.cartObj.price_promotion | qbscurrency}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row info-row">
                        <div class="font-weight-bold">
                            Tổng thanh toán:
                        </div>
                        <div class="col text-right pr-0 font-18 font-weight-bold">
                            {{appService.cartObj.price_after_promotion | qbscurrency}}
                        </div>
                    </div>
                    <div class="row info-row">
                        <div class="">
                            Khách trả:
                        </div>
                        <div class="col text-right  pr-0">
                            <input [(ngModel)]="appService.cartObj.customer_pay" id="customer_pay" (focusout)="focusoutCustomer_pay($event)" (focus)="focusFunction($event)" class="form-control form-control-custom1 input-money-noborder" placeholder="Khách trả" currencyMask type="text">
                        </div>
                    </div>
                    <div class="row info-row">
                        <div class="">
                            Tiền thừa:
                        </div>
                        <div class="col text-right  pr-0">
                            {{this.utilityService.roundToTwo(appService.cartObj.customer_pay - appService.cartObj.price_after_promotion) | qbscurrency}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col ">
                            <input [(ngModel)]="appService.cartObj.note" id="sale_note" class="form-control  input-money-noborder" placeholder="Ghi chú" type="text">
                        </div>
                    </div>
                    <div style="height: calc(100vh - 285px);
                        overflow: auto;" class="qbs-scroll">
                        <div *ngIf="appService.cartObj.is_time_service == 1" style="padding-top: 0px">
                            <!-- START dành cho chỉnh sửa giờ kết thúc -->
                            <input [(ngModel)]="timerEditStr" style="display: none" [ngxTimepicker]="timerEditPicker" (ngModelChange)="timerEditPickerChange($event)" [format]="24">
                            <ngx-material-timepicker #timerEditPicker [format]="24"></ngx-material-timepicker>
                            <!-- END dành cho chỉnh sửa giờ kết thúc -->
                            <div *ngFor="let item of appService.cartObj.cart_service; let i = index" style="border-bottom: 1px dashed gray; padding: 10px 0px;">
                                <div class="row">
                                    <div class="mr-1">
                                        <a *ngIf="item.isEnable == 1" (click)="openSelectTimePrice()">
                                            <!-- <img *ngIf="item.isActive == 1" class="img-circle" src="./assets/img/icons/clock-spinner.gif" alt="image" width="35px" /> -->
                                            <span class="btn-label time-icon text-primary " [ngClass]="{'spinner-border': item.isActive == 1}">
                                                <span class="material-icons">schedule</span>
                                            </span>

                                            <!-- <img *ngIf="item.isActive == 0" class="img-circle" src="./assets/img/icons/clock-spinner.png" alt="image" width="35px" /> -->
                                        </a>
                                        <button class="btn-none" *ngIf="item.isEnable == 0">
                                            <span class="btn-label time-icon text-muted "
                                                [ngClass]="{'spinner-border': false}">
                                                <span class="material-icons">task_alt</span>
                                            </span>
                                            <!-- <img class="img-circle" src="./assets/img/icons/clock-spinner-done.png" alt="image" width="35px" /> -->
                                        </button>
                                    </div>
                                    <div class="col">
                                        <div> Tiền giờ: {{item.price | qbscurrency}} x {{item.quantityLable}}
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">

                                                    <button disabled class="btn btn-timer btn-labeled btn-labeled-left btn-icon">
                                                        <span class="btn-label"><span class="material-icons font-15">
                                                                play_circle </span></span>
                                                        {{item.serviceStartTime | date :'HH:mm dd/MM'
                                                        }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">
                                                    <button *ngIf="item.serviceEndTimeNumberType != 0" (click)="openTimerEdit(item,i, timerEditPicker)" class="btn btn-timer btn-labeled btn-labeled-left btn-icon">
                                                        <span class="btn-label"><span class="material-icons font-15">pause_circle </span></span>
                                                        {{item.serviceEndTime | date :'HH:mm dd/MM'}}
                                                    </button>
                                                    <button *ngIf="item.serviceEndTimeNumberType == 0" (click)="timerStop(item)" class="btn btn-timer timer-running  btn-labeled btn-labeled-left btn-icon">
                                                        <span class="btn-label"><span class="material-icons font-15"> pause_circle </span></span>
                                                        {{this.appService.currentTime | date :'HH:mm dd/MM' }}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right" style="width: 83px;">
                                        {{item.amount | qbscurrency}}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <table id="scrollContainer" style="width: 100%; margin-top: 10px;">
                            <tr *ngIf="appService.cartObj.is_time_service == 1" class="item-row-service">
                                <td style="width: 25px; text-align: center;">
                                    <i class="ti ti-time"></i>
                                    <span class="lbl-stt"></span>
                                </td>
                                <td style="width: 65%;">
                                    <div style="line-height: 16px;">
                                        Giá dịch vụ
                                    </div>
                                </td>

                                <td style="text-align: center;">

                                </td>
                                <td style="text-align: right; color: black;">
                                    {{appService.cartObj.service_price | qbscurrency}}
                                </td>
                            </tr>
                            <tr *ngFor="let item of appService.cartObj.cart; let i = index" [id]="item.product.code" class="item-row">
                                <td style="width: 25px; text-align: center;">
                                    <button class=" btn-remove btn-adjust btn btn-outline-danger btn-icon-only btn-circle btn-sm btn-thick" (click)='removeProduct(i,item)'><i class="ti ti-close"></i></button>
                                    <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                                    <span class="lbl-stt">{{i+1}}</span>
                                </td>
                                <td style="width: 60%;">
                                    <div style="line-height: 16px;" [ngClass]="{'highlight': i==appService.cartObj.highlight_index}">
                                        {{item.product.name}}
                                        <!-- <span>{{item.product.price| qbscurrency}}</span> -->
                                        <span *ngIf="item.product.unit_default != ''" style="font-size: small;"> - {{item.product.unit_default}}</span>
                                    </div>
                                    <div style="line-height: 16px;">
                                        <input [(ngModel)]="item.product.price" type="number" (focus)="focusFunction($event)" (focusout)="focusOutProductPrice(item.product)" currencyMask type="text" class="input-product-price" />
                                        <!-- <span>{{item.product.price| qbscurrency}}</span> -->
                                    </div>
                                    <!-- <div style="line-height: 15px;" *ngIf="item.product.price_temp != item.product.price">
                                        <span style="color: gray; font-size: small; text-decoration: line-through;">
                                            {{item.product.price_temp| qbscurrency}}
                                        </span>
                                    </div> -->
                                </td>
                                <td class="input-quantity-cover" style="text-align: center;">
                                    <button class=" btn-adjust-left btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='quantity_sub(item)'><i class="ti ti-minus"></i></button>
                                    <!-- <span style="color: black;"> {{item.quantity}} </span> -->

                                    <input [(ngModel)]="item.quantity" type="number" (focus)="focusFunction($event)" (focusout)="focusOutQuantity()" class="input-quantity" />
                                    <!-- <span *ngIf="item.product.unit_default =='' || item.product.unit==item.product.unit_default" style="font-size: small;"> {{item.product.unit}}</span>
                                        <span *ngIf="item.product.unit2==item.product.unit_default" style="font-size: small;"> {{item.product.unit2}}</span> -->
                                    <button class="btn-adjust-right btn btn-outline-primary btn-icon-only btn-circle btn-sm btn-thick" (click)='quantity_add(item)'><i class="ti ti-plus"></i></button>
                                </td>
                                <td style="text-align: right; color: black;">
                                    {{this.utilityService.roundToTwo(item.quantity * item.product.price) | qbscurrency:0}}
                                </td>
                            </tr>
                            <tr id="row-last" style="height: 51px;">
                                <td colspan="4">
                                </td>
                            </tr>
                        </table>
                        <div *ngIf="appService.cartObj.cart.length == 0" style="height: 80%;">
                            <app-nodata msg="Chọn sản phẩm bên trái để thêm vào hóa đơn" img="./assets/img/icons/add_cart.svg"></app-nodata>
                        </div>
                    </div>
                    <!-- <div>
                            <ng-multiselect-dropdown [placeholder]="'Nhân viên kỹ thuật'" [data]="VNKTList"
                            [settings]="dropdownSettings" >
                            </ng-multiselect-dropdown>
                        </div> -->
                    <div class="" style="position: fixed;bottom: 0;width: 490px;margin: 0 auto;">
                        <div class="row m-2 justify-content-between">
                            <div class="btn-group">
                                <button class="btn btn-primary btn-labeled btn-labeled-left" [ngClass]="{'isConfirming' : isConfirming == 1, 'isConfirming_no' : isConfirming == 0}" (click)="doPayment()" (focus)="onfocusoutButton()" [disabled]="appService.cartObj.cart.length == 0 && appService.cartObj.cart_service.length == 0">
                                    <span class="btn-label">F12</span>
                                    <span class="qbs-animated">
                                        <span class="qbs-animated-item visible-content">Thanh toán</span>
                                        <span class="qbs-animated-item hidden-content"> Xác nhận </span>
                                    </span>
                                </button>


                                <button *ngIf="isConfirming == 1" class="btn btn-outline-primary" (click)="isConfirming = 0" style="width: 42px;">
                                    <span class="material-icons"> cancel </span>
                                </button>

                                <button *ngIf="isConfirming == 0" mat-button [matMenuTriggerFor]="menu" class="btn btn-outline-primary" style="width: 42px;">
                                    <span *ngIf="appService.cartObj.payment_type == 0" class="material-icons">money</span>
                                    <span *ngIf="appService.cartObj.payment_type == 1" class="material-icons">qr_code_2</span>
                                    <span *ngIf="appService.cartObj.payment_type == 2" class="material-icons">payment</span>
                                </button>
                                <mat-menu #menu="matMenu" class="">
                                    <button mat-menu-item><span class="font-weight-bold">Chọn hình thức thanh toán</span></button>
                                    <a class="dropdown-item" mat-menu-item (click)="changePaymentType(0)"><span class="material-icons mr-3">money</span>Tiền mặt</a>
                                    <a class="dropdown-item" mat-menu-item (click)="changePaymentType(1)"><span class="material-icons mr-3">qr_code_2</span>Chuyển khoản / QRcode</a>
                                    <a class="dropdown-item" mat-menu-item (click)="changePaymentType(2)"><span class="material-icons mr-3">payment</span>Quẹt thẻ</a>
                                </mat-menu>


                            </div>

                            <button class="btn btn-outline-primary btn-labeled btn-labeled-left " (click)="print('Bản nháp')" [disabled]="appService.cartObj.cart.length == 0 && appService.cartObj.cart_service.length == 0" data-toggle="tooltip" data-placement="top" title="" data-original-title="In nháp hóa đơn">
                                <span class="btn-label">F10</span>
                                In nháp
                                <!-- <span class="material-icons">print</span> -->
                            </button>
                            <!-- <button class="btn btn-outline-primary " (click)="print('QRcode')" [disabled]="appService.cartObj.cart.length == 0 && appService.cartObj.cart_service.length == 0" data-toggle="tooltip" data-placement="top" title="" data-original-title="In nháp QRcode thanh toán">
                                <i class="ti ti-printer"></i> <span class="ml-2">QR code</span>
                            </button> -->


                            <button class="btn btn-outline-secondary" (click)="popupDeleteCart()" [disabled]="appService.cartObj.cart.length == 0 && appService.cartObj.cart_service.length == 0" data-toggle="tooltip" data-placement="top" title="" data-original-title="Xóa giỏ hàng">
                                <span class="material-icons">delete</span>
                            </button>

                            <!-- <button class="btn btn-info btn-labeled btn-labeled-left btn-icon" (click)="doOrder()" [disabled]="appService.cartObj.cart.length == 0 && appService.cartObj.cart_service.length == 0">
                                    <span class="btn-label"><i class="ti ti-check-box"></i></span>Đặt hàng
                                </button> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>