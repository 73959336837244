<div>
    <h1 mat-dialog-title>Chọn bàn/Phòng muốn chuyển điến</h1>
    <div class="">
        <div class="row ">
            <div class="font-weight-bold font-20">
                Bắt đầu: {{appService.currentTime | date:'h:mm a' }}
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="font-weight-bold font-18">
                <mat-form-field style="width: 450px;" appearance="fill">
                    <mat-label>Bàn/Phòng đang trống</mat-label>
                    <mat-select [(value)]="deskToIndexSelected" [disabled]="false">
                        <mat-option *ngFor="let item of appService.deskList; let i = index" [value]="i" [disabled]="!(item.cart.length == 0  && item.cart_service.length == 0 ) || item.is_time_service != is_time_service">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row mt-2" *ngIf="is_time_service==1">
            <div class="font-weight-bold font-18">
                <mat-form-field style="width: 450px;" appearance="fill">
                    <mat-label>Giá dịch vụ</mat-label>
                    <mat-select [(value)]="deskPriceIndexSelected" [disabled]="false">
                        <mat-option *ngFor="let item of appService.deskListPrice; let i = index" [value]="i">
                            {{item.price | qbscurrency}} - {{item.name}} - Khung giờ {{item.timeStart}} - {{item.timeEnd}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-around mt-3">
            <button class="btn btn-secondary " (click)="onCancelClick()">Đóng</button>

            <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="onOkClick()" [disabled]='deskToIndexSelected < 0'>
                <span class="btn-label"><i class="ti ti-timer"></i></span>
                Bắt đầu
            </button>
        </div>
    </div>
</div>