export class Cart {
    product_code : string = ""; // không dùng, dùng luôn ở product
    product_name : string = "";// không dùng, dùng luôn ở product
    price : number = 0;// không dùng, dùng luôn ở product
    unit_type : string = "";// không dùng, dùng luôn ở product
    quantity : number = 0;
    shop_code : string = "";// không dùng, dùng luôn ở product
    customer_code: string = "";// không dùng, dùng luôn ở product
    customer_name: string = "";// không dùng, dùng luôn ở product

    product: any;
    highlight_index: number = 0;// highlight product khi mới thêm

    constructor(){

    }
}
