import { environment } from './../../../environments/environment';
import { CartObj } from './../../model/cart-obj';
import { AppService } from './../../_services/app.service';
import { Component, AfterViewInit, Inject, HostListener } from '@angular/core';
import { SaleService } from '../../_services/sale.service';
import { ProductService } from '../../_services/product.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../_services/customer.service';
import { Router } from '@angular/router';
import { User } from '../../model/user';
import { UtilityService } from 'src/app/_services/utility.service';
import { Customer } from 'src/app/model/customer';
import { SharedService } from 'src/app/_services/shared.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Promotion } from 'src/app/model/promotion';
import { PromotionService } from 'src/app/_services/promotion.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CategoryService } from 'src/app/_services/category.service';
import { InputMoneyComponent } from 'src/app/components/input-money/input-money.component';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { SaleInvoiceComponent } from 'src/app/components/sale-invoice/sale-invoice.component';
import { ProductAddComponent } from 'src/app/pages/product/product-add/product-add.component';

declare var $: any;
@Component({
  selector: '[app-header]',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css'],
})
export class AppHeader implements AfterViewInit {
  elem;
  
  // doPaymentEvent: Subscription;
  printEvent: Subscription;
  clickEvent: Subscription;

  dateTimeStr: string

  // cartObj = new CartObj();
  // customer_pay = 0;
  customers: any;
  customers_backup: any;

  //for customer
  filter = {
    deleted: "0",
    type: environment.customer_type,
    name: "",
    category_code: "",
  }
  // for catefory customer
  categorys: any

  //for paging
  viewNumsObj = this.appService.viewNums[2].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;
  sorter = {
    sortStr: this.appService.sort_customer[0].code,
  }
  // promotion
  promotion = new Promotion("%");
  price_after_promotion = 0;
  price_promotion = 0;

  // for VNKTList
  dropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'name',
    selectAllText: 'Chọn tất cả',
    unSelectAllText: 'Bỏ chọn tất cả',
    allowSearchFilter: true,
    searchPlaceholderText: "Tìm kiếm"
  };
  VNKTList = [];

  //for search and scanner
  searchValue = "";
  productCodeTemp = "";
  constructor(
    private router: Router,
    public appService: AppService,
    public saleService: SaleService,
    public productService: ProductService,
    public customerService: CustomerService,
    private toastr: ToastrService,
    public categoryService: CategoryService,
    public utilityService: UtilityService,
    public promotionService: PromotionService,
    private sharedService: SharedService,
    public dialog: MatDialog,
    //for full screen
    @Inject(DOCUMENT) private document: any
  ) {


    
    
  }
  ngOnInit() {

    this.clickEvent = this.sharedService.getLoadPromotionEvent().subscribe((type) => {
      console.log("Header component:",type);
      this.loadPromotion();
    })

    this.printEvent = this.sharedService.getPrintEvent().subscribe((type) => {
      console.log("Call Payment from salev2:");
      this.print(type);
    },);
    //for full screen
    this.chkScreenMode();
    this.elem = document.documentElement;
  }
  ngOnDestroy() {
    if (this.printEvent) {
      this.printEvent.unsubscribe();
    }
    if (this.clickEvent) {
      this.clickEvent.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.loadPromotion();
  }

  
  
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // 
    console.log('Header document keydown: ',event.key);

    if(event.key == "F1") {
      event.preventDefault();
      if(this.appService.listCart.length > 0){
        if(this.appService.cartIndex == 0){
           this.appService.toggleCheckout();
        }else{
          this.selectCart(0);
        }
      }
      else{
        this.addNewCart();
        this.selectCart(0);
      }
    }
    else if(event.key == "F2") {
      event.preventDefault();
      if(this.appService.listCart.length > 1){
        if(this.appService.cartIndex == 1){
           this.appService.toggleCheckout();
        }else{
          this.selectCart(1);
        }
      }
      else{
        this.addNewCart();
        this.selectCart(1);
      }
      
    }
    else if(event.key == "F3") {
      event.preventDefault();
      if(this.appService.listCart.length > 2){
        if(this.appService.cartIndex == 2){
           this.appService.toggleCheckout();
        }else{
          this.selectCart(2);
        }
      }
      else{
        this.addNewCart();
        this.selectCart(2);
      }
    }
    else if(event.key == "F4") {
      event.preventDefault();
      if(this.appService.listCart.length > 3){
        if(this.appService.cartIndex == 3){
           this.appService.toggleCheckout();
        }else{
          this.selectCart(3);
        }
      }
      else{
        this.addNewCart();
        this.selectCart(3);
      }
    }
    
    // else if(event.key == "Shift") {
    //   // console.log('Không hiểu vì sao có Shift');
    // }

    // // nếu đang ở màn hình bán hàng.
    // else if(this.router.url == '/salev2'){
    //   if (event.key === 'Enter') {
    //     console.log(this.productCodeTemp);
    //     if (this.productCodeTemp.trim().length >= 3) {
    //       this.searchValue = this.productCodeTemp;
          
    //       console.log(this.searchValue);
    //       // this.popupAddToCart(this.utilityService.searchProduct(this.searchValue));
    //       let product_temp = this.utilityService.searchProduct(this.searchValue);
    //       if (product_temp) {
    //         this.sharedService.sendAddProductToCartEvent(product_temp);
    //         this.appService.openCheckout();
    //       }else{
    //         this.toastr.warning("", "Không tìm thấy mã sản phẩm");
    //       }
    //     }
    //     this.productCodeTemp = "";
    //   } else {
    //     if (this.productCodeTemp === null || this.productCodeTemp === "") {
    //       this.productCodeTemp = event.key;
    //     } else {
    //       this.productCodeTemp = this.productCodeTemp + event.key;
    //     }
    //   }
    // }

  }



  addNewCart() {
    this.appService.cartObj = new CartObj();
    this.appService.listCart.push(this.appService.cartObj);
    this.appService.cartIndex = this.appService.listCart.length - 1;
  }
  selectCart(i) {
    this.appService.cartIndex = i;
    this.appService.openCheckout();

    if(this.router.url != '/salev2'){
        const dialogRef = this.dialog.open(SaleInvoiceComponent, {
          position: {
            // left: '10px',
            right: '0px',
            top: '0px'
          },
          height : '100%',
          width : '500px',
          autoFocus : false,
          // disableClose: true,
          // panelClass: 'full-screen-modal',
          data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log("close");
          if (typeof result != "undefined" && result.code == "ok") {
            
          }
        });
    }


  }
  selectCartDesk(i) {
    this.appService.deskIndex = i;
    this.appService.openCheckout();
  }
  removeProduct(i, item) {
    this.appService.cartObj.cart.splice(i, 1); //delete index i

    // this.appService.cartObj.total_quantity = this.appService.cartObj.total_quantity - item.quantity;
    // this.appService.cartObj.price_totol = this.appService.cartObj.price_totol - (parseInt(item.product.price) * item.quantity);
    // this.appService.cartObj.customer_pay = this.appService.cartObj.price_totol;
    this.appService.prepareSaleObject({ sale_status: 99 ,isPromotion:true,isPromotionDetail:true,isCheckCustomerPay:false}) // cấp nhật lại toàn bộ cảtObj
    // cập nhật tổng giá trị của Desk
    if (this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
    console.log("item2", this.appService.cartObj.cart);
  }
  quantity_sub(item) {
    if (item.quantity > 0) {
      item.quantity = this.utilityService.roundToTwo(item.quantity - 1);
      console.log("item.quantity:",item.quantity);
      this.appService.prepareSaleObject({ sale_status: 99 ,isPromotion:true,isPromotionDetail:true,isCheckCustomerPay:false})
    }
    // cập nhật tổng giá trị của Desk
    if (this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }
  quantity_add(item) {
    item.quantity = this.utilityService.roundToTwo(item.quantity + 1);
    console.log("item.quantity:",item.quantity);
    this.appService.prepareSaleObject({ sale_status: 99 ,isPromotion:true,isPromotionDetail:true,isCheckCustomerPay:false})
    // cập nhật tổng giá trị của Desk
    if (this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }
  focusOutQuantity() {
    this.appService.prepareSaleObject({ sale_status: 99 ,isPromotion:true,isPromotionDetail:true,isCheckCustomerPay:false})
    // cập nhật tổng giá trị của Desk
    if (this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }

  loadCustomer() {
    let pagingObj = {
      viewNumsObj: 500,
      pageObj: this.curentPage
    };
    console.log("Saxxxxx loadCustomer");
    this.customerService.loadCustomer(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        this.searching = false;
        if (response) {
          this.appService.customers = response.data;
          // this.customers_backup = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.appService.customers.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

        }
      },
      error => {
        this.searching = false;
        console.error(error);
      })
  }
  loadCategory() {
    let filter = {
      type: environment.customer_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })

  }
  customer_cate_select(code){
    this.filter.category_code = code;
    this.loadCustomer();
  }
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCustomer();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCustomer();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCustomer();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCustomer();
    }
  };

  formatter = (x: { name: string }) => x.name;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log(this.filter.name);
        this.loadCustomer();
      }),
      map(
        term => term === '' ? [] : this.appService.customers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)
      ),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
    this.selectCustomer(r);
  }
  onfocusoutButton(){
    console.log("onfocusoutButton");
    $(".btn").trigger("blur");  // bỏ focus để tránh enter
  }
  




  doOrder() {
    if (this.appService.cartObj.is_time_service == 1) {
      this.toastr.warning("", "Dịch vụ không được đặt hàng");
      return
    }

    if (this.appService.cartObj.customer_code == environment.CUSTOMER_CODE_PREFIX) {
      this.toastr.warning("", "Vui lòng chọn khách hàng khi dặt hàng");
      this.popupSelectCustomer();
    } else {
      let param = this.appService.prepareSaleObject({ sale_status: 0 ,isPromotion:true,isPromotionDetail:true,isCheckCustomerPay:true});//1: đã thanh toán, 0: đặt hàng, 2: Chờ thanh toán, 3: đã hủy, 4: đang xử lý
      this.saleService.insertSale(param).subscribe(
        async response => {
          if (response.code == "ok" && response.affectedRows > 0) {
            this.sharedService.sendClickEvent("from app-header");// load Product from Sale component
            console.log("sendClickEventsendClickEventsendClickEventsendClickEvent");
            $('.checkout-widget').backdrop();

            if (this.appService.isFromDeskCart) {

              let tempp = new CartObj();
              tempp.position = { x: this.appService.deskList[this.appService.deskIndex].x, y: this.appService.deskList[this.appService.deskIndex].y };
              tempp.x = this.appService.deskList[this.appService.deskIndex].x;
              tempp.y = this.appService.deskList[this.appService.deskIndex].y;
              tempp.id = this.appService.deskList[this.appService.deskIndex].id;
              tempp.name = this.appService.deskList[this.appService.deskIndex].name;
              tempp.shop_code = this.appService.deskList[this.appService.deskIndex].shop_code;

              this.appService.deskList[this.appService.deskIndex] = tempp;
              localStorage.setItem(environment.deskIndex, this.appService.deskIndex.toString());
              localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
              this.appService.updateDeskRevenue();
            } else {
              if (this.appService.cartIndex > 0) {
                this.appService.listCart.splice(this.appService.cartIndex, 1); //delete cart
                this.appService.cartIndex = 0;
              } else {
                this.appService.cartIndex = 0;
                this.appService.listCart[this.appService.cartIndex] = new CartObj(); //delete cart

              }
              // Xóa chi tiết trong giỏ hàng hiện tại
              this.appService.cartObj = new CartObj();
              localStorage.setItem(environment.cartIndex, this.appService.cartIndex.toString());
              localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
              this.toastr.success("", "Đặt hàng thành công");
            }

          }
        },
        error => {
          console.error(error);
        });
    }
  }
  print(type) {
    console.log("printing...");
    if (this.appService.cartObj.is_time_service == 1 && this.appService.cartObj.service_price == 0 && this.appService.cartObj.cart_service.length > 0) {
      this.toastr.warning("Nhấn vào biểu tượng đồng hồ để dừng", "Bạn chưa dừng thời gian tính tiền");
      return
    }

    if (this.appService.printSizeSelected === 'none') {
      if (type != '') { // in nháp
        this.toastr.warning("Bạn chưa chọn kích thước máy in", "");
      }
      return
    }

    if(type != ''){// in nháp
      this.appService.prepareSaleObject({ sale_status: 99,isPromotion:true,isPromotionDetail:true,isCheckCustomerPay:true});
    }

    this.dateTimeStr = this.utilityService.getFullDateTimeStrFromDate(new Date());
    // this.toastr.success("", "Máy in chưa được thiết lập");
    let lableContents,printContents, popupWin, widthQRcode;
    printContents = document.getElementById('print-section').innerHTML;
    if(this.appService.printSizeSelected === 'k80'){
      widthQRcode = 80
    }else if(this.appService.printSizeSelected === 'k58'){
      widthQRcode = 58
    }

    if(type==""){ // thanh toán+
      lableContents = '';
    }
    else {
      // if(typeof this.appService.currentUser.bank_id == 'undefined' || this.appService.currentUser.bank_id == '' 
      // || typeof this.appService.currentUser.account_no == 'undefined' ||this.appService.currentUser.account_no == '' 
      // || typeof this.appService.currentUser.account_name == 'undefined' ||this.appService.currentUser.account_name == '' 
      // || typeof this.appService.currentUser.vietqr_template == 'undefined' ||this.appService.currentUser.vietqr_template ==''
      // ){
      //   this.toastr.warning('Hướng dẫn Menu: Cài đặt chung-> QR code','Ban chưa thiết lập tài khoản QRcode');
      //   return;
      // }
      type = 'In nháp'
      lableContents = `<div style="position: absolute;top: 22px;left: 0px;font-size: 20px;border: 2px  dashed grey;padding: 5px 20px;">${type}</div>`;
      // lableContents += `<img style="width: ${widthQRcode}mm" src="https://img.vietqr.io/image/${this.appService.currentUser.bank_id}-${this.appService.currentUser.account_no}-${this.appService.currentUser.vietqr_template}.jpg?accountName=${this.appService.currentUser.account_name}&amount=${this.appService.cartObj.price_after_promotion}&addInfo=${this.appService.cartObj.shop_code} customer">`;
      // lableContents += `<img src="https://img.vietqr.io/image/970422-5100103964008-y7S7KlE.jpg?accountName=HO%20XUAN%20HUY&amount=015000&addInfo=hd123">`;
    }
    // else{
    //   lableContents = `<div style="position: absolute;top: 22px;left: 0px;font-size: 20px;border: 2px  dashed grey;padding: 5px 20px;">${type}</div>`;
    // }

    if(this.appService.cartObj.payment_type == 1){ // QRCode
      if(typeof this.appService.currentUser.bank_id != 'undefined' && this.appService.currentUser.bank_id != '' 
      && typeof this.appService.currentUser.account_no != 'undefined' &&this.appService.currentUser.account_no != '' 
      && typeof this.appService.currentUser.account_name != 'undefined' &&this.appService.currentUser.account_name != '' 
      && typeof this.appService.currentUser.vietqr_template != 'undefined' &&this.appService.currentUser.vietqr_template !=''
      ){
        lableContents += `<img style="width: ${widthQRcode}mm" src="https://img.vietqr.io/image/${this.appService.currentUser.bank_id}-${this.appService.currentUser.account_no}-${this.appService.currentUser.vietqr_template}.jpg?accountName=${this.appService.currentUser.account_name}&amount=${this.appService.cartObj.price_after_promotion}&addInfo=${this.appService.cartObj.shop_code} customer">`;
      }
      else{
        this.toastr.warning('Hướng dẫn Menu: Cài đặt chung-> QR code','Ban chưa thiết lập tài khoản QRcode');
        return;
      }
    }


    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>In Hóa Đơn</title>
          <style>
          //........Customized style.......
          </style>
        </head>
        
        <body onload="window.print();window.close()">
          ${printContents}
          ${lableContents}
        </body>
      </html>`
    );
    popupWin.document.close();
    // <body onload="window.print();window.close()">
  }
  //<body onload="window.print();window.close()">${printContents}</body>
  
  // cancelDeleteCart() {
  //   $("#delete-cart-modal").modal('hide');
  // }
  focusFunction(event) {
    event.target.select();
  }
  focusoutCustomer_pay(event) {
    if(this.appService.cartObj.customer_pay == null) this.appService.cartObj.customer_pay = 0
  }

  popupSelectCustomer() {
    this.filter.category_code = '';
    this.loadCategory();
    this.loadCustomer();
    console.log(this.appService.promotions);
    if (!$('#select-customer-modal').hasClass('show')) {
      $("#select-customer-modal").modal('show');
    }else{
      $("#select-customer-modal").modal('hide');
    }
  }
  selectCustomer(item) {
    if(item == ''){
      this.appService.cartObj.customer_id = 0;
      this.appService.cartObj.customer_name = environment.CUSTOMER_NAME_PREFIX;
      this.appService.cartObj.customer_code = environment.CUSTOMER_CODE_PREFIX;
    }else{
      this.appService.cartObj.customer_id = item.id;
      this.appService.cartObj.customer_name = item.name;
      this.appService.cartObj.customer_code = item.code;
      this.appService.cartObj.customer_category_code = item.category_code;
    }
    // cập nhật khuyến mãi theo từng sản phẩm
    // this.appService.preparePromotionEachProduct();
    this.appService.prepareSaleObject({ sale_status: 99,isPromotion:true,isPromotionDetail:true,isCheckCustomerPay:true});
    $("#select-customer-modal").modal('hide');
  }
  


  //for add customer
  isAddingCustomer = false;
  isLoading = false;
  customerObj = new Customer(environment.customer_type);
  customer_add() {
    this.filter.name = "";
    this.customerObj = new Customer(environment.customer_type);
    this.isAddingCustomer = true;
  }
  customer_save() {

    console.log(this.customerObj);
    if (this.customerObj.name.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập tên KH");
      return false;
    } else if (this.customerObj.phone.trim() == "") {
      this.toastr.error("", "Bạn chưa nhập số điện thoại KH");
      return false;
    }


    if (true) {
      this.isLoading = true;
      this.customerService.countAllCustomer(environment.customer_type).subscribe(
        response => {

          let customer_code = environment.CUSTOMER_CODE_PREFIX + (parseInt(response.CountAllCustomer) + 1).toString();
          this.customerObj.code = customer_code;
          this.customerService.insertCustomer(this.customerObj).subscribe(
            async response => {
              this.isLoading = false;
              this.isAddingCustomer = false;
              console.log(response);
              if (response) {
                this.loadCustomer();
                this.selectCustomer(this.customerObj);
                // $("#add-customer-modal").modal('hide');
              }
            },
            error => {
              this.isLoading = false;
              console.error(error);
            })
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
    }
  }
  SelectkieuKhuyenMai(type) {
    this.appService.cartObj.price_promotion = 0;
    this.appService.cartObj.promotion_type = type;
    this.appService.cartObj.promotion_promotion = 0;

    this.appService.cartObj.price_after_promotion = this.appService.cartObj.price_totol;
    this.appService.cartObj.customer_pay = this.appService.cartObj.price_totol;

    console.log("this", this.appService.cartObj.promotion_type);
  }
  focusOutPromotion() {
    console.log("type", this.appService.cartObj.promotion_type);
    console.log("promotion", this.appService.cartObj.promotion_promotion);
    this.appService.preparePromotion(this.appService.cartObj);
  }


  openInputMoney(item) {
    console.log(item);
    const dialogRef = this.dialog.open(InputMoneyComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      // disableClose: true,
      width: '400px',
      height: '400px',
      panelClass: 'full-screen-modal',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        
      }
    });
  }
  // dành cho khuyến mãi
  async loadPromotion() {

    let pagingObj = {
      viewNumsObj: 10,
      pageObj: 1
    };
    let sorter = {
      sortStr: "",
    }
    let filter = {
      deleted: 0,
      dateSelected: this.utilityService.getFullDateStrFromDate(new Date())
    }

    this.promotionService.loadPromotion(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.appService.promotions = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }
  // for full screen
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreenmodes(event){
    this.chkScreenMode();
  }
  isFullScreen: boolean;
  chkScreenMode(){
      if(document.fullscreenElement){
        //fullscreen
        this.isFullScreen = true;
      }else{
        //not in full screen
        this.isFullScreen = false;
      }
  }
  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
}


