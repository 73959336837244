<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <div class="mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                <span class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input class="form-control form-control-rounded form-control-solid" id="key-search" (keydown)="getTypingSearch($event)" type="text" placeholder="Tìm kiếm ...">
            </div>
        </div>
        <div class="col">
            <div class="d-flex justify-content-end">
                <div class="pl-2">
                    <button class="btn btn-primary  btn-labeled btn-labeled-left" (click)="popupCreate()">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                </div>
                <div class="pl-2">
                    <button mat-icon-button [matMenuTriggerFor]="menu2" class="btn btn-secondary btn-labeled btn-labeled-left">
                        <span class="btn-label"><span class="material-icons"> more_vert </span></span>Hành động
                    </button>
                    <mat-menu #menu2="matMenu">
                        <!-- <a class="dropdown-item" mat-menu-item> -->
                        <a class="" mat-menu-item>
                            <label class="mb-0">
                                <label class="ui-switch switch-icon">
                                    <input type="checkbox" [(ngModel)]="filter.deleted" (change)="this.loadMembership();"  name="isResset">
                                    <span></span>
                                </label>
                            </label>
                            <span class="ml-4">Hiện thị đã xóa</span>
                        </a>
                    </mat-menu>
                </div>
            </div>



        </div>
    </div>


    <div class="table-responsive">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px;">#</th>
                    <th>Thành viên</th>
                    <th>Email</th>
                    <th>Điện thoại</th>
                    <th>Vài trò</th>
                    <th>Chi nhánh</th>
                    <th>Note</th>
                    <th class="text-center">Trạng thái</th>
                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of memberships; let i = index">
                    <td>
                        <span style="position: absolute; left: 42px;
            line-height: 20px;">{{i+1}}</span>
                        <a class="media-img" href="javascript:;">
                            <i class="ti ti-user font-20"></i>
                        </a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.name}}</div>
                    </td>
                    <td>{{item.email }}</td>
                    <!-- <td>{{item.created_date | date :'dd/MM/yyyy'}}</td> -->
                    <td>{{item.phone}}</td>
                    <td>{{utilityService.getNameOfRoleUser(item.role)}}</td>
                    <td>
                        <mat-chip-list aria-label="">
                            <span *ngFor="let jtem of appService.currentUser.branchList">
                                <mat-chip *ngIf="item.branchs.indexOf(jtem.code) >= 0">{{jtem.name}}</mat-chip>
                            </span>
                        </mat-chip-list>
                    </td>
                    <td>{{item.description}}</td>
                    <td class="text-center">
                        <span *ngIf="item.deleted != 1" class="badge badge-primary">Hoạt động</span>
                        <span *ngIf="item.deleted == 1" class="badge badge-danger">Ngừng</span>
                    </td>

                    <td>
                        <button class="btn-adjust btn  btn-icon-only btn-circle  btn-thick" (click)='popupUpdate(item)'><i class="ti ti-pencil-alt"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>



<div class="modal fade" id="add-membership-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-membership" (ngSubmit)="insertMembership()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới thành viên</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">


                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Tên nhân viên</mat-label>
                    <input matInput placeholder="Họ và tên" type="text" [(ngModel)]="membership.name" required maxlength="100" name="name">
                </mat-form-field>


                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Điện thoại</mat-label>
                    <input matInput placeholder="Điện thoại" type="text" [(ngModel)]="membership.phone" required minlength="10" maxlength="10" name="phone">
                </mat-form-field>


                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" type="email" [(ngModel)]="membership.email" maxlength="100" name="email">
                </mat-form-field>

                <!-- <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Vai trò</label>
                    <div class="col-sm-9">
                        <select class="show-tick form-control" [(ngModel)]="membership.role" name="roleSelect" class="qbs-select">
                            <option [ngValue]="item.code" *ngFor="let item of appService.memberships">{{item.name}}
                            </option>
                        </select>
                    </div>
                </div> -->

                <mat-form-field appearance="fill">
                    <mat-label>Vai trò</mat-label>
                    <mat-select [(ngModel)]="membership.role" name="role">
                        <mat-option *ngFor="let item of appService.memberships" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Chi nhánh</mat-label>
                    <mat-select [(ngModel)]="branchs" [formControl]="branchSelected" multiple (selectionChange)="category_branch_change($event)">
                        <mat-option *ngFor="let item of appService.currentUser.branchList" [value]="item.code">
                            {{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Mật khẩu</mat-label>
                    <input matInput placeholder="Mật khẩu" [type]="hide ? 'password' : 'text'" [(ngModel)]="membership.password" required name="password">
                    <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
                </mat-form-field>
                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Nhập lại mật khẩu</mat-label>
                    <input matInput placeholder="Nhập lại mật khẩu" [type]="hide ? 'password' : 'text'" [(ngModel)]="membership.repassword" required name="repassword">
                    <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
                </mat-form-field>




                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Ghi chú</mat-label>
                    <input matInput placeholder="Ghi chú" type="text" [(ngModel)]="membership.description" name="description">
                </mat-form-field>



            </div>
            <div class="modal-footer justify-content-center bg-primary-50">
                <div class="row align-items-center">
                    <div class="col text-center">
                        <button class="btn btn-primary  btn-labeled btn-labeled-left" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                            Thêm mới
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>





<div class="modal fade" id="update-membership-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-membership" (ngSubmit)="update()">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật thành viên</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Tên nhân viên</mat-label>
                    <input matInput placeholder="Họ và tên" type="text" [(ngModel)]="membershipSelected.name" required maxlength="100" name="update_name">
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Điện thoại</mat-label>
                    <input matInput placeholder="Điện thoại" type="text" [(ngModel)]="membershipSelected.phone" required minlength="10" maxlength="10" name="update_phone">
                </mat-form-field>


                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" type="email" [(ngModel)]="membershipSelected.email" maxlength="100" name="update_email">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Vai trò</mat-label>
                    <mat-select [(ngModel)]="membershipSelected.role" name="role">
                        <mat-option *ngFor="let item of appService.memberships" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Chi nhánh</mat-label>
                    <mat-select [formControl]="branchSelected" [(ngModel)]="branchs" multiple (selectionChange)="category_branch_change($event)">

                        <mat-option *ngFor="let item of appService.currentUser.branchList" [value]="item.code">
                            {{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Ghi chú</mat-label>
                    <input matInput placeholder="Ghi chú" type="text" [(ngModel)]="membershipSelected.description" name="update_description">
                </mat-form-field>

                <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Reset mật khẩu</label>
                    <div class="col-sm-6 text-right">
                        <label class="ui-switch switch-icon">
                            <input type="checkbox" [(ngModel)]="isResset" name="isResset">
                            <span></span>
                        </label>
                    </div>
                </div>

                <div *ngIf="isResset">
                    <mat-form-field appearance="fill" class="qbh-mat-lg">
                        <mat-label>Mật khẩu</mat-label>
                        <input matInput placeholder="Mật khẩu" [type]="hide ? 'password' : 'text'" [(ngModel)]="membershipSelected.password" required name="update_password">
                        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </a>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="qbh-mat-lg">
                        <mat-label>Nhập lại mật khẩu</mat-label>
                        <input matInput placeholder="Nhập lại mật khẩu" [type]="hide ? 'password' : 'text'" [(ngModel)]="membershipSelected.repassword" required name="update_repassword">
                        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </a>
                    </mat-form-field>
                </div>



                <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Vô hiệu hóa</label>
                    <div class="col-sm-6 text-right">
                        <label class="ui-switch switch-icon">
                            <input type="checkbox" [(ngModel)]="isDeleted" [disabled]="membershipSelected.role=='admin'"
                                name="deleted">
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary   btn-labeled btn-labeled-left" [disabled]="isLoading || this.updateForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Cập nhật
                </button>
            </div>
        </form>
    </div>
</div>