<div class="page-content fade-in-up">
    <div class="flexbox-b mb-5">
        <span class="mr-4 static-badge badge-pink"><i class="la la-pencil"></i></span>
        <div>
            <h5 class="font-strong">Blog</h5>
            <div class="text-light">Found 420 posts</div>
        </div>
    </div>
    <div class="mobile-mb-2 input-group-icon input-group-icon-left input-group-lg mb-5">
        <span class="input-icon input-icon-right"><i class="ti ti-search"></i></span>
        <input class="form-control form-control-air border-0 font-light font-poppins" type="text" placeholder="Search ..." style="box-shadow:0 3px 6px rgba(10,16,20,.15);">
    </div>
    <div class="row mb-5">
        <div class="col-md-4">
            <div class="card mb-4">
                <div class="rel">
                    <img class="card-img-top" src="./assets/img/blog/01.jpeg" alt="image" />
                    <div class="fab fab-bottom card-overlay-fab">
                        <button class="btn btn-primary btn-icon-only btn-circle btn-air" data-toggle="button"><i class="la la-share-alt fab-icon"></i><i class="la la-close fab-icon-active"></i></button>
                        <ul class="fab-menu">
                            <li>
                                <a class="btn btn-soc-facebook btn-icon-only btn-circle btn-air" href="javascript:;"><i class="ti ti-facebook"></i></a>
                            </li>
                            <li>
                                <a class="btn btn-soc-twitter btn-icon-only btn-circle btn-air" href="javascript:;"><i class="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a class="btn btn-soc-dribbble btn-icon-only btn-circle btn-air" href="javascript:;"><i class="fa fa-dribbble"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-img-overlay">
                        <span class="badge badge-purple">FASHION</span>
                    </div>
                </div>
                <div class="card-body">
                    <h4 class="card-title mb-2">
                        <a class="text-primary">Fashion today</a>
                    </h4>
                    <div class="text-muted mb-3">16 January 2017</div>
                    <p class="text-light">Minim dolor in amet nulla laboris enim dolore consequat proident fugiat culpa eiusmod proident sed excepteur excepteur magna irure ex officia ea sunt in incididunt minim nulla dolor dolor labore exercitation ex nostrud.</p>
                    <div
                        class="d-flex align-items-center justify-content-between">
                        <div class="text-primary">
                            <img class="img-circle mr-2" src="./assets/img/users/u10.jpg" alt="image" width="30" />Stella Obrien</div>
                        <div>
                            <span class="text-muted mr-4"><i class="fa fa-heart-o mr-2"></i>124</span>
                            <span class="text-muted"><i class="fa fa-comment-o mr-2"></i>56</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-4">
                <div class="rel">
                    <img class="card-img-top" src="./assets/img/blog/culinary-1.jpeg" alt="image" />
                    <div class="card-img-overlay">
                        <span class="badge badge-pink">CULINARY</span>
                    </div>
                </div>
                <div class="card-body">
                    <h4 class="card-title mb-2">
                        <a class="text-pink">Dish of the day</a>
                    </h4>
                    <div class="text-muted mb-3">16 January 2017</div>
                    <p class="text-light">Minim dolor in amet nulla laboris enim dolore consequat proident fugiat culpa eiusmod proident sed excepteur excepteur magna irure ex officia ea sunt in incididunt minim nulla dolor dolor labore exercitation ex nostrud.</p>
                    <div
                        class="d-flex align-items-center justify-content-between">
                        <div class="text-pink">
                            <img class="img-circle mr-2" src="./assets/img/users/u8.jpg" alt="image" width="30" />Lynn Weaver</div>
                        <div>
                            <span class="text-muted mr-4"><i class="fa fa-heart-o mr-2"></i>124</span>
                            <span class="text-muted"><i class="fa fa-comment-o mr-2"></i>56</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-4">
                <div class="rel">
                    <img class="card-img-top" src="./assets/img/blog/05.jpeg" alt="image" />
                    <div class="card-img-overlay">
                        <span class="badge badge-warning">NEWS</span>
                    </div>
                </div>
                <div class="card-body">
                    <h4 class="card-title mb-2">
                        <a class="color-inherit" href="javascript:;">Title of the news</a>
                    </h4>
                    <div class="text-muted mb-3">16 January 2017</div>
                    <p class="text-light">Minim dolor in amet nulla laboris enim dolore consequat proident fugiat culpa eiusmod proident sed excepteur excepteur magna irure ex officia ea sunt in incididunt minim nulla dolor dolor labore exercitation ex nostrud.</p>
                    <div
                        class="d-flex align-items-center justify-content-between">
                        <div>
                            <img class="img-circle mr-2" src="./assets/img/users/u6.jpg" alt="image" width="30" />Connor Perez</div>
                        <div>
                            <span class="text-muted mr-4"><i class="fa fa-heart-o mr-2"></i>124</span>
                            <span class="text-muted"><i class="fa fa-comment-o mr-2"></i>56</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-md-4">
            <div class="card mb-4">
                <div class="rel">
                    <img class="card-img-top" src="./assets/img/blog/04.jpeg" alt="image" />
                    <div class="card-img-overlay text-white">
                        <span class="badge badge-purple mb-5">FASHION</span>
                        <div class="card-title-bottom color-white mb-2">
                            <h4 class="card-title mb-2">
                                <a>Fashion Trends</a>
                            </h4>
                            <div>16 January 2017</div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="d-inline-flex align-items-center">
                            <img class="img-circle mr-3" src="./assets/img/users/u8.jpg" alt="image" width="40" />
                            <div>
                                <h6 class="text-primary m-0">Lynn Weaver</h6><small class="text-muted">Web Designer</small></div>
                        </div>
                        <div class="d-inline-flex">
                            <span class="text-muted pl-3 pr-3 text-center" style="border-right:1px solid rgba(0,0,0,.1);"><i class="fa fa-heart-o d-block font-16 mb-2"></i>124</span>
                            <span class="text-muted pl-3 pr-3 text-center"><i class="fa fa-comment-o d-block font-16 mb-2"></i>56</span>
                        </div>
                    </div>
                    <p class="text-light">Minim dolor in amet nulla laboris enim dolore consequat proident fugiat culpa eiusmod proident sed excepteur excepteur magna irure ex officia ea sunt in incididunt minim nulla dolor dolor labore exercitation ex nostrud.</p>
                    <div class="text-right">
                        <a class="d-inline-flex align-items-center text-primary" href="javascript:;">Read More<i class="ti-arrow-circle-right font-20 ml-2"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-4">
                <div class="rel">
                    <img class="card-img-top" src="./assets/img/blog/02.jpg" alt="image" />
                    <div class="card-img-overlay text-white card-overlay-dark card-overlay-hover">
                        <span class="badge badge-warning mb-5">FASHION</span>
                        <div class="card-title-bottom color-white mb-2">
                            <h4 class="card-title mb-2">
                                <a>Style of the week</a>
                            </h4>
                            <div>16 January 2017</div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="d-inline-flex align-items-center">
                            <img class="img-circle mr-3" src="./assets/img/users/u2.jpg" alt="image" width="40" />
                            <div>
                                <h6 class="text-warning m-0">Becky Brooks</h6><small class="text-muted">Web Designer</small></div>
                        </div>
                        <div class="d-inline-flex">
                            <span class="text-muted pl-3 pr-3 text-center" style="border-right:1px solid rgba(0,0,0,.1);"><i class="fa fa-heart-o d-block font-16 mb-2"></i>124</span>
                            <span class="text-muted pl-3 pr-3 text-center"><i class="fa fa-comment-o d-block font-16 mb-2"></i>56</span>
                        </div>
                    </div>
                    <p class="text-light">Minim dolor in amet nulla laboris enim dolore consequat proident fugiat culpa eiusmod proident sed excepteur excepteur magna irure ex officia ea sunt in incididunt minim nulla dolor dolor labore exercitation ex nostrud.</p>
                    <div class="text-right">
                        <a class="d-inline-flex align-items-center text-warning" href="javascript:;">Read More<i class="ti-arrow-circle-right font-20 ml-2"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-4">
                <div class="rel">
                    <img class="card-img-top" src="./assets/img/blog/06.jpeg" alt="image" />
                    <div class="card-img-overlay text-white">
                        <span class="badge badge-success mb-5">WORLD</span>
                        <div class="card-title-bottom color-white mb-2">
                            <h4 class="card-title mb-2">
                                <a>Title of the news</a>
                            </h4>
                            <div>16 January 2017</div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="d-inline-flex align-items-center">
                            <img class="img-circle mr-3" src="./assets/img/users/u11.jpg" alt="image" width="40" />
                            <div>
                                <h6 class="text-success m-0">Tyrone Carroll</h6><small class="text-muted">Web Designer</small></div>
                        </div>
                        <div class="d-inline-flex">
                            <span class="text-muted pl-3 pr-3 text-center" style="border-right:1px solid rgba(0,0,0,.1);"><i class="fa fa-heart-o d-block font-16 mb-2"></i>124</span>
                            <span class="text-muted pl-3 pr-3 text-center"><i class="fa fa-comment-o d-block font-16 mb-2"></i>56</span>
                        </div>
                    </div>
                    <p class="text-light">Minim dolor in amet nulla laboris enim dolore consequat proident fugiat culpa eiusmod proident sed excepteur excepteur magna irure ex officia ea sunt in incididunt minim nulla dolor dolor labore exercitation ex nostrud.</p>
                    <div class="text-right">
                        <a class="d-inline-flex align-items-center text-success" href="javascript:;">Read More<i class="ti-arrow-circle-right font-20 ml-2"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-md-6">
            <div class="card mb-4 card-hover-zoom">
                <img class="card-img img-zoom" src="./assets/img/blog/03.jpg" alt="image" />
                <div class="card-img-overlay text-white card-overlay-dark card-overlay-hover d-flex flex-column justify-content-between align-items-start">
                    <div class="w-100 d-flex justify-content-between mb-3">
                        <div>
                            <span class="badge badge-purple mr-2">TRENDS</span>
                            <span class="badge badge-pink">DESIGN</span>
                        </div>
                        <div>
                            <span class="mr-4"><i class="fa fa-heart-o mr-2"></i>124</span>
                            <span><i class="fa fa-comment-o mr-2"></i>56</span>
                        </div>
                    </div>
                    <h2 class="card-title mb-2">
                        <a>Web Design Trends<br>Strict Selection</a>
                    </h2>
                    <div class="text-muted">16 January 2017</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card mb-4 card-hover-zoom">
                <img class="card-img img-zoom" src="./assets/img/blog/07.jpeg" alt="image" />
                <div class="card-img-overlay text-white card-overlay-dark card-overlay-hover d-flex flex-column justify-content-between align-items-start">
                    <div class="w-100 d-flex justify-content-between mb-3">
                        <div>
                            <span class="badge badge-purple mr-2">TRENDS</span>
                            <span class="badge badge-pink">DESIGN</span>
                        </div>
                        <div>
                            <span class="mr-4"><i class="fa fa-heart-o mr-2"></i>124</span>
                            <span><i class="fa fa-comment-o mr-2"></i>56</span>
                        </div>
                    </div>
                    <h2 class="card-title mb-2">
                        <a>Web Design Trends<br>Strict Selection</a>
                    </h2>
                    <div class="text-muted">16 January 2017</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="card mb-4">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/7TnL6r8U818?rel=0" allowfullscreen></iframe>
                </div>
                <div class="card-body">
                    <h4 class="card-title mb-2">
                        <a class="text-primary">'Beautiful Creatures' Trailer 2</a>
                    </h4>
                    <div class="text-muted mb-3">16 January 2017</div>
                    <p class="text-light">Minim dolor in amet nulla laboris enim dolore consequat proident fugiat culpa eiusmod proident sed excepteur excepteur magna irure ex officia ea sunt in incididunt minim nulla dolor dolor labore exercitation ex nostrud.</p>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="text-primary">
                            <img class="img-circle mr-2" src="./assets/img/users/u10.jpg" alt="image" width="30" />Stella Obrien</div>
                        <div>
                            <span class="text-muted mr-4"><i class="fa fa-heart-o mr-2"></i>124</span>
                            <span class="text-muted"><i class="fa fa-comment-o mr-2"></i>56</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card mb-4">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/iycoH4Ie0jI?rel=0" allowfullscreen></iframe>
                </div>
                <div class="card-body">
                    <h4 class="card-title mb-2">
                        <a class="text-info">Angry Birds Blues</a>
                    </h4>
                    <div class="text-muted mb-3">16 January 2017</div>
                    <p class="text-light">Minim dolor in amet nulla laboris enim dolore consequat proident fugiat culpa eiusmod proident sed excepteur excepteur magna irure ex officia ea sunt in incididunt minim nulla dolor dolor labore exercitation ex nostrud.</p>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="text-info">
                            <img class="img-circle mr-2" src="./assets/img/users/u11.jpg" alt="image" width="30" />Tyrone Carroll</div>
                        <div>
                            <span class="text-muted mr-4"><i class="fa fa-heart-o mr-2"></i>124</span>
                            <span class="text-muted"><i class="fa fa-comment-o mr-2"></i>56</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
