import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CartObj } from 'src/app/model/cart-obj';
import { CartService } from 'src/app/model/cartService';
import { AppService } from 'src/app/_services/app.service';
import { CustomerService } from 'src/app/_services/customer.service';
import { ProductService } from 'src/app/_services/product.service';
import { SaleService } from 'src/app/_services/sale.service';
import { SharedService } from 'src/app/_services/shared.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CustomerPickerComponent } from '../customer-picker/customer-picker.component';
import { DeleteConfirmComponent } from '../delete-confirm/delete-confirm.component';
import { ServicedeskpriceComponent } from '../servicedeskprice/servicedeskprice.component';
declare var $: any;

@Component({
  selector: 'app-sale-invoice',
  templateUrl: './sale-invoice.component.html',
  styleUrls: ['./sale-invoice.component.css']
})
export class SaleInvoiceComponent implements OnInit {
  @Input() saletype: string ; //sale và servicedesk

  isConfirming : number = 0;
  //for customer
  filter_customer = {
    name: "",
    type: "customer",
    deleted: 0
  }
  //for search
  searching = false;
  //for paging
  viewNumsObj = this.appService.viewNums[4].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for sorter
  sorter = {
    sortStr: "",
  }
  //cần được reset và tắt popup sau khi thực hiện
  isNeedToBeClose = false;

  // cho giờ kết thúc
  timerEditStr : string = '00:00';

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key == "F9") {
      event.preventDefault();
      $('#search_customer_input').focus();
    }else if(event.key == "F10") {
      event.preventDefault();

        if(this.appService.cartObj.cart.length == 0 && this.appService.cartObj.cart_service.length == 0){
        }else
        this.print('Bản nháp');
      
    }else if(event.key == "F12") {
      event.preventDefault();
        if(this.appService.cartObj.cart.length == 0 && this.appService.cartObj.cart_service.length == 0){
        }else
        this.doPayment();
    }
  }

  
  constructor(
    public appService: AppService,
    public customerService: CustomerService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public utilityService: UtilityService,
    private sharedService: SharedService,
    public saleService: SaleService,
    public productService: ProductService,
  ) {
    console.log('data');
    this.isNeedToBeClose = true;
    this.loadCustomer();
  }

  private openTimePricePicker: Subscription;
  ngOnInit(): void {
    this.filteredCustomer = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.openTimePricePicker = this.sharedService.getOpenTimePricePickerEvent().subscribe(() => {
      console.log("sax 123");
      this.openSelectTimePrice();
    },);
   
  }

  ngOnDestroy() {
    this.openTimePricePicker.unsubscribe();
  }
  
  // customer search
  control = new FormControl('');
  // this.appService.customer_name_temp = "";
  // this.appService.customers: string[] = ['Champs-Élysées23', 'Lombard Str3eet', 'Abbey Road','dsd', 'Fifth Avenue', 'Fifth Avenue1', 'Fifth Avenue2', 'Fifth Avenue3', 'Fifth Avenue4', 'Fifth Avenue5', 'Fifth Avenue6'];
  filteredCustomer: Observable<string[]>;
  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.appService.customers.filter(item => {
      return this._normalizeValue(item.name).includes(filterValue) || this._normalizeValue(item.phone).includes(filterValue)
    });
  }
  private _normalizeValue(value: string): string {
    if(value == null)  return '';
    return value.toLowerCase().replace(/\s/g, '');
  }

  focusCustomer_search(event){
   
  }


  async loadCustomer() {
    let pagingObj = {
      viewNumsObj: 500,
      pageObj: this.curentPage
    };
    this.customerService.loadCustomer(this.filter_customer, this.sorter, pagingObj).subscribe(
      async response => {
        this.searching = false;
        if (response) {
          this.appService.customers = response.data;
        }
      },
      error => {
        this.searching = false;
        console.error(error);
      })
  }

  focusoutCustomer_search(event) {
    setTimeout(() => {
      if (this.appService.customer_name_temp != '' && this.appService.customer_name_temp != this.appService.cartObj.customer_name) {
        // đưa artObj.customer về mặc định
        this.appService.cartObj.customer_id = 0;
        this.appService.cartObj.customer_name = environment.CUSTOMER_NAME_PREFIX;
        this.appService.cartObj.customer_code = environment.CUSTOMER_CODE_PREFIX;
        // đưa this.appService.customer_name_temp về mặc định
        this.appService.customer_name_temp = "";
        this.toastr.warning('', 'Khách hàng không tồn tại');
      }
    }, 800); // 2500 is millisecond
  }


  clearSearchCustomerInput() {
    this.appService.customer_name_temp = '';
    this.appService.cartObj.customer_name = '';
    // forcus vào input search
    $('#search_customer_input').focus();
  }

  popupSelectCustomer(value) {
    const dialogRef = this.dialog.open(CustomerPickerComponent, {
      position: {
        top: '10px',
        right: '10px'
      },
      // width: '98%',
      // height: '98%',
      // panelClass: 'full-screen-modal',
      data: value
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined") {
        this.selectCustomer(result); //result phải đúng định dạng customer
        this.loadCustomer();
      }
    });
  }
  selectCustomer(item) {
    if (item == '') {
      this.appService.cartObj.customer_id = 0;
      this.appService.cartObj.customer_name = environment.CUSTOMER_NAME_PREFIX;
      this.appService.cartObj.customer_code = environment.CUSTOMER_CODE_PREFIX;
    } else {
      this.appService.cartObj.customer_id = item.id;
      this.appService.cartObj.customer_name = item.name;
      this.appService.cartObj.customer_code = item.code;
      this.appService.cartObj.customer_category_code = item.category_code;
    }
    // gán để hiển thị tên
    this.appService.customer_name_temp = this.appService.cartObj.customer_name;
    // cập nhật khuyến mãi theo từng sản phẩm
    // this.appService.preparePromotionEachProduct();
    this.appService.prepareSaleObject({ sale_status: 99, isPromotion: true, isPromotionDetail: true, isCheckCustomerPay: true });
  }
  onCustomerChange(item) {
    this.selectCustomer(item);
  }
  SelectkieuKhuyenMai(type) {
    this.appService.cartObj.price_promotion = 0;
    this.appService.cartObj.promotion_type = type;
    this.appService.cartObj.promotion_promotion = 0;

    this.appService.cartObj.price_after_promotion = this.appService.cartObj.price_totol;
    this.appService.cartObj.customer_pay = this.appService.cartObj.price_totol;

    console.log("this", this.appService.cartObj.promotion_type);
  }

  focusOutPromotion() {
    this.appService.preparePromotion(this.appService.cartObj);
  }
  focusoutCustomer_pay(event) {
    if (this.appService.cartObj.customer_pay == null) this.appService.cartObj.customer_pay = 0
  }
  removeProduct(i, item) {
    this.appService.cartObj.cart.splice(i, 1); //delete index i

    this.appService.prepareSaleObject({ sale_status: 99, isPromotion: true, isPromotionDetail: true, isCheckCustomerPay: false }) // cấp nhật lại toàn bộ cảtObj
    // cập nhật tổng giá trị của Desk
    if (this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
    console.log("item2", this.appService.cartObj.cart);
  }
  focusOutProductPrice(product) {
    this.appService.prepareSaleObject({ sale_status: 99, isPromotion: true, isPromotionDetail: false, isCheckCustomerPay: false })
    // cập nhật tổng giá trị của Desk
    if (this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
    // kiểm tra xem có thay đổi giá hay không
    if(product.price != product.price_temp){
        // this.toastr.warning("Bạn có muôn lưu thay đổi giá bạn vừa nhập hay không","Thay đổi giá");
      let data = {
        title : 'Xác nhận cập nhật giá',
        msg : 'Đồng ý để cập nhật giá lên hệ thống, nếu không thì chỉ áp dụng cho hóa đơn này.',
      }
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: data
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result == 'ok'){
            // close hoặc không close

            this.productService.update(product).subscribe(
              async response => {
                console.log(response);
                if (response.code == "ok") {
                  this.toastr.success("", "Cập nhật hàng hóa thành công");
                  this.sharedService.sendClickEvent("from app-header");// load Product on Sale component
                }
              },
              error => {
                this.toastr.error("Lỗi hệ thống", "Cập nhật hàng hóa không thành công");
              })
          }
        });
    }
  }
  focusOutQuantity() {
    this.appService.prepareSaleObject({ sale_status: 99, isPromotion: true, isPromotionDetail: false, isCheckCustomerPay: false })
    // cập nhật tổng giá trị của Desk
    if (this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }
  focusFunction(event) {
    event.target.select();
  }
  quantity_sub(item) {
    if (item.quantity > 0) {
      item.quantity = this.utilityService.roundToTwo(item.quantity - 1);
      console.log("item.quantity:", item.quantity);
      this.appService.prepareSaleObject({ sale_status: 99, isPromotion: true, isPromotionDetail: false, isCheckCustomerPay: false })
    }
    // cập nhật tổng giá trị của Desk
    if (this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }
  quantity_add(item) {
    item.quantity = this.utilityService.roundToTwo(item.quantity + 1);
    console.log("item.quantity add add:", item.quantity);
    this.appService.prepareSaleObject({ sale_status: 99, isPromotion: true, isPromotionDetail: false, isCheckCustomerPay: false })
    // cập nhật tổng giá trị của Desk
    if (this.appService.isFromDeskCart) this.appService.updateDeskRevenue();
  }
  onfocusoutButton() {
  
  }
  changePaymentType(item){
    this.appService.cartObj.payment_type = item;
    console.log(this.appService.cartObj.payment_type);
  }
  doPayment() {
    $(".btn").trigger("blur");  // bỏ focus để tránh enter
    if (this.appService.cartObj.is_time_service == 1 
      && this.appService.cartObj.cart_service.length > 0 
      && this.appService.cartObj.cart_service[this.appService.cartObj.cart_service.length-1].serviceEndTimeNumberType == 0 ) {
      this.toastr.warning("Nhấn vào biểu tượng đồng hồ để dừng", "Bạn chưa dừng thời gian tính tiền");
      return
    }

    if (this.appService.cartObj.customer_pay < this.appService.cartObj.price_after_promotion && this.appService.cartObj.customer_id == 0) {
      this.toastr.warning("Bạn vui lòng chọn khách quen", "Khách lẻ phải thanh toán đủ tiền");
      return
    }
    
    if(this.appService.cartObj.cart.length == 0 && this.appService.cartObj.cart_service.length == 0){
      this.toastr.warning("", "Bạn chưa chọn sản phẩm hoặc chưa phát sinh dịch vụ");
      return
    }

    this.sendNotification();

    console.log("1111",this.appService.cartObj.payment_type);
    // return;
    let cartObj = this.appService.prepareSaleObject({ sale_status: 1 ,isPromotion:true,isPromotionDetail:true,isCheckCustomerPay:true});//1: đã thanh toán, 0: đặt hàng, 2: Chờ thanh toán, 3: đã hủy, 4: đang xử lý
    // xử lý customer pay
    console.log("2222",this.appService.cartObj.payment_type);
    if(this.isConfirming == 0){ // nếu đang bằng 0 thì chuyển thành 1 và kết thúc
      this.isConfirming = 1;
      return
    }else if(this.isConfirming == 1){
      this.isConfirming = 0;
    console.log("333",this.appService.cartObj.payment_type);
    this.saleService.insertSale(cartObj).subscribe(
      async response => {
        console.log("444",this.appService.cartObj.payment_type);
        if (response.code == "ok" && response.affectedRows > 0) {
          this.sharedService.sendClickEvent("from app-header");// load Product on Sale component
          // $('.checkout-widget').backdrop();
          console.log("isFromDeskCart: ", this.appService.isFromDeskCart);

          this.toastr.success("", "Thanh toán thành công" + this.appService.cartObj.payment_type);
          this.print("");

          // reset CartObj
          let temp = new CartObj();
          temp.payment_type = this.appService.cartObj.payment_type;


          if (this.appService.isFromDeskCart) {

            this.deleteCart();

            this.appService.updateDeskRevenue();
          } else {
            if (this.appService.cartIndex > 0) {
              
              this.appService.listCart[this.appService.cartIndex] = temp; //delete cart
    
              
            } else {
              this.appService.cartIndex = 0;
              this.appService.listCart[this.appService.cartIndex] = temp; //delete cart
            }
            // gán giỏ hàng hiện tại
            this.appService.cartObj = this.appService.listCart[this.appService.cartIndex];
            // gán lại tên customer trong sale cart v2
            this.appService.customer_name_temp = '';
            // storage
            localStorage.setItem(environment.cartIndex, this.appService.cartIndex.toString());
            localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
            // $('.checkout-widget').backdrop();
            this.appService.closeCheckout();
          }
          
          

          // close hoặc không close
          if(this.saletype == 'sale'){
          }
          else if(this.saletype == 'servicedesk'){
            this.sharedService.sendCloseServiceDeskNavEvent();
          }

        }
      },
      error => {
        console.error(error);
      });
    }
  }
  sendNotification() {

  }

  deleteCart() {
    if (this.appService.isFromDeskCart) {

      let tempp = new CartObj();
      tempp.position = { x: this.appService.deskList[this.appService.deskIndex].x, y: this.appService.deskList[this.appService.deskIndex].y };
      tempp.x = this.appService.deskList[this.appService.deskIndex].x;
      tempp.y = this.appService.deskList[this.appService.deskIndex].y;
      tempp.id = this.appService.deskList[this.appService.deskIndex].id;
      tempp.name = this.appService.deskList[this.appService.deskIndex].name;
      tempp.shop_code = this.appService.deskList[this.appService.deskIndex].shop_code;
      tempp.is_time_service = this.appService.deskList[this.appService.deskIndex].is_time_service;

      this.appService.deskList[this.appService.deskIndex] = tempp;
      localStorage.setItem(environment.deskIndex, this.appService.deskIndex.toString());
      localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
    } else {
      if (this.appService.cartIndex > 0) {
        this.appService.listCart.splice(this.appService.cartIndex, 1); //delete cart
        this.appService.cartIndex = 0;
      } else {
        this.appService.cartIndex = 0;
        this.appService.listCart[this.appService.cartIndex] = new CartObj(); //delete cart
      }
      // gán lại tên customer trong sale cart v2
      this.appService.customer_name_temp = '';

      localStorage.setItem(environment.cartIndex, this.appService.cartIndex.toString());
      localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
    }
    this.appService.updateDeskRevenue();
    // $('.checkout-widget').backdrop();
    // $("#delete-cart-modal").modal('hide');
  }
  print(type) {
    this.sharedService.sendPrinttEvent(type);
  }
  popupDeleteCart() {
    console.log('saletype sale invoice',this.saletype)
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      position: {
        // top: '10px',
        // right: '10px'
      },
      data: this.saletype
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
                if(result == 'ok'){
                  // close hoặc không close
                if(this.saletype == 'sale'){
                }
                else if(this.saletype == 'servicedesk'){
                  this.sharedService.sendCloseServiceDeskNavEvent();
                }
                }
    });
  }
  // for time service
  timerStop(item) {
    // tính tiền giờ.
    if (item.isActive == 1) {// nếu đang hiệu lực
      item.isActive = 0;
      this.appService.checkOutService("");
      // đặt giờ kết thúc
      let date = new Date();
      item.serviceEndTime = date;
      item.serviceEndTimeNumberType = date.getTime();
      // lưu tạm giờ kết thúc để cho edit
      this.timerEditStr = item.serviceEndTime.getHours() + ':' + item.serviceEndTime.getMinutes();
      // lưu tạm giờ kết thúc để cho edit
      this.appService.updateDeskRevenue();
    }
  }
  openTimerEdit(item,i, timepicker) { // áp dụng cho giờ kết thúc
    if(i == this.appService.cartObj.cart_service.length - 1){
        this.timerEditStr = item.serviceEndTime.getHours() + ':' + item.serviceEndTime.getMinutes();
        timepicker.open();
    }
  }
  timerEditPickerChange(event){ // sự hiện chọn giờ
      console.log(event);
      // thực hiện cập nhật giờ cho giờ kết thúc
      var lastIndex = this.appService.cartObj.cart_service.length - 1;
      var temp = event.split(":");
      var tempTime = this.appService.cartObj.cart_service[lastIndex].serviceEndTime;
      tempTime.setHours(temp[0],temp[1]);
      console.log(tempTime);
      // Không hiểu sao serviceEndTime được tự động gán time vào và là kiểu timestamp nên phải chuyển sang object.
      this.appService.cartObj.cart_service[lastIndex].serviceEndTime = new Date(this.appService.cartObj.cart_service[lastIndex].serviceEndTime);
      this.appService.cartObj.cart_service[lastIndex].serviceEndTimeNumberType = this.appService.cartObj.cart_service[lastIndex].serviceEndTime.getTime();
      

      console.log(this.appService.cartObj.cart_service[lastIndex].serviceEndTime);
      
      this.appService.calculateServicePrice();
      this.appService.updateDeskRevenue();
  }
  timerStop_bu(item) {
    // tính tiền giờ.
    if (item.isActive == 1) {// tính tiền giờ
      item.isActive = 0;
      this.appService.checkOutService("");

      // đặt giờ kết thúc
      let date = new Date();
      item.serviceEndTime = date;
      item.serviceEndTimeNumberType = date.getTime();
    } else {
      item.isActive = 1;
      this.appService.checkOutService("reset");
      //bỏ giờ kết thúc
      item.serviceEndTime = null;
      item.serviceEndTimeNumberType = 0;
    }
    this.appService.updateDeskRevenue();
  }
  // search customer old version
  // search_customer = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(400),
  //     tap(() => {
  //       this.searching = true;
  //       // this.filter.code = this.filter.name;
  //       console.log(this.filter.name);
  //       this.loadCustomer();
  //     }),
  //     map((term) => {
  //         console.log("term",term);
  //         // return term === '' ? [] : this.appService.customers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15);}
  //         return this.appService.customers;
  //       }
  //     ),
  //     tap(() => { 
  //       console.log('tap tap tap')
  //     }),
  //   )

  // onClick_customer(r) {
  //   // không có tác dụng lắm
  //   console.log(r);
  //   this.filter_customer.name = "";
  //   // this.popupAddToCart(r);
  // }

  // search_customer_blur(){
  //   //string là chưa chọn customer, object là đã chọn customer
  //   if(this.utilityService.getTypeData(this.filter_customer.name) == 'string'){
  //     this.filter_customer.name = "";
  //   }
  //   console.log("search_customer_blur");
  // }
  openSelectTimePrice() {
    // this.appService.deskIndex = index;
    // this.modalService.open(content,{});
    
    console.log(this.appService.deskList[this.appService.deskIndex]);
    console.log(this.appService.deskList[this.appService.deskIndex].cart);

    const dialogRef = this.dialog.open(ServicedeskpriceComponent, {
      width: '500px',
      disableClose: true,
      autoFocus : false,
      data: { name: "hahh", animal: "hhehehehehe" },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
       
        // START xử lý xem cho check trùng giá dịch vụ hay không
        let isCheckSamePrice = true;
        if(this.appService.cartObj.cart_service.length > 0 
          && this.appService.cartObj.cart_service[this.appService.cartObj.cart_service.length-1].isEnable == 1 // item đang hiệu lực
          && this.appService.cartObj.cart_service[this.appService.cartObj.cart_service.length-1].isActive == 0 // đang dừng
          ){
          isCheckSamePrice = false
        }
        // END xử lý xem cho check trùng giá dịch vụ hay không
        this.addServiceToCartDesk(this.appService.deskIndex, result.data, 1, result.timer, isCheckSamePrice)// mặc định 1 quantity
      }
    });

  }
  addServiceToCartDesk(deskIndex, service_product, quantity, timer, isCheckSamePrice) {
    console.log("quantity", quantity);
    if (quantity == null || isNaN(quantity) || quantity <= 0) {
      this.toastr.error("", "Số lượng không hợp lệ");
      return
    }

    let cartService = new CartService();
    cartService.id = service_product.id;
    cartService.name = service_product.name;
    cartService.price = service_product.price;
    //START Xử lý timer đầu vào
    try {
      console.log(timer);
      var timer_temp = new Date();
      timer_temp.setHours(timer.hh,timer.mm);
    } catch (error) {
      timer_temp = new Date();
    }
    cartService.serviceStartTime = timer_temp; // phút
    cartService.serviceStartTimeNumberType = timer_temp.getTime(); // phút
    //END Xử lý timer đầu vào
    
    cartService.quantity = 0; // phút
    console.log("cart", cartService);

    if (typeof this.appService.deskList[deskIndex].cart_service === 'undefined') {
      alert("Đã có lỗi vui lòng đăng nhập lại");
    }
    if (this.appService.deskList[deskIndex].cart_service.length > 0) {
      if (isCheckSamePrice && this.utilityService.checkToAddCartService(this.appService.deskList[deskIndex].cart_service, cartService)) {
        // Chỉ kiểm tra gói giá cuối cùng
        this.toastr.warning("", "Giá dịch vụ không có gì thay đổi");
        return;
      }
      else {
        this.addCart_servicetoCart(cartService);
        // this.appService.deskList[deskIndex].cart_service.push(cartService);
      }
    } else {
      this.addCart_servicetoCart(cartService);
      // this.appService.deskList[deskIndex].cart_service.push(cartService);
    }


    this.appService.deskList[deskIndex].total_quantity = this.utilityService.toFixedIfNecessary((this.appService.deskList[deskIndex].total_quantity + cartService.quantity));
    // this.appService.deskList[deskIndex].price_totol = this.utilityService.toFixedIfNecessary((this.appService.deskList[deskIndex].price_totol + (cartService.quantity * cartService.price)));
    this.appService.deskList[deskIndex].customer_pay = this.appService.deskList[deskIndex].price_totol;

    localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
    // product.temp_quantity = 1; // reset to default

    this.toastr.success("", "Thêm vào hóa đơn thành công");
    this.appService.updateDeskRevenue();
  }
  addCart_servicetoCart(cartService) {
    //kết thúc giờ và của giói giá trước khi thêm gói giá mới.
    let length_temp = this.appService.deskList[this.appService.deskIndex].cart_service.length;
    if (length_temp > 0) {
      let time_temp = new Date();
      this.appService.deskList[this.appService.deskIndex].cart_service[length_temp - 1].serviceEndTimeNumberType = time_temp.getTime();
      this.appService.deskList[this.appService.deskIndex].cart_service[length_temp - 1].serviceEndTime = time_temp;
      this.appService.deskList[this.appService.deskIndex].cart_service[length_temp - 1].isEnable = 0;
      this.appService.deskList[this.appService.deskIndex].cart_service[length_temp - 1].isActive = 0;
    }
    // thêm mới gói giá
    this.appService.deskList[this.appService.deskIndex].cart_service.push(cartService);
  }
}


