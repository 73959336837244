<div class="d-flex justify-content-between align-content-center mb-2 mt-2">
    <div>
        <span class="qbs-title mr-5">Thêm nhanh nhiều sản phẩm</span>
        
        <div class="btn-group">
            <div class="btn btn-outline-primary" [ngClass]="{
            'status-active': item.code == product_type
            }" (click)="product_types_change(item.code)" *ngFor="let item of appService.product_types; let i = index">
                {{item.value}}
                <img class="icon-select" style="width: 15px;" [src]="item.img" alt="image" />
            </div>
        </div>

        <!-- <mat-form-field style="width: 200px;" appearance="fill">
            <mat-label>Loại hàng hóa</mat-label>
            <mat-select [(value)]="product_type" (selectionChange)="product_types_change($event.value)">
                <mat-option *ngFor="let item of appService.product_types" [value]="item.code">
                    {{item.value}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>

    <div>
        <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon mr-2" (click)="insertProducts()">
            <span class="btn-label"><span class="material-icons">
                done
            </span></span>Thêm mới
        </button>
        <button class="btn btn-icon-only " (click)="onCancelClick()">
            <span class="material-icons">
                close
                </span>
        </button>
    </div>
</div>
<div class="qbs-scrollbar">
    <table class="table table-head-purple table-hover" id="table-add-product">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 20px; text-align: center;">#</th>
                <th>Code / Mã vạch <span class="text-danger">*</span> 3 ký tự trở lên</th>
                <th>Tên <span class="text-danger">*</span></th>
                <th>Giá lẻ</th>
                <th>Giá buôn</th>
                <th>Số lượng</th>
                <th>Đơn vị</th>
                <th>Quy cách</th>
                <th>Đơn vị 2</th>
                <th>Danh mục</th>
                <!-- <th>Thương hiệu</th> -->
                <th class="no-sort"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of products; let i = index" [ngClass]="{'tr-hide':i== products.length-1}">
                <td style=" text-align: center; padding: 0.5rem;">
                    {{i+1}}
                </td>
                <td>
                    <input type="text" *ngIf="product_type == 0" [(ngModel)]="item.code" name="code" (keyup.enter)="onEnter(i)" [id]="'code' + i" (focusout)="focusOutCheckCode(i,item.code)" maxlength="30" minlength="3" style="width: 140px;" (keydown.space)="$event.preventDefault();"
                        (ngModelChange)="item.code = $event.toUpperCase()" placeholder="Mã vạch" />
                    <input type="text" *ngIf="product_type == 1" [(ngModel)]="item.code" name="code" (keyup.enter)="onEnter(i)" [id]="'code' + i" (focusout)="focusOutCheckCode(i,item.code)" maxlength="5" minlength="1" style="width: 140px;" (keydown.space)="$event.preventDefault();"
                        (keypress)="keyPressNumbers($event)" placeholder="Nhập vã vạch" />
                </td>
                <td>
                    <input type="text" [(ngModel)]="item.name" name="name" placeholder="Tên" (keyup.enter)="onEnter(i)" maxlength="100">
                </td>
                <td>
                    <input type="text" [(ngModel)]="item.price" name="price" currencyMask class="input-number" (focus)="focusFunction($event)">
                </td>
                <td>
                    <input type="text" [(ngModel)]="item.price2" name="price2" currencyMask class="input-number" (focus)="focusFunction($event)">
                </td>
                <td>
                    <input type="text" [(ngModel)]="item.stock" name="stock" currencyMask class="input-number" (focus)="focusFunction($event)">
                </td>
                <td>
                    <select class="custom-select" [(ngModel)]="item.unit" name="unit" style="width: 60px">
                        <option [ngValue]="item2.code" *ngFor="let item2 of appService.units">{{item2.value}}
                        </option>
                    </select>
                </td>
                <td>
                    <input type="text" [(ngModel)]="item.packing" currencyMask class="input-number" (focus)="focusFunction($event)">
                </td>
                <td>
                    <select class="custom-select" [(ngModel)]="item.unit2" name="unit" style="width: 60px">
                        <option [ngValue]="item2.code" *ngFor="let item2 of appService.units">{{item2.value}}
                        </option>
                    </select>
                </td>
                <td>
                    <select class="custom-select" [(ngModel)]="item.category_code" name="category" style="width: 80px">
                        <option [ngValue]="item2.code" *ngFor="let item2 of categorys">{{item2.name}}</option>
                    </select>
                </td>
                <!-- <td>
                    <select class="custom-select" [(ngModel)]="item.category_code_brand" name="category_brand" style="width: 80px">
                        <option [ngValue]="item2.code" *ngFor="let item2 of categorys_brand">{{item2.name}}</option>
                    </select>
                </td> -->
                <td>
                    <button class="btn btn-sm btn-icon-only mr-1" (click)="onEnter(i)">
                        <span class="material-icons">add</span>
                    </button>
                    <button *ngIf="products.length > 1" class="btn btn-sm btn-icon-only" (click)="remove(i)">
                        <span class="material-icons">remove_circle</span>
                    </button>
                </td>
            </tr>

        </tbody>
    </table>
</div>