<div class="page-content fade-in-up">

    <h5>Chọn danh mục hàng hóa</h5>
    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">

            <div class=" p-2 mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm kiếm danh mục" [inputFormatter]="formatter">
            </div>
            <!-- <ng-template #rt let-r="result" let-t="term">
                <div class="row" (click)="onClick(r)" style="width: 450px;">
                    <div class="img-product-search ml-1" [style.background-image]="this.appService.showImageProduct(r['image_url'])"></div>
                    <div class="col"> #{{ r['code'] }}</div>
                    <div class="col">
                        <ngb-highlight [result]="r.name" [term]="t">
                        </ngb-highlight>
                    </div>
                    <div class="col">Kho: {{ r['stock'] == null ? 0: r['stock'] }}</div>
                    <div class="col">SP: {{ r['products'] == null ? 0: r['products'] }}</div>
                </div>
            </ng-template> -->

        </div>
        <div class="col d-flex justify-content-end align-items-center">
            <div class="pl-2">
                <select class="custom-select" style="width: 105px;" [(ngModel)]="sorter.sortStr" (change)="this.loadCategory();" name="sorter" id="sorter">
                    <option [ngValue]="item.code" *ngFor="let item of appService.sort_category">{{item.name}}</option>
                </select>
            </div>
            <div class="pl-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" (click)="popup_category_add()">
                    <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                </button>
            </div>

        </div>

    </div>
    <div class="add-category" *ngIf="isAdding">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category" (ngSubmit)="insertAndSelectCategory()">
            <div class="row">
                <div class="col">
                    <div style="display: none;" class="form-inline has-validation">
                        <label class="col-form-label">Mã</label>
                        <div class="">
                            <input [(ngModel)]="cate.code" class="form-control" minlength="3" maxlength="10" (focusout)="focusOutCheckCode()" [readonly]="'mts' === CodeType" name="code" type="text" (ngModelChange)="cate.code = $event.toUpperCase()" (keydown.space)="$event.preventDefault();">
                            <span class="help-block">
                                <div class="col-sm-10 d-flex align-items-center">
                                    <label class="radio radio-grey radio-primary radio-inline">
                                        <input type="radio" name="b" [checked]="'mts' === CodeType"
                                            (click)="codeTypeClick('mts');">
                                        <span class="input-span"></span>Mã tự sinh</label>
                            <label class="radio radio-grey radio-primary radio-inline">
                                        <input type="radio" name="b" [checked]="'tn' === CodeType"
                                            (click)="codeTypeClick('tn');">
                                        <span class="input-span"></span>tự nhập</label>
                        </div>
                        </span>
                    </div>
                </div>
                <div class="">
                    <input [(ngModel)]="cate.name" class="form-control" name="name" required maxlength="30" placeholder="Tên nhóm hàng" type="text">
                </div>
            </div>
            <div class="col-4 d-flex align-items-center">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" type="submit" [disabled]="isLoading || this.addForm.invalid">
                        <span class="btn-label"> <span class="material-icons"> done </span> </span>
                        Thêm và chọn
                    </button>
                <button class="btn btn-icon" (click)="isAdding = false">
                        <span class="btn-label"> <span class="material-icons"> close </span> </span>
                    </button>
            </div>
    </div>
    </form>
</div>
<div class="table-responsive">
    <div class="row mt-2 mb-2">
        <div class="select_category row align-items-center mr-1" *ngFor="let item of categorys" (click)="click_select(item)" [ngClass]="{'selected_category' : item.code == this.category_code_selected}">
            <span>
                    {{item.name}}
                </span>
            <span class="badge badge-default ml-1">{{item.products}}</span>
        </div>
    </div>
</div>

<div class="row align-items-center mb-2  paging-cover">
    <div class="col-4">
        <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
        <ul class="pagination p-1" style="margin-bottom: 0px;">
            <li>
                <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                    <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                </a>
            </li>
            <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                <a class="page-link" href="javascript:;">{{item.i}}</a>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                    <i class="ti ti-angle-right"></i>
                </a>
            </li>
        </ul>
    </div>
</div>
</div>


<div class="qbs-footer mt-3 d-flex justify-content-around">
    <button class="btn btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
        <span class="btn-label"> <span class="material-icons"> close </span> </span>
        Đóng
    </button>
</div>