import { DebtCustomerComponent } from './pages/debt-customer/debt-customer.component';
import { BrandComponent } from './pages/brand/brand.component';
import { PromotionAddComponent } from './pages/promotion/promotion-add/promotion-add.component';
import { CustomerCateComponent } from './pages/customer-cate/customer-cate.component';
import { PromotionComponent } from './pages/promotion/promotion.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { StockinCartComponent } from './pages/stockin/stockin-cart/stockin-cart.component';
import { StockinComponent } from './pages/stockin/stockin.component';
import { ReportComponent } from './pages/report/report.component';
import { CategoryComponent } from './pages/category/category.component';
import { ProductComponent } from './pages/product/product.component';
import { WarehouseComponent } from './pages/warehouse/warehouse.component';
import { SaleComponent } from './pages/sale/sale.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './/layouts/layout.component';

import { Dashboard4Component } from './pages/dashboard-4/dashboard-4.component';




import { TablesComponent } from './pages/tables/tables.component';

import { ChartjsComponent } from './pages/charts/chartjs/chartjs.component';
import { MorrisChartComponent } from './pages/charts/morris-chart/morris-chart.component';
import { PeityChartComponent } from './pages/charts/peity-chart/peity-chart.component';
import { SparklineChartComponent } from './pages/charts/sparkline-chart/sparkline-chart.component';



import { BlogListComponent } from './pages/blog/blog-list/blog-list.component';
import { ArticleComponent } from './pages/blog/article/article.component';


import { SearchComponent } from './pages/search/search.component';

import { LoginComponent } from './pages/login/login.component';
import { Login3Component } from './pages/login-3/login-3.component';
import { LockscreenComponent } from './pages/lockscreen/lockscreen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ProfileComponent } from './pages/profile/profile.component';
import {FormsModule} from "@angular/forms";
import { CustomerComponent } from './pages/customer/customer.component';
import { Report2Component } from './pages/report2/report2.component';
import { OrderComponent } from './pages/order/order.component';
import { SettingComponent } from './pages/setting/setting.component';
import { DebtComponent } from './pages/debt/debt.component';
import { ServicedeskComponent } from './pages/servicedesk/servicedesk.component';
import { ServicedeskpriceComponent } from './components/servicedeskprice/servicedeskprice.component';
import { UnitComponent } from './components/unit/unit.component';
import { ReportMonthComponent } from './pages/report-month/report-month.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { InventoryListComponent } from './pages/inventory-list/inventory-list.component';
import { BranchComponent } from './pages/branch/branch.component';
import { ReportProductComponent } from './pages/report-product/report-product.component';
import { ReportCustomerComponent } from './pages/report-customer/report-customer.component';
import { PromotionDetailComponent } from './pages/promotion/promotion-detail/promotion-detail.component';
import { ReportEmployeeComponent } from './pages/report-employee/report-employee.component';
import { HomeComponent } from './pages/home/home.component';
import { StampPrintComponent } from './pages/stamp-print/stamp-print.component';
import { SettingSystemComponent } from './components/setting-system/setting-system.component';
import { RegisterComponent } from './pages/register/register.component';
import { SupplierComponent } from './pages/supplier/supplier.component';
import { SupplierCateComponent } from './pages/supplier-cate/supplier-cate.component';
import { ShipOrderComponent } from './pages/shippings/ship-order/ship-order.component';
import { ShipControlComponent } from './pages/shippings/ship-control/ship-control.component';
import { ShipSettingComponent } from './pages/shippings/ship-setting/ship-setting.component';
import { Salev2Component } from './pages/salev2/salev2.component';

const routes: Routes = [
    {path: '', redirectTo: 'salev2', pathMatch: 'full'},
    {
        
        "path": "",
        "component": LayoutComponent,
        "children": [
        
            {
                path: "home",
                component: HomeComponent
            },
            {
                path: "sale",
                component: SaleComponent
            },
            {
                path: "customer",
                component: CustomerComponent
            },
            {
                path: "customer-cate",
                component: CustomerCateComponent
            },
            {
                path: "supplier",
                component: SupplierComponent
            },
            {
                path: "supplier-cate",
                component: SupplierCateComponent
            },
            {
                path: "warehouse",
                component: WarehouseComponent
            },
            {
                path: "product",
                component: ProductComponent
            },
            {
                path: "category",
                component: CategoryComponent
            },
            {
                path: "report",
                component: ReportComponent
            },
            {
                path: "report2",
                component: Report2Component
            },
            {
                path: "report-month",
                component: ReportMonthComponent
            },
            {
                path: "stockin",
                component: StockinComponent,
            },
            {
                path: "inventory",
                component: InventoryComponent,
            },
            {
                path: "inventory-list",
                component: InventoryListComponent,
            },
            {
                path: "stockincart",
                component: StockinCartComponent
            },
            {
                path: "membership",
                component: MembershipComponent
            },
            {
                path: "order",
                component: OrderComponent
            },
            {
                path: "profile",
                component: ProfileComponent
            },
            {
                path: "setting",
                component: SettingComponent
            },
            {
                path: "brand",
                component: BrandComponent
            },
            {
                path: "promotion",
                component: PromotionComponent
            },
            {
                path: "promotion-add",
                component: PromotionAddComponent
            },
            {
                path: "promotion-detail",
                component: PromotionDetailComponent
            },
            {
                path: "debt",
                component: DebtComponent
            },
            {
                path: "debt-customer",
                component: DebtCustomerComponent
            },
            {
                path: "servicedesk",
                component: ServicedeskComponent
            },
            {
                path: "deskprice",
                component: ServicedeskpriceComponent
            },
            {
                path: "unit",
                component: UnitComponent
            },
            {
                path: "branch",
                component: BranchComponent
            },
            {
                path: "report-product",
                component: ReportProductComponent
            },
            {
                path: "report-customer",
                component: ReportCustomerComponent
            },
            {
                path: "report-employee",
                component: ReportEmployeeComponent
            },
            {
                path: "stamp-print",
                component: StampPrintComponent
            },
            {
                path: "qbspossystem",
                component: SettingSystemComponent
            },
            {
                path: "ship-order",
                component: ShipOrderComponent
            },
            {
                path: "ship-control",
                component: ShipControlComponent
            },
            {
                path: "ship-setting",
                component: ShipSettingComponent
            },
            {
                path: "salev2",
                component: Salev2Component
            },

            {
                path: "dashboard_4",
                component: Dashboard4Component
            },
        
            {
                path: "tables/basic",
                component: TablesComponent
            },
            {
                path: "charts/chartjs",
                component: ChartjsComponent
            },
            {
                path: "charts/charts_morris",
                component: MorrisChartComponent
            },
            {
                path: "charts/charts_peity",
                component: PeityChartComponent
            },
            {
                path: "charts/charts_sparkline",
                component: SparklineChartComponent
            },

            {
                path: "blog/blog_list",
                component: BlogListComponent
            },
            {
                path: "blog/article",
                component: ArticleComponent
            },
                       
            {
                "path": "search",
                "component": SearchComponent
            },
          
        ]
    },
    {
        "path": "login",
        "component": Login3Component
    },
    {
        "path": "register",
        "component": RegisterComponent
    },
    {
        "path": "**",
        "redirectTo": "error_404",
        "pathMatch": "full"
    },
];

@NgModule({
  declarations: [
  
    Dashboard4Component,

    

    TablesComponent,
    ChartjsComponent,
    MorrisChartComponent,
    PeityChartComponent,
    SparklineChartComponent,
    BlogListComponent,
    ArticleComponent,
  
    SearchComponent,
  
    LoginComponent,
    LockscreenComponent,
    ProfileComponent,
  ],
  imports: [ RouterModule.forRoot(routes, { useHash: true }) ,FormsModule],
  exports: [ 
    RouterModule,
  ]
})

export class AppRoutingModule { }
