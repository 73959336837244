<div class="row mb-2 mt-2 justify-content-between">
    <div class="col p-2 ">
        <!-- <span class="mr-4 static-badge badge-primary"><i class="ti ti-receipt"></i></span> -->
        <div style="display: inline-block;">
            <div class="mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="typeahead-template" class="form-control" [(ngModel)]="code_filter" [ngbTypeahead]="search" type="text" placeholder="Tìm mã phiếu" [resultTemplate]="rt" [inputFormatter]="searchFormatter">
            </div>
            <ng-template #rt let-r="result" let-t="term">
                <div class="row" (click)="onClick(r)" style="width: 450px;">

                    <!-- <div class="col"> #{{ r['code'] }}</div> -->
                    <div class="col">
                        <ngb-highlight [result]="r.code" [term]="t">
                        </ngb-highlight>
                    </div>
                    <div class="col">SP: {{ r['product_quantity']}}</div>
                    <div class="col">SL: {{ r['quantity'] }}</div>
                    <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
                </div>
            </ng-template>
        </div>
        <div style="display: inline-block;">
            <div class="form-inline">
                <div class="form-group hidden">
                    <div class="input-group">
                        <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group" style="width: 140px;">
                        <input #dpFromDate class="form-control" placeholder="yyyy-mm-dd" name="dpFromDate" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary ti ti-calendar" (click)="datepicker.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
                <div class="form-group ml-2 mr-2">
                    Đến
                </div>
                <div class="form-group">
                    <div class="input-group" style="width: 140px;">
                        <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate" [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary ti ti-calendar" (click)="datepicker.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="d-flex justify-content-end align-items-center">
            <div class="p-2">
                <select class="custom-select" style="width: 150px;" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="this.getSalesTitleBydays();" name="sorter" id="sorter">
                    <option [ngValue]="item.code" *ngFor="let item of appService.sort_reports">{{item.name}}</option>
                </select>
            </div>
            <div class="p-2">
                <button class="btn btn-sm btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="goToAddNew()">
                    <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                </button>
                <!-- <button class="btn btn-sm btn-info btn-labeled btn-labeled-left btn-icon" data-toggle="modal"
            data-target="#add-customer-modal">
            <span class="btn-label"><i class="ti-download"></i></span>Xuất Excel
          </button> -->
            </div>
        </div>
    </div>
</div>
<div class="row align-items-center mb-2">
    <div class="col-4">
        <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
            {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
        <ul class="pagination p-1" style="margin-bottom: 0px;">
            <li>
                <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                    <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                </select>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                    <span aria-hidden="true">
                        <i class="ti ti-angle-left"></i>
                    </span>
                </a>
            </li>
            <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                <a class="page-link" href="javascript:;">{{item.i}}</a>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                    <i class="ti ti-angle-right"></i>
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="table-responsive">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr class="text-center">
                <th style="width: 50px;">#</th>
                <th>Code</th>
                <th>Ngày kiểm</th>
                <th>Người kiểm</th>
                <th>Mặt hàng</th>
                <th>Ghi chú</th>
                <!-- <th>Chênh lệch</th> -->
                <th class="no-sort">trạng thái</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of imports; let i = index" class="h-row-item" (click)="goToDetail(item)" class="text-center">
                <td>
                    <a>
                        <i class="ti ti-receipt"></i>
                        <span style="position: absolute;left: 29px;">{{i+1}} </span>
                    </a>
                </td>
                <td>
                    {{item.code}}
                </td>
                <td>
                    {{item.created_date | date: "H:mm dd/MM/yyyy":'UTC'}}
                </td>
                <td>{{item.user_name}}</td>
                <td>{{item.product_quantity}}</td>
                <td>{{item.note}}</td>
                <!-- <td> {{item.quantity}}</td> -->

                <td>
                    <span class="badge badge-success">Hoàn thành</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="row align-items-center mb-2  paging-cover">
    <div class="col-4">
        <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
            {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
        <ul class="pagination p-1" style="margin-bottom: 0px;">
            <li>
                <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                    <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                </select>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                    <span aria-hidden="true">
                        <i class="ti ti-angle-left"></i>
                    </span>
                </a>
            </li>
            <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                <a class="page-link" href="javascript:;">{{item.i}}</a>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                    <i class="ti ti-angle-right"></i>
                </a>
            </li>
        </ul>
    </div>
</div>



<div class="modal fade" id="sale-import-detail-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-sale-import-detail">
            <div class="modal-header p-3">
                <h5 class="modal-title">Phiếu kiểm hàng #{{saleItem.code}}</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-3">
                <div class="chat-list">
                    <div class="scroller">
                        <div class="">
                            <div class="row p-10">
                                <h4 class="col text-center">
                                    Người thực hiện: <strong> {{saleItem.user_name}}</strong>
                                </h4>
                            </div>
                            <div class="row p-10">
                                <div class="col text-right mr-1" style="font-style: italic;">
                                    {{saleItem.created_date | date: "H:mm dd/MM/yyyy" }}
                                </div>
                            </div>
                            <div class="row info-row">
                                <div class="col">
                                    Ghi chú:
                                </div>
                                <div class="col text-right">
                                    {{saleItem.note }}
                                </div>
                            </div>
                            <table style="width: 100%; margin-top: 20px;">
                                <tr class="font-weight-bold text-center">
                                    <td colspan="2">
                                        Hàng hóa
                                    </td>
                                    <td>Tồn kho</td>
                                    <td>Thực tế</td>
                                    <td>Lệch</td>
                                </tr>
                                <tr *ngFor="let item of cartDetail; let i = index" class="item-row">
                                    <td style="width: 25px; text-align: center;">
                                        <span class="lbl-stt">{{i+1}}</span>
                                    </td>
                                    <td style="width: 45%;">
                                        <div style="line-height: 16px;">
                                            {{item.product_name}} - <span style="color: gray;">{{item.price|
                                                number}}</span>
                                        </div>
                                    </td>
                                    <td style="text-align: center;">
                                        {{item.product_stock}}
                                    </td>
                                    <td style="text-align: center;">
                                        {{item.quantity}}
                                    </td>
                                    <td style="text-align: center; color: black;" [ngClass]="{'text-danger' : item.quantity - item.product_stock}">
                                        {{item.quantity - item.product_stock}}
                                    </td>
                                </tr>
                                <!-- <tr style="background: lightgray;line-height: 30px;">
                                    <td colspan="2" style="text-align: center;font-weight: bold;">Tổng</td>
                                    <td style="text-align: center;font-weight: bold;">{{saleItem.total_quantity}}</td>
                                    <td style="text-align: right; color: black; font-weight: bold;">
                                        {{saleItem.price_totol | qbscurrency}}</td>
                                </tr> -->
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button class="btn m-1 btn-outline-primary btn-icon-only btn-thick" (click)="print('')"><i
                        class="ti ti-printer"></i></button>
                <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
            </div>
            <!-- <div class="modal-footer justify-content-between bg-primary-50">
          <div class="row align-items-center">
            <div class="col text-center">
              <button class="btn btn-primary btn-rounded">Thêm mới</button>
            </div>
          </div>
        </div> -->
        </form>
    </div>
</div>

<div id="print-section-on-inventory" style="display: none;">
    <div style=" font-family: Tahoma; font-size: 14px;">
        <h3 style="text-align: center;">Phiêu khiểm hàng</h3>
        <div style="text-align: right"> {{saleItem.created_date | date: "H:mm dd/MM/yyyy" }}
        </div>
        <table style="font-size: 13px; width: 100%;">
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Nhân viên thực hiện: </td>
                <td style="text-align: right;">{{saleItem.user_name}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Ghi chú: </td>
                <td style="text-align: right;">{{saleItem.note }}</td>
            </tr>
        </table>

        <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
            <table style="font-size: 12px; width: 100%;">
                <tr style="text-align: right; font-weight: bold; ">
                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle; width: 25%;">Tồn kho</td>
                    <td style="vertical-align: middle; width: 25%;">Thực tế</td>
                    <td style="vertical-align: middle; width: 25%;">Lệch</td>
                </tr>
            </table>
            <table style="font-size: 12px; width: 100%;" *ngFor="let item of cartDetail; let i = index">
                <tr>
                    <td colspan="4" style="vertical-align: middle; border-top: 1px dashed lightgray;">{{i+1}} . {{item.product_name}} - <span style="color: gray;">{{item.price|
                        number}}</span></td>
                </tr>
                <tr style="text-align: right; ">
                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle; width: 25%;">{{item.product_stock}}</td>
                    <td style="vertical-align: middle; width: 25%;">{{item.quantity}}</td>
                    <td style="vertical-align: middle; width: 25%;">{{item.quantity - item.product_stock | qbscurrency}}</td>
                </tr>
            </table>


        </div>
        <div style="text-align: center;">Cảm ơn quý khách</div>
        <div style="text-align: center; margin-bottom: 10; font-size: 8;">GIẢI PHÁP SỐ QBS</div>
    </div>
</div>