<div>

    <div class="revenueEmployeeCard row d-flex justify-content-between align-items-center" *ngFor="let item of categories; let i = index" (click)="select_card(item.code)">
        <div>
            <span class="mr-1">
                {{i+1}}.
            </span>
            <span>
                {{item.name}}
            </span>
            <span class="text-info">
                 - {{item.phone}}
            </span>
            <div class="ml-3 mt-1">
                <span>
                    {{item.email}}
            </span>
                <span class="text-muted">
                - {{utilityService.getNameOfRoleUser(item.role)}}
            </span>
            </div>
        </div>

        <div class="row d-flex justify-content-between align-items-center">
            <div class="font-16 text-right" style="width: 160px;">
                {{item.invoices | qbscurrency}}
                <span class="material-icons font-16 ">
                    receipt_long
                </span>
            </div>

            <div class="font-16 text-right" style="width: 120px;">
                {{item.revenue | qbscurrency}}
                <img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg">
            </div>
        </div>
    </div>

</div>