import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-revenue-product-card-list',
  templateUrl: './revenue-product-card-list.component.html',
  styleUrls: ['./revenue-product-card-list.component.css']
})
export class RevenueProductCardListComponent implements OnInit {
  @Input("datas") categories: any[] = [];
  @Input("datasSum") datasSum: any;
  @Input("selected") selected: string = "";
  @Input("unitSelected") unitSelected: string;
  @Output("click2") clickEvent = new EventEmitter<any>();
 
  constructor() { 

  }

  ngOnInit(): void {

  }
  select_card(code) {
    // console.log(code);
    this.clickEvent.emit(code);
  }

}
