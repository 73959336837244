import { environment } from './../../environments/environment';

export class Product {
    code: string ="";
    code_parent: string ="";
    type: number = 0;
    name : string = "";
    name_child : string = "";
    price: number = 0;
    price2: number = 0;
    price_in: number;
    category_code ="DM";
    category_code_brand = "BRAND";
    image_url:string = "";
    description:string ="";
    unit ="";
    unit2 ="";
    price_unit2: number;
    price2_unit2: number;
    unit_default ="";
    packing : number = 1;
    stock : number = 0;
    color ="sa";
    deleted : number = 0;
    ship_weight : number = 0;
    ship_length : number = 0;
    ship_width : number = 0;
    ship_height : number = 0;
    constructor(unit) {
        this.category_code = environment.CATEGORY_CODE_PREFIX;
        this.unit = unit;
    }
}
