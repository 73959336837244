import { UtilityService } from './../../_services/utility.service';
import { AppService } from './../../_services/app.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit {
  @Input("data") data: any = [];
  @Input("cartDetail") cartDetail: any = [];
  dateTimeStr : string
  constructor(
    public appService: AppService,
    // public utilityService: UtilityService
    ) { 
    console.log(this.data);
    console.log(this.cartDetail);
    // this.dateTimeStr = this.utilityService.getFullDateTimeStrFromDate(new Date());
  }

  ngOnInit(): void {
  }

}
