import { newArray } from '@angular/compiler/src/util';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-stamp-printing',
  templateUrl: './stamp-printing.component.html',
  styleUrls: ['./stamp-printing.component.css']
})
export class StampPrintingComponent implements AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public cartObj: any
  ) { 

    cartObj.cart.forEach(element => {
      element.quantitys = newArray(element.quantity);
    });

    console.log('cartObj',cartObj);
  }
  ngAfterViewInit() {
    this.print();
  }

  print() {

    // // this.toastr.success("", "Máy in chưa được thiết lập");
    // let printContents, popupWin;
    // printContents = document.getElementById('print-section-stamp-component').innerHTML;

    // popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    // popupWin.document.open();
    // popupWin.document.write(`
    //   <html>
    //     <head>
    //       <title>In tem sản phẩm ---------</title>
    //       <style>
    //       //........Customized style.......
    //       </style>
    //     </head>
        
    //     <body onload="window.print();window.close() ">
    //       ${printContents}
     
    //     </body>
    //   </html>`
    // );
    // // onload="window.print();window.close()
    // popupWin.document.close();
  }
}
