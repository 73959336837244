import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ShopService } from 'src/app/_services/shop.service';
declare var $: any;

@Component({
  selector: 'app-setting-system',
  templateUrl: './setting-system.component.html',
  styleUrls: ['./setting-system.component.css']
})
export class SettingSystemComponent implements OnInit {
  data = [];
  item = {
    "zalo_access_token": '', "zalo_refresh_token": '',"modified_date":''
  };
  isLoading = false;
  constructor(
    public shopService: ShopService,
    private toastr: ToastrService,
  ) {
    this.loadData();
   }

  ngOnInit(): void {
  }

  loadData() {
      this.shopService.loadSystem().subscribe(
        async response => {
          console.log("-----shopService.loadSystem----");
          if (response) {
            this.data = response
          }
        },
        error => {
          console.error(error);
        })
  }
  popupUpdate(item) {
    this.item = item;
    $("#update-item-modal").modal('show');
  }
  updateItem() {
    this.isLoading = true;
 
    this.shopService.updateSystemInfo({ "zalo_access_token": this.item.zalo_access_token, "zalo_refresh_token": this.item.zalo_refresh_token }).subscribe(
      response => {
        this.isLoading = false;
        if (response.code == "ok" && response.affectedRows > 0) {
          this.toastr.success("Cập nhật thông tin thành công",);
          $("#update-item-modal").modal('hide');
          this.loadData();
        }
        else {
          this.toastr.error("", "Cập nhật thông tin không thành công");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      })
  }
  focusFunction(event) {
    event.target.select();
  }

}
