import { ProductService } from './../../_services/product.service';
import { CategoryService } from './../../_services/category.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/_services/app.service';
import { Label } from 'ng2-charts';
import { ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { UtilityService } from 'src/app/_services/utility.service';
import { SaleService } from 'src/app/_services/sale.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  category: any
  sumInfoCategorys : any;
  sumShopInfo ={
    total : 0,
    category_shop : 0,
    product_quantity_shop : 0,
    product_shop : 0,
  };
  //for top 5 product
  revenueProductList = [];
  //for top 5 customer
  revenueCustomerList = [];

  // for chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: function(value, context) {
          if(parseInt(value) >= 1000 || parseInt(value) <= -1000){
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '';
          } else {
            return  value + '' ;
          }
          // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          // return context.chart.data.labels[context.dataIndex]; // ten cot
        }
      }
    }
  };

  //for date picker
  dateStrStart: any = "";
  dateStrEnd: any = "";

  // cầu hình chart
  public barChartType: ChartType = 'horizontalBar'; // bar
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  // data chart category
  public barChartLabels: Label[] = [];
  public barChartData: ChartDataSets[] = [
    // { data: [], label: 'Series A' },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];
  // data chart top 5 sản phẩm
  public barChartTopProductLabels: Label[] = [];
  public barChartTopProductData: ChartDataSets[] = [];
  // data chart top 5 khác hàng
  public barChartTopCustomerLabels: Label[] = [];
  public barChartTopCustomerData: ChartDataSets[] = [];
  
  constructor(
    public categoryService: CategoryService,
    public utilityService: UtilityService,
    public productService: ProductService,
    public saleService: SaleService,
    public appService: AppService,
  ) { 
    this.dateStrStart = this.utilityService.getFullDateStrFromDate(new Date());
    this.dateStrEnd = this.dateStrStart;

    this.getTotalInfoShop();
    this.getInfoCategorys();
    this.loadData_topProduct();
    this.loadData_topCustomer();
  }

  ngOnInit() {
  }

  onClick_selectDate(dateObj) {
    this.dateStrStart = dateObj.dateStrStart;
    this.dateStrEnd = dateObj.dateStrEnd;

    this.loadData_topProduct();
    this.loadData_topCustomer();
  }

  async getTotalInfoShop() {

    let filter = {};
    this.productService.getTotalInfoShop(filter).subscribe(
      async response => {
  
        console.log(response);
        if (response) {
          this.sumShopInfo = response
        }
      },
      error => {
     
        console.error(error);
      })

  }
  async getInfoCategorys() {
    let pagingObj = {
      viewNumsObj: 10,
      pageObj: 1
    };
    let filter = {
      name: "",
      type: environment.cate_type
    }
    let sorter = {
      sortStr: this.appService.sort_category[5].code,
    }

    this.categoryService.loadCategory(filter, sorter , pagingObj).subscribe(
      async response => {
        if (response) {
          this.sumInfoCategorys = response.data;

          // chuẩn bị data cho chart
          let temp=[]
          let temp2=[]
          for (let i = 0; i < this.sumInfoCategorys.length; i++) {
          
            temp.push(this.sumInfoCategorys[i].stock_priceSum);
            temp2.push(this.sumInfoCategorys[i].name);
          }
          //load data chart
          this.barChartData = [{ data: temp, label: 'Tồn kho' }];
          this.barChartLabels = temp2;
          // tính toán percent
          this.utilityService.calculatePercent(this.sumInfoCategorys, 'stock_priceSum');
          console.log(this.revenueProductList);
          
        }
      },
      error => {
        console.error(error);
      })
  }
  // top 5 sản phẩm
  loadData_topProduct(){
    let filter = {
      fromDate: this.dateStrStart,
      toDate: this.dateStrEnd,
      fromTime: '',
      toTime: '',
      unit_report: this.appService.currentUser.unit_report, //customer and product không sử dụng
      type_report: "", //customer and product không sử dụng
      code: "", //customer code
      category_code : '',
      name : '', // product name or code ,
      user_id: ''
    }
    let pagingObj = {
      viewNumsObj: 10, // top 5
      pageObj: 1 // trang 1
    };
    let sorter = {
      sortStr: this.appService.sort_revenue_customer_reports[0].code,
    }
    this.saleService.getRevenueProduct(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.revenueProductList = response.data;

          let temp=[]
          let temp2=[]
          for (let i = 0; i < this.revenueProductList.length; i++) {
            temp.push(this.revenueProductList[i].revenue);
            temp2.push(this.revenueProductList[i].name);
          }
          //load data
          this.barChartTopProductData = [{ data: temp, label: 'Top 5 danh thu sản phẩm' }];
          this.barChartTopProductLabels = temp2;

          // tính toán percent
          this.utilityService.calculatePercent(this.revenueProductList, 'revenue');
          console.log(this.revenueProductList);
        }
      },
      error => {
        console.error(error);
      });
  }
  loadData_topCustomer() {
    let filter = {
      fromDate: this.dateStrStart,
      toDate: this.dateStrEnd,
      type_report: "customer", //customer and product
      code: "", //customer code
      category_code: '', //customer category
    }
    console.log("filter", filter);
    let pagingObj = {
      viewNumsObj: 10, // top 5
      pageObj: 1 // trang 1
    };
    let sorter = {
      sortStr: this.appService.sort_revenue_customer_reports[0].code,
    }
    this.saleService.getRevenueCustomer(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.revenueCustomerList = response.data;
          let temp=[]
          let temp2=[]
          for (let i = 0; i < this.revenueCustomerList.length; i++) {
            temp.push(this.revenueCustomerList[i].revenue);
            temp2.push(this.revenueCustomerList[i].name);
          }
          //load data
          this.barChartTopCustomerData = [{ data: temp, label: 'Top 5 danh thu khách hàng' }];
          this.barChartTopCustomerLabels = temp2;

          // tính toán percent
          this.utilityService.calculatePercent(this.revenueCustomerList, 'revenue');
          console.log(this.revenueCustomerList);
        }
      },
      error => {
        console.error(error);
      });
  }
}
