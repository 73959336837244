<table class="table table-head-purple table-hover" id="datatable">
    <thead class="thead-default thead-lg">
        <tr>
            <th class="text-center" rowspan="2">#</th>
            <th rowspan="2">Sản phẩm</th>
            <th class="text-center" colspan="2">Dư đầu kỳ</th>
            <th class="text-center title-color-2" colspan="5">Phát sinh trong kỳ</th>
            <th class="text-center  " colspan="2">Dư cuối kỳ</th>
        </tr>
        <tr>
            <!-- # -->
            <!-- Sản phẩm -->
            <th class="text-right">Số lượng</th>
            <th class="text-right">Thành tiền<img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg"></th>

            <th class="text-right  title-color-2">SL nhập</th>
            <th class="text-right  title-color-2">PS Nợ<img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg"></th>
            <th class="text-right  title-color-2">SL chỉnh</th>
            <th class="text-right  title-color-2">SL xuất</th>
            <th class="text-right  title-color-2">PS Có<img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg"></th>

            <th class="text-right ">Số lượng</th>
            <th class="text-right ">Thành tiền<img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="datasSum != undefined" class="font-weight-bold" style="background-color: mistyrose;">
            <td colspan="2" class="text-center">
                Tổng
            </td>

            <td class="text-right" [ngClass]="{'text-danger': (datasSum !== undefined && datasSum.rangeBefore_quantity_unit2 < 0)}">
                <div *ngIf="datasSum !== undefined">
                    <span *ngIf="unitSelected=='unit2'">
                        <span>{{datasSum.rangeBefore_quantity_unit2 | qbscurrency}} </span>
                    </span>
                    <span *ngIf="unitSelected=='unit'">
                        <span>{{datasSum.rangeBefore_quantity_unit | qbscurrency}} </span>
                    </span>
                </div>
            </td>
            <td class="text-right">
                <span *ngIf="unitSelected=='unit2'">
                {{datasSum.rangeBefore_revenue_unit2 | qbscurrency}}</span>
                <span *ngIf="unitSelected=='unit'">
                    {{datasSum.rangeBefore_revenue_unit | qbscurrency}}</span>
            </td>

            <td class="text-right">
                <!-- <span class="text-muted font-13">{{item. unit2}}</span>  -->
                <span *ngIf="unitSelected=='unit2'">{{datasSum.rangeIn_quantityIn_unit2 | qbscurrency}}</span>
                <span *ngIf="unitSelected=='unit'">{{datasSum.rangeIn_quantityIn_unit | qbscurrency}}</span>
            </td>
            <td class="text-right">
                {{datasSum != undefined && datasSum.rangeIn_revenueIn | qbscurrency}}
            </td>
            <td class="text-right">
                <!-- <span class="text-muted font-13">{{item.unit2}}</span> -->
                <span *ngIf="unitSelected=='unit2'">{{datasSum.rangeIn_quantityInventory_unit2 | qbscurrency}}</span>
                <span *ngIf="unitSelected=='unit'">{{datasSum.rangeIn_quantityInventory_unit | qbscurrency}}</span>
            </td>
            <td class="text-right">
                <!-- <span class="text-muted font-13">{{item.unit2}}</span>  -->
                <span *ngIf="unitSelected=='unit2'">{{datasSum.rangeIn_quantityOut_unit2 | qbscurrency}}</span>
                <span *ngIf="unitSelected=='unit'">{{datasSum.rangeIn_quantityOut_unit | qbscurrency}}</span>
            </td>
            <td class="text-right">
                {{datasSum != undefined && datasSum.rangeIn_revenueOut | qbscurrency}}
            </td>

            <td class="text-right">
                <span *ngIf="unitSelected=='unit2'">
                {{(datasSum.rangeBefore_quantity_unit2 + datasSum.rangeIn_quantityIn_unit2 + datasSum.rangeIn_quantityInventory_unit2 - datasSum.rangeIn_quantityOut_unit2) | qbscurrency}}
                </span>
                <span *ngIf="unitSelected=='unit'">
                {{(datasSum.rangeBefore_quantity_unit + datasSum.rangeIn_quantityIn_unit + datasSum.rangeIn_quantityInventory_unit - datasSum.rangeIn_quantityOut_unit) | qbscurrency}}
                </span>
            </td>
            <td class="text-right">
                <div *ngIf="datasSum != undefined">
                    {{utilityService.stringToNumber(datasSum.rangeBefore_revenue_unit2) + utilityService.stringToNumber(datasSum.rangeIn_revenueIn) + utilityService.stringToNumber(datasSum.rangeIn_revenueInventory) - utilityService.stringToNumber(datasSum.rangeIn_revenueOut)
                    | qbscurrency}}
                </div>
                <!-- {{(item.rangeBefore_revenue_unit2 + item.rangeIn_revenueIn + utilityService.stringToNumber(item.rangeIn_revenueInventory) - item.rangeIn_revenueOut) | qbscurrency}} -->

            </td>
        </tr>
        <tr *ngFor="let item of categories; let i = index" (click)="select_card(item)">
            <td class="text-center">
                {{i+1}}.
            </td>
            <td>
                {{item.name}}
                <span class="text-muted font-14">
                    - {{item.code}}
                </span>
            </td>

            <td class="text-right" [ngClass]="{'text-danger': (item.rangeBefore_quantity_unit2 < 0)}">
                <span *ngIf="unitSelected=='unit2'" class="d-flex justify-content-between">
                    <span class="">{{item.unit2}}</span>
                <span>{{item.rangeBefore_quantity_unit2 | qbscurrency}} </span>
                </span>
                <span *ngIf="unitSelected=='unit'" class="d-flex justify-content-between">
                    <span class="">{{item.unit}}</span>
                <span>{{item.rangeBefore_quantity_unit | qbscurrency}} </span>
                </span>
            </td>
            <td class="text-right">
                <span *ngIf="unitSelected=='unit2'">
                {{item.rangeBefore_revenue_unit2 | qbscurrency}}</span>
                <span *ngIf="unitSelected=='unit'">
                    {{item.rangeBefore_revenue_unit | qbscurrency}}</span>
            </td>

            <td class="text-right">
                <!-- <span class="text-muted font-13">{{item. unit2}}</span>  -->
                <span *ngIf="unitSelected=='unit2'">{{item.rangeIn_quantityIn_unit2 | qbscurrency}}</span>
                <span *ngIf="unitSelected=='unit'">{{item.rangeIn_quantityIn_unit | qbscurrency}}</span>
            </td>
            <td class="text-right">
                {{item.rangeIn_revenueIn | qbscurrency}}
            </td>
            <td class="text-right">
                <!-- <span class="text-muted font-13">{{item.unit2}}</span> -->
                <span *ngIf="unitSelected=='unit2'">{{item.rangeIn_quantityInventory_unit2 | qbscurrency}}</span>
                <span *ngIf="unitSelected=='unit'">{{item.rangeIn_quantityInventory_unit | qbscurrency}}</span>
            </td>
            <td class="text-right">
                <!-- <span class="text-muted font-13">{{item.unit2}}</span>  -->
                <span *ngIf="unitSelected=='unit2'">{{item.rangeIn_quantityOut_unit2 | qbscurrency}}</span>
                <span *ngIf="unitSelected=='unit'">{{item.rangeIn_quantityOut_unit | qbscurrency}}</span>
            </td>
            <td class="text-right">
                {{item.rangeIn_revenueOut | qbscurrency}}
            </td>

            <td class="text-right">
                <span *ngIf="unitSelected=='unit2'">
                {{(item.rangeBefore_quantity_unit2 + item.rangeIn_quantityIn_unit2 + item.rangeIn_quantityInventory_unit2 - item.rangeIn_quantityOut_unit2) | qbscurrency}}
                </span>
                <span *ngIf="unitSelected=='unit'">
                {{(item.rangeBefore_quantity_unit + item.rangeIn_quantityIn_unit + item.rangeIn_quantityInventory_unit - item.rangeIn_quantityOut_unit) | qbscurrency}}
                </span>
            </td>
            <td class="text-right">
                {{utilityService.stringToNumber(item.rangeBefore_revenue_unit2) + utilityService.stringToNumber(item.rangeIn_revenueIn) + utilityService.stringToNumber(item.rangeIn_revenueInventory) - utilityService.stringToNumber(item.rangeIn_revenueOut) | qbscurrency}}
                <!-- {{(item.rangeBefore_revenue_unit2 + item.rangeIn_revenueIn + utilityService.stringToNumber(item.rangeIn_revenueInventory) - item.rangeIn_revenueOut) | qbscurrency}} -->

            </td>
        </tr>
    </tbody>
</table>