<div class="row mb-2 mt-2 justify-content-between">
    <div class="col">
        <div class="">
            <select class="custom-select" [(ngModel)]="userNvbhSelected" (ngModelChange)="userSelected_change()">
        <option value="">Nhân viên : All</option>
        <option [ngValue]="item.id" *ngFor="let item of userNvbhList">{{item.name}}</option>
      </select>
        </div>
    </div>



    <div class="col pr-0 d-flex justify-content-end">
        <div class="pr-2">
            <select class="custom-select" [(ngModel)]="day_num" (ngModelChange)="day_num_change()">
        <option [ngValue]="item.code" *ngFor="let item of appService.day_nums">{{item.name}}</option>
      </select>
        </div>
        <div class="pr-2">
            <select class="custom-select" [(ngModel)]="sale_status" (ngModelChange)="sale_status_change()">
        <option [ngValue]="item.code" *ngFor="let item of appService.sale_statuss">{{item.name}}</option>
      </select>
        </div>
        <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="picker.open()">
      <span class="btn-label "><span class="material-icons font-18">
            today
            </span></span>{{fromDateStr}}
    </button>

        <mat-form-field appearance="fill" style="display: none;">
            <!-- <mat-label>Choose a date</mat-label> -->
            <input matInput [matDatepicker]="picker" (dateChange)="dateChangeEvent($event)">
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>

    </div>

</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">
    <!-- <li class="nav-item">
      <a class="nav-link active" href="#tab-11-1" data-toggle="tab"><i class="ti ti-briefcase mr-2"></i>Active</a>
  </li> -->
    <li class="nav-item" *ngFor="let item of salesTitleByday_result; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.dateStr == this.selectedDateStr}" (click)="selectDate(item.dateStr)">
            <div style="font-size: large;">{{item.day_month}}</div>
            <div *ngIf="item.sum_price>0">{{item.sum_price | qbscurrency:0}}<img style="width: 15px;" src="./assets/img/icons/vnd.svg"></div>
            <div *ngIf="item.sum_price==0">---</div>
        </a>
    </li>

</ul>

<div class="tab-content">

</div>


<!-- <div style="padding: 10px;"></div> -->

<div style="margin: 20px 0px 12px 0px; font-size: 17px; font-weight: bold;">
    <span color="primary">Hóa đơn</span> : {{sales.length}}
    <!-- <ion-label color="primary">-</ion-label> : {{sales_sum}} VNĐ -->

    <!-- <div class="btn-group">
    <button class="btn btn-secondary">
        <span class="btn-icon"><i class="ti-heart"></i>Hóa đơn</span>
    </button>
    <span class="btn-label-out btn-label-out-right">{{sales.length}}</span>
</div> -->

</div>


<div class="d-flex flex-wrap mb-5">
    <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of sales; let i = index" (click)="popupSaleDetail(item)" [ngClass]="{'cart-done': item.status == 1,'cart-ordering': item.status == 0,'cart-cancel': item.status == 3,'cart-processing': item.status == 4}">
        <div class="rel" style="height: 50px;">
            <!-- <img class="card-img-top" src="./assets/img/invoice.svg" alt="image" style="height: 100px;" /> -->
            <div class="card-img-overlay">
                <div class="row align-items-center">
                    <div class="col">
                        <img style="width: 16px;" src="./assets/img/icons/vnd.svg">
                    </div>
                    <div class="col">
                        <span style=" font-weight: bold;">{{item.price_totol | qbscurrency:0}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <p style="max-height: 37px; overflow: hidden; text-align: center;">{{item.customer_name}}</p>
            <div class="d-flex align-items-center justify-content-between mt-2">
                <div class="text-center">
                    <div style="font-weight: bold;">
                        {{item.product_quantity}}</div>
                    <div class="text-muted font-11">Mặt hàng</div>
                </div>
                <div class="text-center">
                    <div style="font-weight: bold;">
                        {{item.quantity}}</div>
                    <div class="text-muted font-11">SL</div>
                </div>
            </div>
            <div class="text-right mt-2">
                <div>
                    <!-- <span *ngIf="item.status == 1" class="badge-primary badge-point"></span>
          <span *ngIf="item.status == 0" class="badge-warning badge-point"></span> -->

                    <span *ngIf="item.customer_pay == item.price_totol" class="badge-primary badge-point"></span>
                    <span *ngIf="item.customer_pay != item.price_totol" class="badge-danger badge-point"></span> {{item.created_date | date: "H:mm" }}<i class="ti ti-time" style="font-size: 13px;"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row align-items-center mb-2  paging-cover">
    <div class="col-4">
        <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
      {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
        <ul class="pagination p-1" style="margin-bottom: 0px;">
            <li>
                <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
          <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
        </select>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                    <span aria-hidden="true">
            <i class="ti ti-angle-left"></i>
          </span>
                </a>
            </li>
            <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                <a class="page-link" href="javascript:;">{{item.i}}</a>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                    <i class="ti ti-angle-right"></i>
                </a>
            </li>
        </ul>
    </div>
</div>



<div class="modal fade" id="sale-detail-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-add-product">
            <div class="modal-header p-3">
                <h5 class="modal-title"> <b>Mã đơn #{{saleSelectedItem.code}}</b></h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-3">
                <div class="chat-list">
                    <div class="scroller">
                        <div class="">
                            <div class="row info-row">
                                <div class="col">
                                    Nhân viên:
                                </div>
                                <div class="col text-right">
                                    {{saleSelectedItem.user_name}}
                                </div>
                            </div>
                            <div class="row info-row" *ngIf="saleSelectedItem.status == 4">
                                <div class="col">
                                    Nhân viên GH:
                                </div>
                                <div class="col text-right">
                                    {{saleSelectedItem.processing_user_name}}
                                </div>
                            </div>
                            <div class="row info-row">
                                <div class="col">
                                    Khách hàng:
                                </div>
                                <div class="col text-right">
                                    {{saleSelectedItem.customer_name}}
                                </div>
                            </div>
                            <div class="row info-row">
                                <div class="col">
                                    Tổng đơn:
                                </div>
                                <div class="col text-right">
                                    {{saleSelectedItem.price_totol | qbscurrency}}
                                </div>
                            </div>
                            <div class="row info-row">
                                <div class="col">
                                    Chiết khấu:
                                </div>
                                <div class="col text-right">
                                    0
                                </div>
                            </div>
                            <div class="row info-row">
                                <div class="col">
                                    Khách trả:
                                </div>
                                <div class="col text-right">
                                    {{saleSelectedItem.customer_pay | qbscurrency}}
                                </div>
                            </div>

                            <div class="container">
                                <div class="row m-2  justify-content-between">
                                    <button class="btn btn-outline-primary btn-icon-only btn-sm"><i class="ti ti-printer"></i>
                  </button>
                                </div>
                            </div>

                            <div *ngIf=" (saleSelectedItem.service_details.length > 0)" style="padding-top: 10px">
                                <div *ngFor="let item of saleSelectedItem.service_details; let i = index" style="border-bottom: 1px dashed gray; padding: 10px 0px;">
                                    <div class="row">
                                        <div class="ml-3">
                                            <a>
                                                <img class="img-circle" src="./assets/img/icons/clock-spinner.png" alt="image" width="35px" />
                                            </a>
                                        </div>
                                        <div class="col">
                                            <div> Tiền giờ: {{item.price | qbscurrency}} x {{item.quantityLable}}</div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">
                                                        <span><i class="ti ti-control-play"></i></span>
                                                        <span>: {{item.serviceStartTime | date :'HH:mm dd/MM/yyyy' }}</span>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="font-15 mt-1 d-flex align-items-center" style="color: gray;">
                                                        <span><i class="ti ti-control-pause"></i></span>
                                                        <span>: {{item.serviceEndTime | date :'HH:mm dd/MM/yyyy' }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mr-3 text-right">
                                            {{item.amount | qbscurrency}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <table style="width: 100%; margin-top: 20px;">
                                <tr *ngFor="let item of cartDetail; let i = index" class="item-row">
                                    <td style="width: 25px; text-align: center;">
                                        <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                                        <span class="lbl-stt">{{i+1}}</span>
                                    </td>
                                    <td style="width: 55%;">
                                        <div style="line-height: 16px;">
                                            {{item.product_name}} - <span style="color: gray;">{{item.price| qbscurrency}}</span>
                                        </div>
                                    </td>
                                    <!-- <td style="text-align: right;"><span style="font-size: 11px;">{{item.product.price| qbscurrency}}</span> </td> -->
                                    <td style="text-align: center;">
                                        <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti-minus"></i></a> -->

                                        x {{item.quantity}}
                                        <!-- <a (click)="removeProduct(i,item)" class="text-primary"><i class=" btn-remove ti ti-plus"></i></a> -->
                                    </td>
                                    <td style="text-align: right; color: black;">
                                        {{item.quantity * item.price | qbscurrency:0}}</td>
                                </tr>
                                <tr style="background: lightgray;line-height: 30px;">
                                    <td colspan="2" style="text-align: center;font-weight: bold;">Tổng</td>
                                    <td style="text-align: center;font-weight: bold;">{{saleSelectedItem.quantity}}</td>
                                    <td style="text-align: right; color: black; font-weight: bold;">
                                        {{saleSelectedItem.price_totol | qbscurrency}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>