import { AppService } from './app.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CartObj } from '../model/cart-obj';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    // public appService: AppService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      console.log("ressssss",err);
      if (err.status === 401 ) {
        // auto logout if 401 response returned from api
        // this.userService.logout();
        // location.reload(true);
        // this.appService.currentUser = new User();
        localStorage.setItem(environment.currentUser, JSON.stringify(new User()));
        this.router.navigate(['/login', { id: 1990 }]);
    
        // this.appService.listCart = [new CartObj()];
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }))
  }
}
