
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';


@Injectable({
  providedIn: 'root'
})
export class IoService {

  constructor(
    public http: HttpClient,
    private appService: AppService
    ){

  }

  uploadImage(file, param) {
    const formData = new FormData();
    formData.append('uploadFile', file, file.name);
    formData.append('action', "UPLOAD_IMAGE");
    // formData.append('shop_code', this.appService.currentUser.shop_code);
    // formData.append('user_id', this.appService.currentUser.id.toString());
    formData.append('des', param.des);
    formData.append('name', param.name);
    return this.http.post<any>(this.appService.getApiEndpoint('io/uploadImg'), formData).map(data => { return data; });
  }
  
}




