import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';


@Injectable()
export class PromotionService {

  constructor(public http: HttpClient,
    private appService : AppService) {
    console.log('UserService constructor');
  }

  checkCode(filter) {
    return this.http.post<any>(this.appService.getApiEndpoint('promotion/checkCode'), filter,).map(data => { return data; });
  }
  insertPromotion(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('promotion'), data,).map(data => { return data; });
  }
  updatePromotion(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('promotion'), data,).map(data => { return data; });
  }
  loadPromotion(filter, sorter, pagingObj){
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('promotion/get'), data,).map(data => { return data; });
  }
  //
  
  // countAllCustomer(filter){
  //   filter.shop_code = this.appService.currentUser.shop_code;
  //   return this.http.post<any>(this.appService.getApiEndpoint('promotion.php?action=countAllCustomer'),filter,).map(data => { return data; });
  // }
  // update(data){
  //   delete data["modal"];
  //   data.shop_code = this.appService.currentUser.shop_code;
  //   return this.http.post<any>(this.appService.getApiEndpoint('promotion.php?action=UPDATE'),data,).map(data => { return data; });
  // }
  

}




