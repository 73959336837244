import { ProductService } from './../../_services/product.service';
import { Membership } from './../../model/membership';
import { UserService } from './../../_services/user.service';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { ScriptLoaderService } from './../../_services/script-loader.service';
import { environment } from './../../../environments/environment';
import { UtilityService } from './../../_services/utility.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { SaleService } from '../../_services/sale.service';
import { CartObj } from '../../model/cart-obj';
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ReturnsProductComponent } from 'src/app/components/returns-product/returns-product.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SaleDetailComponent } from 'src/app/components/sale-detail/sale-detail.component';
declare var $: any;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  isLoading = false;

  sales = [];

  day_num = environment.DAY_NUM_DEFAULT;
  salesTitleByday_result = [];
  sum_prices = 0;

  // for detail
  saleSelectedItem = new CartObj();
  cartDetail: any;
  //status
  sale_status = environment.SALE_STATUS_ALL;

  //member nvbh
  memberships = [];
  memberships_backup = [];
  membership = new Membership();
  nvbhSelected: any;

  // user selected
  userNvbhSelected = "";
  userNvbhList = [];

  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDateStruct | null;
  toDate: NgbDate | null;
  fromDateStr: string;
  selectedDateStr: string;
  dateListSelected: any;

  dateSelected : any

  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for search
  searching = false;
  code_filter = "";
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }
  filter = {
    code: "",
    name: "",
  }
  //
  cancel_reason = ""
  //

  constructor(
    public utilityService: UtilityService,
    public appService: AppService,
    public saleService: SaleService,
    private _script: ScriptLoaderService,
    private toastr: ToastrService,
    public userService: UserService,
    public productService: ProductService,
    private calendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    public formatter: NgbDateParserFormatter,
    private modalService: NgbModal,
    config: NgbModalConfig,
    public dialog: MatDialog,
  ) {

    config.backdrop = 'static';
    config.keyboard = false;

    this.fromDate = calendar.getToday();
    this.fromDateStr = this.utilityService.getFullDateStrFromNgbDate(this.fromDate);
    this.selectedDateStr = this.fromDateStr
    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }
  dateChangeEvent(event){// chon tu picker
    
    console.log(this.utilityService.getFullDateStrFromDate(event.value));
    // chỉ lấy ngày cuối trong khoảng thời gian
    this.fromDateStr = this.utilityService.getFullDateStrFromDate(event.value);
    this.selectedDateStr = this.fromDateStr;
    this.getSalesTitleBydays(); //list sale day
    this.loadSales(this.fromDateStr); // sale detail
  }
  selectDate(dateStr) { // chon tu danh sach title
    // console.log(dateStr);
    // this.fromDate = dateStr;
    // console.log(this.fromDate);
    this.selectedDateStr = dateStr;
    this.loadSales(this.selectedDateStr);
  }


  ngOnInit() {

    this.getSalesTitleBydays();
    this.loadMembership();
    this.loadMembershipVNBH();
  }

  ngAfterViewInit() {
    this.loadSales(this.selectedDateStr);
  }


  day_num_change() {
    this.getSalesTitleBydays();
  }
  sale_status_change() {
    this.getSalesTitleBydays();
    this.loadSales(this.selectedDateStr);
  }
  sale_status_click(item) {
    this.sale_status = item.code
    console.log(this.sale_status);
    this.getSalesTitleBydays();
    this.loadSales(this.selectedDateStr);
  }
  userSelected_change() {
    console.log(this.userNvbhSelected);
    this.getSalesTitleBydays();
    this.loadSales(this.selectedDateStr);
  }
  getSalesTitleBydays() {

    let param = {
      type: environment.SALES_TYPE,
      user_id: this.userNvbhSelected,
      day_num: this.day_num,
      status: this.sale_status,
      dateSelected: this.fromDateStr,
    }
    this.searching = true;
    this.saleService.getSalesTitleByBays(param).subscribe(
      async response => {
        this.searching = false;
        console.log('getSalesTitleBydays');
        console.log(response);
        if (response) {
          this.salesTitleByday_result = response;
          for (let i = 0; i < this.salesTitleByday_result.length; i++) {
            this.sum_prices = this.sum_prices + parseInt(this.salesTitleByday_result[i].sum_price);
          }
        }
      },
      error => {
        this.searching = false;
        console.error(error);
      });

  }
  getSalesTitleBydays_v2(day_num, toDateStr) {

    let param = {
      type: environment.SALES_TYPE,
      user_id: this.userNvbhSelected,
      day_num: day_num,
      status: this.sale_status,
      dateSelected: toDateStr,
    }
    this.searching = true;
    this.saleService.getSalesTitleByBays(param).subscribe(
      async response => {
        this.searching = false;
        console.log('getSalesTitleBydays');
        console.log(response);
        if (response) {
          this.salesTitleByday_result = response;
          for (let i = 0; i < this.salesTitleByday_result.length; i++) {
            this.sum_prices = this.sum_prices + parseInt(this.salesTitleByday_result[i].sum_price);
          }
        }
      },
      error => {
        this.searching = false;
        console.error(error);
      });

  }
  loadSales(dateSeleted) {

    let filter = {
      type: environment.SALES_TYPE,
      status: this.sale_status,
      day_num: 0, // số ngày bao cáo, 0 : tất cả
      fromDate: dateSeleted,
      toDate: dateSeleted,
      code: this.code_filter,
      user_id: this.userNvbhSelected,
    }
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.saleService.getSalesTitleBydays(filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.sales = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.sales.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;
        }
      },
      error => {
        console.error(error);
      });

  }
  loadSales_v2(fromDateStr, toDateStr) {

    let filter = {
      type: environment.SALES_TYPE,
      status: this.sale_status,
      day_num: 0, // số ngày bao cáo, 0 : tất cả
      fromDate: fromDateStr,
      toDate: toDateStr,
      code: this.code_filter,
      user_id: this.userNvbhSelected,
    }
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.saleService.getSalesTitleBydays(filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.sales = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.sales.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;
        }
      },
      error => {
        console.error(error);
      });

  }

  popupSaleDetail(item) {
        this.saleSelectedItem = item;
        // xử lý dữ liệu  service_details từ String để hiển thị
        try {
          this.saleSelectedItem.service_details = JSON.parse(this.saleSelectedItem.service_detail_str);
        } catch (error) {
          this.saleSelectedItem.service_details = [];
          console.error(error);
        }
    
        const dialogRef = this.dialog.open(SaleDetailComponent, {
          width: '60%',
          panelClass: 'full-screen-modal',
          data: this.saleSelectedItem,
          maxHeight: '98vh' //you can adjust the value as per your view
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log("callback:::: ", result);

          if(typeof result != "undefined"){
            if(result.code == "confirmDelete"){
              $("#confirmDelete-modal").modal('show');
            }else if(result.code == "returning_ok"){
              this.getSalesTitleBydays();
              this.loadSales(this.selectedDateStr);
              this.toastr.success("", "trả hàng thành công");
            }
          }
        });
  }


  doPayment() {
    console.log(this.saleSelectedItem);
    let data = {
      id: this.saleSelectedItem.id,
      status: environment.SALE_STATUS_DONE,
      note: "",
    };

    this.saleService.updateSaleStatus(data).subscribe(
      async response => {
        $("#sale-detail-modal").modal('hide');
        console.log(response);
        if (response.code == "ok" && response.affectedRows > 0) {
          this.getSalesTitleBydays();
          this.loadSales(this.selectedDateStr);
          this.toastr.success("", "Thanh toán thành công");
        }
      },
      error => {
        $("#sale-detail-modal").modal('hide');
        console.error(error);
      })
  }

  onClick_selectDate(dateObj) {
    console.log(dateObj);
    this.getSalesTitleBydays_v2(this.day_num,dateObj.dateStrEnd);
    this.loadSales_v2(dateObj.dateStrStart,dateObj.dateStrEnd);
  }
  // openDeleteSale(confirmDelete) {
  //   this.modalService.open(confirmDelete, { size: 'sm' });
  // }
  confirmDeleteSale() {
    if (this.cancel_reason.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập lý do hủy");
      return;
    }

    let data = {
      id: this.saleSelectedItem.id,
      status: environment.SALE_STATUS_DELETED,
      note: this.cancel_reason,
    };

    this.isLoading = true;
    this.saleService.updateSaleStatus(data).subscribe( // 3 là hủy đơn
      async response => {
        this.isLoading = false;
        if (response.code == "ok" && response.affectedRows > 0) {
          this.toastr.success("", "Hủy đơn thành công");
          this.getSalesTitleBydays();
          this.loadSales(this.selectedDateStr);
          // this.modalService.dismissAll();
          // $("#sale-detail-modal").modal('hide');
          $("#confirmDelete-modal").modal('hide');
          this.cancel_reason = "";
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }


  async loadMembership() {
    let filter = {
      role: "nvgh"
    };
    this.userService.loadMembership(filter).subscribe(
      async response => {
        if (response) {
          this.memberships = response;
          this.memberships_backup = response;
          if (this.memberships.length > 0) {
            this.nvbhSelected = this.memberships[0];
          }
          console.log(this.memberships);
        }
      },
      error => {
        console.error(error);
      })
  }
  async loadMembershipVNBH() {
    let filter = {
      role: "nvbh,admin"
    };
    this.userService.loadMembership(filter).subscribe(
      async response => {
        if (response) {
          this.userNvbhList = response;
        }
      },
      error => {
        console.error(error);
      })
  }

  popupSelectNvgh() {
    $("#select-nvgh-modal").modal('show');
  }

  getTypingSearchNvgh(ev: any) {
    // Reset items back to all of the items
    this.memberships = this.memberships_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 0) {
      this.memberships = this.memberships.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  selectCustomer(item) {
    console.log(item);
  }
  doShipping() {
    console.log(this.nvbhSelected);
    // status 4 Đang xử lý

    let data = {
      id: this.saleSelectedItem.id,
      status: environment.SALE_STATUS_TAKING,
      note: "",
      processing_user_id: this.nvbhSelected.id,
    };

    this.isLoading = true;
    this.saleService.updateSaleStatus(data).subscribe( // 3 là hủy đơn
      async response => {
        this.isLoading = false;
        if (response.code == "ok" && response.affectedRows > 0) {
          this.toastr.success("", "Giao NVGH thành công");
          this.getSalesTitleBydays();
          this.loadSales(this.selectedDateStr);
          $("#select-nvgh-modal").modal('hide');
          $("#sale-detail-modal").modal('hide');
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })


    // console.log(this.saleSelectedItem);
    // this.saleService.updateSaleStatusNVGH(this.saleSelectedItem.id, environment.SALE_STATUS_TAKING, this.nvbhSelected.id, this.nvbhSelected.name).subscribe(
    //   async response => {
    //     // this.loading.dismiss();
    //     console.log(response);
    //     if (response) {
    //       $("#select-nvgh-modal").modal('hide');
    //       $("#sale-detail-modal").modal('hide');
    //       this.getSalesTitleBydays();
    //       this.loadSales(this.fromDateStr);
    //       this.toastr.success("Giáo shipper thành công", "");
    //     } else {
    //       this.toastr.error("", "Có lỗi xảy ra");
    //     }
    //   },
    //   error => {
    //     // this.loading.dismiss();
    //     this.toastr.error("", "Có lỗi xảy ra");
    //     console.error(error);
    //   })
  }
  // for paging
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadSales(this.selectedDateStr); // sale detail
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadSales(this.selectedDateStr); // sale detail
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadSales(this.selectedDateStr); // sale detail
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadSales(this.selectedDateStr); // sale detail
    }
  };

  // print(status) {
  //   let type = 'In lại';
  //   if(status == 3){
  //     type = 'Đã hủy';
  //   }else if(status == 1){
  //     type = 'In lại';
  //   }else if(status == 4){
  //     type = 'Đang xử lý';
  //   }else if(status == 0){
  //     type = 'Đặt hàng';
  //   }
  //   // this.toastr.success("", "Máy in chưa được thiết lập");
  //   let lableContents, printContents, popupWin;
  //   printContents = document.getElementById('print-section-on-order').innerHTML;
  //   lableContents = type == "" ? '' : `<div style="position: absolute;top: 22px;left: 0px;font-size: 20px;border: 2px  dashed grey;padding: 5px 20px;">${type}</div>`;

  //   popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  //   popupWin.document.open();
  //   popupWin.document.write(`
  //     <html>
  //       <head>
  //         <title>In Hóa Đơn</title>
  //         <style>
  //         //........Customized style.......
  //         </style>
  //       </head>
        
  //       <body onload="window.print();window.close()">
  //         ${printContents}
  //         ${lableContents}
  //       </body>
  //     </html>`
  //   );
  //   popupWin.document.close();

  // }



  //for search 
  inputFormatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {

        this.code_filter = this.code_filter.trim();
        console.log(this.code_filter);
        this.getSalesTitleBydays();
      }),
      map(term => term === '' ? []
        : this.sales.filter(v => v.code.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => {

      }),
    )
  // datas = ["d","sd"];
  onClick(item) {
    console.log(item);
    this.code_filter = "";
    console.log("this.code_filter", this.code_filter);
    this.popupSaleDetail(item);
    // this.popupAddToCart(r);
  }

  onClick_selectCate(item) {
    console.log(item);
  }
}

