<div class="page-brand">
    <a routerLink="/">
        <span class="brand"><img style="width: 35px; padding-right: 10px;"
                src="./assets/img/icons/favicon.png" />{{appService.currentUser.shop_name2}}</span>
        <span class="brand-mini">QBS</span>
    </a>
</div>
<div class="flexbox flex-1">
    <!-- START TOP-LEFT TOOLBAR-->
    <ul class="nav navbar-toolbar">
        <li>
            <a class="nav-link sidebar-toggler js-sidebar-toggler" href="javascript:;">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </a>
        </li>
        <li class="desktop dropdown" *ngFor="let item of appService.listCart; let i = index">
            <button class="cart-cover btn-none" (click)="selectCart(i)" [ngClass]="{'cart-active': i== appService.cartIndex}">
                <span class="lable" >{{item.customer_name}}</span>
                <img style="width: 15px" src="./assets/img/icons/cart.svg" alt="image" />
                <span [ngClass]="{'badge-primary': i== appService.cartIndex, 'badge-default': i != appService.cartIndex}" class="badge badge-circle h-badge">{{item.cart.length}}</span>
                <span class="text-secondary h2-badge font-11">F{{i+1}}</span>
            </button>
        </li>
        <li class="desktop dropdown" *ngIf="appService.listCart.length < 6">
            <a (click)="addNewCart()" style="padding: 0px;">
                <i class="ti ti-plus" style="font-weight: bold; font-size: 17px;"></i>
            </a>
        </li>

    </ul>


    <!-- END TOP-LEFT TOOLBAR-->
    <!-- START TOP-RIGHT TOOLBAR-->
    <ul class="nav navbar-toolbar">




        <li style="display: flex;" class="mr-3">
            <div>
                <div class="font-13">{{appService.currentUser.shop_name}}</div>
                <div class="font-11 text-right">{{appService.currentUser.name}}</div>
            </div>
        </li>
        <li style="display: flex;" class="qbs-button1 mr-2">
            <span class="material-icons spinner-border">
                schedule
                </span>
            <div style="font-size: 20px; margin-left: 4px;">{{appService.currentTime | date: "HH:mm"}}</div>
        </li>

        <li style="display: flex;">
            <button class="text-muted mr-2" style="background: none;border: none;" data-trigger="focus" data-container="body" data-toggle="popover" data-placement="right" data-content='Hỗ trợ 0896.888.102' data-original-title="" title="">
                <span class="material-icons font-30"> help</span>
            </button>
        </li>
        <li style="display: flex;">

            <a *ngIf="!isFullScreen" (click)="openFullscreen()" class="mr-2">
                <span class="material-icons font-30"> zoom_out_map </span>
            </a>
            <a *ngIf="isFullScreen" (click)="closeFullscreen()" class="mr-2">
                <span class="material-icons font-30"> zoom_in_map</span>
            </a>

        </li>


        <!-- <li *ngIf="appService.currentUser.isLoggedIn == true" class="dropdown dropdown-user">
            <a class="nav-link dropdown-toggle link" data-toggle="dropdown">
             
                <div>
                    <div>{{appService.currentUser.shop_name}}</div>
                    <div class="font-13 text-right">{{appService.currentUser.name}}</div>
                </div>

                <img style="width: 40px;" src="./assets/img/icons/person.svg" alt="image" />
            </a>
            <div class="dropdown-menu dropdown-arrow dropdown-menu-right admin-dropdown-menu">
                <div class="dropdown-arrow"></div>
                <div class="dropdown-header justify-content-end">
                    <div>
                        <h5 class="font-strong text-white">{{appService.currentUser.name}}</h5>
                        <div>
                            <span class="admin-badge mr-3"><i class="ti-alarm-clock mr-2"></i>30m.</span>
                            <span class="admin-badge"><i class="ti ti-lock mr-2"></i>{{appService.currentUser.role}}</span>
                        </div>

                    </div>
                    <div class="pl-3">
                        <img style="width: 50px;" src="./assets/img/icons/person.svg" alt="image" />
                    </div>
                </div>
                <div class="admin-menu-features">
                    <a class="admin-features-item" routerLink="/sale">
                        <img style="width: 30px;" src="./assets/img/icons/add_cart.svg" alt="image" />
                        <span class="material-icons font-30">
                            account_circle
                            </span>
                        <div class="font-16">Bán hàng</div>
                    </a>
                    <a class="admin-features-item" routerLink="/order">
                        <img style="width: 30px;" src="./assets/img/icons/sale.svg" alt="image" />
                        <div class="font-16">Đơn hàng</div>
                    </a>
                    <a class="admin-features-item" routerLink="/report-product">
                        <img style="width: 30px;" src="./assets/img/icons/wholesale.svg" alt="image" />
                        <div class="font-16">Sản phẩm</div>
                    </a>
                    <a class="admin-features-item" routerLink="/report-customer">
                        <img style="width: 30px;" src="./assets/img/icons/person.svg" alt="image" />
                        <div class="font-16">Khách hàng</div>
                    </a>

                </div>
                <div class="admin-menu-features  justify-content-end">
                    <a class="admin-features-item" routerLink="/setting">
                        <span class="material-icons font-30">
                            account_circle
                            </span>
                        <div>Cá nhân</div>
                    </a>
                    <a class="admin-features-item" routerLink="/setting">
                        <span class="material-icons font-30">
                            settings
                            </span>
                        <div>Cài đặt</div>
                    </a>
                </div>
                <div class="admin-menu-content">
                    <div class="d-flex justify-content-end">
                        <a class="d-flex align-items-center" (click)="logout()"><i
                                class="ti ti-power-off ml-2 pr-1"></i>Đăng xuất</a>
                    </div>
                </div>
            </div>
        </li> -->
        <!-- <li>
            <a class="nav-link quick-sidebar-toggler">
                <span class="ti-align-right"></span>
            </a>
        </li> -->
    </ul>
    <!-- END TOP-RIGHT TOOLBAR-->
</div>



<div class="checkout-widget" style="overflow: auto;">
    <app-printer-section datas="appService.cartObj"></app-printer-section>
    <div class="tab-content">
        <!-- <app-sale-invoice></app-sale-invoice> -->
    </div>
</div>




<div class="modal fade" id="select-customer-modal">
    <div class="modal-dialog modal-xl " role="document">
        <form class="modal-content form-horizontal" id="select-customer-form">
            <div class="modal-header p-2">
                <h5 class="modal-title">Chọn khách hàng</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-2">

                <div class="row mb-2" *ngIf="!isAddingCustomer">
                    <div class="col-sm-8">
                        <div class="mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                            <span *ngIf="searching" class="input-icon input-icon-right font-16"><i
                                    class="ti ti-reload"></i></span>
                            <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i
                                    class="ti ti-search"></i></span>
                            <input id="cus-head-typeahead-template" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" name="search" placeholder="Tìm khách hàng" [resultTemplate]="rt" [inputFormatter]="formatter">
                        </div>
                        <ng-template #rt let-r="result" let-t="term">
                            <div class="row" (click)="onClick(r)" style="width: 450px;">
                                <div class="img-product-search ml-1" [style.background-image]="this.appService.showImageCustomer(r['image_url'])"></div>
                                <div class="col"> #{{ r['code'] }}</div>
                                <div class="col">
                                    <ngb-highlight [result]="r.name" [term]="t">
                                    </ngb-highlight>
                                </div>
                                <div class="col">Phone: {{ r['phone'] }}</div>
                                <div class="col">Nhóm: {{ r['category_code'] }}</div>
                                <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-sm-4 d-flex justify-content-end">
                        <button class="btn btn-primary btn-labeled btn-labeled-left " (click)="customer_add()">
                            <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                        </button>
                    </div>
                </div>
                <form (ngSubmit)="customer_save()" id="customer_add" *ngIf="isAddingCustomer">
                    <div class="row mb-2">
                        <div class="col-sm-8">
                            <div class="row">
                                <div class="col-sm-6">
                                    <input [(ngModel)]="customerObj.name" name="name" maxlength="50" class="form-control" type="text" placeholder="Tên">
                                </div>
                                <div class="col-sm-6">
                                    <input [(ngModel)]="customerObj.phone" name="phone" maxlength="10" min="10" class="form-control" type="text" placeholder="Số điện thoại">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm">
                                    <input [(ngModel)]="customerObj.address" name="address" maxlength="200" class="form-control" type="text" placeholder="Địa chỉ">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 d-flex">
                            <button class="btn btn-primary btn-labeled btn-labeled-left font-16" [disabled]="isLoading">
                                <span class="btn-label" [ngClass]="{'spinner-border': isLoading}"><i
                                        class="ti ti-save"></i>
                                </span>
                                 Lưu và chọn
                                </button>
                            <button class="btn btn-icon  ml-2 font-16" (click)="isAddingCustomer = false" style="display: inline-block;">
                                    <span class="btn-label" >
                                        <i class="ti ti-back-left"></i>
                                    </span>
                                </button>
                        </div>
                    </div>
                </form>

                <div class="row align-items-center d-flex justify-content-between mb-2">
                    <div class="row align-items-center ">
                        <div class="select_category row align-items-center mr-1" (click)="customer_cate_select('')" [ngClass]="{'selected_category' : '' == this.filter.category_code}">
                            <span>
                                Tất cả
                            </span>
                            <!-- <span class="badge badge-default ml-1">{{item.products}}</span> -->
                        </div>
                        <div class="select_category row align-items-center mr-1" *ngFor="let item of categorys" (click)="customer_cate_select(item.code)" [ngClass]="{'selected_category' : item.code == this.filter.category_code}">
                            <span>
                                {{item.name}}
                            </span>
                            <!-- <span class="badge badge-default ml-1">{{item.products}}</span> -->
                        </div>
                    </div>

                    <div class="row align-items-center mr-2 ">
                        <div>
                            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                                {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
                        </div>
                        <div class="d-flex justify-content-end">
                            <ul class="pagination p-1" style="margin-bottom: 0px;">
                                <li>
                                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">
                                            {{item.Value}}</option>
                                    </select>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                                        <span aria-hidden="true">
                                            <i class="ti ti-angle-left"></i>
                                        </span>
                                    </a>
                                </li>
                                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                                        <i class="ti ti-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div class="item-customer" (click)="selectCustomer('')" style="background: #ff760740;">
                    <div>Khách lẻ</div>
                    <div style="font-size: small;">Khách lẻ</div>
                    <div style="font-size: small;"><span class="text-muted">Mặc định</span></div>
                </div>
                <div *ngFor="let item of appService.customers; let i = index" class="item-customer" (click)="selectCustomer(item)">
                    <div>{{item.name}}</div>
                    <div style="font-size: small;">{{item.phone}}</div>
                    <div style="font-size: small;"><span class="text-muted">{{item.categoryName}}</span></div>
                </div>
            </div>
        </form>
    </div>
</div>