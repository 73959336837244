<div id="sidebar-collapse">

    <div style="background: white; font-size: 19px; padding: 5px; border-bottom: 1px solid #30435747;">
        <div class="d-flex align-items-center">
            <img style="width: 40px; padding-right: 10px;" src="./assets/img/icons/logo.png" />
            <div>
                <span class="">{{appService.currentUser.shop_name}}</span>
                <div class="font-14">{{appService.currentUser.name}}</div>
            </div>
        </div>
        <div>
            <select class="desktop custom-select mt-1" name="branchList" id="branchList" [(ngModel)]="appService.currentUser.currentBranch" (change)="category_branch_change($event)" style="width: 45%;font-size: 15px;
            line-height: 19px; border: none;
            padding: 0px 4px;">
        <option value="">Tất cả</option>
        <option [ngValue]="item.code" *ngFor="let item of appService.currentUser.branchList">{{item.name}}</option>
      </select>
        </div>
    </div>

    <ul class="side-menu" metis-menu>
        <!-- <li routerLinkActive="active">
      <a routerLink="/sale"><i class="sidebar-item-icon ti ti-shopping-cart"></i>
        <span class="nav-label">Bán lẻ</span>
      </a>
    </li> -->
        <li routerLinkActive="active">
            <a routerLink="/salev2">
                <span class="material-icons sidebar-item-icon">local_mall</span>
                <span class="nav-label">Bán hàng</span>
            </a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/servicedesk">
                <span class="material-icons sidebar-item-icon">circle_notifications</span>
                <span class="nav-label">Dịch vụ</span>
            </a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/order">
                <span class="material-icons sidebar-item-icon">receipt_long</span>
                <span class="nav-label">Hóa đơn</span>
            </a>
        </li>


        <li>
            <a href="#" class="has-arrow">
                <span class="material-icons sidebar-item-icon">qr_code</span>
                <span class="nav-label">Hàng hóa</span>
            </a>
            <ul class="nav-2-level">
                <li routerLinkActive="active"><a routerLink="/product"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Danh sách</span></a></li>
                <li routerLinkActive="active"><a routerLink="/category"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Nhóm hàng</span></a></li>
                <li routerLinkActive="active"><a routerLink="/brand"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Thương hiệu</span></a></li>
                <li routerLinkActive="active"><a routerLink="/unit"><i class="ti ti-minus pr-2"></i><span class="nav-label">Đơn
              vị</span></a></li>
            </ul>
        </li>

        <!-- <li routerLinkActive="active">
      <a routerLink="/promotion">
        <span class="material-icons sidebar-item-icon">local_mall</span>
        <span class="nav-label">Bán lẻ</span>
      </a>
    </li> -->

        <!-- <li routerLinkActive="active"><a routerLink="/promotion"><i class="sidebar-item-icon ti ti-gift"></i><span
          class="nav-label">Khuyến mãi</span></a>
        </li> -->

        <li routerLinkActive="active">
            <a routerLink="/promotion">
                <span class="material-icons sidebar-item-icon">redeem</span>
                <span class="nav-label">Khuyến mãi</span>
            </a>
        </li>

        <li>

            <!-- <a href="#" class="has-arrow"><i class="sidebar-item-icon ti ti-user"></i><span class="nav-label">Khách
          hàng</span></a> -->
            <a href="#" class="has-arrow">
                <span class="material-icons sidebar-item-icon">mood</span>
                <span class="nav-label">Khách
          hàng</span>
            </a>
            <ul class="nav-2-level">
                <li routerLinkActive="active"><a routerLink="/customer"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Danh sách</span></a></li>
                <li routerLinkActive="active"><a routerLink="/customer-cate"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Nhóm KH</span></a></li>
            </ul>
        </li>
        <li>
            <!-- <a href="#" class="has-arrow"><i class="sidebar-item-icon ti ti-user"></i><span class="nav-label">Khách
          hàng</span></a> -->
            <a href="#" class="has-arrow">
                <span class="material-icons sidebar-item-icon">business</span>
                <span class="nav-label">Nhà cung cấp</span>
            </a>
            <ul class="nav-2-level">
                <li routerLinkActive="active"><a routerLink="/supplier"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Danh sách</span></a></li>
                <li routerLinkActive="active"><a routerLink="/supplier-cate"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Nhóm NCC</span></a></li>
            </ul>
        </li>
        <li>
            <a href="#" class="has-arrow">
                <span class="material-icons sidebar-item-icon">category</span>
                <span class="nav-label">Kho hàng</span>
            </a>
            <ul class="nav-2-level">
                <li routerLinkActive="active">
                    <a routerLink="/stockincart"> <i class="ti ti-minus pr-2"></i><span class="nav-label">Nhập hàng</span></a>
                </li>
                <li routerLinkActive="active"><a routerLink="/stockin"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Phiếu nhập hàng</span></a></li>
                <li routerLinkActive="active"><a routerLink="/inventory"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Kiểm kê</span></a></li>
                <li routerLinkActive="active"><a routerLink="/inventory-list"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Phiếu kiểm kê</span></a></li>
            </ul>
        </li>
        <li>
            <a href="#" class="has-arrow">
                <span class="material-icons sidebar-item-icon">monetization_on</span>
                <span class="nav-label">Công nợ</span>
            </a>
            <ul class="nav-2-level">
                <!-- <li routerLinkActive="active">
                    <a routerLink="/debt"> <i class="ti ti-minus pr-2"></i><span class="nav-label">Nhân viên</span></a>
                </li> -->
                <li routerLinkActive="active"><a routerLink="/debt-customer"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Khách hàng</span></a>
                </li>
            </ul>
        </li>
        <!-- 
    <li routerLinkActive="active"><a routerLink="/membership"><i class="sidebar-item-icon ti ti-hand-open"></i><span
          class="nav-label">Nhân viên</span></a></li> -->

        <li routerLinkActive="active">
            <a routerLink="/membership">
                <span class="material-icons sidebar-item-icon">supervisor_account</span>
                <span class="nav-label">Nhân viên</span>
            </a>
        </li>

        <!-- <li routerLinkActive="active"><a routerLink="/warehouse"><i class="sidebar-item-icon ti ti-home"></i><span
          class="nav-label">Kho hàng</span></a>
    </li> -->
        <li>
            <!-- <a href="#" class="has-arrow"><i class="sidebar-item-icon ti ti-bar-chart"></i><span class="nav-label">Báo
          cáo</span></a> -->
            <a href="#" class="has-arrow">
                <span class="material-icons sidebar-item-icon">bar_chart</span>
                <span class="nav-label">Báo cáo</span>
            </a>
            <ul class="nav-2-level">
                <li routerLinkActive="active">
                    <a routerLink="/home"> <i class="ti ti-minus pr-2"></i><span class="nav-label">Tổng quát</span></a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/warehouse"> <i class="ti ti-minus pr-2"></i><span class="nav-label">Tồn kho</span></a>
                </li>
                <li routerLinkActive="active"><a routerLink="/report"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Doanh thu ngày</span></a></li>
                <li routerLinkActive="active"><a routerLink="/report-month"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Doanh thu tháng</span></a></li>
                <!-- <li routerLinkActive="active">
                    <a routerLink="/report2"> <i class="ti ti-minus pr-2"></i><span class="nav-label">Hóa đơn</span></a>
                </li> -->
                <li routerLinkActive="active">
                    <a routerLink="/report-customer"> <i class="ti ti-minus pr-2"></i><span class="nav-label">Doanh thu theo Khách
              hàng</span></a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/report-product"> <i class="ti ti-minus pr-2"></i><span class="nav-label">Doanh thu theo Sản
              phẩm</span></a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/report-employee"> <i class="ti ti-minus pr-2"></i><span class="nav-label">Doanh thu theo Nhân
              viên</span></a>
                </li>

            </ul>
        </li>
        <li>
            <!-- <a href="#" class="has-arrow"><i class="sidebar-item-icon ti ti-bar-chart"></i><span class="nav-label">Báo
          cáo</span></a> -->
            <a href="#" class="has-arrow">
                <span class="material-icons sidebar-item-icon">code</span>
                <span class="nav-label">Công cụ</span>
            </a>
            <ul class="nav-2-level">
                <li routerLinkActive="active">
                    <a routerLink="/stamp-print"> <span class="material-icons pr-2">qr_code</span><span class="nav-label">In
              tem</span></a>
                </li>
            </ul>
        </li>
        <li class="heading"><span class="nav-label">Cài đặt</span></li>
        <!-- <li routerLinkActive="active"><a routerLink="/setting"><i class="sidebar-item-icon ti ti-printer"></i><span class="nav-label">Máy in</span></a></li> -->
        <!-- <li routerLinkActive="active"><a routerLink="/setting"><i class="sidebar-item-icon ti ti-settings"></i><span
          class="nav-label">Cài đặt</span></a></li> -->

        <li routerLinkActive="active">
            <a routerLink="/setting">
                <span class="material-icons sidebar-item-icon">settings</span>
                <span class="nav-label">Cài đặt chung</span>
            </a>
        </li>
        <li>
            <a href="#" class="has-arrow">
                <span class="material-icons sidebar-item-icon">local_shipping</span>
                <span class="nav-label">Vận chuyển</span>
            </a>
            <ul class="nav-2-level">
                <!-- <li routerLinkActive="active"><a routerLink="/ship-order"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Danh sách đơn</span></a></li>
                <li routerLinkActive="active"><a routerLink="/ship-control"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Đơn vị vận chuyển</span></a></li> -->
                <li routerLinkActive="active"><a routerLink="/ship-setting"><i class="ti ti-minus pr-2"></i><span
              class="nav-label">Kết nối vận chuyển</span></a></li>
            </ul>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/branch">
                <span class="material-icons sidebar-item-icon">maps_home_work</span>
                <span class="nav-label">Chi nhánh</span>
            </a>
        </li>

    </ul>





    <!-- <ul class="side-menu" metis-menu> -->

    <div class="sidebar-footer">
        <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
            <a class="d-flex align-items-center" (click)="logout()">
                <i class="ti ti-power-off ml-2 pr-1"></i>Đăng xuất
            </a>
            <span class="mr-2 font-13" style="color: white;">{{version}}</span>
        </div>
        <!-- <a href="javascript:;"><i class="ti ti-announcement"></i></a>
    <a routerLink="/calendar"><i class="ti ti ti-calendar"></i></a>
    <a routerLink="/mailbox"><i class="ti ti-comments"></i></a> -->
        <!-- <a routerLink="/login"><i class="ti ti ti-power-off"></i></a> -->
    </div>
</div>