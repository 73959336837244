<table class="table table-head-purple table-hover">
    <thead class="thead-default thead-lg">
        <tr>
            <th class="text-center">#</th>
            <th>Sản phẩm</th>
            <!-- <th class="text-right">Tồn kho</th> -->
            <th class="text-right">Số hóa đơn</th>
            <th class="text-right">Số lượng</th>
            <th class="text-right">Doanh thu<img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg"></th>

        </tr>
    </thead>
    <tbody>

        <tr class="font-weight-bold row_sum">
            <td colspan="2" class="text-center">
                Tổng
            </td>

            <td class="text-right">
                <span *ngIf="datasSum.length > 0">{{datasSum[0].invoices | qbscurrency}}</span>
                <span class="material-icons font-16 " style="color: grey;">
                    receipt_long
                </span>
            </td>
            <td class="text-right">
                <span *ngIf="datasSum.length > 0 && unitSelected=='unit'">
                     {{datasSum[0].quantity_unit | qbscurrency}}
                </span>
                <span *ngIf="datasSum.length > 0 && unitSelected=='unit2'">
                   {{datasSum[0].quantity_unit2 | qbscurrency}}
                </span>
            </td>

            <td class="text-right">
                <span *ngIf="datasSum.length > 0"> {{datasSum[0].revenue | qbscurrency}}</span>
            </td>
        </tr>
        <tr *ngFor="let item of categories; let i = index" (click)="select_card(item)">
            <td class="text-center">
                {{i+1}}.
            </td>
            <td>
                {{item.name}}
                <span class="text-muted font-14">
                    - {{item.code}}
                </span>
            </td>

            <!-- <td class="text-right" [ngClass]="{'text-danger': (item.stock < 0)}">
                {{item.stock | qbscurrency}}
                <span class="material-icons font-16 " style="color: grey;">
                    battery_full
                </span>
            </td> -->
            <td class="text-right">
                {{item.invoices | qbscurrency}}
                <span class="material-icons font-16 " style="color: grey;">
                    receipt_long
                </span>
            </td>
            <td class="text-right">
                <span *ngIf="unitSelected=='unit'">
                    <span class="text-muted font-13">{{item.unit}}</span> {{item.quantity_unit | qbscurrency}}
                </span>
                <span *ngIf="unitSelected=='unit2'">
                    <span class="text-muted font-13">{{item.unit2}}</span> {{item.quantity_unit2 | qbscurrency}}
                </span>
            </td>

            <td class="text-right">
                {{item.revenue | qbscurrency}}
            </td>
        </tr>
    </tbody>
</table>
<!-- 
<div class="revenueCustomerCard row d-flex justify-content-between align-items-center"
    *ngFor="let item of categories; let i = index" (click)="select_card(item)">
    <div>
        <span class="mr-1">
            {{i+1}}.
        </span>
        <span>
            {{item.name}}
        </span>
        <span class="text-muted font-14">
            - {{item.code}}
        </span>
        <span>
            - {{item.price | qbscurrency}}
            <span class="material-icons font-16 " style="color: grey;">
                sell
            </span>
        </span>
        <div *ngIf="item.unit!=''" class="btn btn-info p-1" [ngClass]="{'btn-info': item.unit_default =='' || item.unit==item.unit_default}" style="line-height: 10px;">{{item.unit}}</div>
        <div *ngIf="item.unit2!=''" class="btn btn-secondary p-1" [ngClass]="{'btn-info': item.unit2==item.unit_default}" style="line-height: 10px;">{{item.unit2}}</div>
    </div>

    <div class="ml-auto font-16 text-right" style="width: 100px;" [ngClass]="{'text-danger': (item.stock < 0)}">
        {{item.stock | qbscurrency}}
        <span class="material-icons font-16 " style="color: grey;">
            battery_full
        </span>
    </div>
    <div class="row d-flex justify-content-between align-items-center">
        <div class="font-16 text-right" style="width: 100px;">
            {{item.quantity_unit | qbscurrency}}
            <span class="material-icons font-16 " style="color: grey;">
                local_offer
            </span>
        </div>
        <div class="font-16 text-right" style="width: 100px;">
            {{item.quantity_unit2 | qbscurrency}}
            <span class="material-icons font-16 " style="color: grey;">
                local_offer
            </span>
        </div>

        <div class="font-16 text-right" style="width: 140px;">
            {{item.revenue | qbscurrency}}
            <img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg">
        </div>
    </div>
</div> -->