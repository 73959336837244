
import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/_services/app.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-printer-section',
  templateUrl: './printer-section.component.html',
  styleUrls: ['./printer-section.component.css']
})
export class PrinterSectionComponent implements OnInit {

  @Input() data: any;
  @Input("datas") datas: any = [];
  dateTimeStr : string
  constructor(
    public appService: AppService,
    public utilityService: UtilityService
    ) { 
    console.log(this.datas);
    console.log(this.appService.printSizeSelected);
    this.dateTimeStr = this.utilityService.getFullDateTimeStrFromDate(new Date());
  }

  ngOnInit(): void {
  }

}