import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from 'src/app/model/customer';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { CustomerService } from 'src/app/_services/customer.service';
import { environment } from 'src/environments/environment';
import { EmployeePickerComponent } from '../employee-picker/employee-picker.component';

@Component({
  selector: 'app-customer-picker',
  templateUrl: './customer-picker.component.html',
  styleUrls: ['./customer-picker.component.css']
})
export class CustomerPickerComponent implements OnInit {


  //for add customer
  isAddingCustomer = true;
  isLoading = false;
  customerObj = new Customer(environment.customer_type);

  constructor(
    public appService: AppService,
    public customerService: CustomerService,
    public categoryService: CategoryService,
    public dialogRef: MatDialogRef<CustomerPickerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if(Number.isNaN(Number(data))){
      this.customerObj.name = data;
    }else
      this.customerObj.phone = data;
  }

  ngOnInit(): void {
  }


  customer_save() {

    console.log(this.customerObj);
    if (this.customerObj.name.trim() == "") {
      return false;
    } else if (this.customerObj.phone == "") {
      return false;
    }

    if (true) {
      this.isLoading = true;
      this.customerService.countAllCustomer(environment.customer_type).subscribe(
        response => {
          let customer_code = environment.CUSTOMER_CODE_PREFIX + (parseInt(response.CountAllCustomer) + 1).toString();
          this.customerObj.code = customer_code;
          this.customerService.insertCustomer(this.customerObj).subscribe(
            async response => {
              this.isLoading = false;
              console.log(response);
              if (response) {
                // đóng popup và trả về kết quả
                if (response.code == "ok") {
                  this.dialogRef.close(this.customerObj);
                }
              }
            },
            error => {
              this.isLoading = false;
              console.error(error);
            })
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
    }
  }

 
  
}
