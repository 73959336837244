import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppService } from 'src/app/_services/app.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-working-shift-picker',
  templateUrl: './working-shift-picker.component.html',
  styleUrls: ['./working-shift-picker.component.css']
})
export class WorkingShiftPickerComponent implements OnInit {

  @Output("click2") clickEvent: EventEmitter<any> = new EventEmitter();

  // param time

  name: string = "Tất cả";
  timeStrStart: string = "";
  timeStrEnd: string = "";
  itemSelected : any;
  constructor(
    public utilityService: UtilityService,
    public appService: AppService,
  ) {
    // itemSelected = n
    // load datas
    if(typeof(this.appService.currentUser.workingShifts) === 'undefined' || this.appService.currentUser.workingShifts == ''){
      this.appService.currentUser.workingShifts = '[]';
    }
    this.appService.workingShiftList = JSON.parse(this.appService.currentUser.workingShifts);
  }

  ngOnInit(): void {
  }
  // time search
  selectDateType(item) {
    this.itemSelected = item;
    if(item == null){
      this.name = "Tất cả";
      this.timeStrStart  = "";
      this.timeStrEnd = "";
    }else{
      this.name = item.name;
      this.timeStrStart  = item.timeStart;
      this.timeStrEnd = item.timeEnd;
    }
      
    // trả về
    this.clickEvent.emit({ timeStrStart: this.timeStrStart, timeStrEnd: this.timeStrEnd});
  }


}
