<div class="row mb-2 mt-2 justify-content-between">
    <div class="col">
        <div class="flexbox-b">
            <span class="mr-4 static-badge badge-primary"><span class="material-icons">redeem</span></span>
            <div>
                <div style="font-size: 20px;">Tạo Chương Trình Khuyến Mãi</div>
            </div>
        </div>

    </div>

</div>

<form #promotionAddForm="ngForm">
    <div class="row">
        <div class="col">
            <div class="form-group">
                <label class="col-form-label">Mã</label>
                <div class="">
                    <input [(ngModel)]="promotion.code" class="form-control" required name="code" type="text" placeholder="Mã" (ngModelChange)="promotion.code = $event.toUpperCase()" (focusout)="focusOutCheckCode()" (keydown.space)="$event.preventDefault();">

                </div>
            </div>
            <div class="form-group ">
                <label class="col-form-label">Kiểu khuyến mãi</label>
                <div class="d-flex align-items-center">
                    <label class="radio radio-grey radio-primary radio-inline" *ngFor="let item of appService.promotion_types">
                        <input type="radio" name="b" [checked]="item.code == promotion.type"
                            (click)="SelectkieuKhuyenMai(item.code)">
                        <span class="input-span"></span>{{item.name}}</label>

                </div>
            </div>

        </div>
        <div class="col">
            <div class="form-group ">
                <label class="col-form-label">Tên</label>
                <div class="">
                    <input [(ngModel)]="promotion.name" class="form-control" required maxlength="100" name="name" id="ssss" placeholder="Tên chương trình" type="text">
                </div>
            </div>
            <div class="form-group ">
                <label class="col-form-label">Khuyến mãi</label>
                <div *ngIf="promotion.type != 'dl'" class="">
                    <div class="input-group-icon input-group-icon-right">
                        <span class="input-icon input-icon-right">{{promotion.type}}</span>
                        <input class="form-control" type="text" [(ngModel)]="promotion.promotion" name="promotion" currencyMask required placeholder="Giá trị">
                    </div>
                </div>
                <div *ngIf="promotion.type == 'dl'" class="">
                    <select class="custom-select mr-2" data-width="200px" [(ngModel)]="promotion.promotion_price_range" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.promotion_price_ranges">
                            {{item.value}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Đối tượng tham gia</div>
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-nhom-san-pham" data-toggle="tab" aria-expanded="false">Nhóm sản
                                phẩm</a>
                        </li>

                    </ul>
                </div>
                <div class="ibox-body">
                    <div class="tab-content">
                        <div class="tab-pane fade active show" id="tab-nhom-san-pham" aria-expanded="true">
                            <div>
                                <ng-multiselect-dropdown [placeholder]="'Danh mục sản phẩm'" name="nsp" [data]="CategoryList" [(ngModel)]="CategorySelectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="mb-2 mt-2">Nhưng sản phẩm thuộc danh mục đã chọn sẽ được tham gia chương trình
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Đối tượng tham gia</div>
                    <ul class="nav nav-pills">

                        <li class="nav-item">
                            <a class="nav-link " href="#tab-nhom-khach-hang" data-toggle="tab" aria-expanded="true">Nhóm
                                khách
                                hàng</a>
                        </li>

                    </ul>
                </div>
                <div class="ibox-body">
                    <div class="tab-content">

                        <div class="tab-pane fade active show" id="tab-nhom-khach-hang" aria-expanded="false">
                            <div>
                                <ng-multiselect-dropdown [placeholder]="'Nhóm khách hàng'" name="nkh" [data]="categorys_customer" [(ngModel)]="categorys_customer_selectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="mb-2 mt-2">Nhưng khách hàng thuộc nhóm đã chọn sẽ được tham gia chương trình
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col">

            <div class="form-group">
                <label class="col-form-label">Mô tả</label>
                <div class="">
                    <textarea [(ngModel)]="promotion.description" class="form-control" name="description" placeholder="Nội dung chương trình khuyến mãi" type="text" rows="3"></textarea>
                </div>
            </div>
        </div>
        <div class="col">


            <div class="form-group" id="date_5">
                <label class="col-form-label">Thời gian áp dụng</label>

                <div class="">

                    <mat-form-field appearance="fill" name="promotion_time" style="width: 100%;">
                        <mat-label>Thời gian áp dụng</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Bắt đầu" [(ngModel)]="dateStart" name="dateStart">
                            <input matEndDate placeholder="Kết thúc" [(ngModel)]="dateEnd" name="dateEnd" (dateChange)="dateChangeEvent($event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker touchUi #picker></mat-date-range-picker>
                    </mat-form-field>



                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-end">
                <div class="p-2">

                    <button class="btn btn-primary btn-sm btn-labeled btn-labeled-left btn-icon" name="save" [disabled]="isLoading || this.promotionAddForm.invalid" (click)="insertPromotion()">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-save"></i></span>
                        Lưu
                    </button>

                </div>
                <div class="p-2">
                    <button class="btn btn-sm btn-labeled btn-labeled-left btn-icon" name="cancel" data-toggle="modal" data-target="#add-customer-modal" (click)="backToList()">
                        <span class="btn-label"><i class="ti ti-close"></i></span>Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>