<div class="page-content fade-in-up">
    <div class="row justify-content-between">
        <app-range-time-picker (click2)="onClick_selectDate($event)"></app-range-time-picker>
        <div class="d-flex justify-content-end  align-items-center">
            <select style="width: 120px;" class="custom-select mr-2" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sort_revenue_customer_reports">{{item.name}}
                        </option>
            </select>
            <div class="">
                <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="data_export()">
                    <span class="btn-label "><i class="ti ti ti-arrow-down "></i></span>Download Excel
                </button>
            </div>
        </div>
    </div>
    <div class="mt-2">
        <app-revenue-employee-cart-list [datas]="this.revenueCustomerList" [selected]="" (click)="onClick_selectCate($event)">
        </app-revenue-employee-cart-list>
        <div class="row align-items-center mb-2">
            <div class="col-4">
                <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
            {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
            </div>
            <div class="col-8 d-flex justify-content-end">
                <ul class="pagination p-1" style="margin-bottom: 0px;">
                    <li>
                        <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
              </select>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                            <span aria-hidden="true">
                  <i class="ti ti-angle-left"></i>
                </span>
                        </a>
                    </li>
                    <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                        <a class="page-link" href="javascript:;">{{item.i}}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                            <i class="ti ti-angle-right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>