<div>
    <h4 mat-dialog-title>Chọn nhân viên</h4>
    <div class="m-2">
        <div class="row mb-2">
            <div class="row">
                <div class="select_category mb-2" *ngFor="let item of userNvbhList" (click)="click_select(item)" [ngClass]="{'selected_category' : item.id == selected}">
                    <div>
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-around">
            <button class="btn btn-secondary " (click)="onCancelClick()">Đóng</button>
        </div>
    </div>
</div>