import { environment } from "src/environments/environment";

export class CartService {
    id: number = 0;
    name : string = "";
    // price : number = 0;
    serviceEndTime : Date;
    serviceEndTimeNumberType : number = 0;
    serviceStartTime : Date;
    serviceStartTimeNumberType : number = 0;
    quantity : number = 0;
    quantityLable : string = "";
    amount : number = 0;
    isActive : number = 1;
    isEnable : number = 1;

    product_code : string = "";
    product_name : string = "";
    price : number = 0;
    shop_code : string = "";
    customer_id: number = 0;
    customer_code: string = "";
    customer_name: string = "";
    customer_category_code: string;

    product: any;

    constructor(){
        this.customer_code = environment.CUSTOMER_CODE_PREFIX;
        this.customer_name = environment.CUSTOMER_NAME_PREFIX;
        this.customer_category_code = environment.CUSTOMER_CATE_CODE_PREFIX;
        this.customer_id = 0;
    }
}
