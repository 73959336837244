import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/_services/app.service';
import { SaleService } from 'src/app/_services/sale.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-report-employee',
  templateUrl: './report-employee.component.html',
  styleUrls: ['./report-employee.component.css']
})
export class ReportEmployeeComponent implements OnInit {

 //date
 revenueCustomerList = [];
 // param

 dateEnd: any;
 dateStrStart: any ="";
 dateStrEnd: any ="";

 //for sorter
 sorter = {
   sortStr: this.appService.sort_revenue_customer_reports[0].code,
 }
 //for paging
 viewNumsObj = this.appService.viewNums[3].Code;
 curentPage = 1;
 totalItemOnPage: any;
 totalItems: any;
 listPage: any = [];

 constructor(
   public appService: AppService,
   public formatter: NgbDateParserFormatter,
   public utilityService: UtilityService,
   public saleService: SaleService,
 ) {
  this.dateStrStart = this.utilityService.getFullDateStrFromDate(new Date());
  this.dateStrEnd = this.dateStrStart;
}

ngOnInit(): void {
  this.loadData();
}
 // sự kiện từ component catelist
 onClick_selectCate(event) {
   // if(typeof event == "string"){
   //   this.router.navigate(['/report2', { customer: event }]);
   // }
   
 }
 sort_change($event){
   console.log(this.sorter);
   this.loadData();
 }

 onClick_selectDate(dateObj) {
    this.dateStrStart = dateObj.dateStrStart;
    this.dateStrEnd = dateObj.dateStrEnd;
    this.loadData();
  }
 loadData(){
   let filter = {
     fromDate: this.dateStrStart,
     toDate: this.dateStrEnd,
     type_report: "customer", //customer and product
     code: "", //customer code
   }
   console.log("filter", filter);
   let pagingObj = {
     viewNumsObj: this.viewNumsObj,
     pageObj: this.curentPage
   };
   this.saleService.getRevenueEmployee(filter, this.sorter, pagingObj).subscribe(
     async response => {
       if (response) {
         this.revenueCustomerList = response.data;

         this.totalItems = response.totalItems;
         this.totalItemOnPage = this.revenueCustomerList.length;
         //for select page
         let pages_temp = response.totalPage;
         this.listPage = [];
         for (let i = 1; i <= pages_temp; i++) {
           this.listPage.push({ i: i });
         }
       }
     },
     error => {
       console.error(error);
     });
 }
 data_export() {
   let filter = {
     fromDate: this.dateStrStart,
     toDate: this.dateStrEnd,
     type_report: "employee", //customer and product
     code: "", //customer code
   }
   this.saleService.exportRevenueEmployee(filter);
 }



 viewNumsObjChange() {
   this.curentPage = 1;
   console.log(this.viewNumsObj);
   this.loadData();
 }
 pageChange = function (page) {
   if (page === 'prev' && this.curentPage > 1) {
     this.curentPage = this.curentPage - 1;
     this.loadData();
   } else if (page === 'next' && this.curentPage < this.listPage.length) {
     this.curentPage = this.curentPage + 1;
     this.loadData();
   } else if ((typeof page === 'number') && page !== this.curentPage) {
     this.curentPage = page;
     this.loadData();
   }
 };





}
