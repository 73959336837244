import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/_services/app.service';
import { SaleService } from 'src/app/_services/sale.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { SaleDetailViewReturningComponent } from '../sale-detail-view-returning/sale-detail-view-returning.component';

@Component({
  selector: 'app-sale-detail',
  templateUrl: './sale-detail.component.html',
  styleUrls: ['./sale-detail.component.css']
})
export class SaleDetailComponent implements OnInit {
  // for detail
  cartDetail: any;
  // for return
  isReturning = false;
  returning_quantity = 0;
  returning_price = 0;
  constructor(
    public saleService: SaleService,
    public appService: AppService,
    private toastr: ToastrService,
    public utilityService: UtilityService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SaleDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    console.log("this.data:");
    console.log(this.data);
    this.loadCartDetail(this.data.id);
   }

  ngOnInit(): void {
  }

  loadCartDetail(saleID) {
    this.saleService.loadCartDetail(saleID).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.cartDetail = response;
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })
  }

  openDeleteSale(): void {
    let result = {
      code: "confirmDelete",
    }
    this.dialogRef.close(result);
  }
  returning(): void {
    this.isReturning = !this.isReturning;
  }
  view_returning_history(): void {
    const dialogRef = this.dialog.open(SaleDetailViewReturningComponent, {
      position: {
      },
      panelClass: 'full-screen-modal',
      data: this.data
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }
  returning_confirm(): void {
    if(this.returning_quantity > 0){ // nếu số lượng trả > 0
        console.log("bắt đầu thực hiện trả hàng");
        console.log(this.data);
        console.log(this.cartDetail);
        let data = {
          id: this.data.id,
          code: this.data.code,
          cartDetail: this.cartDetail
        }
        this.saleService.updateSaleReturning(data).subscribe(
          async response => {
            if (response.code == "ok" && response.affectedRows > 0) {
                
                this.dialogRef.close({code:'returning_ok'});
              }
          },
          error => {
            console.error(error);
          });
    }else{ 
      this.toastr.warning("Số lượng không hợp lệ","");
    }
  }
  focusFunction(event) {
    event.target.select();
  }
  returning_quantity_sub(item) {
    if(item.quantity_return > 0){
      item.quantity_return = this.utilityService.roundToTwo(item.quantity_return - 1);
      this.calculate_returning();
    }else{
      this.toastr.warning("Số lượng không hợp lệ","");
    }
  }
  returning_quantity_add(item) {
    if(item.quantity_return < item.quantity){
      item.quantity_return = this.utilityService.roundToTwo(item.quantity_return + 1);
      this.calculate_returning();
    }else{
      this.toastr.warning("Số lượng không hợp lệ","");
    }
  }
  quantity_tempChange(item){
    if(item.quantity_return >= 0 && item.quantity_return <= item.quantity){
        // chỉ đượng trả lại số lượng nhỏ hơn hoặc bằng số lượng mua.
        this.toastr.warning("Số lượng không hợp lệ","");
    }
    else{
      item.quantity_return = 0;
    }

    this.calculate_returning();
  }
  calculate_returning(){
    this.returning_quantity = 0;
    this.returning_price = 0;
      this.cartDetail.forEach(item => {
        this.returning_quantity += item.quantity_return;
        this.returning_price += item.quantity_return * item.price;
      });
  }
  print(status) {
    let type = 'In lại';
    if(status == 3){
      type = 'Đã hủy';
    }else if(status == 1){
      type = 'In lại';
    }else if(status == 4){
      type = 'Đang xử lý';
    }else if(status == 0){
      type = 'Đặt hàng';
    }
    // this.toastr.success("", "Máy in chưa được thiết lập");
    let lableContents, printContents, popupWin;
    printContents = document.getElementById('print-section-on-order').innerHTML;
    lableContents = type == "" ? '' : `<div style="position: absolute;top: 22px;left: 0px;font-size: 20px;border: 2px  dashed grey;padding: 5px 20px;">${type}</div>`;

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>In Hóa Đơn</title>
          <style>
          //........Customized style.......
          </style>
        </head>
        
        <body onload="window.print();window.close()">
          ${printContents}
          ${lableContents}
        </body>
      </html>`
    );
    popupWin.document.close();

  }
  close(): void {
    this.dialogRef.close();
  }

}
