<div>
    <div>Nhập chọn số tiền</div>
    <div>
        <div style="width: 100%;">
            <div *ngFor="let item of datas; let i = index" class="item_button">
                <button class="btn">{{item.value}}</button>
            </div>
            <div class="item_button">
                <button class="btn">
                    <span class="material-icons">
                        backspace
                        </span>
                </button>
            </div>
        </div>
    </div>
</div>