import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ReportCustomerDetailComponent } from 'src/app/components/report-customer-detail/report-customer-detail.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { SaleService } from 'src/app/_services/sale.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-customer',
  templateUrl: './report-customer.component.html',
  styleUrls: ['./report-customer.component.css']
})
export class ReportCustomerComponent implements OnInit {

  //date
  revenueCustomerList = [];
  revenueCustomerSum = [];
  // param time
  // date_type_selected: string = "thisDay";
  // dateStart: any;
  // dateEnd: any;
  dateStrStart: any = "";
  dateStrEnd: any = "";

  //for sorter
  sorter = {
    sortStr: this.appService.sort_revenue_customer_reports[0].code,
  }
  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  //for customer category
  categorys: any
  category_code = '';

  constructor(
    public appService: AppService,
    public formatter: NgbDateParserFormatter,
    public utilityService: UtilityService,
    public saleService: SaleService,
    public categoryService: CategoryService,
    public dialog: MatDialog,
  ) {
    this.dateStrStart = this.utilityService.getFullDateStrFromDate(new Date());
    this.dateStrEnd = this.dateStrStart;
    
  }

  ngOnInit(): void {
    this.loadCategory();
    this.loadData();
  }
  // sự kiện từ component catelist
  onClick_selectItem(item) {
    let data = {
      fromDate: this.dateStrStart,
      toDate: this.dateStrEnd,
      type_report: "ProductCustomer", //customer and product not using
      code: item.code, //customer code
      customer_name: item.name, //customer code
      customer_company: item.company, //customer code
      customer_tax: item.tax, //customer code
      customer_phone: item.phone, //customer code
    }
    const dialogRef = this.dialog.open(ReportCustomerDetailComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      width: '95%',
      height: '95%',
      panelClass: 'full-screen-modal',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result.data", result.data);
      }
    });

  }
  sort_change($event) {
    console.log(this.sorter);
    this.loadData();
  }
  onClick_selectDate(dateObj) {
    this.dateStrStart = dateObj.dateStrStart;
    this.dateStrEnd = dateObj.dateStrEnd;
    this.loadData();
  }
  // selectDateType(date_type) {
  //   if (date_type != 'rangeDate') {
  //     this.dateStart = null;
  //     this.dateEnd = null;
  //   }
  //   this.date_type_selected = date_type; 
  //   if(this.date_type_selected == 'thisDay'){
  //     this.dateStrStart = this.utilityService.getFullDateStrFromDate(new Date());
  //     this.dateStrEnd = this.dateStrStart;
  //   }
  //   else if(this.date_type_selected == 'thisMonth'){
  //     this.dateStrStart = this.utilityService.getFirstFullDateStrFromDate(new Date());
  //     this.dateStrEnd = this.utilityService.getLastFullDateStrFromDate(new Date());
  //   }
  //   else if(this.date_type_selected == 'thisYear'){
  //     var today = new Date();
  //     this.dateStrStart = "01/01/" + today.getFullYear();
  //     this.dateStrEnd =  "31/12/" + today.getFullYear();
  //   }

  //   this.loadData();
  // }

  loadData() {
    let filter = {
      fromDate: this.dateStrStart,
      toDate: this.dateStrEnd,
      type_report: "customer", //customer and product
      code: "", //customer code
      category_code: this.category_code, //customer category
    }
    console.log("filter", filter);
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    this.saleService.getRevenueCustomer(filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.revenueCustomerList = response.data;
          this.revenueCustomerSum = response.dataSum;
          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.revenueCustomerList.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
        }
      },
      error => {
        console.error(error);
      });
  }
  data_export() {
    let filter = {
      fromDate: this.dateStrStart,
      toDate: this.dateStrEnd,
      type_report: "customer", //customer and product
      code: "", //customer code
    }
    this.saleService.exportRevenueCustomer(filter);
  }




  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadData();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadData();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadData();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadData();
    }
  };

  loadCategory() {
    let filter = {
      type: environment.customer_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })

  }



}
