<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-4">
            <div style="padding: 20px;">
                <div style="text-align: center;">
                    <h2 style="font-size: 25px; font-weight: bold;">Tổng doanh thu tồn kho</h2>
                </div>
                <div style="text-align: center;">
                    <img style="width: 30px;" src="../assets/img/icons/vnd.svg">
                </div>
                <div style="text-align: center;">
                    <div style="font-size: 23px; font-weight: bold;">{{sumShopInfo.total | qbscurrency:0}}</div>
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="row mb-2 mt-4">
                <div class="col-md-4">
                    <div class="card mb-2">
                        <div class="card-body flexbox-b">
                            <div class="easypie mr-4" data-line-width="10">
                                <span class="flexbox text-success"><span class="material-icons ml-3 font-40">
                category
                </span></span>
                            </div>
                            <div>
                                <h3 class="font-strong text-success font-36">{{sumShopInfo.category_shop}}</h3>
                                <h5>Nhóm hàng</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mb-2">
                        <div class="card-body flexbox-b">
                            <div class="easypie mr-4" data-percent="42" data-bar-color="#5c6bc0" data-size="80" data-line-width="8">
                                <span class="flexbox text-primary">
                <span class="material-icons  ml-3 font-40">
                  inventory_2
                  </span>
                                </span>
                            </div>
                            <div>
                                <h3 class="font-strong text-primary font-36">{{sumShopInfo.product_shop}}</h3>
                                <h5>Hàng Hóa</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mb-2">
                        <div class="card-body flexbox-b">
                            <div class="easypie mr-4" data-percent="70" data-bar-color="#ff4081" data-size="80" data-line-width="8">
                                <span class="flexbox text-pink"><span class="material-icons  ml-3 font-40">
                looks_one
                </span></span>
                            </div>
                            <div>
                                <h3 class="font-strong text-pink font-36">{{sumShopInfo.product_quantity_shop |number:0}}</h3>
                                <h5>Số Lượng</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="qbs-diver"></div>
    <app-range-time-picker class="pb-2" (click2)="onClick_selectDate($event)"></app-range-time-picker>
    <div class="qbs-panel-cover">
        <div class="qbs-panel-header">
        </div>
        <div class="qbs-panel-body">
            <div class="row">
                <div class="col p-2 top_report_cover">
                    <h5>Top 10 Tồn kho theo nhóm hàng</h5>
                    <!-- <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType"> </canvas>-->
                    <table class="table_top_reprot table table-head-purple table-hover">
                        <tbody>
                            <tr *ngFor="let item of sumInfoCategorys; let i = index">
                                <td style="width: 50%;">{{i+1}}. {{item.name}}</td>
                                <td>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.temp">{{item.temp}}%</div>
                                        <span>{{item.stock_priceSum| qbscurrency:0}}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col p-2 top_report_cover">
                    <h5>Top 10 doanh thu sản phẩm</h5>
                    <!-- <canvas baseChart [datasets]="barChartTopProductData" [labels]="barChartTopProductLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType"></canvas> -->
                    <table class="table_top_reprot table table-head-purple table-hover">
                        <tbody>
                            <tr *ngFor="let item of revenueProductList; let i = index">
                                <td style="width: 50%;">{{i+1}}. {{item.name}}</td>
                                <td>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.temp">{{item.temp}}%</div>
                                        <span>{{item.revenue| qbscurrency:0}}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="row">

                <div class="col p-2 top_report_cover">
                    <h5>Top 10 doanh thu khách hàng</h5>
                    <table class="table_top_reprot table table-head-purple table-hover">
                        <tbody>
                            <tr *ngFor="let item of revenueCustomerList; let i = index">
                                <td style="width: 50%;">{{i+1}}. {{item.name}}</td>
                                <td>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.temp">{{item.temp}}%</div>
                                        <span>{{item.revenue| qbscurrency:0}}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col p-2 top_report_cover">
                    <h5>Top 10 công nợ khách hàng</h5>
                    <!-- <canvas baseChart [datasets]="barChartTopCustomerData" [labels]="barChartTopCustomerLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType"> </canvas>-->


                </div>

            </div>

        </div>
    </div>

    <!-- <div style="display: block; height: 400px;">
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
      </canvas>
    </div> -->

    <ul class="media-list media-list-divider">
        <li class="media" *ngFor="let item of sumInfoCategorys; let i = index">
            <a class="avatar-img-list mr-2" href="javascript:;" [style.background-image]="this.appService.showImageProduct(item.image_url)">
                <!-- <i class="ti ti-folder font-20"></i> -->
                <!-- <img class="img-circle" [src]="app.getUrlImage(item.image_url)" alt="image" width="30" /> -->
            </a>
            <!-- <a class="media-img" href="javascript:;">
          [style.background-image]="showImage(item.image_url)"
          <img src="./assets/img/icons/cate.svg" alt="image" width="40" />
        </a> -->
            <div class="media-body d-flex">
                <div class="flex-1">
                    <h5 class="media-heading">{{item.name}}</h5>
                    <p class="font-15 m-0 ">Sản phầm: <span style="color: black;">{{item.products}}</span> Tồn kho: <span style="color: black;">{{item.stockSum | qbscurrency:0}}</span></p>
                </div>
                <div class="text-right ml-2">
                    <h5 class="mb-1 font-strong">{{item.stock_priceSum | qbscurrency:0}}
                        <img style="width:20px; margin-bottom: 4px;" src="../assets/img/icons/vnd.svg"></h5>
                    <!-- <div class="text-muted">{{item.stock_price | qbscurrency:0}}</div> -->
                </div>
            </div>
        </li>
    </ul>


</div>
<!-- 
  <div class="row">
    <app-range-time-picker (click2)="onClick_selectDate($event)"></app-range-time-picker>
  </div>
  <div class="row">
    <div class="col-lg-4">
      <div class="ibox ibox-fullheight">
        <div class="ibox-head">
          <div class="ibox-title">Top Khách hàng</div>
          <div class="ibox-tools">
            <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="ti ti-more-alt"></i></a>
          </div>
        </div>
        <div class="ibox-body">
          <div class="slimScrollDiv" style="position: relative;">
            <ul class="timeline scroller"  >
              
              <li class="timeline-item">
                <i class="ti ti-check timeline-icon"></i>2 Issue fixed
                <small class="float-right text-muted ml-2 nowrap">Just now</small>
              </li>
              <li class="timeline-item">
                <span class="timeline-point"></span>2 Issue fixed<small class="float-right text-muted ml-2 nowrap">Just now</small></li>
  
              <li class="timeline-item"><i class="ti-announcement timeline-icon"></i>
                <span>7 new feedback
                  <span class="badge badge-warning badge-pill ml-2">important</span>
                </span><small class="float-right text-muted">5 mins</small>
              </li>
              <li class="timeline-item"><i class="ti-truck timeline-icon"></i>25 new orders sent<small
                  class="float-right text-muted ml-2 nowrap">24 mins</small></li>
              <li class="timeline-item"><i class="ti-harddrives timeline-icon"></i>
                <span>Server Error
                  <span class="badge badge-success badge-pill ml-2">resolved</span>
                </span><small class="float-right text-muted">2 hrs</small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="ibox ibox-fullheight">
        <div class="ibox-head">
          <div class="ibox-title">Top Sản Phẩm</div>
        </div>
        <div class="ibox-body">
          <div class="slimScrollDiv" style="position: relative;">
            <ul class="timeline scroller"  >
              
              <li class="timeline-item">
                <span class="timeline-point"></span>2 Issue fixed
                <small class="float-right text-muted ml-2 nowrap">Just now</small>
              </li>
              <li class="timeline-item">
                <span class="timeline-point"></span>2 Issue fixed<small class="float-right text-muted ml-2 nowrap">Just now</small></li>
  
              <li class="timeline-item"><i class="ti-flag timeline-icon"></i>
                <span>7 new feedback
                  <span class="badge badge-warning badge-pill ml-2">important</span>
                </span><small class="float-right text-muted">5 mins</small>
              </li>
              <li class="timeline-item"><i class="ti-truck timeline-icon"></i>25 new orders sent<small
                  class="float-right text-muted ml-2 nowrap">24 mins</small></li>
              <li class="timeline-item"><i class="ti-harddrives timeline-icon"></i>
                <span>Server Error
                  <span class="badge badge-success badge-pill ml-2">resolved</span>
                </span><small class="float-right text-muted">2 hrs</small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="ibox ibox-fullheight">
        <div class="ibox-head">
          <div class="ibox-title">Top Nhân Viên</div>
          <div class="ibox-tools">
            <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="ti ti-more-alt"></i></a>
          </div>
        </div>
        <div class="ibox-body">
          <div class="slimScrollDiv" style="position: relative;">
            <ul class="timeline scroller"  >
              
              <li class="timeline-item">
                <i class="ti ti-check timeline-icon"></i>2 Issue fixed
                <small class="float-right text-muted ml-2 nowrap">Just now</small>
              </li>
              <li class="timeline-item">
                <span class="timeline-point"></span>2 Issue fixed<small class="float-right text-muted ml-2 nowrap">Just now</small></li>
  
              <li class="timeline-item"><i class="ti-announcement timeline-icon"></i>
                <span>7 new feedback
                  <span class="badge badge-warning badge-pill ml-2">important</span>
                </span><small class="float-right text-muted">5 mins</small>
              </li>
              <li class="timeline-item"><i class="ti-truck timeline-icon"></i>25 new orders sent<small
                  class="float-right text-muted ml-2 nowrap">24 mins</small></li>
              <li class="timeline-item"><i class="ti-harddrives timeline-icon"></i>
                <span>Server Error
                  <span class="badge badge-success badge-pill ml-2">resolved</span>
                </span><small class="float-right text-muted">2 hrs</small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> -->