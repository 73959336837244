import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-unit-select',
  templateUrl: './unit-select.component.html',
  styleUrls: ['./unit-select.component.css']
})
export class UnitSelectComponent implements OnInit {
  @Output("click2") clickEvent = new EventEmitter<any>();
  @Input("selected") selected: string;
  datas = [
    {code : "unit", value: "Đơn vị 1"},
    {code : "unit2", value: "Đơn vị 2"},
  ]
  constructor() {
      console.log("UnitSelectComponent: ",this.selected);
   }

  ngOnInit(): void {
  }
  select_click(code) {
    this.clickEvent.emit(code);
  }
}
