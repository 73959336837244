<div class="row mb-2 mt-2 justify-content-between">
    <div class="col">
        <!-- <span class="mr-4 static-badge badge-primary"><i class="ti ti-receipt"></i></span> -->
        <div style="display: inline-block;">
            <div class="mobile-mb-2 input-group-icon input-group-icon-left mr-3">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="typeahead-template" class="form-control" [(ngModel)]="code_filter" [ngbTypeahead]="search" type="text" placeholder="Tìm mã phiếu" [resultTemplate]="rt" [inputFormatter]="searchFormatter">
            </div>
            <ng-template #rt let-r="result" let-t="term">
                <div class="row" (click)="onClick(r)" style="width: 450px;">

                    <!-- <div class="col"> #{{ r['code'] }}</div> -->
                    <div class="col">
                        <ngb-highlight [result]="r.code" [term]="t">
                        </ngb-highlight>
                    </div>
                    <div class="col">SP: {{ r['product_quantity']}}</div>
                    <div class="col">SL: {{ r['quantity'] }}</div>
                    <!-- <div class="col">{{ ' SP:' + r['products']}}</div> -->
                </div>
            </ng-template>
        </div>
        <div style="display: inline-block;">
            <div class="form-inline">
                <div class="form-group hidden">
                    <div class="input-group">
                        <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                {{ date.day }}
              </span>
                        </ng-template>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group" style="width: 140px;">
                        <input #dpFromDate class="form-control" placeholder="yyyy-mm-dd" name="dpFromDate" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary ti ti-calendar" (click)="datepicker.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
                <div class="form-group ml-2 mr-2">
                    Đến
                </div>
                <div class="form-group">
                    <div class="input-group" style="width: 140px;">
                        <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate" [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary ti ti-calendar" (click)="datepicker.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="d-flex justify-content-end align-items-center">
            <div class="pl-2">
                <select class="custom-select" style="width: 150px;" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="this.getSalesTitleBydays();" name="sorter" id="sorter">
          <option [ngValue]="item.code" *ngFor="let item of appService.sort_reports">{{item.name}}</option>
        </select>
            </div>
            <div class="pl-2">
                <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="goToAddNew()">
          <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
        </button>
                <!-- <button class="btn  btn-info btn-labeled btn-labeled-left btn-icon" data-toggle="modal"
          data-target="#add-customer-modal">
          <span class="btn-label"><i class="ti-download"></i></span>Xuất Excel
        </button> -->
            </div>
        </div>
    </div>
</div>
<div class="row align-items-center mb-2 paging-cover">
    <div class="col-4">
        <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
      {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
        <ul class="pagination p-1" style="margin-bottom: 0px;">
            <li>
                <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
          <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
        </select>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                    <span aria-hidden="true">
            <i class="ti ti-angle-left"></i>
          </span>
                </a>
            </li>
            <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                <a class="page-link" href="javascript:;">{{item.i}}</a>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                    <i class="ti ti-angle-right"></i>
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="table-responsive">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr class="text-center">
                <th style="width: 50px;">#</th>
                <th>Code</th>
                <th>Ngày nhập</th>
                <th>Người nhập</th>
                <th>Mặt hàng</th>
                <th>Số lượng</th>
                <th>Ghi chú</th>
                <th class="no-sort">trạng thái</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of imports; let i = index" class="h-row-item text-center" (click)="goToDetail(item)">
                <td>
                    <a>
                        <i class="ti ti-receipt"></i>
                        <span style="position: absolute;left: 29px;">{{i+1}} </span>
                    </a>
                </td>
                <td>
                    {{item.code}}
                </td>
                <td>
                    {{item.created_date | date: "H:mm dd/MM/yyyy":'UTC'}}
                </td>
                <td>{{item.user_name}}</td>
                <td>{{item.product_quantity}}</td>
                <td> {{item.quantity}}</td>
                <td> {{item.note}}</td>

                <td>
                    <span class="badge badge-success">Hoàn thành</span>
                </td>
            </tr>

        </tbody>
    </table>
</div>

<div class="row align-items-center mb-2  paging-cover">
    <div class="col-4">
        <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
      {{((curentPage - 1) * viewNumsObj)+ totalItemOnPage }}</span>
    </div>
    <div class="col-8 d-flex justify-content-end">
        <ul class="pagination p-1" style="margin-bottom: 0px;">
            <li>
                <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
          <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
        </select>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                    <span aria-hidden="true">
            <i class="ti ti-angle-left"></i>
          </span>
                </a>
            </li>
            <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                <a class="page-link" href="javascript:;">{{item.i}}</a>
            </li>
            <li class="page-item">
                <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                    <i class="ti ti-angle-right"></i>
                </a>
            </li>
        </ul>
    </div>
</div>



<div class="modal fade" id="sale-import-detail-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-sale-import-detail">
            <div class="modal-header p-2">
                <h5 class="modal-title">Phiếu nhập hàng</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body">
                <div class="chat-list">
                    <div class="scroller">
                        <div class="">
                            <div class="row p-10">
                                <h4 class="col text-center">
                                    Nhân viên: <strong> {{saleItem.user_name}}</strong>
                                </h4>
                            </div>
                            <div class="row p-10">
                                <div class="col text-left" style="font-weight: bold;">
                                    Code: {{saleItem.code}}
                                </div>
                                <div class="col text-right mr-1" style="font-style: italic;">
                                    {{saleItem.hhmmddmmyyyStr }}
                                </div>
                            </div>

                            <div class="row info-row">
                                <div class="col">
                                    Tổng hóa đơn:
                                </div>
                                <div class="col text-right">
                                    {{saleItem.price_totol | qbscurrency}}
                                </div>
                            </div>
                            <div class="row info-row">
                                <div class="col">
                                    Nhà cung cấp:
                                </div>
                                <div class="col text-right">
                                    {{saleItem.customer_name}}
                                </div>
                            </div>

                            <div class="row info-row">
                                <div class="col">
                                    Ghi chú: {{saleItem.note}}
                                </div>
                            </div>

                            <!-- <div class="row info-row">
                                <div class="col">
                                    Trả nhà cung cấp:
                                </div>
                                <div class="col text-right">
                                    {{saleItem.customer_pay | qbscurrency}}
                                </div>
                            </div> -->
                            <table style="width: 100%; margin-top: 1px;">
                                <tr *ngFor="let item of cartDetail; let i = index" class="item-row">
                                    <td style="width: 25px; text-align: center;">
                                        <!-- <a (click)="removeProduct(i,item)" class="text-danger"><i class=" btn-remove ti-close"></i></a> -->
                                        <span class="lbl-stt">{{i+1}}</span>
                                    </td>
                                    <td style="width: 55%;">
                                        <div style="line-height: 16px;">
                                            {{item.product_name}} - <span style="color: gray;">{{item.price| qbscurrency}}</span>
                                        </div>
                                    </td>
                                    <!-- <td style="text-align: right;"><span style="font-size: 11px;">{{item.product.price| qbscurrency}}</span> </td> -->
                                    <td style="text-align: center;">

                                        x {{item.quantity}}
                                        <span style="font-size: small;"> {{item.unit}}</span>
                                    </td>
                                    <td style="text-align: right; color: black;">
                                        {{item.quantity * item.price | qbscurrency:0}}</td>
                                </tr>
                                <tr style="background: lightgray;line-height: 30px;">
                                    <td colspan="2" style="text-align: center;font-weight: bold;">Tổng</td>
                                    <td style="text-align: center;font-weight: bold;">{{saleItem.total_quantity}}</td>
                                    <td style="text-align: right; color: black; font-weight: bold;">
                                        {{saleItem.price_totol | qbscurrency}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-between bg-primary-50">
                <button class="btn btn-outline-primary btn-icon-only " (click)="print('In lại')"><i
                    class="ti ti-printer"></i></button>
                <button class="btn btn-primary " data-dismiss="modal">Đóng</button>
            </div>
        </form>
    </div>
</div>




<div id="print-section-stockin" style="display: none;">
    <div style=" font-family: Tahoma; font-size: 14px;">
        <h3 style="text-align: center;">{{appService.currentUser.shop_name}}</h3>
        <div style="text-align: center;">{{appService.currentUser.shop_phone}}</div>
        <h4 style="text-align: center;">Phiếu nhập kho</h4>
        <!-- <div style="text-align: right">{{this.dateTimeStr}}</div> -->
        <div style="text-align: right"> {{saleItem.created_date | date: "H:mm dd/MM/yyyy" }}</div>

        <table style="font-size: 13px; width: 100%;">
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Tổng hóa đơn: </td>
                <td style="text-align: right;">{{saleItem.price_totol | qbscurrency}}</td>
            </tr>
            <tr style="text-align: center; font-weight: bold; ">
                <td style="text-align: left;">Nhân viên: </td>
                <td style="text-align: right;"> {{appService.currentUser.name}} </td>
            </tr>
        </table>
        <!-- Chi tiết sử dụng dịch vụ -->
        <!-- END Chi tiết sử dụng dịch vụ -->
        <div style="border-top: 1px dashed gray; border-bottom: 1px dashed gray; margin: 10px 0px;">
            <table style="font-size: 12px; width: 100%;">
                <tr style="text-align: center; font-weight: bold; ">
                    <td style="vertical-align: middle;"></td>
                    <td style="vertical-align: middle;">Tên</td>
                    <td style="text-align: right;vertical-align: middle;">ĐG</td>
                    <td style="text-align: center; min-width: 40px; vertical-align: middle;">SL</td>
                    <td style="text-align: right; vertical-align: middle;">T.Tiền</td>
                </tr>
                <!-- giá dịch vụ -->
                <!-- Hết giá dịch vụ -->
                <tr *ngFor="let item of cartDetail; let i = index">
                    <td style="vertical-align: middle;">{{i+1}}</td>
                    <td style="vertical-align: middle;">{{item.product_name}}</td>
                    <td style="text-align: right; vertical-align: middle;">{{item.price| qbscurrency}}</td>
                    <td style="text-align: left; vertical-align: middle;">x {{item.quantity}}<span style="font-size: 9px;"> {{item.unit}}</span></td>
                    <td style="text-align: right; vertical-align: middle;">{{item.quantity * item.price | qbscurrency:0}}
                    </td>
                </tr>
                <tr style="font-weight: bold;">
                    <td colspan="4" style="border-top: 1px dashed gray;padding-top: 5px;">Tổng</td>
                    <!-- <td>{{appService.cartObj.total_quantity}}</td> -->
                    <td style="border-top: 1px dashed gray;padding-top: 5px;">{{saleItem.price_totol | qbscurrency}}
                    </td>
                </tr>
            </table>
        </div>
        <div style="text-align: center;">Cảm ơn quý khách, hẹn gặp lại</div>
        <div style="text-align: center; margin-bottom: 10; font-size: 7;">GIẢI PHÁP SỐ QBS</div>
    </div>
</div>