import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-range-time-picker',
  templateUrl: './range-time-picker.component.html',
  styleUrls: ['./range-time-picker.component.css']
})
export class RangeTimePickerComponent implements OnInit {
  
  @Output("click2") clickEvent: EventEmitter<any> = new EventEmitter();
  @Input("datetype") datetype: string = "";
  // param time
  date_type_selected: string = "";
  dateStart: any;
  dateEnd: any;
  dateStrStart: any = "";
  dateStrEnd: any = "";
  constructor(
    public utilityService: UtilityService,
    // public appService: AppService,
  ) {
    console.log("this.date_type",this.datetype);
    if(this.datetype != ''){
      this.selectDateType(this.datetype); // mặc định load ngày hôm nay
    }else
    this.selectDateType('Hôm nay'); // mặc định load ngày hôm nay
  }

  ngOnInit(): void {
  }
  // time search
  selectDateType(date_type) {
    this.date_type_selected = date_type;

    if (date_type == 'Thời gian') {
      this.dateStart = null;
      this.dateEnd = null;
    }
    else {
      if (this.date_type_selected == 'Hôm nay') {
        this.dateStrStart = this.utilityService.getFullDateStrFromDate(new Date());
        this.dateStrEnd = this.dateStrStart;
      }
      else if (this.date_type_selected == 'Tuần này') {
        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        if (curr.getDate() == first) first = first - 6; // nếu ngày chọn là ngày chủ nhật
        else first++;
        var curentMonday = new Date(curr.setDate(first));

        this.dateStrStart = this.utilityService.getFullDateStrFromDate(curentMonday);
        curentMonday.setDate(curentMonday.getDate() + 6);
        this.dateStrEnd = this.utilityService.getFullDateStrFromDate(curentMonday);
      }
      else if (this.date_type_selected == 'Tháng này') {
        this.dateStrStart = this.utilityService.getFirstFullDateStrFromDate(new Date());
        this.dateStrEnd = this.utilityService.getLastFullDateStrFromDate(new Date());
      }
      else if (this.date_type_selected == 'Quý này') {
        this.dateStrStart = this.utilityService.getFirstQuarterFromDate(new Date());
        this.dateStrEnd = this.utilityService.getLastQuarterFromDate(new Date());
      }
      else if (this.date_type_selected == 'Sáu tháng') {
        this.dateStrStart = this.utilityService.getSixMonthDateStrFromDate(new Date());
        this.dateStrEnd = this.utilityService.getFullDateStrFromDate(new Date());
      }
      else if (this.date_type_selected == 'Chín tháng') {
        this.dateStrStart = this.utilityService.getNineMonthDateStrFromDate(new Date());
        this.dateStrEnd = this.utilityService.getFullDateStrFromDate(new Date());
      }
      else if (this.date_type_selected == 'Năm nay') {
        var today = new Date();
        this.dateStrStart = "01/01/" + today.getFullYear();
        this.dateStrEnd = "31/12/" + today.getFullYear();
      }
      else if (this.date_type_selected == 'Tất cả') {
        var today = new Date();
        this.dateStrStart = "01/01/2020";
        this.dateStrEnd = this.utilityService.getFullDateStrFromDate(new Date());
      }
      // trả về
      // trả về
      
      const diffDays = this.utilityService.getDiffDaysFromStr(this.dateStrStart,this.dateStrEnd);
      this.clickEvent.emit({ dateStrStart: this.dateStrStart, dateStrEnd: this.dateStrEnd, diffDays: diffDays});
    }


  }
  dateChangeEvent(event: MatDatepickerInputEvent<Date>) {
    if (event.value !== null) {
      if (this.dateStart) {
        this.dateStrStart = this.utilityService.getFullDateStrFromDate(new Date(this.dateStart));
      }
      if (this.dateEnd) {
        this.dateStrEnd = this.utilityService.getFullDateStrFromDate(new Date(this.dateEnd));
      }
      // trả về
      // const diffTime = Math.abs(this.dateStart - this.dateEnd);
      const diffDays =this.utilityService.getDiffDaysFromDate(this.dateStart,this.dateEnd);
      this.clickEvent.emit({ dateStrStart: this.dateStrStart, dateStrEnd: this.dateStrEnd, diffDays: diffDays});
    }
  }

}
