<div class="modal-content form-horizontal" id="select-customer-form">
    <div class="modal-header p-2">
        <h5 class="modal-title">Thêm mới khách hàng</h5>
        <a data-dismiss="modal" (click)="this.dialogRef.close();">
            <i class="ti ti-close"></i>
        </a>
    </div>
    <div class="modal-body p-2">

        <form (ngSubmit)="customer_save()" #addForm="ngForm" id="customer_add" *ngIf="isAddingCustomer">
            <div class="row mb-2">
                <div class="col-sm-8   pl-0 ">
                    <div class="row">
                        <div class="col-sm-6 pl-0 ">
                            <input [(ngModel)]="customerObj.name" name="name" required maxlength="50" class="form-control" type="text" placeholder="Tên">
                        </div>
                        <div class="col-sm-6">
                            <input [(ngModel)]="customerObj.phone" name="phone" required maxlength="10" numberOnly minlength="10" class="form-control" type="text" placeholder="Số điện thoại">
                        </div>
                    </div>
                    <div class="row mt-2 ">
                        <div class="col-sm  pl-0 ">
                            <input [(ngModel)]="customerObj.address" name="address" maxlength="200" class="form-control" type="text" placeholder="Địa chỉ">
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 pr-0 d-flex  justify-content-center align-items-center ">
                        <button class="btn btn-primary btn-labeled btn-labeled-left font-16" [disabled]="isLoading || this.addForm.invalid">
                            <span class="btn-label" [ngClass]="{'spinner-border': isLoading}"><i class="ti ti-save"></i>
                            </span>
                            Lưu và chọn
                        </button>
                </div>
            </div>
        </form>

    </div>
</div>