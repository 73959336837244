import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// import { environment } from '@environments/environment';
// import { User } from '@app/_models';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(user) {
        // return this.http.get<any>(`http://api.thietbisoqb.com:3000/haha`, user).pipe(map(data => {
      return this.http.post<any>(`${environment.apiUrl+environment.apiEndpoint}users/login`, user).pipe(map(data => {
          console.log("data:::::::",data);
           return data;
         }));
      // return this.http.post<any>(this.appService.getApiEndpoint('user.php?action=LOGIN'), user)
      //   .pipe(map(data => { return data; }));
    }
    register(user) {
        // return this.http.get<any>(`http://api.thietbisoqb.com:3000/haha`, user).pipe(map(data => {
      return this.http.post<any>(`${environment.apiUrl+environment.apiEndpoint}users/`, user).pipe(map(data => {
          console.log("data:::::::",data);
           return data;
         }));
      // return this.http.post<any>(this.appService.getApiEndpoint('user.php?action=LOGIN'), user)
      //   .pipe(map(data => { return data; }));
    }

    
    // login_ori(username: string, password: string) {
    //     return this.http.post<any>(`${environment.apiUrl+environment.apiEndpoint}/users/authenticate`, { username, password })
    //         .pipe(map(user => {
    //             console.log(user);
    //             // store user details and jwt token in local storage to keep user logged in between page refreshes
    //             localStorage.setItem('currentUser', JSON.stringify(user));
    //             this.currentUserSubject.next(user);
    //             return user;
    //         }));
    // }


}