import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ShopService } from 'src/app/_services/shop.service';
import { AppService } from 'src/app/_services/app.service';
import { environment } from 'src/environments/environment';
import { MatDialog, } from '@angular/material/dialog';
import { CartObj } from 'src/app/model/cart-obj';
import { ToastrService } from 'ngx-toastr';

import { UtilityService } from 'src/app/_services/utility.service';
import { CartService } from 'src/app/model/cartService';
import { DeskChangeComponent } from 'src/app/components/desk-change/desk-change.component';

import { SharedService } from 'src/app/_services/shared.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-servicedesk',
  templateUrl: './servicedesk.component.html',
  styleUrls: ['./servicedesk.component.css']
})
export class ServicedeskComponent implements OnInit {
  closeServiceDeskNav: Subscription;

  productpicker : any; //nav left 
  saleinvoice : any; //nav right
  // subscription: Subscription; //for nav
  // @ViewChild('saleinvoice')//for nav
  
  isLoading = false;
  // deskActive = 0;
  productpicker_nav_width = this.appService.screenWidth - 554
  // deskRevenue = 0;
  constructor(
    private sharedService: SharedService,
    public shopService: ShopService,
    public appService: AppService,
    private modalService: NgbModal,
    private utilityService: UtilityService,
    public dialog: MatDialog,
    config: NgbModalConfig,
    private toastr: ToastrService,
  ) {
    //for setting modal
    config.backdrop = 'static';
    config.keyboard = false;

    this.loadDesk();
  }

  // ngAfterViewInit(): void {
  //   this.subscription = this.sidenav.onClose.subscribe(() =>
  //     console.log("Closed event from observable"));
  // }

  // ngOnDestroy(): void {
  //   this.subscription.unsubscribe();
  // }

  ngOnInit(): void {
    this.closeServiceDeskNav = this.sharedService.getCloseServiceDeskNavEvent().subscribe(() => {
      this.closeNav(this.productpicker,this.saleinvoice);
    },);
  }
  ngOnDestroy() {
    if (this.closeServiceDeskNav) {
      this.closeServiceDeskNav.unsubscribe();
    }
    // console.warn("QBS_ngOnDestroy");
    localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
  }
  openSelectTimePrice(i){
    this.appService.deskIndex = i;
    this.sharedService.sendOpenTimePricePickerEvent();
  }
  loadDesk() {
    console.log("this.deskList : ", this.appService.deskList);
    var deskList_storage = JSON.parse(localStorage.getItem(environment.deskList));
    if (true) {
      this.shopService.loadDesk().subscribe(
        async response => {
          if (response) {
            this.appService.deskList = [];
            let tempp;
            for (let item of response) {
              tempp = new CartObj();
              tempp.id = 0;
              tempp.name = item.name; // tên bàn
              tempp.shop_code = item.shop_code; // tên bàn
              tempp.is_time_service = 0;

              tempp.position = { x: item.x, y: item.y };

              let result = {};
              Object.keys(tempp).forEach(key => result[key] = tempp[key]);
              Object.keys(item).forEach(key => result[key] = item[key]);

              // START kiểm tra cart và cart_service có trong storage không thì gán vào
              if(deskList_storage == null || deskList_storage == 'undefined'){

              }else{
                deskList_storage.forEach(item_storage => {
                    if(typeof item_storage.cart_service !='undefined'){
                        if(item_storage.shop_code == result['shop_code'] && item_storage.name == result['name']){
                          result['cart_service'] = item_storage.cart_service;
                        }
                    }
                    if(typeof item_storage.cart !='undefined'){
                        if(item_storage.shop_code == result['shop_code'] && item_storage.name == result['name']){
                          result['cart'] = item_storage.cart;
                        }
                    }
                  });
                  
              }
              // END kiểm tra cart và cart_service có trong storage không thì gán vào
              
              this.appService.deskList.push(result);
            }
            // this.appService.deskList = response;
            console.log("this.deskList 1", this.appService.deskList);
            this.appService.updateDeskRevenue();
          }
        },
        error => {
          console.error(error);
        })
    } else {
      // this.appService.deskList = JSON.parse(localStorage.getItem(environment.deskList));
      // console.log("this.deskList 2", this.appService.deskList);
    }
  

    

  }

  openSelectProduct(content, index) {
    this.appService.deskIndex = index;
    this.modalService.open(content, { size: 'xl' });
  }
  closeNav(productpicker: any,saleinvoice: any){
    productpicker.close();
    saleinvoice.close();
    this.appService.updateDeskRevenue();
    localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
  }
  backdropClick_close_nav(){
    this.appService.updateDeskRevenue();
    localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
  }
  // openSelectProductv2(left: any, index) {

  //   console.log("this.appService.deskIndex", this.appService.deskList[this.appService.deskIndex]);
  //   this.appService.deskIndex = index;

  //   const dialogRef = this.dialog.open(ServicedeskSaleComponent, {
  //     position: {
  //       // left: '10px',
  //       // right: '10px'
  //     },
  //     width: '99%',
  //     height: '99%',
  //     panelClass: 'full-screen-modal',
  //     data: { deskIndex: index,}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log("close");
  //     this.appService.updateDeskRevenue();
  //     localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
  //     if (typeof result != "undefined" && result.code == "ok") {
  //       console.log("result.data", result.data);
       
  //     }
  //   });
  // }

  openSelectDeskChange(index, is_time_service) {
    this.appService.deskIndex = index;


    const dialogRef = this.dialog.open(DeskChangeComponent, {
      width: '500px',
      data: { index: index, is_time_service: is_time_service }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result.data", result.data);
        //thực hiện change service desk
        if (this.utilityService.changeDeskService(index, result.data.deskToIndex)) {
          if (is_time_service == 1) {
            this.appService.deskIndex = result.data.deskToIndex; // đặt index hiện tại
            this.addServiceToCartDesk(this.appService.deskIndex, result.data.deskPrice, 1, false)// mặc định 1 quantity
          }
          this.toastr.success("", "Chuyển bàn/phòng thành công");
        } else {
          this.toastr.warning("", "Đã có lỗi xảy ra vui lòng thử lại, hoặc họi hỗ trợ");
        }
      }
    });

  }

  addServiceToCartDesk(deskIndex, service_product, quantity, isCheckSamePrice) {
    console.log("quantity", quantity);
    if (quantity == null || isNaN(quantity) || quantity <= 0) {
      this.toastr.error("", "Số lượng không hợp lệ");
      return
    }

    let cartService = new CartService();
    cartService.id = service_product.id;
    cartService.name = service_product.name;
    cartService.price = service_product.price;
    cartService.serviceStartTime = new Date(); // phút
    cartService.serviceStartTimeNumberType = new Date().getTime(); // phút
    cartService.quantity = 0; // phút
    // cart.quantity = parseInt(product.temp_quantity);
    // cart.quantity = parseInt(this.txt_quantity.toString());
    console.log("cart", cartService);

    if (typeof this.appService.deskList[deskIndex].cart_service === 'undefined') {
      alert("Đã có lỗi vui lòng đăng nhập lại");
    }
    if (this.appService.deskList[deskIndex].cart_service.length > 0) {
      if (isCheckSamePrice && this.utilityService.checkToAddCartService(this.appService.deskList[deskIndex].cart_service, cartService)) {
        // Chỉ kiểm tra gói giá cuối cùng
        this.toastr.warning("", "Giá dịch vụ không có gì thay đổi");
        return;
      }
      else {
        this.addCart_servicetoCart(cartService);
        // this.appService.deskList[deskIndex].cart_service.push(cartService);
      }
    } else {
      this.addCart_servicetoCart(cartService);
      // this.appService.deskList[deskIndex].cart_service.push(cartService);
    }


    this.appService.deskList[deskIndex].total_quantity = this.utilityService.toFixedIfNecessary((this.appService.deskList[deskIndex].total_quantity + cartService.quantity));
    // this.appService.deskList[deskIndex].price_totol = this.utilityService.toFixedIfNecessary((this.appService.deskList[deskIndex].price_totol + (cartService.quantity * cartService.price)));
    this.appService.deskList[deskIndex].customer_pay = this.appService.deskList[deskIndex].price_totol;

    localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
    // product.temp_quantity = 1; // reset to default

    this.toastr.success("", "Thêm vào hóa đơn thành công");
    this.appService.updateDeskRevenue();
  }
  addCart_servicetoCart(cartService) {
    //kết thúc giờ và của giói giá trước khi thêm gói giá mới.
    let length_temp = this.appService.deskList[this.appService.deskIndex].cart_service.length;
    if (length_temp > 0) {
      let time_temp = new Date();
      this.appService.deskList[this.appService.deskIndex].cart_service[length_temp - 1].serviceEndTimeNumberType = time_temp.getTime();
      this.appService.deskList[this.appService.deskIndex].cart_service[length_temp - 1].serviceEndTime = time_temp;
      this.appService.deskList[this.appService.deskIndex].cart_service[length_temp - 1].isEnable = 0;
      this.appService.deskList[this.appService.deskIndex].cart_service[length_temp - 1].isActive = 0;
    }
    // thêm mới gói giá
    this.appService.deskList[this.appService.deskIndex].cart_service.push(cartService);
  }

  opendCheckout(productpicker : any, saleinvoice: any,index) {
    // phúc vụ cho việc close
    this.productpicker = productpicker;
    this.saleinvoice = saleinvoice;
    this.appService.deskIndex = index;
    this.appService.openCheckoutDesk();
    console.log(this.appService.cartObj);
    this.productpicker.open();
    this.saleinvoice.open();
  
  }

}
