<div class="page-content fade-in-up">
    <button class="btn btn-gradient-peach btn-icon-only btn-circle " style="position: absolute;
    z-index: 5;
     right: 0px;
     top: 0px;" (click)="onCancelClick()">
        <i class="ti ti-close"></i>
    </button>
    <table>
        <tr style="vertical-align: top;">
            <td>
                <app-product-picker></app-product-picker>
            </td>
            <td class="pl-2" style="width: 500px;  
        background: white;
        /* background-image: url(./assets/img/bill-background.jpg); */
        ">
                <app-sale-invoice></app-sale-invoice>
            </td>
        </tr>
    </table>

</div>