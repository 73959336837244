import { ToastrService } from 'ngx-toastr';
import { UtilityService } from './../../../_services/utility.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Promotion } from './../../../model/promotion';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryCustomerService } from '../../../_services/category-customer.service';
import { CategoryService } from '../../../_services/category.service';
import { environment } from 'src/environments/environment';
import { PromotionService } from 'src/app/_services/promotion.service';
import { CustomerService } from 'src/app/_services/customer.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppService } from 'src/app/_services/app.service';
import { SharedService } from 'src/app/_services/shared.service';
declare var $: any;
@Component({
  selector: 'app-promotion-add',
  templateUrl: './promotion-add.component.html',
  styleUrls: ['./promotion-add.component.css']
})
export class PromotionAddComponent implements OnInit {
  // model: NgbDateStruct;
  isLoading = false;
  @ViewChild('promotionAddForm') promotionAddForm: HTMLFormElement;
  // for add
  promotion = new Promotion("%");
  fromDateSelected : string;
  toDateSelected : string;

  //DateTime
  dateStart: any;
  dateEnd: any;
  

  //khuyến mãi
  // kieuKhuyenMai = "%";
  khuyenMai = 0;


  // customer - category
  categorys_customer = [];
  categorys_customer_selectedItems = [];

  //Category
  CategoryList = [];
  CategorySelectedItems = [];
  dropdownSettings = {};




  constructor(
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    public appService: AppService,
    public categoryCustomerService: CategoryCustomerService,
    public categoryService: CategoryService,
    private toastr: ToastrService,
    public customerService: CustomerService,
    public promotionService: PromotionService,
    public sharedService: SharedService,

    private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,
    private router: Router,) {

    // this.maxDate.setDate(this.maxDate.getDate() + 7);
    // this.bsRangeValue = [this.bsValue, this.maxDate];
    this.dateStart = new Date();
    this.dateEnd = new Date();
  }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(id);

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'code',
      textField: 'name',
      selectAllText: 'Chọn tất cả',
      unSelectAllText: 'Bỏ chọn tất cả',
      // itemsShowLimit: 10,
      allowSearchFilter: true,
      searchPlaceholderText: "Tìm kiếm"
    };

    this.loadCategoryCustomer();
    this.loadCategory();
  }
  ngAfterViewInit() {
    // this._script.load('./assets/js/scripts/form-plugins.js');
  }

  SelectkieuKhuyenMai(type) {
    // this.kieuKhuyenMai = type;
    this.promotion.type = type;
    this.promotion.promotion = 0;
    this.promotion.promotion_price_range = this.appService.promotion_price_ranges[0].code;
    // console.log("type", type);
    // console.log("promotion.type", this.promotion.type);
    console.log("this", this.promotion.type);
  }
  dateChangeEvent(event: MatDatepickerInputEvent<Date>) {
    if(event.value !== null){
      // if (this.dateStart) {
      //   this.dateStrStart = this.utilityService.getFullDateStrFromDate(new Date(this.dateStart));
      // }
      // if (this.dateEnd) {
      //   this.dateStrEnd = this.utilityService.getFullDateStrFromDate(new Date(this.dateEnd));
      // }
    }
  }
  loadCategoryCustomer() {
  
      let filter = {
        type: environment.customer_type
      };
  
      this.categoryService.loadAllCategory(filter).subscribe(
        async response => {
          if (response) {
            this.categorys_customer = response;
          }
        },
        error => {
  
          console.error(error);
        })
  }
  loadCategory() {
    let filter = {
      type: environment.cate_type
    };

    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        if (response) {
          this.CategoryList = response;
        }
      },
      error => {

        console.error(error);
      })
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  // onDateSelect($event) {

  // }


  insertPromotion() {
    let hasError = false;
    console.log(this.dateStart);
    console.log(this.dateEnd);
    if (this.promotion.name.trim() == "") {
      this.toastr.warning("Vui lòng nhập tên chương trình","Cảnh báo");
      hasError = true;
    }
    if (this.promotion.code.trim() == "") {
      this.toastr.warning("Vui lòng nhập code chương trình","Cảnh báo");
      hasError = true;
    }
    if (this.dateStart== null || this.dateEnd == null) {
      this.toastr.warning("Vui lòng nhập thời gian áp dụng","Cảnh báo");
      hasError = true;
    }
    if(hasError){
      return;
    }

    this.promotion.time_start = this.utilityService.getFullDateStrFromDate(this.dateStart);
    this.promotion.time_end = this.utilityService.getFullDateStrFromDate(this.dateEnd);
    
    var names = this.CategorySelectedItems.map(function(item) {
      return item['code'];
    });
    var namesss = this.categorys_customer_selectedItems.map(function(item) {
      return item['code'];
    });

    this.promotion.cate_join = names.toString();
    this.promotion.cateCustomer_join = namesss.toString();
   
    if (!this.promotionAddForm.invalid) {
      this.isLoading = true;
      this.promotionService.insertPromotion(this.promotion).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.info("Tạo chương trình khuyến mãi","Thành công");
            // this.appService.loadPromotion();
            this.sharedService.sendoadPromotionEvent("");// load promotion from header component
            
            this.backToList();
          }
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
    }
  }

  
  backToList(){
    this.router.navigate(['/promotion', {  }]);
  }


  focusOutCheckCode(){
    let filter = {
      code: this.promotion.code,
    }
    console.log(filter);
    this.promotionService.checkCode(filter).subscribe(
      response => {
        if (response.result > 0) {
          console.log(response);
          this.promotionAddForm.controls.code.setErrors("Đã tồn tại code");
          this.toastr.error("", "Đã tồn tại code");
        }

        console.log(response);
      },
      error => {
        console.error(error);
      })
  }
}
