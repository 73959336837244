import { Router } from '@angular/router';
import { UtilityService } from './../../_services/utility.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ProductService } from '../../_services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Cart } from '../../model/cart';
import { HostListener } from '@angular/core';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/model/product';
import { SharedService } from 'src/app/_services/shared.service';
import { PromotionService } from 'src/app/_services/promotion.service';

declare var $: any;

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.css']
})
export class SaleComponent implements OnInit {
  addProductToCartEvent: Subscription;
  clickEvent: Subscription;
  loading = false;
  display_type = 0;
  //for search and scanner
  searchValue = "";
  productCodeTemp = "";
  // searchStr: string;
  searchFailed = false;

  //for promotions;
  promotions = [];

  //for paging
  viewNumsObj = this.appService.viewNums[4].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;
  categorys_brand: any
  // size screen
  itemAreaSize = 10;
  constructor(
    public appService: AppService,
    private router: Router,
    public productService: ProductService,
    public categoryService: CategoryService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private sharedService: SharedService,
    public promotionService: PromotionService,

  ) {


    this.sorter.sortStr = this.appService.sort_products[0].code;
    this.productSelected = this.product;
    if (localStorage.getItem(environment.display_type)) {
      this.display_type = parseInt(localStorage.getItem(environment.display_type));
    }

    // if(this.appService.products.length == 0){
    this.loadProduct();
    this.loadCategoryBrand();
    this.loadCategory();


    setTimeout(()=>{this.loadProductForScan();},2500);
    
    



  }

  ngOnInit() {
    this.addProductToCartEvent = this.sharedService.getAddProductToCartEvent().subscribe((data) => {
      console.log("Call from other component:",data);
      console.log("Call from other component:",{'item': data.item, 'quantity': data.quantity});
      this.addToCart(data.item,data.quantity);
    },);

     this.clickEvent = this.sharedService.getClickEvent().subscribe((type) => {
      console.log("Sale component:",type);
      this.loadProduct();
    });

  }

  ngOnDestroy() {
    if (this.clickEvent) {
      this.clickEvent.unsubscribe();
    }
    if (this.addProductToCartEvent) {
      this.addProductToCartEvent.unsubscribe();
    }
  }

  ngAfterViewInit() {

  }
  clearSearchInput(){
    if(this.filter.name !=''){
      this.filter.name='';
      this.loadProduct();
    }
  }
  // @HostListener('document:keypress', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   // event.preventDefault();
  //   console.log('Sale document:keypress',event.key);

  
  //   if (event.key === 'Enter') {
  //     console.log(this.productCodeTemp);
  //     if (this.productCodeTemp.trim().length > 3) {
  //       this.searchValue = this.productCodeTemp;
  //       this.productCodeTemp = "";
  //       console.log(this.searchValue);
  //       // this.popupAddToCart(this.utilityService.searchProduct(this.searchValue));
  //       let product_temp = this.utilityService.searchProduct(this.searchValue);
  //       if (product_temp) {
  //         this.productSelected = product_temp;
  //         this.addToCart(this.productSelected, this.txt_quantity);
  //         this.appService.openCheckout();
  //       }else{
  //         this.toastr.warning("", "Không tìm thấy mã sản phẩm");
  //       }
  //     }
  //   } else {
  //     if (this.productCodeTemp === null || this.productCodeTemp === "") {
  //       this.productCodeTemp = event.key;
  //     } else {
  //       this.productCodeTemp = this.productCodeTemp + event.key;
  //     }
  //   }
  // }

  // products = [];
  products_backup: any
  categories: any
  filter = {
    code: "",
    name: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  sorter = {
    sortStr: "",
  }

  // for add product
  product = new Product("");
  // for update product
  productSelected: any;
  isDeleted: boolean;

  //for cart
  productCart = {
    name: "",
    code: "",
    price: 0,
    price_in: 0,
    description: "",
  };
  txt_quantity = 1;







  resetProduct() {
    let temp_unit = "";
    if(this.appService.units.length > 0)
    temp_unit = this.appService.units[0].code;

    this.product = new Product(temp_unit);
  }
  loadProduct() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.appService.products = response.data;
            console.log(this.appService.products);
        
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.appService.products.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

          }
        },
        error => {

        })
    }
  }
  loadProductForScan() {
    let pagingObj = {
      viewNumsObj: 2000,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.productService.loadProduct(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.appService.products_backup = response.data;

            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.appService.products.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

          }
        },
        error => {

        })
    }
  }
  setUnit_default(code,unit_default){

    let data ={
      unit_default: unit_default
    }
    this.productService.updateSomeInfo(code,data).subscribe(
      async response => {
        console.log(response);
        if (response.code == "ok") {
          this.toastr.success("", "Cập nhật đơn vị mặc định thành công");
          this.loadProduct();
        }
      },
      error => {
        console.error(error);
      })
  }
  loadTotalPage() {
    // request to server
    this.productService.loadItemSum(this.filter)
      .subscribe(
        response => {
          console.log('ItemListComponent load Item');
          console.log(response);
          this.totalItems = response[0].NumberOfItems;
          let pages_temp = Math.ceil(this.totalItems / this.appService.viewNumsObj);
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          console.log(pages_temp);
          console.log(this.listPage);
        },
        error => {
          console.error(error);
        });
  };

  async loadCategory() {

    let filter = {
      type: environment.cate_type
    };

    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.categories = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })

  }
  popup_product_add(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-product-modal").modal('show');
  }


  popup_product_import(event) {
    this.resetProduct();
    this.countAllProduct();
    $("#add-customer-modal").modal('show');
  }



  category_change(event) {
    this.loadProduct();
  }
  sort_change(event) {
    this.loadProduct();
  }

  doSorting(item){
    if(item == 'name'){
      this.sorter.sortStr = this.sorter.sortStr == "name ASC" ? "name DESC": 'name ASC' ;
      console.log(this.sorter.sortStr);
    }else if(item == 'stock'){
      this.sorter.sortStr = this.sorter.sortStr == "stock ASC" ? "stock DESC": 'stock ASC' ;
      console.log(this.sorter.sortStr);
    }else if(item == 'price'){
      this.sorter.sortStr = this.sorter.sortStr == "price ASC" ? "price DESC": 'price ASC' ;
      console.log(this.sorter.sortStr);
    }
    this.loadProduct();
  }
  
  // getTypingSearch(ev: any) {

  //   // Reset items back to all of the items
  //   this.appService.products = this.appService.products_backup;

  //   // set val to the value of the searchbar
  //   const val = ev.target.value.trim();

  //   // if the value is an empty string don't filter the items
  //   if (val && val.length > 2) {
  //     this.appService.products = this.appService.products.filter((item) => {
  //       return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  //     })
  //   }
  // }
 
  countAllProduct() {

    this.productService.countAllProduct().subscribe(
      response => {
        this.product.code = this.appService.currentUser.shop_code + (parseInt(response[0].CountAllProduct) + 1).toString();
      },
      error => {
        console.error(error);
      })

  }

  popupAddToCart(item) {
    if (item) {
      this.txt_quantity = 1;
      this.productSelected = item;

      if (!$('#add-to-cart-modal').hasClass('show')) {
        $("#add-to-cart-modal").modal('show');
      }
    }
  }

  quantity_add() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity)) {
      this.txt_quantity = ++this.txt_quantity;
    }
  }
  quantity_sub() {
    console.log(!isNaN(this.txt_quantity));
    if (!isNaN(this.txt_quantity) && this.txt_quantity > 1) {
      this.txt_quantity = --this.txt_quantity;
    }
  }
  quantity_add2(item) {
    if (!isNaN(item.temp_quantity)) {
      item.temp_quantity = ++item.temp_quantity;
    }
  }
  
  quantity_sub2(item) {
    if (!isNaN(item.temp_quantity) && item.temp_quantity > 1) {
      item.temp_quantity = --item.temp_quantity;
    }
  }
  focusFunction(event) {
    event.target.select();
  }
  addToCart(product,quantity) {
    
    //Quan trọng ____________ xử lý giá cho loại giá đơn vị 2

    if(product.packing == null || product.packing ==0) product.packing = 1;
    // product.price_unit = product.price;
    product.price_unit2 = this.utilityService.mathRound(product.price_temp / product.packing);
    // product.price2_unit = product.price2;
    if(product.price2 == 0 || product.price2_temp == 0){
      product.price2 = product.price;
      product.price2_temp = product.price;
    }
    else
    product.price2_unit2 = this.utilityService.mathRound(product.price2_temp / product.packing);




    if(product.unit2 !='' && product.unit2==product.unit_default){ // nếu là unit2 thì tính giá unit2
      product.price = product.price_unit2;
      // product.unit = product.unit_default;
    }
    //Quan trọng ____________ xử lý giá cho loại giá đơn vị 2

    console.log(this.appService.listCart);
    console.log(this.appService.cartIndex);
    console.log("--------------------------------------------", product);
    
    let cart = new Cart();
    cart.product = Object.assign({}, product); // copy product không tham chiếu

    cart.unit_type = product.unit2==product.unit_default ? "unit2" : 'unit';
    // cart.quantity = this.txt_quantity;
    cart.quantity = quantity;
    //kiểm tra trong giỏ đã có sp chưa, nếu chưa thì thêm nếu có thì update
    if (this.appService.listCart[this.appService.cartIndex].cart.length > 0) {
      if (this.utilityService.checkToAddCart(this.appService.listCart[this.appService.cartIndex].cart, cart) > -1) {
        // nếu có thì thêm luôn ở trong hàm check
      }
      else {
        this.appService.listCart[this.appService.cartIndex].cart.push(cart);
      }
    } else {
      this.appService.listCart[this.appService.cartIndex].cart.push(cart);
    }

    // this.appService.listCart[this.appService.cartIndex].total_quantity = this.utilityService.toFixedIfNecessary((this.appService.listCart[this.appService.cartIndex].total_quantity + cart.quantity));
    // this.appService.listCart[this.appService.cartIndex].price_totol = this.utilityService.toFixedIfNecessary((this.appService.listCart[this.appService.cartIndex].price_totol + (cart.quantity * product.price)));
    // this.appService.listCart[this.appService.cartIndex].customer_pay = this.appService.listCart[this.appService.cartIndex].price_totol;
    console.log(this.appService.cartObj.cart);
    this.appService.prepareSaleObject({sale_status:99,isPromotion:false,isPromotionDetail:false, isCheckCustomerPay:false}); //99 chi de cap nhatprice2

    localStorage.setItem(environment.listCart, JSON.stringify(this.appService.listCart));
    product.temp_quantity = 1; // reset to default
    $("#add-to-cart-modal").modal('hide');
    this.toastr.remove;
    this.toastr.success("", "Thêm vào giỏ hàng thành công");
  }

  openCheckout(item, quantity) {
    this.addToCart(item, quantity);
    this.appService.openCheckout();
  }
  openCheckout2(item, quantity ) {
    this.addToCart(item, quantity);
    this.appService.prepareSaleObject({ sale_status: 99 ,isPromotion:true,isPromotionDetail:true, isCheckCustomerPay:false})
    this.appService.openCheckout();
    item.temp_quantity = 1;
  }

  popupPayment(item) {
    console.log("xacd");
  }
  changeViewType(type) {
    this.display_type = type;
    localStorage.setItem(environment.display_type, this.display_type.toString());
  }


  //for paging
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.appService.viewNumsObj);
    this.loadProduct();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadProduct();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadProduct();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadProduct();
    }
  };






  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        // this.filter.code = this.filter.name;
        console.log(this.filter.name);
        this.loadProduct();
      }),
      map(term => term === '' ? []
        : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => {

      }),
    )
    datas = ["d","sd"];
  onClick(r) {
    console.log(r);
    this.filter.name = "";
    this.addToCart(r, 1)
    // this.popupAddToCart(r);
  }
  
  // sự kiện từ component catelist
  onClick_selectCate(event){

    if(typeof event.category_code !== "undefined"){
      this.filter.category_code = event.category_code
      console.log("category_code:",this.filter.category_code);
      this.loadProduct();
    }
    
  }

  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.product_avatar + ')';
  // }
  statesWithFlags: { name: string, flag: string }[] = [
    { 'name': 'Alabama', 'flag': '5/5c/Flag_of_Alabama.svg/45px-Flag_of_Alabama.svg.png' },
    { 'name': 'Alaska', 'flag': 'e/e6/Flag_of_Alaska.svg/43px-Flag_of_Alaska.svg.png' },
  ];

  loadCategoryBrand() {
    let filter = {
      type: environment.brand_type
    };
    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.categorys_brand = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  mouseover(item){
    console.log("mouseover",item)
  }
  mouseout(item){
    console.log("mouseout",item)
  }

  

}

