<style>
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url("./assets/img/gb-blue.jpg");
    }
    
    .login-content {
        max-width: 900px;
        margin: 100px auto 50px;
    }
    
    .auth-head-icon {
        position: relative;
        height: 60px;
        width: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        background-color: #fff;
        color: #5c6bc0;
        box-shadow: 0 5px 20px #d6dee4;
        border-radius: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }
    
    body.empty-layout .theme-config,
    body.empty-layout .to-top {
        display: none !important;
    }
</style>

<div class="overlay"></div>
<div class="row login-content">
    <div class="col-sm-6 bg-white">
        <div class="text-center">
            <span class="auth-head-icon"><i class="ti ti-user"></i></span>
        </div>
        <div class="ibox m-0" style="box-shadow: none;">
            <form class="ibox-body" id="loginForm">
                <h3 class="font-strong text-center mb-5">Đăng nhập</h3>


                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Mã shop</mat-label>
                    <input matInput placeholder="Mã shop" type="text" [(ngModel)]="user.shop_code" required (ngModelChange)="user.shop_code = $event.toUpperCase()" (keydown.space)="$event.preventDefault();" name="shop_code">
                </mat-form-field>

                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Tài khoản</mat-label>
                    <input matInput placeholder="Phone/Email" type="text" [(ngModel)]="user.phone" required  name="phone">
                </mat-form-field>


                <mat-form-field appearance="fill" class="qbh-mat-lg">
                    <mat-label>Mật khẩu</mat-label>
                    <input matInput placeholder="Mật khẩu" [type]="hide ? 'password' : 'text'" [(ngModel)]="user.password" required name="password">
                    <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </a>
                </mat-form-field>



                <div class="flexbox mb-5 mt-2">
                    <label class="checkbox checkbox-inline checkbox-primary">
                        <input type="checkbox" checked="true">
                        <span class="input-span"></span>Nhớ đăng nhập</label>

                    <a class="text-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Gọi hotline 0896 888 102">Quên mật khẩu?</a>
                </div>
                <div class="text-center">
                    <button (click)="login()" class="btn btn-primary  btn-air" [disabled]="isLoading">
                        <span class="btn-label" [ngClass]="{'spinner-border': isLoading}"><i
                            class="ti ti-key"></i></span>
                        Đăng nhập
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="col-sm-6 d-inline-flex align-items-center text-white py-4 px-5">
        <div>
            <div class="h2 mb-4">Phần mềm quản lý bán hàng QBSs</div>
            <div class="mb-4 pt-3">
                <button class="btn btn-outline btn-icon-only btn-circle mr-3"><a href="https://www.facebook.com/giaiphapsoqbs"><i class="ti ti-facebook"></i></a></button>
                <!-- <button class="btn btn-outline btn-icon-only btn-circle mr-3"><a href="https://thietbisoqb.com"><i class="ti ti-facebook"></i></a></button>  -->
                <!-- <button class="btn btn-outline btn-icon-only btn-circle mr-3"><i class="fa fa-pinterest-p"></i></button> -->
            </div>
            <p>Phát triển dựa theo nhu cầu của khách hàng.<br>Công cụ sáng tạo và quản lý bán hàng</p>
            <p>CTY TNHH Giải pháp Số QBS</p>
            <!-- <div class="flexbox-b mb-3"><i class="ti-check mr-3 text-success"></i>Lorem Ipsum dolar set.</div> -->
            <!-- <div class="flexbox-b mb-3"><i class="ti-check mr-3 text-success"></i>Lorem Ipsum dolar set.</div> -->
            <!-- <div class="flexbox-b mb-5"><i class="ti-check mr-3 text-success"></i>Lorem Ipsum dolar set.</div> -->
            <!-- <button class="btn btn-outline btn-rounded btn-fix">Register</button> -->
        </div>
    </div>
</div>