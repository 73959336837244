<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col-sm-4 pl-0 pr-0 result-search-cover d-flex align-items-center">
            <div class="mobile-mb-2 input-group-icon input-group-icon-left input-search-cover" style="width: 100%;">
                <span *ngIf="searching" class="input-icon input-icon-right font-16"><i class="ti ti-reload"></i></span>
                <span *ngIf="!searching" class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
                <input id="typeahead-templates" class="form-control" [(ngModel)]="filter.name" [ngbTypeahead]="search" type="text" placeholder="Tìm hàng hóa" [resultTemplate]="rt" [inputFormatter]="formatter" (focus)="focusFunction($event)">
                <a (click)="clearSearchInput()" class="input-close input-icon input-icon-right font-16"><span class="material-icons">
                    cancel
                    </span></a>
            </div>
            <ng-template #rt let-r="result" let-t="term" class="">
                <div class="d-flex align-content-center justify-content-between" (click)="onClick(r)">
                    <div class="d-flex align-content-start align-items-center">
                        <div class="img-product-search mr-1" [style.background-image]="this.appService.showImageProduct(r['image_url'])">
                        </div>
                        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                        <div class="mr-1 ml-1" style="font-size: smaller"> #{{ r['code'] }}</div>

                    </div>
                    <div style="font-weight: bold; width: 100px; text-align: right;">
                        <img style="width:15px; margin-bottom: 2px;" src="../assets/img/icons/vnd.svg"> {{ (r['price'] | qbscurrency )}}
                    </div>
                </div>
            </ng-template>
        </div>
        <!-- <div class="col">
      <div class=" p-2 mobile-mb-2 input-group-icon input-group-icon-left mr-3">
        <span class="input-icon input-icon-right font-16"><i class="ti ti-search"></i></span>
        <input class="form-control form-control-rounded " [(ngModel)]="searchValue" name="key-search" id="key-search" (keydown)="getTypingSearch($event)"
          type="text" placeholder="Tìm hàng hóa">
      </div>
    </div> -->
        <div class="col pr-0">
            <div class="d-flex justify-content-end">
                <!-- <div class="p-2">
          <select class="custom-select" [(ngModel)]="filter.category_code" (change)="category_change($event)">
            <option value="">Danh mục</option>
            <option [ngValue]="item.code" *ngFor="let item of categories">{{item.name}}</option>
          </select>
        </div>
        <div class="p-2">
          <select class="custom-select" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)">
            <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}</option>
          </select>
        </div> -->
                <div class="pr-2">
                    <select class="custom-select" [(ngModel)]="filter.category_code" (change)="category_change($event)" name="category" id="category">
                        <option value="">Danh mục</option>
                        <option [ngValue]="item.code" *ngFor="let item of categories">{{item.name}}</option>
                    </select>
                </div>
                <div class="pr-2">
                    <select class="custom-select" [(ngModel)]="filter.category_code_brand" (change)="category_change($event)" name="category" id="category">
                        <option value="">Thương hiệu</option>
                        <option [ngValue]="item.code" *ngFor="let item of categorys_brand">{{item.name}}</option>
                    </select>
                </div>
                <div class="pr-2">
                    <select style="width: 100px;" class="custom-select" data-width="200px" [(ngModel)]="sorter.sortStr" (change)="sort_change($event)" name="sorter" id="sorter">
                        <option [ngValue]="item.code" *ngFor="let item of appService.sort_products">{{item.name}}
                        </option>
                    </select>
                </div>
                <div class="pr-0">
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-icon" ngbDropdownToggle>
                            <span class="btn-label">
                                <i *ngIf="display_type ==0" class="ti ti-layout-grid2"></i>
                                <i *ngIf="display_type ==1" class="ti ti-align-justify"></i>
                            </span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="changeViewType(0)">
                                <i class="ti ti-layout-grid2"></i>
                                Dạng lưới
                            </button>
                            <button ngbDropdownItem (click)="changeViewType(1)">
                                <i class="ti ti-align-justify"></i>
                                Dạng danh sách
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-categorylist class="desktop" [datas]="this.categories" [selected]="this.filter.category_code" (click)="onClick_selectCate($event)"></app-categorylist>
    <!-- {{itemAreaSize}} -->
    <div class="row align-items-center mb-2 paging-cover">
        <div class="col-4 p-0">
            <span class="mobile-sm">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 p-0 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="custom-select" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="display_type ==1" class="table-responsive">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px;">#</th>
                    <th class="sorting" (click)="doSorting('name')">Tên
                        <span>
                            <span *ngIf="sorter.sortStr != 'name ASC' && sorter.sortStr != 'name DESC'" class="material-icons sort">sort</span>
                        <span *ngIf="sorter.sortStr == 'name ASC'" class="material-icons">north</span>
                        <span *ngIf="sorter.sortStr == 'name DESC'" class="material-icons">south</span>
                        </span>
                    </th>
                    <th>Danh mục</th>
                    <th class="text-right sorting" (click)="doSorting('stock')">Tồn kho
                        <span>
                            <span *ngIf="sorter.sortStr != 'stock ASC' && sorter.sortStr != 'stock DESC'" class="material-icons sort">sort</span>
                        <span *ngIf="sorter.sortStr == 'stock ASC'" class="material-icons">north</span>
                        <span *ngIf="sorter.sortStr == 'stock DESC'" class="material-icons">south</span>
                        </span>
                    </th>
                    <th>Đơn vị</th>
                    <!-- <th>Quy cách</th> -->
                    <th class="text-right sorting" (click)="doSorting('price')">Giá
                        <span>
                            <span *ngIf="sorter.sortStr != 'price ASC' && sorter.sortStr != 'price DESC'" class="material-icons sort">sort</span>
                        <span *ngIf="sorter.sortStr == 'price ASC'" class="material-icons">north</span>
                        <span *ngIf="sorter.sortStr == 'price DESC'" class="material-icons">south</span>
                        </span>
                    </th>
                    <th style="text-align: center;">Thao tác</th>
                    <!-- <th class="text-right">Giá 2</th> -->
                    <!-- <th class="no-sort"></th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of appService.products; let i = index" class="h-row-item row-display-type-1">
                    <td>
                        <!-- <a class="media-img" href="javascript:;">
              <img class="img-circle" src="./assets/img/icons/user.svg" alt="image" width="30" />
            </a> -->
                        <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageProduct(item.image_url)"></a>
                    </td>
                    <td (click)="popupAddToCart(item)">
                        <div style="font-size: 18px;"> {{item.name}}
                            <!-- <i *ngIf="item.stock < 1" class="la la-question-circle font-18 text-danger"
                title="Hết hàng trong kho"></i> -->
                            <div [ngClass]="{'text-danger': item.stock < 1}" style="font-size: 13px; font-weight: bold;">
                                {{item.code}}
                            </div>
                        </div>
                    </td>
                    <td>{{item.category_name}}</td>
                    <td class="text-right"><span [ngClass]="{'text-danger': item.stock < 1}">{{item.stock}}</span></td>
                    <!-- <td>{{item.unit}}</td> -->
                    <td>
                        <!-- {{item.packing +" "+item.unit2}} -->
                        <div class="btn-group btn-group-sm">
                            <div *ngIf="item.unit!=''" (click)="setUnit_default(item.code,item.unit)" class="btn btn-info p-1" [ngClass]="{'btn-info': item.unit_default =='' || item.unit==item.unit_default}" style="line-height: 10px;">{{item.unit}}</div>
                            <div *ngIf="item.unit2!=''" (click)="setUnit_default(item.code,item.unit2)" class="btn btn-secondary p-1" [ngClass]="{'btn-info': item.unit2==item.unit_default}" style="line-height: 10px;">{{item.unit2}}</div>
                        </div>
                    </td>
                    <td class="text-right">{{item.price| qbscurrency:0}}</td>
                    <!-- <td class="text-right">{{item.price2| qbscurrency:0}}</td> -->
                    <td>
                        <div class="enterQuantiti-temp-2 d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div style="display: inline-block;">
                                    <button class="btn  btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_sub2(item)"><i class="ti ti-minus"></i></button>
                                </div>
                                <div style="display: inline-block; margin: 0 5px;">
                                    <input [(ngModel)]="item.temp_quantity" type="number" (focus)="focusFunction($event)" decimals="2" class="form-control" style="font-size: 18px; padding: 0px; height: 29px; text-align: center; width: 40px;" />
                                </div>
                                <div style="display: inline-block;"><button class="btn  btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_add2(item)"><i class="ti ti-plus"></i></button></div>
                            </div>

                            <div>
                                <div style="display: inline-block;">
                                    <button class="btn  btn-primary btn-icon" (click)="openCheckout2(item, item.temp_quantity)">
                                        <span class="material-icons font-17">
                                            paid
                                        </span>
                                    </button>
                                </div>
                                <div style="display: inline-block;" class="ml-4">
                                    <button class="btn  btn-primary btn-icon" (click)="addToCart(item, item.temp_quantity)">
                                        <span class="material-icons font-17">
                                            local_mall
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>

                </tr>

            </tbody>
        </table>
    </div>
    <div *ngIf="display_type ==0" class="display_type_0">
        <div class="d-flex flex-wrap mb-2">
            <!-- <div class="mb-3 mr-4  cart-item-gird" *ngFor="let item of appService.products; let i = index"
        (click)="popupAddToCart(item)"> -->
            <div class="mb-2 cart-item-gird" [style.width.px]="appService.itemCartWidth_sale" style="margin-right: 6px;" *ngFor="let item of appService.products; let i = index">
                <div class="enterQuantiti-temp">
                    <div class="d-flex align-items-center">
                        <div style="display: inline-block;">
                            <button class="btn  btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_sub2(item)"><i class="ti ti-minus"></i></button>
                        </div>
                        <div style="display: inline-block; margin: 0 4px;">
                            <input [(ngModel)]="item.temp_quantity" type="number" (focus)="focusFunction($event)" (keyup.enter)="addToCart(item, item.temp_quantity)" class="input-quantity" style="font-size: 18px; padding: 0px; height: 30px; text-align: center; width: 50px;" />
                        </div>
                        <div style="display: inline-block;"><button class="btn  btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_add2(item)"><i class="ti ti-plus"></i></button></div>
                    </div>
                    <div class="mt-2">
                        <div style="display: inline-block;">
                            <button class="btn  btn-primary btn-icon" (click)="openCheckout2(item, item.temp_quantity)">
                                <span class="material-icons ">
                                    paid
                                </span>
                            </button>
                        </div>
                        <div style="display: inline-block;" class="ml-3">
                            <button class="btn  btn-primary btn-icon" (click)="addToCart(item, item.temp_quantity)">
                                <span class="material-icons ">
                                    add_shopping_cart
                                    </span>
                                    <!-- <img class="img-circle" src="./assets/img/icons/add_cart.svg" alt="image" width="30" /> -->
                            </button>
                        </div>
                    </div>
                    <div class="mt-1">
                        <div style=" white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; ">{{item.name}}</div>
                    </div>
                </div>
                <div class="rel img-product2" [style.background-image]="this.appService.showImageProduct(item.image_url)">
                    <span class=".card-img-overlay">
                        <span *ngIf="item.stock < 1" class="ml-1 badge-danger badge-point"></span>
                    </span>
                </div>
                <div class="card-body">
                    <!--  (click)="popupAddToCart(item)" -->
                    <div style=" white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; ">{{item.name}}</div>
                    <div class="d-flex align-items-center justify-content-between mt-1">
                        <div class="text-center">
                            <div style="font-weight: bold;" [ngClass]="{'text-danger': item.stock < 1}">
                                <span>{{item.stock}}</span>
                            </div>
                        </div>
                        <div class="text-center">
                            <div *ngIf="item.unit_default =='' || item.unit==item.unit_default" style="font-weight: bold;">{{item.price | qbscurrency}}</div>
                            <div *ngIf="item.unit2 !='' && item.unit2==item.unit_default" style="font-weight: bold;">{{ utilityService.mathRound(item.price_temp / item.packing) | qbscurrency}}</div>

                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mt-1">
                        <div class="text-center">

                            <div class="text-muted font-11">
                                <div class="btn-group btn-group-sm">
                                    <div *ngIf="item.unit!=''" (click)="setUnit_default(item.code,item.unit)" class="btn btn-info p-1" [ngClass]="{'btn-info': item.unit_default =='' || item.unit==item.unit_default}" style="line-height: 10px;">{{item.unit}}</div>
                                    <div *ngIf="item.unit2!=''" (click)="setUnit_default(item.code,item.unit2)" class="btn btn-secondary p-1" [ngClass]="{'btn-info': item.unit2==item.unit_default}" style="line-height: 10px;">{{item.unit2}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="text-muted font-11">VNĐ</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<div class="modal fade" id="add-to-cart-modal">
    <div class="modal-dialog" role="document">
        <div class="modal-content form-horizontal" id="form-add-product">
            <div class="modal-header p-4">
                <h5 class="modal-title">Chọn hàng hóa <img style="width: 20px;" src="./assets/img/icons/add_cart.svg" alt="image" /></h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4 mb-3">
                <div style="text-align: center; padding: 25px 0px;">
                    <h2 style="font-weight: bold;">{{productSelected.name}}</h2>
                    <h4>{{productSelected.price | qbscurrency}} x {{txt_quantity}} = {{txt_quantity * productSelected.price | qbscurrency}}
                    </h4>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-end">
                        <button class="btn btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_sub()"><i class="ti ti-minus"></i></button>
                    </div>
                    <div class="col">
                        <input [(ngModel)]="txt_quantity" type="number" decimals="2" class="form-control" style="font-size: 24px; padding: 4px; text-align: center;" />
                    </div>
                    <div class="col ">
                        <button class="btn btn-outline-primary btn-icon-only btn-circle btn-thick" (click)="quantity_add()"><i class="ti ti-plus"></i></button>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-between bg-primary-50">
                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="openCheckout(this.productSelected, txt_quantity)">
                    <span class="btn-label"><i class="ti ti-money"></i></span>Thanh toán
                </button>
                <button class="btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="addToCart(this.productSelected, txt_quantity)">
                    <span class="btn-label"><i class="ti ti-shopping-cart"></i></span>Thêm vào hóa đơn
                </button>

            </div>
        </div>
    </div>
</div>