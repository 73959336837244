<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <div class="form-group" style="width: 205px;">
                <div class="input-group">
                    <input class="form-control" placeholder="dd/mm/yyyy" name="dp" [(ngModel)]="dateStrSelected" ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelect($event)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary ti ti-calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="d-flex justify-content-end">
                <select class="custom-select" [(ngModel)]="userNvbhSelected" (change)="userSelected_change()">
            <option value="">Nhân viên : All</option>
            <option [ngValue]="item.id" *ngFor="let item of userNvbhList">{{item.name}}</option>
          </select>

                <select class="custom-select" style="width: 115px;" [(ngModel)]="day_num" (ngModelChange)="day_change()" name="day_num" id="day_num">
            <option [ngValue]="item.code" *ngFor="let item of appService.day_nums">{{item.name}}</option>
          </select>
            </div>
        </div>
    </div>
    <!-- <div class="row mb-2 mt-2 justify-content-between">
    <div class="col d-flex justify-content-center">
      <button class="btn btn-primary btn-labeled " data-toggle="modal"
        data-target="#add-customer-modal" (click)="check()">
        Xem
      </button>
    </div>
  </div> -->

    <div class="row justify-content-between" style="margin: 20px 0px 12px 0px; font-size: 17px; font-weight: bold;">
        <div>
            <span style="background: #fda1b5;"><span color="primary">Doanh thu</span> : {{sum_prices | qbscurrency}} </span>
            <span class="ml-4" style="    background: #bedff7;"><span color="primary">Thanh toán</span> : {{sum_payment | qbscurrency}}</span>
        </div>

        <div><span color="primary">Công nợ</span> : {{sum_congno | qbscurrency}}</div>
    </div>

    <div>
        <div style="display: block; height: 400px;">
            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
      </canvas>
        </div>
    </div>
    <!-- <div><ngx-charts-bar-vertical
        [view]="view"
        [scheme]="colorScheme"
        [results]="single"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical></div> -->
</div>