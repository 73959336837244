import { Router } from '@angular/router';
import { UtilityService } from './../../_services/utility.service';
import { SaleService } from './../../_services/sale.service';
import { AppService } from './../../_services/app.service';
import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DebtService } from 'src/app/_services/debt.service';
import { MatDialog } from '@angular/material/dialog';
import { DebtCustomerDetailComponent } from 'src/app/components/debt-customer-detail/debt-customer-detail.component';
declare var $: any;
@Component({
  selector: 'app-debt-customer',
  templateUrl: './debt-customer.component.html',
  styleUrls: ['./debt-customer.component.css']
})
export class DebtCustomerComponent implements OnInit {

  //filter status
  selectedStatusSale = environment.SALE_STATUS_DONE;
  dateStrStart = "01/01/2020";
  dateStrEnd: any ="";
  userNvbhSelected = "";
  display_type = 0;



    
  //for debt
  debtItem ={
    data : [],
    debtTotal : 0,
    totalItems : 0,
    totalPage : 0,
  };



  ////////////////////////////////////
  cartDetail: any;
  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  //for paging
  viewNumsObj = this.appService.viewNums[0].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
 
  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }


  constructor(
    private router: Router,
    public appService: AppService,
    public utilityService: UtilityService,
    public debtService: DebtService,
    private calendar: NgbCalendar,
    public dialog: MatDialog,
    public formatter: NgbDateParserFormatter
  ) {

    this.fromDate = calendar.getToday();
    this.dateStrEnd = this.utilityService.getFullDateStrFromNgbDate(this.fromDate);

  }

  ngOnInit() {
    this.loadDebts();
  }
  loadDebts() {
    let filter = {
      "type": environment.SALES_TYPE,
      "status": this.selectedStatusSale,
      "day_num": 0, // số ngày bao cáo, 0 : tất cả
      "fromDate": this.dateStrStart,
      "toDate": this.dateStrEnd,
      "code": this.code_filter,
      "user_id": this.userNvbhSelected,
    };
    let pagingObj = {
      "viewNumsObj": this.viewNumsObj,
      "pageObj": this.curentPage
    };

    let data = {"filter": filter, "sorter": this.sorter, "pagingObj": pagingObj};

    this.debtService.getDebtReport(data).subscribe(
      async response => {
        if (response) {
          this.debtItem = response
        }
      },
      error => {
        console.error(error);
      });

  }
  onClick_selectDate(dateObj) {
    this.dateStrStart = dateObj.dateStrStart;
    this.dateStrEnd = dateObj.dateStrEnd;
    this.loadDebts();
  }
  openDebtCustomerDetail(item, index) {
    
    // Gửi kèm ngày tháng
    item.dateStrStart = this.dateStrStart;
    item.dateStrEnd = this.dateStrEnd;
    const dialogRef = this.dialog.open(DebtCustomerDetailComponent, {
      width: '100%',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {

      if (typeof result != "undefined" && result.code == "ok") {
        console.log("Đã có thanh toán nợ", result.data);
        this.loadDebts();
      }

    });

  }
  

  
}



